import { useTranslation } from "react-i18next";
import * as routes from "constants/routes";
import styles from "pages/register/RegisterPage.module.scss";
import { Checkbox, Form, Input } from "components/forms/forms";
import { FormToJson } from "api/apiUtils";
import { FormEvent, useEffect, useState } from "react";
import Button, { ButtonPosition } from "components/buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { useSignupMutation } from "api/manualApi";
import { SignUpRequest } from "types/api";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import { InputError } from "api/api";

function TermsLink() {
  const { t: tu } = useTranslation("user");

  const termsLink = "https://www.eden.space/legal-notice";
  return (
    <span>
      {tu("I agree to the")}{" "}
      <a target={"_blank"} rel={"noreferrer"} href={termsLink}>
        {tu("terms and conditions")}
      </a>
    </span>
  );
}

function SignUpPage() {
  const navigate = useNavigate();
  const { t: tu } = useTranslation("user");
  const { t: tc } = useTranslation("company");
  const { t: tv } = useTranslation("validation");
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();

  const [signUp, { error, isLoading, isSuccess }] = useSignupMutation();

  useEffect(() => {
    const err = getErrorResponse(error);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      // redirect to dashboard
      navigate(routes.ROOT);
    }
  }, [isSuccess]);

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // Get all the data from the form
    const formJson = FormToJson<SignUpRequest>(event);

    // Send it to the API
    signUp(formJson);
  }

  return (
    <div className={styles.pageWrapper}>
      <Form intercomTarget={"register-form"} onSubmit={submit} className={styles.formWrapper}>
        <h2>{tu("Create your Mion account")}</h2>
        <Input label={tu("Email")} name={"email"} type={"email"} errors={Tie("Email", ie, ["user"])} />
        <Input label={tu("FirstName")} name={"firstName"} errors={Tie("FirstName", ie, ["user"])} />
        <Input label={tu("LastName")} name={"lastName"} errors={Tie("Lastname", ie, ["user"])} />
        <Input label={tc("CompanyName")} name={"companyName"} errors={Tie("CompanyName", ie, ["company"])} />
        <Input label={tu("Password")} name={"password"} type={"password"} errors={Tie("Password", ie, ["user"])} />
        <Input
          label={tu("Repeat Password")}
          name={"repeatPassword"}
          type={"password"}
          errors={Tie("RepeatPassword", ie, ["user"])}
        />
        <Checkbox
          label={<TermsLink />}
          intercomTarget={"acceptsTerms"}
          name={"acceptsTerms"}
          errors={Tie("AcceptsTerms", ie, ["user"])}
        />
        <Button
          intercomTarget={"signup-button"}
          type={"submit"}
          buttonPosition={ButtonPosition.fullWidth}
          disabled={isLoading}
        >
          {!isLoading && tu("Create Account")}
          {isLoading && tu("Creating Account...")}
        </Button>
        {errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}
        <div className={styles.haveAccount}>
          {tu("Already have an account?")}
          <Link to={"/login"}> {tu("Log in here.")}</Link>
        </div>
      </Form>
    </div>
  );
}

export default SignUpPage;
