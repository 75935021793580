import {
  Country,
  GetCheckoutSessionArg,
  GetCheckoutSessionResp,
  GetCustomerArg,
  GetCustomerResp,
  merchantApi,
  RequestCheckoutAttemptArg,
  RequestCheckoutAttemptResp,
  RequestCustomerCheckoutLoginArg,
  RequestCustomerCheckoutLoginResp,
} from "api/api.generated";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store"; // import everything from "api/merchantApi.generated" and make it available from this file
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { TypedUseMutationResult, TypedUseQueryHookResult } from "@reduxjs/toolkit/dist/query/react";

// import everything from "api/merchantApi.generated" and make it available from this file
export * from "api/api.generated";

// Use enhancedApi

export const ApiTags = [
  "addresses",
  "phoneNumbers",
  "keys",
  "customer",
  "companyKeys",
  "companyTree",
  "complianceHits",
  "countries",
  "bankAccounts",
  "checkoutAttempt",
  "paymentSessions",
  "bankTransfers",
  "companies",
  "company",
  "refundAttempts",
  "refundSessions",
  "users",
  "userCompanies",
  "userPermissionRoles",
  "dashboardStats",
  "shoppingCarts",
  "shopProducts",
];

const api = merchantApi.enhanceEndpoints({
  addTagTypes: ApiTags,
  endpoints: {
    addUserToCompany: {
      invalidatesTags: ["companies", "users", "userCompanies"],
    },
    addShoppingCartProduct: {
      invalidatesTags: ["shoppingCarts"],
    },
    createAccountCustomer: {
      invalidatesTags: ["customer", "addresses", "phoneNumbers"],
    },
    createCheckoutGuestCustomer: {
      invalidatesTags: ["customer", "addresses", "phoneNumbers"],
    },
    createCompanyAttachment: {
      invalidatesTags: ["attachments", "companies", "userCompanies"],
    },
    completeShoppingCart: {
      invalidatesTags: ["shoppingCarts"],
    },
    complianceCheck: {
      providesTags: ["complianceHits", "customer", "paymentSessions"],
    },
    createAdminUser: {
      invalidatesTags: ["users"],
    },
    createCompany: {
      invalidatesTags: ["companies", "userCompanies", "companyTree"],
    },
    getCompanyTree: {
      providesTags: ["companyTree"],
    },
    createCompanyAddress: {
      invalidatesTags: ["addresses", "companies"],
    },
    createCompanyBankAccount: {
      invalidatesTags: ["bankAccounts"],
    },
    createCompanyPaymentSession: {
      invalidatesTags: ["paymentSessions"],
    },
    createCompanyRefundSession: {
      invalidatesTags: ["refundSessions"],
    },
    createCompanyUser: {
      invalidatesTags: ["users"],
    },
    createCompanyProductAttachment: {
      invalidatesTags: ["attachments", "shopProducts"],
    },
    createComplianceHit: {
      invalidatesTags: ["complianceHits"],
    },
    createCustomerAddress: {
      invalidatesTags: ["addresses", "customer"],
    },
    createKey: {
      invalidatesTags: ["keys"],
    },
    createShoppingCart: {
      invalidatesTags: ["shoppingCarts"],
    },
    createShoppingCartShippingAddress: {
      invalidatesTags: ["addresses", "shoppingCarts"],
    },
    deactivateCompany: {
      invalidatesTags: ["companies"],
    },
    deleteCompanyBankAccount: {
      invalidatesTags: ["bankAccounts"],
    },
    deleteUser: {
      invalidatesTags: ["users"],
    },
    deleteCompanyAttachment: {
      invalidatesTags: ["attachments", "companies", "userCompanies"],
    },
    deleteCompanyProductAttachment: {
      invalidatesTags: ["attachments", "shopProducts"],
    },
    demoshopCreatePaymentSession: {
      invalidatesTags: ["paymentSessions"],
    },
    edenAdminApproveBankTransfer: {
      invalidatesTags: ["bankTransfers"],
    },
    edenAdminCancelBankTransfer: {
      invalidatesTags: ["bankTransfers"],
    },
    edenAdminGetBankTransferWithdrawals: {
      providesTags: ["bankTransfers"],
    },
    getAddressesByCompany: {
      providesTags: ["addresses", "companies", "userCompanies"],
    },
    getAddressesByCustomer: {
      providesTags: ["addresses", "customer"],
    },
    getAllComplianceHits: {
      providesTags: ["complianceHits"],
    },
    getAllCustomers: {
      providesTags: ["customer", "addresses", "phoneNumbers"],
    },
    getAllUsers: {
      providesTags: ["users"],
    },
    getBankAccountByCompanyId: {
      providesTags: ["bankAccounts"],
    },
    getBankAccountsByCompanyId: {
      providesTags: ["bankAccounts"],
    },
    getBankTransferByCompanyId: {
      providesTags: ["bankTransfers"],
    },
    getCheckoutSession: {
      providesTags: ["paymentSessions"],
    },
    getCheckoutStatus: {
      providesTags: ["paymentSessions"],
    },
    getCompanyAttachment: {
      providesTags: ["attachments", "companies"],
    },
    getCompanyBankTransfersByAccount: {
      providesTags: ["bankTransfers"],
    },
    getCompanyById: {
      providesTags: ["companies", "userCompanies", "company"],
    },
    getCompanyProduct: {
      providesTags: ["shopProducts"],
    },
    getCompanyPaymentSession: {
      providesTags: ["paymentSessions"],
    },
    getCompanyRefundSession: {
      providesTags: ["refundSessions"],
    },
    getCompanyRefundSessions: {
      providesTags: ["refundSessions"],
    },
    getComplianceHit: {
      providesTags: ["complianceHits"],
    },
    getCountries: {
      providesTags: ["countries"],
    },
    getIpCountry: {
      providesTags: ["countries"],
    },
    getCustomer: {
      providesTags: ["customer"],
    },
    getCustomerForEdit: {
      providesTags: ["customer", "addresses", "phoneNumbers"],
    },
    getCustomersByCompanyId: {
      providesTags: ["customer", "addresses", "phoneNumbers"],
    },
    getCustomerIdentityVerifications: {
      providesTags: ["customer"],
    },
    getKeysByCompany: {
      providesTags: ["keys"],
    },
    getPaymentSessionsByCompanyId: {
      providesTags: ["paymentSessions"],
    },
    getSelfUser: {
      providesTags: ["users", "loggedInUser"],
    },
    getShoppingCart: {
      providesTags: ["shoppingCarts"],
    },
    getUserById: {
      providesTags: ["users"],
    },
    getUserCompanies: {
      providesTags: ["userCompanies", "users"],
    },
    getUserHasEdenAdminRole: {
      providesTags: ["userPermissionRoles", "users"],
    },
    getUserOwnCompanies: {
      providesTags: ["userCompanies", "users", "loggedInUser"],
    },
    getUserOwnPermissionsByCompany: {
      providesTags: ["userPermissionRoles", "loggedInUser"],
    },
    getUserPermissionsByCompany: {
      providesTags: ["userPermissionRoles", "users", "userCompanies"],
    },
    getUsersByCompanyId: {
      providesTags: ["users", "userCompanies"],
    },
    initiateCustomerIdentityVerification: {
      invalidatesTags: ["customer"],
    },
    invalidateKey: {
      invalidatesTags: ["keys"],
    },
    listCompanyShoppingCarts: {
      providesTags: ["shoppingCarts"],
    },
    listCompanyProducts: {
      providesTags: ["shopProducts"],
    },
    listProducts: {
      providesTags: ["shopProducts"],
    },
    loginCustomer: {
      invalidatesTags: ["customer"],
    },
    logoutCustomer: {
      invalidatesTags: ["customer"],
    },
    removeUserFromCompany: {
      invalidatesTags: ["companies", "users", "userCompanies"],
    },
    requestCheckoutAttempt: {
      invalidatesTags: ["checkoutAttempt"],
    },
    requestCustomerCheckoutLogin: {},
    updateApiKeyPermissionRoles: {
      invalidatesTags: ["keys"],
    },
    updateCompany: {
      invalidatesTags: ["companies", "companyTree"],
    },
    updateCompanyAddress: {
      invalidatesTags: ["addresses", "companies"],
    },
    updateCustomerAddress: {
      invalidatesTags: ["addresses", "customer"],
    },
    updateShoppingCart: {
      invalidatesTags: ["shoppingCarts"],
    },
    updateCompanyProduct: {
      invalidatesTags: ["shopProducts"],
    },
    updateUser: {
      invalidatesTags: ["users"],
    },
    updateUserEdenAdminPermissionRole: {
      invalidatesTags: ["users", "userPermissionRoles"],
    },
    updateUserPassword: {
      invalidatesTags: ["users"],
    },
    updateUserPermissionRoles: {
      invalidatesTags: ["users", "userPermissionRoles"],
    },
    updateComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateCompanyComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateUserComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateCheckoutComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateCustomerComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateApiKeyComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
    updateCheckoutRefundSessionComplianceHitStatus: {
      invalidatesTags: ["complianceHits"],
    },
  },
});

// add selector to get countries as select options based on the response from the api using RTQ selector
export type CountryOption = {
  label: string;
  value: string;
};

// Assuming your API slice is named 'merchantApi' and is added to the Redux store under 'api'
export const selectCountriesData = merchantApi.endpoints.getCountries.select();

export const getCountriesSelector = createSelector(
  (state: RootState) => selectCountriesData(state)?.data ?? [],
  (countries: Country[]): Country[] => {
    return countries;
  },
);

export const getCountriesAsOptions = createSelector(
  (state: RootState) => selectCountriesData(state)?.data ?? [],
  (countries: Country[]): CountryOption[] => {
    return countries.map((country) => ({
      label: country.formattedName || country.name || "",
      value: country.iso3 || "",
    }));
  },
);

export type PhoneExtensionOption = {
  iso2: string;
  label: string;
  value: string;
  code: number | undefined;
};

// selector to get a list of options for phone extentions
export const getPhoneExtensionsAsOptions = createSelector(
  (state: RootState) => selectCountriesData(state)?.data ?? [],
  (countries: Country[]): PhoneExtensionOption[] => {
    let result: PhoneExtensionOption[];
    result = countries
      .map((country) => ({
        iso2: country.iso2 || "",
        label: country.formattedName + " +" + country.phoneCode + " " + country.iso2 || "",
        value: country.iso3 || "",
        code: country.phoneCode,
      }))
      .sort((a, b) => ((a.code?.toString() || "x") > (b.code?.toString() || "x") ? 1 : -1)); // sort by phone code alphabetically (if code is not available, put it at the end)

    // deduplicate result
    result = result.filter(
      (country, index, self) =>
        index ===
        self.findIndex((t) => {
          return t.value === country.value && t.code !== 0;
        }),
    );

    return result;
  },
);

export type CheckCustomerResponse = TypedUseMutationResult<
  RequestCustomerCheckoutLoginResp,
  RequestCustomerCheckoutLoginArg,
  BaseQueryFn<RequestCustomerCheckoutLoginArg, RequestCustomerCheckoutLoginResp>
>;
export type GetSessionResponse = TypedUseQueryHookResult<
  GetCheckoutSessionResp,
  GetCheckoutSessionArg,
  BaseQueryFn<GetCheckoutSessionArg, GetCheckoutSessionResp>
>;
export type GetCustomerResponse = TypedUseQueryHookResult<
  GetCustomerResp,
  GetCustomerArg,
  BaseQueryFn<GetCustomerArg, GetCustomerResp>
>;

export type CheckoutAttemptResponse = TypedUseMutationResult<
  RequestCheckoutAttemptResp,
  RequestCheckoutAttemptArg,
  BaseQueryFn<RequestCheckoutAttemptArg, RequestCheckoutAttemptResp>
>;

export default api;
