import React, { memo, useEffect, useRef } from "react";
import classNames from "classnames";
import { Copy16Regular, Eye16Regular } from "@fluentui/react-icons";
import ToastContext from "features/toast/context";
import { copyToClipboard } from "utils/copyToClipboard";
import styles from "components/common/longTextWithCopy/longTextWithCopy.module.scss";
import { toastCategory } from "features/toast/Toasts";
import labelStyles from "components/forms/label/label.module.scss";
import { useModal } from "components/dialogue/ModalContext";

export type LongTextProps = {
  text: string | Array<string>;
  link?: string;
  copyText?: string;
  className?: string;
  hoverTooltip?: boolean;
  modalTooltip?: boolean;
};

const LongTextWithCopy = (props: LongTextProps) => {
  const toastRef = React.useContext(ToastContext);
  const componentRef = useRef<HTMLDivElement>(null);
  const { showModal, closeModal } = useModal();

  const [hoverstyle, setHoverstyle] = React.useState({} as React.CSSProperties);

  // move tooltip to top or bottom depending on position of the element
  useEffect(() => {
    if (!props.hoverTooltip) {
      return;
    }

    const handleHover = () => {
      if (componentRef.current) {
        const rect = componentRef.current.getBoundingClientRect();
        const ttMargin = String(rect.height / 2);
        if (rect.y > window.innerHeight / 2) {
          setHoverstyle({ bottom: ttMargin + "px", top: "" });
        } else {
          setHoverstyle({ bottom: "", top: ttMargin + "px" });
        }
      }
    };

    const element = componentRef.current;
    if (element) {
      componentRef.current.addEventListener("mouseenter", handleHover);
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", handleHover);
      }
    };
  }, []);

  const copyText = () => {
    copyToClipboard(props.copyText || "");
    toastRef?.current?.addToast("Copied to clipboard", toastCategory.success);
  };
  const textArray = Array.isArray(props.text) ? props.text : [props.text];
  return (
    <div ref={componentRef} className={classNames(styles.longText, props.className)}>
      {textArray.map((text, i) => (
        <div key={i}>
          {props.text && (
            <div
              className={classNames(styles.action, styles.view, labelStyles.tooltip)}
              onClick={() => {
                if (props.modalTooltip) {
                  showModal(
                    <div onClick={closeModal} className={styles.prewrap}>
                      {text}
                    </div>,
                  );
                }
              }}
            >
              <Eye16Regular />
              <span className={classNames(styles.content)}>{text}</span>
              {props.hoverTooltip && (
                <div className={labelStyles.tooltipTextWrapper}>
                  <div style={hoverstyle} className={classNames(labelStyles.toolTipTextTableContainer, styles.prewrap)}>
                    <div className={labelStyles.tooltipText}>{text}</div>
                  </div>
                </div>
              )}
            </div>
          )}
          {props.copyText && (
            <button className={classNames(styles.action, styles.copy)} onClick={copyText}>
              <Copy16Regular />
              Copy
            </button>
          )}
          {props.link && (
            <a href={props.link} className={classNames(styles.action, styles.link)} target="_blank" rel="noreferrer">
              <Eye16Regular />
              Link
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

const LongTextWithCopyMemo = memo(LongTextWithCopy);
export default LongTextWithCopyMemo;
