import clone from "clone";
import { SortDirection } from "components/table/cells/header/HeaderCell";
import { AndClause, Clause, formatFilterParameters, OrClause } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import { useEffect, useState } from "react";

// sorting, adding admin columns, etc for table
export function useTableColumns<T>(
  columns: ColumnMetaData<T>[],
): [
  ColumnMetaData<T>[],
  string | undefined,
  SortDirection | undefined,
  AndClause | OrClause,
  string | undefined,
  (filters: AndClause | OrClause) => void,
] {
  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>();
  const [filters, setFilters] = useState<AndClause | OrClause>(new AndClause([]));
  const [cols, setCols] = useState<ColumnMetaData<T>[]>(columns);

  // this is triggered when clicking on a header
  const sortFunction = (key: string, direction: SortDirection) => {
    if (direction === SortDirection.NONE) {
      setSortBy(undefined);
      setSortDirection(undefined);
      return;
    }

    setSortBy(key);
    setSortDirection(direction);
  };

  // initialize/change when orig columns change
  useEffect(() => {
    const c = columns.map((col) => {
      // add functions
      col.sort = sortFunction;
      return col;
    });

    if (c.length === cols.length) {
      return;
    }

    // set only if length changed, to avoid infinite re-renders
    setCols(c);
  }, [columns]);

  // set sorted direction to correct column
  useEffect(() => {
    const c = cols.map((col) => {
      if (sortBy && col.key === sortBy) {
        col.sortedDirection = sortDirection;
      } else {
        col.sortedDirection = SortDirection.NONE;
      }
      return col;
    });

    setCols(c);
  }, [sortBy, sortDirection]);

  function handleFilterUpdate(filters: AndClause | OrClause) {
    setFilters(filters);
  }

  return [cols, sortBy, sortDirection, filters, stringifyAndFormatFilters(filters), handleFilterUpdate];
}

function stringifyAndFormatFilters(filters: AndClause | OrClause): string | undefined {
  const filtersOut = clone<Clause>(filters);

  // convert datetimes to UTC for correct db comparison
  // run the filter format functions for each filter
  formatFilterParameters(filtersOut);

  const filtersString = JSON.stringify(filtersOut);

  // in case empty, return undefined
  if (
    !filtersString ||
    filtersString === "{}" ||
    filtersString === "[]" ||
    filtersString === JSON.stringify(new AndClause([])) ||
    filtersString === JSON.stringify(new OrClause([]))
  ) {
    return undefined;
  }

  return filtersString;
}
