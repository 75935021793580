import classNames from "classnames";
import styles from "components/buttons/button.module.scss";
import { ColorVariant, GetColorClass, GetSizeClass, SizeVariant } from "components/buttons/buttonVariants";
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, LegacyRef, ReactNode, useContext } from "react";
import { Link, LinkProps } from "react-router-dom";
import { copyToClipboard } from "utils/copyToClipboard";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
// Exporting them here again so that we don't have to import from two different places
export { SizeVariant, ColorVariant } from "./buttonVariants";

export enum ButtonPosition {
  left,
  right,
  center,
  fullWidth,
  none,
}

const ButtonPositionClass = new Map([
  [ButtonPosition.left, styles.positionLeft],
  [ButtonPosition.right, styles.positionRight],
  [ButtonPosition.center, styles.positionCenter],
  [ButtonPosition.fullWidth, styles.positionFullWidth],
  [ButtonPosition.none, styles.positionNone],
]);

export type ButtonProps = {
  icon?: ReactNode;
  buttonColor?: ColorVariant;
  buttonPosition?: ButtonPosition;
  buttonSize?: SizeVariant;
  children?: ReactNode;
  hideMobileText?: boolean;
  hideMobile?: boolean;
  intercomTarget?: string;
  disabled?: boolean;
};

const Button = forwardRef(
  (
    {
      icon,
      buttonColor,
      buttonPosition,
      buttonSize,
      children,
      hideMobileText,
      hideMobile,
      intercomTarget,
      ...buttonProps
    }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & ButtonProps,
    ref: LegacyRef<HTMLButtonElement> | undefined,
  ) => {
    const color = buttonProps.disabled ? ColorVariant.disabled : buttonColor;

    return (
      <button
        data-intercom-target={intercomTarget}
        data-testid={intercomTarget}
        ref={ref}
        {...buttonProps}
        className={classNames(
          styles.button,
          GetColorClass(color),
          ButtonPositionClass.get(buttonPosition || ButtonPosition.left),
          GetSizeClass(buttonSize),
          { [styles.collapseTablet]: hideMobileText || false, [styles.hideMobile]: hideMobile || false },
          buttonProps.className,
        )}
      >
        {icon}
        {children && <span className={styles.text}>{children}</span>}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;

export function LinkButton({
  icon,
  buttonColor,
  buttonPosition,
  buttonSize,
  children,
  hideMobileText,
  hideMobile,
  intercomTarget,
  ...buttonProps
}: LinkProps & ButtonProps) {
  return (
    <Link
      data-intercom-target={intercomTarget}
      {...buttonProps}
      className={classNames(
        styles.button,
        GetColorClass(buttonColor),
        ButtonPositionClass.get(buttonPosition || ButtonPosition.left),
        GetSizeClass(buttonSize),
        { [styles.collapseTablet]: hideMobileText || false, [styles.hideMobile]: hideMobile || false },
        buttonProps.className,
        buttonProps.disabled ? styles.disabled : undefined,
      )}
    >
      {icon && <span>{icon}</span>}
      {children && <span className={styles.text}>{children}</span>}
    </Link>
  );
}

export function ExternalLinkButton({
  icon,
  buttonColor,
  buttonPosition,
  buttonSize,
  children,
  hideMobileText,
  hideMobile,
  intercomTarget,
  ...buttonProps
}: ButtonProps & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  return (
    <a
      {...buttonProps}
      data-intercom-target={intercomTarget}
      data-testid={intercomTarget}
      className={classNames(
        styles.button,
        GetColorClass(buttonColor),
        ButtonPositionClass.get(buttonPosition || ButtonPosition.left),
        GetSizeClass(buttonSize),
        { [styles.collapseTablet]: hideMobileText || false, [styles.hideMobile]: hideMobile || false },
        buttonProps.className,
        buttonProps.disabled ? styles.disabled : undefined,
      )}
    >
      {icon && <span>{icon}</span>}
      {children && <span className={styles.text}>{children}</span>}
    </a>
  );
}

export function ButtonWrapper(props: {
  leftChildren?: Array<React.ReactNode> | React.ReactNode;
  rightChildren?: Array<React.ReactNode> | React.ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames(styles.buttonWrapper, props.className)}>
      <div className={styles.leftButtonContainer}>{props.leftChildren}</div>
      <div className={styles.rightButtonContainer}>{props.rightChildren}</div>
    </div>
  );
}

// ButtonRow is a wrapper for a row of buttons. It will automatically position the buttons
// based on the buttonPosition prop.
export function ButtonRow(props: {
  className?: string;
  children: Array<React.ReactNode> | React.ReactNode;
  tight?: boolean;
}) {
  return (
    <div className={classNames(styles.buttonRow, props.className, { [styles.tightRow]: props.tight || false })}>
      {props.children}
    </div>
  );
}

export const CopyButton = forwardRef(
  (
    {
      copyText,
      ...buttonProps
    }: { copyText?: string } & React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > &
      ButtonProps,
    ref: React.Ref<HTMLButtonElement> | undefined,
  ) => {
    const toastRef = useContext(ToastContext);

    const copy = () => {
      copyToClipboard(copyText || "");
      toastRef?.current?.addToast("Copied to clipboard", toastCategory.success);
    };

    return (
      <Button
        {...buttonProps}
        onClick={(event) => {
          event.preventDefault();
          copy();
        }}
        ref={ref}
      />
    );
  },
);
CopyButton.displayName = "CopyButton";
