import styles from "features/sidebar/sections/filter/filter-section.module.scss";
import FilterComponent from "features/sidebar/sections/filter/FilterComponent";
import { ColumnMetaData } from "features/table/types";
import { AndClause, OrClause, FilterInterface } from "features/sidebar/sections/filter/filter";

type FilterSectionProps<T> = {
  filters: OrClause | AndClause;
  filterableColumns: Array<ColumnMetaData<T>>;
  defaultFilter: FilterInterface;
  handleFilterUpdate: (filters: AndClause | OrClause) => void;
};

function FilterSection<T>(props: FilterSectionProps<T>) {
  return (
    <div className={styles.filterPopoverContent}>
      <FilterComponent
        filters={props.filters}
        columns={props.filterableColumns}
        defaultFilter={props.defaultFilter}
        child={false}
        onFilterUpdate={props.handleFilterUpdate}
      />
    </div>
  );
}

export default FilterSection;
