import React, { ReactNode } from "react";
import styles from "features/navigation/nav.module.scss";
import classNames from "classnames";

type Props = {
  icon?: ReactNode;
  collapsed?: boolean;
  children: React.ReactNode;
};

export default function CustomItemWrapper(props: Props) {
  return (
    <div className={classNames(styles.customItemWrapper)}>
      {props.icon && <div className={styles.icon}>{props.icon}</div>}
      {!props.collapsed && <div className={styles.customItemContent}>{props.children}</div>}
    </div>
  );
}
