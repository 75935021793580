import { Select } from "components/forms/forms";
import styles from "features/navigation/companySelect/CompanySelect.module.scss";
import { components, DropdownIndicatorProps, InputProps, SingleValueProps } from "react-select";
import { selectSelectedCompanyId, setSelectedCompanyId } from "features/navigation/navSlice";
import api, { CompanyRecordInternal } from "api/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import DefaultSymbol from "features/navigation/companySelect/defaultCompanySymbol.svg";
import { ChevronRight20Regular as ChevronIcon } from "@fluentui/react-icons";
import classNames from "classnames";
import { getRestEndpoint } from "utils/apiUrlBuilder";

export type CompanyOption = {
  value?: string;
  label?: string;
};

// create a default company image

export function CompanySelect(props: { collapsed: boolean }) {
  const dispatch = useAppDispatch();
  const [selectedCompany, setSelectedCompany] = useState<CompanyOption>();

  // Get all companies related to the user
  const { data: companies, isLoading: loadingCompanies } = api.useGetUserOwnCompaniesQuery();

  // get from local storage if not in storage
  const selectedCompanyId =
    useAppSelector(selectSelectedCompanyId) || localStorage.getItem("selectedCompanyId") || undefined;
  // If the selectedCompanyId in the navSlice is not in the companies array, set it to the first company in the array
  useEffect(() => {
    if (companies && companies.length > 0) {
      const comp = companies.find(
        (company: CompanyRecordInternal) => selectedCompanyId && company.companyId === selectedCompanyId,
      );
      if (comp) {
        dispatch(setSelectedCompanyId(comp.companyId));
        setSelectedCompany({
          value: comp.companyId,
          label: comp.displayName ? comp.displayName : comp.companyName,
        });
      } else {
        // first in the list by default
        dispatch(setSelectedCompanyId(companies[0].companyId));
        setSelectedCompany({
          value: companies[0].companyId,
          label: companies[0].displayName ? companies[0].displayName : companies[0].companyName,
        });
      }
    }
  }, [companies, selectedCompanyId]);

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronIcon />
      </components.DropdownIndicator>
    );
  };

  // disable chrome autofill
  const Input = (props: InputProps) => {
    return <components.Input {...props} type="search" autoComplete="off" />;
  };

  const SingleValue = (op: SingleValueProps) => {
    const company = op.data as CompanyOption;

    const comp: CompanyRecordInternal | undefined = companies?.find(
      (c: CompanyRecordInternal) => company?.value && c.companyId === company.value,
    );

    return (
      <components.SingleValue {...op}>
        <div className={styles.optionsWrapper}>
          <div className={styles.symbol}>
            <img
              src={
                comp?.publicSymbolAttachmentPath ? getRestEndpoint() + comp.publicSymbolAttachmentPath : DefaultSymbol
              }
              alt="company logo"
            />
          </div>
          <div className={classNames(styles.label, { [styles.hidden]: props.collapsed })}>{company.label}</div>
        </div>
      </components.SingleValue>
    );
  };

  const Option = SingleValue;

  // Remove the indicator
  // const IndicatorContainer = (props: DropdownIndicatorProps) => {
  //   return <components.DropdownIndicator {...props}>{""}</components.DropdownIndicator>;
  // };

  function handleCompanyChange(e: unknown) {
    const companyId = (e as { value: string }).value;
    dispatch(setSelectedCompanyId(companyId));
    dispatch(api.util.invalidateTags(["company", "addresses", "phoneNumbers", "companyKeys", "companyTree"]));

    const comp = companies?.find((company) => company.companyId === companyId);
    setSelectedCompany({
      value: companyId,
      label: comp?.displayName ? comp.displayName : comp?.companyName,
    });
  }

  const selectComponents = props.collapsed
    ? {
        SingleValue,
        Option,
        Input,
        IndicatorsContainer: () => null,
        IndicatorSeparator: () => null,
        // Input: () => null,
      }
    : {
        SingleValue,
        Option,
        Input,
        IndicatorSeparator: () => null,
        DropdownIndicator,
      };

  return (
    <Select
      isClearable={false}
      styles={{
        control: (provided) => ({
          ...provided,
          height: "44px",
          backgroundColor: "transparent",
          border: "1px dashed transparent",
          // borderColor: state.isFocused ? "var(--input-focus-border-color)" : "transparent",
          // boxShadow: "none",
          // borderColor: "transparent",
          borderRadius: "100px",
          "&:hover": {
            // backgroundColor: "var(--input-hover-background)",
            cursor: "pointer",
            border: "1px dashed black",
            // borderColor: "#d8d8d8",
          },
          "&:focus": {
            outline: "none",
          },
          "&:active": {
            outline: "none",
          },
        }),
        menuPortal: (provided) => ({
          ...provided,
          left: "unset",
          top: "unset",
          transform: "translate(0px, -52px)",
        }),
        input: (provided) => ({
          ...provided,
          marginLeft: "52px",
          width: "100%",
          // padding: "0",
          caretColor: "transparent", // hide the blinking caret
          outline: "none !imporant",
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: "0",
        }),
        placeholder: (provided) => ({
          ...provided,
          marginLeft: "16px",
        }),
        menuList: (provided) => ({
          ...provided,
          "&:hover": {
            cursor: "pointer",
          },
        }),
      }}
      placeholder={"Loading..."}
      // menuIsOpen={true}
      selectComponents={selectComponents}
      menuPlacement={"top"}
      menuPosition={"fixed"}
      // isClearable={false}
      intercomTarget={"dashboard-company-select"}
      isLoading={loadingCompanies}
      value={selectedCompany}
      onChange={(e) => handleCompanyChange(e)}
      options={companies?.map((company) => ({
        value: company.companyId,
        label: company.displayName ? company.displayName : company.companyName,
      }))}
    />
  );
}
