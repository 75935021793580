import classNames from "classnames";
import { Form } from "components/forms/forms";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "pages/shop/shippingAddress.module.scss";
import AddressFormFields from "features/customerFormFields/addressFormFields";
import api, { Address, CountryOption, InputError } from "api/api";
import { FormToJson } from "api/apiUtils";
import Button, { ButtonPosition, ButtonRow } from "components/buttons/Button";
import { isFullShippingAddressRequired, ShopViewParams } from "pages/shop/ShopPage";
import Spinny from "features/spinny/Spinny";

function ShippingAddress(props: ShopViewParams & { editing: boolean; setEditing: (editing: boolean) => void }) {
  const { t } = useTranslation("shop");

  const [country, setCountry] = useState<CountryOption>();
  const [ie, setInputErrors] = useState<InputError[]>();

  const [createShippingAddress, createShippingAddressResp] = api.useCreateShoppingCartShippingAddressMutation();

  useEffect(() => {
    if (createShippingAddressResp.error) {
      props.handleApiError(createShippingAddressResp.error);
    }

    if (createShippingAddressResp.isSuccess) {
      props.setEditing(false);
    }
  }, [createShippingAddressResp]);

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!props.shoppingCart?.shoppingCartId || !props.shop?.shopHandle) {
      return;
    }

    setInputErrors(undefined);

    // Create
    const formJson = FormToJson<Address>(event, "address");

    createShippingAddress({
      companyShop: props.shop.shopHandle,
      shoppingCartId: props.shoppingCart?.shoppingCartId,
      address: formJson,
    });
  }

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div className={styles.shippingAddressContainer}>
      <h2>{t("shippingAddress")}</h2>
      <Form intercomTarget={"shipping address"} onSubmit={submit} ref={formRef}>
        <div
          className={classNames(styles.formPart, {
            [styles.loadingForm]: !props.shoppingCart,
          })}
        >
          <AddressFormFields
            setCountry={setCountry}
            country={country}
            ie={ie}
            disabled={!props.editing}
            addressRequired={isFullShippingAddressRequired(props.shoppingCart)}
            filledAddress={props.shoppingCart?.shippingAddress}
          />
          <ButtonRow>
            {props.editing && (
              <Button
                intercomTarget={"submit-shipping-address"}
                type={"submit"}
                disabled={createShippingAddressResp.isLoading || !props.shoppingCart?.shoppingCartId}
                icon={createShippingAddressResp.isLoading ? <Spinny /> : undefined}
                buttonPosition={ButtonPosition.right}
              >
                {t("Save", { ns: "common" })}
              </Button>
            )}
          </ButtonRow>
        </div>
      </Form>
    </div>
  );
}

export default ShippingAddress;
