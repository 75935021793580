import React, { ReactNode } from "react";
import styles from "features/templates/tablePageTemplate/table-page-template.module.scss";

import classNames from "classnames";
import PageTitle from "components/pageTitle/PageTitle";

type TablePageTemplateProps = {
  title?: string;
  titleComponent?: ReactNode;
  titleContent?: ReactNode;
  sidebarExpanded?: boolean;
  sidebar?: ReactNode;
  pagination?: ReactNode;
  tableSummary?: ReactNode;
  tableControls?: ReactNode;
  breadcrumbs?: Array<string | ReactNode>;
  children?: ReactNode;
  onNameChange?: (title: string) => void;
  isDraft?: boolean;
};

export default function TablePageTemplate(props: TablePageTemplateProps) {
  const regularTitle = (
    <PageTitle
      title={props.title || ""}
      breadcrumbs={props.breadcrumbs}
      onNameChange={props.onNameChange}
      isDraft={props.isDraft}
    >
      {props.titleContent}
    </PageTitle>
  );

  const title = props.title ? regularTitle : props.titleComponent;

  return (
    <div className={classNames(styles.contentWrapper)}>
      {title}

      {props.tableControls}

      <div className={classNames(styles.tableSummaryWrapper, { [styles.empty]: !props.tableSummary })}>
        {props.tableSummary}
      </div>

      <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
          <div className={styles.tableExpander}>{props.children}</div>
        </div>
      </div>

      <div className={classNames(styles.paginationWrapper)}>{props.pagination}</div>

      <div className={classNames(styles.pageSidebarWrapper, { [styles.sidebarExpanded]: props.sidebarExpanded })}>
        {props.sidebar}
      </div>
    </div>
  );
}

export function TableControlSection(props: { intercomTarget: string; children?: React.ReactNode }) {
  return (
    <div
      className={classNames(styles.tableControlsSection, { [styles.empty]: !props.children })}
      data-intercom-target={props.intercomTarget}
    >
      {props.children}
    </div>
  );
}

export function TableControlsButtonGroup(props: { intercomTarget: string; children?: React.ReactNode }) {
  return (
    <div className={classNames(styles.tableControlsButtonGroup)} data-intercom-target={props.intercomTarget}>
      {props.children}
    </div>
  );
}
