import api from "api/api";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";

const edenAdminRole = "edenAdmin";
const companyAdminRole = "companyAdmin";

const permissionRefetchInterval = 60000; // refetch permissions every minute

// hook to check permissions
const useHasPermission = (allowedRoles: string[]): boolean => {
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const { data: rolePermissions } = api.useGetUserOwnPermissionsByCompanyQuery(selectedCompanyId || "", {
    skip: !selectedCompanyId,
    pollingInterval: permissionRefetchInterval,
  });

  // logic for frontend permissions:

  const userIsEdenAdmin = rolePermissions?.permissionRoles?.includes(edenAdminRole);
  const userIsCompanyAdmin = rolePermissions?.permissionRoles?.includes(companyAdminRole);
  const hasRequiredRole = allowedRoles.some((role) => rolePermissions?.permissionRoles?.includes(role));

  return (
    userIsEdenAdmin || // eden admin has access to anything
    (userIsCompanyAdmin && !allowedRoles.includes(edenAdminRole)) || // company admin has access to anything except views that are only for eden admin
    hasRequiredRole // one of the user's roles is one of the accepted roles
  );
};

export type ComponentPermissionCheckerProps = {
  roles: string[];
  children: React.ReactNode;
};

function ComponentPermissionChecker(props: ComponentPermissionCheckerProps) {
  const hasPermission = useHasPermission(props.roles);

  if (hasPermission) {
    return <>{props.children}</>;
  }

  // no access to this component
  return <></>;
}

export default ComponentPermissionChecker;
export { useHasPermission };
