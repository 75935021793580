import React, { useEffect } from "react";
import MerchantServicesLogo from "icons/mion-logo-black.svg";
import { useGetServicesQuery } from "api/manualApi";
import { services } from "api/manualApiTypes";
import styles from "pages/bootstrapping/bootstrappingPage.module.scss";
import Button, { ColorVariant } from "components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BootstrappingPage() {
  const { t } = useTranslation("bootstrapping");
  const { data: servicesData, refetch: getServices } = useGetServicesQuery(undefined, { pollingInterval: 1000 });

  const [servicesState, setServicesState] = React.useState({} as services);
  const [version = "Unknown"] = servicesState?.version?.split(" | ") ?? [];

  const navigate = useNavigate();

  const retryServices = () => {
    getServices();
    if (servicesState.rootService.status == 1) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (servicesState?.rootService?.status == 1) {
      navigate("/");
    }
  }, [servicesState]);

  useEffect(() => {
    if (servicesState?.rootService?.status == 1) {
      navigate("/");
    }
    if (servicesData) {
      setServicesState(servicesData);
    }
  }, [servicesData]);

  return (
    <div className={styles.bootstrappingPageWrapper}>
      <div className={styles.bootstrappingFormWrapper}>
        <div className={styles.logo}>
          <img src={MerchantServicesLogo} alt="Logo" className={styles.logoBootstrapping} />
        </div>
        <div className={styles.bootstrappingDataWrapper}>
          Merchant Services ({version}): {t("Bootstrapping")}
          <br />
          <Button
            buttonColor={ColorVariant.primary}
            onClick={retryServices}
            intercomTarget={"bootstrapping-page-retry-button"}
          >
            {t("Retry")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BootstrappingPage;
