import { LockClosed12Regular as LockIcon } from "@fluentui/react-icons";
import styles from "components/table/cells/cell.module.scss";
import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  ChevronUp16Filled as ChevronUpIcon,
  ChevronDown16Filled as ChevronDownIcon,
  ChevronUpDown16Filled as ChevronUpDownIcon,
} from "@fluentui/react-icons";

interface headerCell {
  heading: string;
  className?: string;
  locked?: boolean;
  localizePackage?: string;
  sortedDirection?: SortDirection;
  sort?: (key: string, direction: SortDirection) => void; // sortable if has a sort function
  colKey?: string;
}

export enum SortDirection {
  NONE = "",
  ASC = "asc",
  DESC = "desc",
}

function HeaderCell({ heading, className, locked, localizePackage, sortedDirection, sort, colKey }: headerCell) {
  const { t } = useTranslation(localizePackage);

  if (localizePackage) {
    heading = t(heading);
  }

  const sortable = sort !== undefined;

  const handleSortClick = () => {
    if (sortable && colKey) {
      if (sortedDirection === SortDirection.ASC) {
        sort(colKey, SortDirection.DESC);
      } else if (sortedDirection === SortDirection.DESC) {
        sort(colKey, SortDirection.NONE);
      } else {
        sort(colKey, SortDirection.ASC);
      }
    }
  };

  return (
    <div
      onClick={handleSortClick}
      className={classNames(styles.header, className, { [styles.locked]: locked, [styles.sortable]: sortable })}
    >
      <div className={styles.content}>
        {locked ? (
          <div className="icon small">
            <LockIcon />
          </div>
        ) : (
          ""
        )}
        <div className="text">{heading}</div>
        {sortable && <SortIcon sortedDirection={sortedDirection || SortDirection.NONE} />}
      </div>
    </div>
  );
}

type SortButtonProps = {
  sortedDirection: SortDirection | SortDirection.NONE;
};

function SortIcon(props: SortButtonProps) {
  return (
    <div className="icon small">
      {props.sortedDirection === SortDirection.ASC && <ChevronUpIcon />}
      {props.sortedDirection === SortDirection.DESC && <ChevronDownIcon />}
      {props.sortedDirection === SortDirection.NONE && <ChevronUpDownIcon />}
    </div>
  );
}

const HeaderCellMemo = React.memo(HeaderCell);
export default HeaderCellMemo;
