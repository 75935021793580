import classNames from "classnames";
import { ReactNode } from "react";
import styles from "components/cards/Card.module.scss";

type CardProps = {
  children: ReactNode;
  className?: string;
};

export default function Card(props: CardProps) {
  return <div className={classNames(styles.cardWrapper, props.className)}>{props.children}</div>;
}

export function CardHeader(props: { header: ReactNode }) {
  return <div className={styles.cardHeader}>{props.header}</div>;
}
