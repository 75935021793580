import { useLocation, useParams } from "react-router-dom";
import Page from "layout/Page";
import styles from "pages/demo/demoProductPage.module.scss";
import { products } from "pages/demo/products";
import { useTranslation } from "react-i18next";
import userImage from "pages/demo/images/user-image.jpeg";
import DemoUserHeader from "pages/demo/DemoUserHeader";
import PayEdenButton from "pages/demo/PayEdenButton";

function DemoProductPage() {
  const { productId } = useParams();
  const { t } = useTranslation("demoShop");
  const product = products.find((p) => p.id === productId) || products[0];

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const success = query.get("success");

  return (
    <Page>
      <>
        <DemoUserHeader
          name={"Henrik Muller"}
          address={"Eden Drive 1, 0765, Austia"}
          email={"henrik@example.com"}
          image={userImage}
        />
        <h1 className={styles.pageTitle}>Mion Demo Shop</h1>
        <div className={styles.product}>
          <div className={styles.productImage}>
            <img src={product.image} alt={product.name} />
          </div>
          {success && (
            <div className={styles.successMessage}>
              <h2>{t("PaymentSuccessful")}</h2>
              <p>{t("PaymentSuccessDescription")}</p>
            </div>
          )}
          {!success && (
            <div className={styles.productInfo}>
              <h1>{product.name}</h1>
              <p>{product.description}</p>
              <div className={styles.productPrice}>€{product.price}</div>
            </div>
          )}
          {!success && <PayEdenButton product={product} />}
        </div>
      </>
    </Page>
  );
}

export default DemoProductPage;
