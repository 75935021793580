import React, { useEffect } from "react";
import Router from "Router";
import ToastContext from "features/toast/context";
import Toasts, { addToastHandle } from "features/toast/Toasts";
import styles from "app.module.scss";
import classNames from "classnames";
import { polyfillFFDrag } from "utils/drag";
import CookieConsent from "components/gdpr/CookieConsent";

declare global {
  interface Window {
    clarity: (consent: string, on?: boolean) => void;
  }
}

function App() {
  const toastRef = React.useRef<addToastHandle>();

  useEffect(() => {
    polyfillFFDrag();
  }, []);

  return (
    <>
      <CookieConsent />
      <ToastContext.Provider value={toastRef}>
        <div className={classNames(styles.app)}>
          <Toasts ref={toastRef} />
          <Router />
        </div>
      </ToastContext.Provider>
    </>
  );
}

export default App;
