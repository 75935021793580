// Drag and drop!!
// https://www.youtube.com/watch?v=vJG9lnO7jOM

import styles from "components/forms/file/file.module.scss";
import React, { useEffect, useId, useRef, useState } from "react";
import classNames from "classnames";
import { useFetchFile } from "api/manualApi";

interface fileProps {
  disabled?: boolean;
  label?: string;
  onFileChange?: (file: File | null) => void;
  fileName?: string;
  intercomTarget?: string;
  allowedFileTypes?: string[];
  fileGetPath?: string;
}
function File({ disabled, label, onFileChange, fileName, intercomTarget, allowedFileTypes, fileGetPath }: fileProps) {
  const drop = useRef<HTMLDivElement>(null);
  const hiddenFileRef = useRef<HTMLInputElement>(null);

  const defaultMessageValue: (string | React.ReactElement)[] = ["Drag file here or click to select"];

  const [message, setMessage] = useState<(string | React.ReactElement)[]>(defaultMessageValue);
  const [dragging, setDragging] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const savedImage = useFetchFile(fileGetPath);

  useEffect(() => {
    // clear preview image on file change
    setPreviewImage(null);
  }, [fileGetPath]);

  useEffect(() => {
    if (!previewImage && savedImage) {
      setPreviewImage(savedImage);
    }
  }, [savedImage]);

  useEffect(() => {
    if (disabled) {
      setMessage(["Current file: " + fileName, "File upload disabled"]);
      return;
    }
    setMessage([fileName ? "Current file: " + fileName : "", "To change, drop file or click to select"]);
  }, [fileName, disabled]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = () => {
    hiddenFileRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      processFiles(event.target.files);
    }
  };

  const processFiles = (files: FileList) => {
    setFileError(false);
    if (files && files.length) {
      if (files.length > 1) {
        setMessage(["Too many files", "Drop file or click to select"]);
        setFileError(true);
        if (onFileChange) {
          onFileChange(null);
        }
        return;
      }

      if (onFileChange) {
        setPreviewImage(URL.createObjectURL(files[0]));
        onFileChange(files[0]);
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
    if (e.dataTransfer) {
      const files = e.dataTransfer.files;

      processFiles(files);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drop.current) {
      setDragging(false);
    }
  };

  const inputId = useId();
  return (
    <div
      className={classNames(styles.fileDropWrapper, styles.primary, disabled ? styles.disabled : "")}
      data-intercom-target={intercomTarget}
    >
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={styles.hiddenFileInput}
        id={inputId}
        data-testid={intercomTarget}
        ref={hiddenFileRef}
        onChange={handleFileChange}
        type="file"
        style={{ position: "fixed", top: "-100em" }}
        disabled={disabled}
        accept={allowedFileTypes?.join(",")}
        multiple={false}
      />
      {disabled && (
        <div ref={drop} className={classNames(styles.fileDropAreaDisabled)}>
          {previewImage && (
            <div className={styles.filePreview}>
              <img src={previewImage} />
            </div>
          )}
          <div className={styles.messages}>
            {message.map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </div>
        </div>
      )}
      {!disabled && (
        <div
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={handleClick}
          ref={drop}
          className={classNames(
            styles.fileDropArea,
            { [styles.dragging]: dragging },
            { [styles.error]: !dragging && fileError },
          )}
        >
          {previewImage && (
            <div className={styles.filePreview}>
              <img src={previewImage} />
            </div>
          )}
          <div className={styles.messages}>
            {message.map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default File;
