import styles from "pages/checkout/paymentMethods/paymentMethods.module.scss";
import LightningIcon from "pages/checkout/paymentMethods/images/lightning-icon.svg";
import BitcoinIcon from "pages/checkout/paymentMethods/images/bitcoin-icon.svg";
import { Check20Regular as InvoiceIcon, WalletCreditCard16Regular as CreditCardIcon } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PaymentMethod } from "api/api";

type Props = {
  onSelect: (value?: PaymentMethod) => void;
  methodSelected?: PaymentMethod;
  enabledPaymentMethods: PaymentMethod[];
};

export default function PaymentMethodSelector(props: Props) {
  const { t } = useTranslation("checkout");

  const methods = [
    {
      method: PaymentMethod.Lightning,
      icon: <img src={LightningIcon} alt={"Lightning icon"} />,
      label: t("Lightning"),
    },
    {
      method: PaymentMethod.OnChain,
      icon: <img src={BitcoinIcon} alt={"Bitcoin icon"} />,
      label: t("Bitcoin"),
    },
    {
      method: PaymentMethod.Card,
      icon: <CreditCardIcon />,
      label: t("Card"),
    },
    {
      method: PaymentMethod.Invoice,
      icon: <InvoiceIcon />,
      label: t("Invoice"),
    },
  ];

  // show as disabled button
  const disabledMethods = [PaymentMethod.Card, PaymentMethod.Invoice];

  return (
    <div className={styles.paymentMethodSelectorWrapper}>
      <div className={styles.paymentMethodSelectorTitle}>{t("Payment method")}</div>
      <div className={styles.paymentAlternatives} role="radiogroup">
        {methods.map((method, index) => (
          <div
            className={classNames(styles.paymentMethodOption, {
              [styles.selected]: props.methodSelected === method.method,
              [styles.disabled]:
                !props.enabledPaymentMethods.includes(method.method) || disabledMethods.includes(method.method),
              [styles.hidden]: !props.enabledPaymentMethods.includes(method.method), // hide the payment method if it's not enabled in the handler
            })}
            // Selectable option like an input
            role="radio"
            // Make it focusable
            tabIndex={index + 1}
            // Select using up and down arrows
            // select option on enter key or space key
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                if (props.enabledPaymentMethods.includes(method.method)) {
                  props.onSelect(method.method);
                }
              }
            }}
            onClick={() => {
              if (props.enabledPaymentMethods.includes(method.method)) {
                props.onSelect(method.method);
              }
            }}
            aria-checked={props.methodSelected === method.method}
            key={method.method}
          >
            <div className={styles.paymentMethodOptionLabelWrapper}>
              <div className={styles.paymentMethodIcon}>{method.icon}</div>
              <div className={styles.paymentMethodLabel}>
                {method.label}
                {/* If the payment method is disabled, show "coming soon" */}
                {disabledMethods.includes(method.method) && t("ComingSoonSuffix")}
              </div>
            </div>
            <div className={classNames(styles.radioIndicator)} />
          </div>
        ))}
      </div>
    </div>
  );
}
