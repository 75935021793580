import styles from "pages/consumerCompliance/modules/indentomat/Identomat.module.scss";
import { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import { StepProps } from "pages/consumerCompliance/modules/types";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api, {
  CompleteIdentityVerificationRequest,
  IdentificationCreationStatus,
  IdentificationVerificationStatus,
  IdentityVerificationRecord,
  VerificationProvider,
} from "api/api"; // This is a module for the Identomat KYC provider. It will render the Identomat iframe.
import { ComplianceCheckContext } from "pages/consumerCompliance/ComplianceCheckPage";
import { NavigateButtons } from "pages/consumerCompliance/modules/ComplianceSteps";
import Button, { SizeVariant } from "components/buttons/Button";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse } from "components/errors/validationErrors";
import classNames from "classnames";

const initialCountdown = 5;

// This is a module for the Identomat KYC provider. It will render the Identomat iframe.
function Identomat(props: StepProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [countdown, setCountdown] = useState(initialCountdown);
  const [existingFetched, setExistingFetched] = useState(false);
  const [identomatSessionToken, setIdentomatSessionToken] = useState<string | undefined>();
  const [verificationUrl, setVerificationUrl] = useState<string | undefined>();
  const { t } = useTranslation("consumerCompliance");
  const { t: tv } = useTranslation("validation");

  const [errMessage, setErrMessage] = useState<string | undefined>();
  const [completedData, setCompletedData] = useState<IdentityVerificationRecord | undefined>();

  const { loggedInCustomer } = useContext(ComplianceCheckContext);

  // check if already completed
  const { data: existingIdentityVerifications, isSuccess: getExistingIsSuccess } =
    api.useGetCustomerIdentityVerificationsQuery(loggedInCustomer?.customerId || "", {
      skip: !loggedInCustomer?.customerId || existingFetched, // do not refetch
    });

  const [
    initiateCustomerIdentityVerification,
    {
      data: initiateData,
      isSuccess: initiateIsSuccess,
      isUninitialized: initiateIsUninitialized,
      reset: resetInitiate,
      error: initiateError,
    },
  ] = api.useInitiateCustomerIdentityVerificationMutation();

  const [
    completeCustomerIdentityVerification,
    {
      data: completionCompletedData,
      isSuccess: completeIsSuccess,
      isLoading: completeIsLoading,
      reset: resetComplete,
      error: completeError,
    },
  ] = api.useCompleteCustomerIdentityVerificationMutation();

  // set completed data from the completion call
  useEffect(() => {
    if (completionCompletedData && completeIsSuccess) {
      setCompletedData(completionCompletedData);
    }
  }, [completionCompletedData]);

  // set current sesion from initiate
  useEffect(() => {
    if (initiateData?.identomatSessionToken && initiateData?.verificationUrl) {
      setIdentomatSessionToken(initiateData.identomatSessionToken);
      setVerificationUrl(initiateData.verificationUrl);
    }
  }, [initiateData]);

  // set completed data from existing identity verifications
  // initiate a new session if a completed one is not found
  useEffect(() => {
    // wait for the existing identity verifications before proceeding
    if (existingIdentityVerifications === undefined) {
      return;
    }

    setExistingFetched(true);

    // get the one from current completion if exists
    if (existingIdentityVerifications && existingIdentityVerifications.length > 0 && getExistingIsSuccess) {
      // first check for completed ones
      for (const existing of existingIdentityVerifications) {
        if (
          existing.verificationStatus === IdentificationVerificationStatus.Verified &&
          existing.identificationCreationStatus === IdentificationCreationStatus.Complete
        ) {
          // if has a completed one use that one
          setIdentomatSessionToken(existing.identomatSessionToken);
          setVerificationUrl(existing.verificationUrl);
          setCompletedData(existing);

          return;
        }
      }

      // check for pending ones
      for (const existing of existingIdentityVerifications) {
        // set current identification from latest pending one
        if (existing.verificationStatus === IdentificationVerificationStatus.Pending) {
          setIdentomatSessionToken(existing.identomatSessionToken);
          setVerificationUrl(existing.verificationUrl);

          return;
        }
      }
    }

    // initiate new session if not found pending or completed ones
    if (initiateIsUninitialized) {
      initiate();
    }
  }, [existingIdentityVerifications]);

  const handleBack = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Only allow back if this is not the first step
    if (props.step > 0) {
      props.setStep(props.step - 1);
    }
  };

  const handleNext = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!completedData) {
      return;
    }

    if (props.index < props.steps.length - 1) {
      props.setCompletedSteps((prevSteps) => [...prevSteps, "identomat"]);
      props.setStep(props.step + 1);
    }
  };

  const complete = (req: CompleteIdentityVerificationRequest) => {
    if (completeIsSuccess) {
      console.warn("Already did successful completion.");
      return;
    }

    if (loggedInCustomer?.customerId) {
      completeCustomerIdentityVerification({
        customerId: loggedInCustomer.customerId,
        completeIdentityVerificationRequest: req,
      });
    }
  };

  // Async function to count down to zero after successfull verification
  const countdownToNextStep = () => {
    if (countdown === 0) {
      props.setCompletedSteps((prevSteps) => [...prevSteps, "identomat"]);
      props.setStep(props.step + 1);
    } else {
      setTimeout(() => {
        if (completedData) {
          setCountdown(countdown - 1);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (initiateIsSuccess) {
      setErrMessage(undefined);
    }
  }, [initiateIsSuccess]);

  useEffect(() => {
    // if the complete query is not successful, return
    if (!completedData) {
      setCountdown(initialCountdown);
      return;
    }

    if (
      completedData?.verificationStatus === IdentificationVerificationStatus.Verified &&
      completedData?.identificationCreationStatus === IdentificationCreationStatus.Complete
    ) {
      setErrMessage(undefined);
      // Set a countdown to the next step and continue after the countdown
      countdownToNextStep();
    }
  }, [completedData]);

  const handleIdentomatDone = (e: MessageEvent) => {
    if (e.origin !== "https://widget.identomat.com") return;
    if (e.data !== "DONE") return;

    console.log("Identomat process done, trying to complete with token", identomatSessionToken);

    if (!identomatSessionToken) {
      console.error("No identomat session token found to call complete");
      return;
    }

    complete({
      identomatSessionToken: identomatSessionToken,
      verificationProvider: VerificationProvider.Identomat,
    });
  };

  useEffect(() => {
    // only countdown if is completed, didn't start a new session and is still in this step
    if (countdown <= initialCountdown && completedData && props.step === props.index) {
      countdownToNextStep();
    } else if (!completedData || props.step !== props.index) {
      setCountdown(initialCountdown);
    }
  }, [countdown, props.step]);

  // Create an event listener for the iframe to listen for the "DONE" message from the identomat iframe.
  // This will be sent when the user has completed the KYC process.
  useEffect(() => {
    window.addEventListener("message", handleIdentomatDone);

    return () => {
      window.removeEventListener("message", handleIdentomatDone);
    };
    // refresh when token changes
  }, [identomatSessionToken, verificationUrl]);

  useEffect(() => {
    if (!loggedInCustomer?.customerId) {
      setErrMessage("Not logged in");
      return;
    }
  }, [loggedInCustomer]);

  const initiate = () => {
    if (loggedInCustomer?.customerId) {
      setErrMessage(undefined);
      initiateCustomerIdentityVerification({
        customerId: loggedInCustomer.customerId,
        initiateIdentityVerificationRequest: {
          // add the return path here using useLocation if needed
        },
      });
    } else {
      console.error("Customer not logged in, cannot initiate KYC verification.");
    }
  };

  const reInitiate = () => {
    resetComplete();
    resetInitiate();
    initiate();
  };

  useEffect(() => {
    const err = getErrorResponse(initiateError || completeError);
    setErrMessage(err?.message);
  }, [initiateError, completeError]);

  return (
    <ProgressTabContainer className={styles.identomatWrapper}>
      {errMessage && (
        <Note noteType={NoteType.error} title={t("Error")}>
          {tv(errMessage)}
        </Note>
      )}
      {completedData && (
        <>
          {completedData?.verificationStatus === IdentificationVerificationStatus.Verified && (
            <>
              {completedData?.identificationCreationStatus === IdentificationCreationStatus.Complete && (
                <div>
                  <h2>{t("VerificationSuccessful")}</h2>
                  <p>{t("VerificationSuccessfulMessage")}</p>
                  <p>{t("countdown", { count: countdown })}</p>
                </div>
              )}
              {completedData?.identificationCreationStatus !== IdentificationCreationStatus.Complete && (
                <div>
                  <h2>{t("VerificationSuccessfulButSomethingWentWrong")}</h2>
                  <p>{t("VerificationSuccessfulButSomethingWentWrongMessage")}</p>
                  <Button onClick={reInitiate}>{t("RetryIdentityVerificationButton")}</Button>
                </div>
              )}
            </>
          )}
          {completedData?.verificationStatus === IdentificationVerificationStatus.Pending && (
            <div>
              <h2>{t("VerificationPending")}</h2>
              <p>{t("VerificationPendingMessage")}</p>
            </div>
          )}
          {completedData?.verificationStatus === IdentificationVerificationStatus.Rejected && (
            <div>
              <h2>{t("VerificationFailed")}</h2>
              <p>{t("VerificationFailedMessage")}</p>
              <Button onClick={reInitiate}>{t("RetryIdentityVerificationButton")}</Button>
            </div>
          )}
          {completedData?.verificationStatus === IdentificationVerificationStatus.TimedOut && (
            <div>
              <h2>{t("VerificationTimedOut")}</h2>
              <p>{t("VerificationTimedOutMessage")}</p>
              <Button onClick={reInitiate}>{t("RetryIdentityVerificationButton")}</Button>
            </div>
          )}
        </>
      )}
      {/* loading indicator */}
      {!hasLoaded && (!initiateIsUninitialized || completeIsLoading) && (
        <div className={classNames(styles.identomatIframe, styles.loadingIFrame)}></div>
      )}

      {(props.step === props.index || hasLoaded) && verificationUrl && !completedData && (
        <>
          <iframe
            allowFullScreen={true}
            onLoad={() => {
              setHasLoaded(true);
            }}
            className={styles.identomatIframe}
            src={verificationUrl}
            allow="camera *;"
          />
          {
            <div className={styles.manualActionContainer}>
              <span>{t("IdentityVerificationManualCompleteInfo")}</span>
              <Button
                type={"button"}
                buttonSize={SizeVariant.small}
                onClick={() => {
                  complete({
                    identomatSessionToken: identomatSessionToken,
                    verificationProvider: VerificationProvider.Identomat,
                  });
                }}
              >
                {t("Complete")}
              </Button>
            </div>
          }
        </>
      )}
      {props.step === props.index && initiateIsUninitialized && !completeIsLoading && !completedData && (
        <div className={styles.manualActionContainer}>
          {/* manual initiate in case the initiate was not started because there was an existing session */}
          {!completedData && <span>{t("IdentityVerificationManualInitiateInfo")}</span>}
          {completedData && <span>{t("IdentityVerificationManualInitiateOnSuccessInfo")}</span>}
          <Button type={"button"} buttonSize={SizeVariant.small} onClick={reInitiate}>
            {t("IdentityVerificationManualInitiateButton")}
          </Button>
        </div>
      )}
      <NavigateButtons step={props.step} handleBack={handleBack} handleNext={handleNext} disableNext={!completedData} />
    </ProgressTabContainer>
  );
}

export default Identomat;
