import charger from "pages/demo/images/charger.jpeg";
import console from "pages/demo/images/console.jpeg";
import crystalBall from "pages/demo/images/crystal-ball.jpeg";
import lamp from "pages/demo/images/lamp.jpeg";
import soundMachine from "pages/demo/images/sound-machine.jpeg";
import projector from "pages/demo/images/projector.jpeg";
import recordPlayer from "pages/demo/images/record-player.jpeg";
import drawing from "pages/demo/images/drawing.jpeg";
import headphones from "pages/demo/images/headphones.jpeg";
import teapot from "pages/demo/images/teapot.jpeg";

export type product = {
  id: string;
  name: string;
  price: number;
  description: string;
  image: string;
};

export const products: product[] = [
  {
    id: "1",
    name: "Aurora Light Lamp",
    price: 1.0,
    description: "Elegant lamp projecting mesmerizing aurora patterns",
    image: lamp,
  },
  {
    id: "2",
    name: "Echoes of Nature Sound Machine",
    price: 0.25,
    description: "Relaxing sound machine with various nature sounds",
    image: soundMachine,
  },
  {
    id: "3",
    name: "Mystic Crystal Ball",
    price: 10.2,
    description: "Ornamental crystal ball with swirling mist effect",
    image: crystalBall,
  },
  {
    id: "4",
    name: "Retro Gaming Console",
    price: 0.3,
    description: "Vintage-inspired gaming console with preloaded classic games",
    image: console,
  },
  {
    id: "5",
    name: "Galaxy Star Projector",
    price: 4.5,
    description: "Immersive room projector displaying stars and galaxies",
    image: projector,
  },
  {
    id: "6",
    name: "Smart Herbal Teapot",
    price: 2.0,
    description: "Intelligent teapot with infusion timer and temperature control",
    image: teapot,
  },
  {
    id: "7",
    name: "Vintage Vinyl Record Player",
    price: 0.25,
    description: "Classic design record player with modern features",
    image: recordPlayer,
  },
  {
    id: "8",
    name: "Briefcase Charger",
    price: 0.25,
    description: "Eco-friendly, high-capacity solar-powered phone charger",
    image: charger,
  },
  {
    id: "9",
    name: "Digital Art Drawing Pad",
    price: 0.21,
    description: "High precision drawing pad for digital artists",
    image: drawing,
  },
  {
    id: "10",
    name: "Wireless Sleep Headphones",
    price: 0.21,
    description: "Comfortable, sleep-friendly wireless headphones",
    image: headphones,
  },
];
