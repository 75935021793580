import { useContext } from "react";
import styles from "pages/checkout/checkoutSuccess/CheckoutSuccess.module.scss";
import { CheckmarkCircleRegular as SuccessIcon } from "@fluentui/react-icons";
import RedirectCounter from "pages/checkout/checkoutSuccess/RedirectCounter";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { useTranslation } from "react-i18next";

export default function CheckoutSuccess() {
  const { t } = useTranslation("checkout");
  const { sessionResp } = useContext(CheckoutContext);

  return (
    <div className={styles.successWrapper}>
      <div className={styles.successIcon}>
        <SuccessIcon />
      </div>
      <div className={styles.successTitle}>{t("CheckoutSuccess")}</div>
      {sessionResp?.data?.redirectUrl && <RedirectCounter />}
    </div>
  );
}
