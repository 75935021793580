import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import styles from "pages/apikeys/ApiKeysPage.module.scss";
import api, { KeyRecord } from "api/api";
import { KeyMultiple20Regular as ApiKeysIcon, Save20Regular as SaveIcon } from "@fluentui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Form, InputRow, ResultField } from "components/forms/forms";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import classNames from "classnames";
import { FormEvent, useEffect, useRef, useState } from "react";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse } from "components/errors/validationErrors";

function ApiKeysPage() {
  const { t } = useTranslation("apiKeys");
  const { t: tp } = useTranslation("permissions");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const { keyId } = useParams<{ keyId: string }>();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errMessage, setErrMessage] = useState<string>();

  const [updatePermissions, { error: permissionSaveErrors, isLoading: loadingSavePerm }] =
    api.useUpdateApiKeyPermissionRolesMutation();

  // Get the API keys for the selected company
  // const [fetchKey, { data: apiKey }] = api.useLazyGetKeyByCompanyQuery();
  const { data: apiKey } = api.useGetKeyByCompanyQuery(
    { companyId: selectedCompanyId || "", keyId: keyId || "" },
    { skip: !selectedCompanyId || !keyId },
  );

  const { data: allRoles } = api.useGetAllApiKeyRolesQuery();

  const [invalidateApiKey, { isLoading: invalidatingLoading }] = api.useInvalidateKeyMutation();

  const handleInvalidate = (apiKeyUuid: string, companyId: string) => {
    return async () => {
      const result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: t("confirmInvalidate"),
      });
      if (result) {
        invalidateApiKey({
          keyId: apiKeyUuid,
          companyId: companyId,
        });
      }
    };
  };

  function setPermissions(apiKey: KeyRecord) {
    setIsAdmin(false);
    // otherwise, loop through the roles and check the checkboxes
    const checkboxes = formRef.current?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
    checkboxes?.forEach((checkbox) => {
      checkbox.checked = false;
      if (checkbox.name && apiKey.permissionRoles?.includes(checkbox.name)) {
        if (checkbox.name === "companyAdmin") {
          setIsAdmin(true);
        }
        checkbox.checked = true;
      }
    });
  }

  // useeffect to update the permissions form checkboxes when the apikey or allRoles changes
  useEffect(() => {
    // console.log(apiKey?.permissionRoles, allRoles);
    // if the apikey is not loaded, or the roles are not loaded, do nothing
    if (!apiKey || !allRoles) {
      return;
    }
    setPermissions(apiKey);
  }, [apiKey?.permissionRoles?.length, allRoles?.length]);

  // on submit, update the permissions for the key using api.useUpdateKeyPermissionsMutation
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Get all the data from the form
    // const form = new FormData(event.currentTarget);
    const roles: string[] = [];

    const checkboxes = event.currentTarget.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.name && checkbox.checked) {
        roles.push(checkbox.name);
      }
    });

    // Send it to the API
    updatePermissions({
      keyId: apiKey?.keyId || "",
      companyId: apiKey?.companyId || "",
      roles: {
        roles: roles,
      },
    });
  };

  function resetPermissions(apiKey?: KeyRecord) {
    setPermissions(apiKey || ({} as KeyRecord));
  }

  useEffect(() => {
    const err = getErrorResponse(permissionSaveErrors);
    setErrMessage(err?.message);
  }, [permissionSaveErrors]);

  return (
    <PopoutPageTemplate
      onClose={() => navigate(-1)}
      show={true}
      title={apiKey?.name}
      icon={
        <div className={styles.keyModalIconWrapper}>
          <ApiKeysIcon />
          <div className={classNames(styles.statusIndicator, { [styles.active]: !apiKey?.invalidatedAt })} />
        </div>
      }
    >
      <div className={styles.keysWrapper}>
        <InputRow>
          <ResultField label={t("Key ID")} copyText={apiKey?.keyId}>
            {apiKey?.keyId}
          </ResultField>
        </InputRow>
        <Button
          buttonPosition={ButtonPosition.fullWidth}
          key={"invalidate-button"}
          intercomTarget={"invalidate-button"}
          buttonColor={ColorVariant.error}
          onClick={handleInvalidate(apiKey?.keyId || "", apiKey?.companyId || "")}
          disabled={invalidatingLoading || !!apiKey?.invalidatedAt}
        >
          {!apiKey?.invalidatedAt ? t("Invalidate") : t("Invalidated")}
        </Button>
        <div className={styles.permissionsWrapper}>
          <div className={styles.permissionsHeader}>
            <h4>{t("Permissions", { ns: "common" })}</h4>
          </div>
          <Form
            className={styles.permissionsBody}
            intercomTarget={"permissions-form"}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            {/* list allRoles as a series of checkboxes */}
            {allRoles?.map((role) => (
              <div className={styles.permissionItem} key={role.role}>
                <Checkbox
                  id={role.role}
                  name={role.role}
                  label={tp(`${role.role}.label`)}
                  disabled={!!apiKey?.invalidatedAt || (isAdmin && role.role !== "companyAdmin")}
                  onChange={(e) => {
                    if (e.target.name === "companyAdmin") {
                      setIsAdmin(e.target.checked);
                    }
                  }}
                />
                {/* I'm using label here to let users also click the description */}
                <label htmlFor={role.role} className={styles.permissionDescription}>
                  {tp(`${role.role}.description`)}
                </label>
              </div>
            ))}
            {/* If there are errors then add a Note */}
            {permissionSaveErrors && (
              <Note noteType={NoteType.error} title={t("Error")}>
                {errMessage}
              </Note>
            )}
            <ButtonRow>
              <Button
                intercomTarget={"save-permissions-button"}
                buttonColor={ColorVariant.primary}
                type={"button"}
                disabled={!!apiKey?.invalidatedAt || loadingSavePerm}
                onClick={() => resetPermissions(apiKey)}
              >
                {t("Cancel")}
              </Button>
              <Button
                intercomTarget={"save-permissions-button"}
                buttonColor={ColorVariant.success}
                type={"submit"}
                disabled={!!apiKey?.invalidatedAt || loadingSavePerm}
                icon={loadingSavePerm ? <Spinny /> : <SaveIcon className={styles.loadingIcon} />}
              >
                {t("Save permissions")}
              </Button>
            </ButtonRow>
          </Form>
        </div>
      </div>
    </PopoutPageTemplate>
  );
}

export default ApiKeysPage;
