import { ReactNode } from "react";
import styles from "layout/page.module.scss";

interface pageProps {
  children: ReactNode;
  head?: ReactNode;
}

function Page(props: pageProps) {
  return (
    <div className={styles.page}>
      {props.head && <div className={styles.headContainer}>{props.head}</div>}
      {props.children}
    </div>
  );
}

export default Page;
