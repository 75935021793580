import { useTranslation } from "react-i18next";
import styles from "pages/checkout/checkoutPage.module.scss";
import { CheckoutContext } from "pages/checkout/CheckoutPage"; // Icon for adding a user from fluentui
import { ArrowEnterRegular as GuestIcon, PersonAddRegular as CreateAccountIcon } from "@fluentui/react-icons";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import classNames from "classnames";

export default function AccountOptions() {
  const { t } = useTranslation("checkout");

  const navigate = useNavigate();

  const { setIsGuest, customerResp, sessionId, email, paymentMethod } = useContext(CheckoutContext);

  const allowGuest = customerResp?.data?.allowGuest || undefined;

  function navigateCreateAccount() {
    setIsGuest(false);

    navigate(`${routes.CONSUMER_ACCOUNT}?ps=${sessionId}`, {
      state: {
        customerEmail: email,
        paymentMethod: paymentMethod,
      },
    });
  }

  return (
    <div className={styles.accountOptionsWrapper}>
      <div
        className={classNames(styles.accountOption, { [styles.accountOptionDisabled]: !allowGuest })}
        title={t("ContinueAsGuest")}
        onClick={() => {
          if (allowGuest) {
            setIsGuest(true);
          }
        }}
      >
        <div className={styles.optionIcon}>
          <GuestIcon />
        </div>
        <div className={styles.accountOptionTextWrapper}>
          <div className={styles.optionLabel}>{t("ContinueAsGuest")}</div>
          <div className={styles.optionText}>{t("ContinueAsGuestInfo")}</div>
        </div>
      </div>
      <div className={classNames(styles.accountOption)} title={t("CreateAccount")} onClick={navigateCreateAccount}>
        <div className={styles.optionIcon}>
          <CreateAccountIcon />
        </div>
        <div className={styles.accountOptionTextWrapper}>
          <div className={styles.optionLabel}>{t("CreateAccount")}</div>
          <div className={styles.optionText}>{t("ContinueWithAccountInfo")}</div>
        </div>
      </div>
    </div>
  );
}
