//modules is an object with the keys found in steps, it should return an executable component
import { FormEvent, ReactElement } from "react";
import { StepProps } from "pages/consumerCompliance/modules/types";
import ComplianceForm from "pages/consumerCompliance/modules/complianceForm/ComplianceForm";
import Identomat from "pages/consumerCompliance/modules/indentomat/Identomat";
import { useTranslation } from "react-i18next";
import { ProgressStepState, Step } from "features/progressTabs/ProgressHeader";
import Complete from "pages/consumerCompliance/modules/complete/Complete";
import PersonalInfo from "pages/consumerCompliance/modules/personalInfo/PersonalInfo";
import Button, { ButtonRow } from "components/buttons/Button";
import styles from "pages/consumerCompliance/ComplianceCheckPage.module.scss";

// This is a list of modules that can be rendered based on the step name. We update this whenever we
// add a new type of step. For example add a new KYC provider for a specific country.
export const modules: { [key: string]: (props: StepProps) => ReactElement } = {
  personalInfo: PersonalInfo,
  questionnaire: ComplianceForm,
  identomat: Identomat,
  complete: Complete,
};

function GetStepComponent(props: StepProps) {
  return (modules?.[props.stepName] && modules?.[props.stepName]({ ...props })) || <div>Step not found</div>;
}

// RenderStepHeaders will render the step headers based on the steps array from the backend.
export function RenderStepHeaders(
  steps: string[],
  step: number,
  setStep: (step: number) => void,
  completedSteps: string[],
) {
  const { t } = useTranslation("consumerCompliance");

  const getState = (index: number) => {
    if (completedSteps.includes(steps[index])) {
      return ProgressStepState.completed;
    } else if (index === step) {
      return ProgressStepState.active;
    } else {
      return ProgressStepState.disabled;
    }
  };

  return steps.map((stepName, index) => {
    return <Step key={index} label={t(stepName)} state={getState(index)} onClick={() => setStep(index)} />;
  });
}

// RenderSteps will render the steps based on the steps array.
// TODO: Change this to accept a unknown object with additional data. This can be type checked and used in the steps.
export function RenderSteps(
  steps: string[],
  step: number,
  setStep: (step: number) => void,
  completedSteps: string[],
  setCompletedSteps: (updateFunction: (prevSteps: string[]) => string[]) => void,
) {
  return steps.map((stepName, index) => {
    return (
      <div key={index}>
        <GetStepComponent
          stepName={stepName}
          step={step}
          setStep={setStep}
          index={index}
          steps={steps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
      </div>
    );
  });
}

export function NavigateButtons(props: {
  step: number;
  hideBack?: boolean;
  disableNext?: boolean;
  showSave?: boolean;
  loading?: boolean;
  handleBack: (event: FormEvent<HTMLButtonElement>) => void;
  handleNext?: (event: FormEvent<HTMLButtonElement>) => void;
  handleSave?: (event: FormEvent<HTMLButtonElement>) => void;
}) {
  const { t } = useTranslation("consumerCompliance");

  return (
    <ButtonRow className={styles.navigateButtons}>
      {props.handleNext ? (
        <Button onClick={props.handleNext} disabled={props.disableNext || props.loading}>
          {t("Next")}
        </Button>
      ) : (
        <Button disabled={props.loading} type="submit">
          {t("Next")}
        </Button>
      )}

      {props.showSave && (
        <>
          {props.handleSave ? (
            <Button onClick={props.handleSave} disabled={props.disableNext || props.loading}>
              {t("Save")}
            </Button>
          ) : (
            <Button disabled={props.loading} type="submit">
              {t("Save")}
            </Button>
          )}
        </>
      )}

      {!props.hideBack ? (
        <Button disabled={props.loading} onClick={props.handleBack}>
          {t("Back")}
        </Button>
      ) : (
        <div />
      )}
    </ButtonRow>
  );
}
