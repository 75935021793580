import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import api, { Country, ProductRecord, ShoppingCartRecord, VATType } from "api/api";
import Button, { ButtonPosition, SizeVariant } from "components/buttons/Button";
import { ServerErrorResultType } from "components/errors/errors";
import styles from "pages/shop/shopProducts.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cart20Filled as CartIcon, Delete20Filled as DeleteIcon } from "@fluentui/react-icons";
import ShoppingCartToast from "pages/shop/ShoppingCartToast";
import { CurrencyIso3, CurrencyUnits } from "constants/backend";
import { ShopViewParams } from "pages/shop/ShopPage";
import { AttachmentImageCarousel } from "components/imageCarousel/AttachmentImageCarousel";

function ShopProducts(props: ShopViewParams) {
  const { t } = useTranslation("shop");

  const [newCartProduct, setNewCartProduct] = useState<{
    product?: ProductRecord;
    quantity?: number;
  }>();

  const [vatCountry, setVatCountry] = useState<Country | undefined>(undefined);

  function getProductPriceWithVat(product: ProductRecord): number | string | undefined {
    if (!vatCountry || !product.price) {
      return product.priceFloat;
    }

    let vat: number | undefined;

    // vat based on country and product vat type
    switch (product.vatType) {
      case VATType.Standard:
        vat = vatCountry.vatStandard;
        break;
      case VATType.Reduced:
        vat = vatCountry.vatReduced;
        break;
      case VATType.ReducedAlt:
        vat = vatCountry.vatReducedAlt;
        break;
      case VATType.SuperReduced:
        vat = vatCountry.vatSuperReduced;
        break;
      case VATType.Parking:
        vat = vatCountry.vatParking;
        break;
    }

    return product.priceFloat === undefined ? undefined : (product.priceFloat * (1 + (vat || 0))).toFixed(2);
  }

  const countriesResponse = api.useGetCountriesQuery();

  const ipCountryResponse = api.useGetIpCountryQuery(undefined, {
    refetchOnReconnect: true,
  });

  const productsResponse = api.useListProductsQuery(
    { companyShop: props.shop?.shopHandle || "", limit: 100, offset: 0 },
    {
      refetchOnMountOrArgChange: true,
      skip: !props.shop?.shopHandle,
    },
  );

  const [createCart] = api.useCreateShoppingCartMutation();

  // set the country to take vat from
  useEffect(() => {
    // default from company
    let country = props.shop?.companyCountry;

    // for eu dynamic, use cart country or country from user ip
    if (props.shop?.shopVatType === "eu_dynamic" && ipCountryResponse.data) {
      // vat is taken from the country of the consumer
      if (props.shoppingCart?.vatCountryIso3 && countriesResponse.data) {
        country = countriesResponse.data.find((c) => c.iso3 === props.shoppingCart?.vatCountryIso3) || country;
      } else {
        country = ipCountryResponse.data || country;
      }
    }

    setVatCountry(country);
  }, [props.shop, ipCountryResponse, countriesResponse]);

  // create cart if it doesn't exist, add product
  function handleUpdateProductInCart(product: ProductRecord, quantity: number) {
    if (!props.shop?.shopHandle) {
      return;
    }

    if (props.shoppingCart?.shoppingCartId) {
      props.updateProductInCart?.(
        product,
        quantity,
        props.shoppingCart?.shoppingCartId,
        (product: ProductRecord, quantity: number) => {
          setNewCartProduct({ product, quantity });
        },
      );
    } else if (!props.shoppingCart?.shoppingCartId) {
      // create the cart if not in a cart
      createCart({
        companyShop: props.shop?.shopHandle,
        shoppingCart: {
          currencyIso3: product.currencyIso3,
        },
      })
        .unwrap()
        .catch((err: ServerErrorResultType | FetchBaseQueryError) => {
          props.handleApiError(err);
        })
        .then((res: ShoppingCartRecord | void) => {
          if (!res?.shoppingCartId) {
            console.error("Error creating shopping cart");
            return;
          }

          props.setCartId?.(res.shoppingCartId);

          // after the cart is created, add the product
          props.updateProductInCart?.(
            product,
            quantity,
            res.shoppingCartId,
            (product: ProductRecord, quantity: number) => {
              setNewCartProduct({ product, quantity });
            },
          );
        });
    }
  }

  function amountInCart(productId?: string) {
    if (!productId) {
      return 0;
    }

    return props.shoppingCart?.products?.find((p) => p.productId === productId)?.quantity || 0;
  }

  return (
    <div>
      <div className={styles.productGrid}>
        {productsResponse.data?.records
          ?.filter((product) => product.status === "active")
          .map((product, index) => (
            <div key={index} className={styles.productCard}>
              <AttachmentImageCarousel
                className={styles.productImage}
                imagePaths={product.publicImageAttachmentPaths || []}
              />
              <h3 className={styles.productName}>{product.displayName}</h3>
              <p className={styles.productDescription}>{product.description}</p>
              <div className={styles.productPrice}>
                {getProductPriceWithVat(product)}
                {product.currencyIso3 && product.currencyIso3 in CurrencyUnits
                  ? CurrencyUnits[product.currencyIso3 as CurrencyIso3]
                  : ""}
              </div>
              <div className={styles.addToCartButtonRow}>
                <Button
                  buttonPosition={ButtonPosition.none}
                  icon={<CartIcon />}
                  onClick={() => {
                    if (product.productId) {
                      handleUpdateProductInCart(product, amountInCart(product.productId) + 1);
                    }
                  }}
                >
                  {t("addToCart")}
                </Button>
                {amountInCart(product.productId) > 0 && (
                  <>
                    <div className={styles.amountInCartIndicator}>
                      <CartIcon /> <span>{amountInCart(product.productId)}x</span>
                    </div>
                    <Button
                      icon={<DeleteIcon />}
                      buttonSize={SizeVariant.tiny}
                      onClick={() => {
                        if (product.productId) {
                          // remove from cart
                          handleUpdateProductInCart(product, 0);
                        }
                      }}
                    ></Button>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
      <ShoppingCartToast
        shopHandle={props.shop?.shopHandle}
        product={newCartProduct?.product}
        quantity={newCartProduct?.quantity}
        cart={props.shoppingCart}
      />
    </div>
  );
}

export default ShopProducts;
