import { useEffect, useState } from "react";
import { Dismiss20Regular as CloseIcon, Cart20Filled as CartIcon } from "@fluentui/react-icons";
import { CSSTransition } from "react-transition-group";
import styles from "pages/shop/shoppingCartToast.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import * as routes from "constants/routes";
import { ProductRecord, ShoppingCartRecordJoined } from "api/api.generated";
import classNames from "classnames";

export default function ShoppingCartToast(props: {
  shopHandle?: string;
  product?: ProductRecord; // new product that was added
  quantity?: number; // new quantity of the product
  cart?: ShoppingCartRecordJoined;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("shop");
  const [visible, setVisible] = useState(false);

  const [quantity, setQuantity] = useState<number | undefined>();
  const [product, setProduct] = useState<ProductRecord | undefined>();

  // show when product or cart changes
  useEffect(() => {
    if (props.product && props.cart) {
      show(props.product, props.quantity);
    }
  }, [props.cart]);

  function close() {
    setVisible(false);
  }

  function show(prod?: ProductRecord, quant?: number) {
    if (visible) {
      close();
    }

    setTimeout(() => {
      setProduct(prod);
      setQuantity(quant);
      setVisible(true);
    }, 500);
  }

  return (
    <div className={classNames(styles.toastContainer, { [styles.hidden]: !visible })}>
      <CSSTransition
        timeout={500}
        in={visible}
        classNames={{
          enter: styles.toastEnter,
          enterActive: styles.toastEnterActive,
          exit: styles.toastExit,
          exitActive: styles.toastExitActive,
        }}
      >
        <div className={styles.toast}>
          <div className={styles.close} onClick={close}>
            <CloseIcon />
          </div>
          <div className={styles.toastContent}>
            {(quantity || 0) > 0 && <div>{t("productAddedToCart", { product: product?.displayName })}</div>}
            {(quantity || 0) === 0 && <div>{t("productDeletedFromCart", { product: product?.displayName })}</div>}

            <div>
              <Button
                onClick={() => {
                  if (props.shopHandle) {
                    navigate(routes.SHOPPING_CART.replace(":shopHandle", props.shopHandle));
                  }
                }}
                icon={<CartIcon />}
              >
                {t("viewCart")}
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
