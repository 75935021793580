import { QuestionCircle16Regular as HelpIcon } from "@fluentui/react-icons";
import styles from "components/forms/helpTooltip/helpTooltip.module.scss";

export default function HelpTooltip(props: { label?: string; text?: string }) {
  return (
    <>
      {props.text && (
        <div className={styles.tooltip}>
          <HelpIcon />
          <div className={styles.tooltipTextWrapper}>
            <div className={styles.tooltipTextContainer}>
              <div className={styles.tooltipHeader}>{props?.label}</div>
              <div className={styles.tooltipText}>{props.text}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
