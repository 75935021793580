import classNames from "classnames";
import React, { ReactNode } from "react";
import { Checkmark16Filled as CheckedIcon, LineHorizontal1Filled as DisabledIcon } from "@fluentui/react-icons";
import styles from "components/forms/checkbox/checkbox.module.scss";
import { GetColorClass, InputColorVaraint } from "components/forms/variants";

export enum CheckboxSize {
  normal = "normal",
  small = "small",
  tiny = "tiny",
}

const CheckboxSizeClasses = new Map<CheckboxSize, string>([
  [CheckboxSize.normal, styles.normal],
  [CheckboxSize.small, styles.small],
  [CheckboxSize.tiny, styles.tiny],
]);

export type CheckboxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  sizeVariant?: CheckboxSize;
  colorVariant?: InputColorVaraint;
  label: string | ReactNode;
  intercomTarget?: string;
  errors?: string[];
  infoText?: string;
  warningText?: string;
};

export default function Checkbox({
  label,
  sizeVariant,
  colorVariant,
  intercomTarget,
  errors,
  infoText,
  warningText,
  ...rest
}: CheckboxProps) {
  const sizeClass = CheckboxSizeClasses.get(sizeVariant || CheckboxSize.normal);

  let colorClass = GetColorClass(colorVariant || InputColorVaraint.primary);
  if (warningText != undefined) {
    colorClass = GetColorClass(InputColorVaraint.warning);
  }
  if (errors != undefined) {
    colorClass = GetColorClass(InputColorVaraint.error);
  }

  return (
    <div
      className={classNames(styles.checkboxWrapper, sizeClass, colorClass, {
        [styles.disabled]: rest.disabled === true,
      })}
    >
      <label
        className={classNames(styles.checkboxContainer)}
        data-intercom-target={intercomTarget}
        tabIndex={0}
        onKeyDown={(e) => {
          // on any character key press, space or enter, click the checkbox
          if (e.key === " " || e.key === "Enter") {
            e.currentTarget.click();
          }
        }}
      >
        <input {...rest} type="checkbox" className={styles.checkbox} />
        <div className={styles.checkboxIcon}>{rest.disabled ? <DisabledIcon /> : <CheckedIcon />}</div>
        <div>{label}</div>
      </label>
      {infoText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackInfo)}>
          <div className={styles.feedbackText}>{infoText}</div>
        </div>
      )}
      {errors && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackError)}>
          <div className={styles.feedbackText}>{errors?.join(", ")}</div>
        </div>
      )}
      {warningText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackWarning)}>
          <div className={styles.feedbackText}>{warningText}</div>
        </div>
      )}
    </div>
  );
}
