import { useTokenLoginMutation } from "api/manualApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as routes from "constants/routes";
import { useEffect, useState } from "react";
import { getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import { useTranslation } from "react-i18next";

function TokenLoginPage() {
  const { t: tv } = useTranslation("validation");
  const [searchParams, ] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const to = searchParams.get("to") || "";
  const [login, { error: loginError, isSuccess }] = useTokenLoginMutation();
  const [errMessage, setErrMessage] = useState<string>();

  useEffect(() => {
    if (token) {
      login(token);
    } else {
      setErrMessage("Token missing");
    }
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      // Replace the current page to prevent the user from navigating back to it
      navigate("/", { replace: true });
      // Use setTimeout to ensure the replace action completes before navigating
      setTimeout(() => navigate(routes.ROOT + to, { replace: false }), 0);
    }
  }, [isSuccess]);

  useEffect(() => {
    const err = getErrorResponse(loginError);
    if (err?.message) {
      setErrMessage(err?.message);
    } else if (err) {
      setErrMessage("Unknown error");
    }
  }, [loginError]);

  return <div>{errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}</div>;
}

export default TokenLoginPage;
