import Page from "layout/Page";
import PageTitle from "components/pageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import AdminSettingsPanel from "pages/superAdmin/adminSettings";

function SuperAdminPage() {
  const { t } = useTranslation();

  return (
    <Page head={<PageTitle title={t("Admin Settings")} />}>
      <AdminSettingsPanel />
    </Page>
  );
}

export default SuperAdminPage;
