import { merchantServicesApi as api } from "api/manualApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    edenAdminGetBankTransferWithdrawals: build.query<
      EdenAdminGetBankTransferWithdrawalsResp,
      EdenAdminGetBankTransferWithdrawalsArg
    >({
      query: () => ({ url: `/admin/bank-transfers/bank-withdrawals` }),
    }),
    edenAdminApproveBankTransfer: build.mutation<EdenAdminApproveBankTransferResp, EdenAdminApproveBankTransferArg>({
      query: (queryArg) => ({ url: `/admin/bank-transfers/${queryArg}/approve`, method: "PUT" }),
    }),
    edenAdminCancelBankTransfer: build.mutation<EdenAdminCancelBankTransferResp, EdenAdminCancelBankTransferArg>({
      query: (queryArg) => ({ url: `/admin/bank-transfers/${queryArg}/cancel`, method: "PUT" }),
    }),
    getAllComplianceHits: build.query<GetAllComplianceHitsResp, GetAllComplianceHitsArg>({
      query: (queryArg) => ({
        url: `/admin/compliance-hits`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
        },
      }),
    }),
    createComplianceHit: build.mutation<CreateComplianceHitResp, CreateComplianceHitArg>({
      query: (queryArg) => ({ url: `/admin/compliance-hits`, method: "POST", body: queryArg }),
    }),
    getComplianceHit: build.query<GetComplianceHitResp, GetComplianceHitArg>({
      query: (queryArg) => ({ url: `/admin/compliance-hits/${queryArg}` }),
    }),
    getComplianceHitJoined: build.query<GetComplianceHitJoinedResp, GetComplianceHitJoinedArg>({
      query: (queryArg) => ({ url: `/admin/compliance-hits/${queryArg}/joined` }),
    }),
    updateComplianceHitStatus: build.mutation<UpdateComplianceHitStatusResp, UpdateComplianceHitStatusArg>({
      query: (queryArg) => ({
        url: `/admin/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getAllCustomers: build.query<GetAllCustomersResp, GetAllCustomersArg>({
      query: (queryArg) => ({
        url: `/admin/customers`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    getAllFailureEvents: build.query<GetAllFailureEventsResp, GetAllFailureEventsArg>({
      query: () => ({ url: `/admin/failure-events` }),
    }),
    getAllSystemSettingsFlags: build.query<GetAllSystemSettingsFlagsResp, GetAllSystemSettingsFlagsArg>({
      query: () => ({ url: `/admin/system/settings/flags` }),
    }),
    updateSystemSettingsFlag: build.mutation<UpdateSystemSettingsFlagResp, UpdateSystemSettingsFlagArg>({
      query: (queryArg) => ({
        url: `/admin/system/settings/flags/${queryArg.flag}`,
        method: "PUT",
        body: queryArg.systemSettingsFlagInternal,
      }),
    }),
    getAllUsers: build.query<GetAllUsersResp, GetAllUsersArg>({
      query: (queryArg) => ({
        url: `/admin/users`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    createAdminUser: build.mutation<CreateAdminUserResp, CreateAdminUserArg>({
      query: (queryArg) => ({ url: `/admin/users`, method: "POST", body: queryArg }),
    }),
    getUserById: build.query<GetUserByIdResp, GetUserByIdArg>({
      query: (queryArg) => ({ url: `/admin/users/${queryArg}` }),
    }),
    deleteUser: build.mutation<DeleteUserResp, DeleteUserArg>({
      query: (queryArg) => ({ url: `/admin/users/${queryArg}`, method: "DELETE" }),
    }),
    getUserCompanies: build.query<GetUserCompaniesResp, GetUserCompaniesArg>({
      query: (queryArg) => ({ url: `/admin/users/${queryArg}/companies` }),
    }),
    getUserHasEdenAdminRole: build.query<GetUserHasEdenAdminRoleResp, GetUserHasEdenAdminRoleArg>({
      query: (queryArg) => ({ url: `/admin/users/${queryArg}/permission-roles/edenAdmin` }),
    }),
    updateUserEdenAdminPermissionRole: build.mutation<
      UpdateUserEdenAdminPermissionRoleResp,
      UpdateUserEdenAdminPermissionRoleArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.userId}/permission-roles/edenAdmin`,
        method: "PATCH",
        body: queryArg.roles,
      }),
    }),
    getCheckoutSession: build.query<GetCheckoutSessionResp, GetCheckoutSessionArg>({
      query: (queryArg) => ({ url: `/checkout/${queryArg}` }),
    }),
    getAttachmentsByPaymentSessionId: build.query<
      GetAttachmentsByPaymentSessionIdResp,
      GetAttachmentsByPaymentSessionIdArg
    >({
      query: (queryArg) => ({ url: `/checkout/${queryArg.paymentSessionId}/attachments` }),
    }),
    createCheckoutAttachment: build.mutation<CreateCheckoutAttachmentResp, CreateCheckoutAttachmentArg>({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCheckoutAttachment: build.mutation<UpdateCheckoutAttachmentResp, UpdateCheckoutAttachmentArg>({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCheckoutAttachment: build.mutation<DeleteCheckoutAttachmentResp, DeleteCheckoutAttachmentArg>({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getComplianceHitsByPaymentSessionId: build.query<
      GetComplianceHitsByPaymentSessionIdResp,
      GetComplianceHitsByPaymentSessionIdArg
    >({
      query: (queryArg) => ({ url: `/checkout/${queryArg.paymentSessionId}/compliance-hits` }),
    }),
    createCheckoutComplianceHit: build.mutation<CreateCheckoutComplianceHitResp, CreateCheckoutComplianceHitArg>({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    updateCheckoutComplianceHitStatus: build.mutation<
      UpdateCheckoutComplianceHitStatusResp,
      UpdateCheckoutComplianceHitStatusArg
    >({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    requestCheckoutAttempt: build.mutation<RequestCheckoutAttemptResp, RequestCheckoutAttemptArg>({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/payment-attempts`,
        method: "POST",
        body: queryArg.checkoutAttempt,
      }),
    }),
    getComplianceHitsByRefundSessionId: build.query<
      GetComplianceHitsByRefundSessionIdResp,
      GetComplianceHitsByRefundSessionIdArg
    >({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/refund-sessions/${queryArg.refundSessionId}/compliance-hits`,
      }),
    }),
    createCheckoutRefundSessionComplianceHit: build.mutation<
      CreateCheckoutRefundSessionComplianceHitResp,
      CreateCheckoutRefundSessionComplianceHitArg
    >({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/refund-sessions/${queryArg.refundSessionId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    updateCheckoutRefundSessionComplianceHitStatus: build.mutation<
      UpdateCheckoutRefundSessionComplianceHitStatusResp,
      UpdateCheckoutRefundSessionComplianceHitStatusArg
    >({
      query: (queryArg) => ({
        url: `/checkout/${queryArg.paymentSessionId}/refund-sessions/${queryArg.refundSessionId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getCheckoutStatus: build.query<GetCheckoutStatusResp, GetCheckoutStatusArg>({
      query: (queryArg) => ({ url: `/checkout/${queryArg}/status` }),
    }),
    getAllCompanies: build.query<GetAllCompaniesResp, GetAllCompaniesArg>({
      query: () => ({ url: `/companies` }),
    }),
    createCompany: build.mutation<CreateCompanyResp, CreateCompanyArg>({
      query: (queryArg) => ({ url: `/companies`, method: "POST", body: queryArg }),
    }),
    getCompanyById: build.query<GetCompanyByIdResp, GetCompanyByIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}` }),
    }),
    updateCompany: build.mutation<UpdateCompanyResp, UpdateCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}`, method: "PUT", body: queryArg.company }),
    }),
    deactivateCompany: build.mutation<DeactivateCompanyResp, DeactivateCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}`, method: "DELETE" }),
    }),
    getAddressesByCompany: build.query<GetAddressesByCompanyResp, GetAddressesByCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/addresses` }),
    }),
    createCompanyAddress: build.mutation<CreateCompanyAddressResp, CreateCompanyAddressArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses`,
        method: "POST",
        body: queryArg.address,
      }),
    }),
    getAddressByCompanyId: build.query<GetAddressByCompanyIdResp, GetAddressByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}` }),
    }),
    updateCompanyAddress: build.mutation<UpdateCompanyAddressResp, UpdateCompanyAddressArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}`,
        method: "PUT",
        body: queryArg.address,
      }),
    }),
    deleteCompanyAddress: build.mutation<DeleteCompanyAddressResp, DeleteCompanyAddressArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}`,
        method: "DELETE",
      }),
    }),
    getCompanyAddressAttachmentsByCompanyAddressId: build.query<
      GetCompanyAddressAttachmentsByCompanyAddressIdResp,
      GetCompanyAddressAttachmentsByCompanyAddressIdArg
    >({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}/attachments` }),
    }),
    createCompanyAddressAttachment: build.mutation<
      CreateCompanyAddressAttachmentResp,
      CreateCompanyAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCompanyAddressAttachment: build.mutation<
      UpdateCompanyAddressAttachmentResp,
      UpdateCompanyAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCompanyAddressAttachment: build.mutation<
      DeleteCompanyAddressAttachmentResp,
      DeleteCompanyAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCompanyAddressAttachment: build.query<GetCompanyAddressAttachmentResp, GetCompanyAddressAttachmentArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    getAttachmentsByCompanyId: build.query<GetAttachmentsByCompanyIdResp, GetAttachmentsByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/attachments` }),
    }),
    createCompanyAttachment: build.mutation<CreateCompanyAttachmentResp, CreateCompanyAttachmentArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCompanyAttachment: build.mutation<UpdateCompanyAttachmentResp, UpdateCompanyAttachmentArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCompanyAttachment: build.mutation<DeleteCompanyAttachmentResp, DeleteCompanyAttachmentArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCompanyAttachment: build.query<GetCompanyAttachmentResp, GetCompanyAttachmentArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/attachments/${queryArg.attachmentId}/metadata` }),
    }),
    getBankAccountsByCompanyId: build.query<GetBankAccountsByCompanyIdResp, GetBankAccountsByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/bank-accounts` }),
    }),
    createCompanyBankAccount: build.mutation<CreateCompanyBankAccountResp, CreateCompanyBankAccountArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/bank-accounts`,
        method: "POST",
        body: queryArg.bankAccount,
      }),
    }),
    getBankAccountByCompanyId: build.query<GetBankAccountByCompanyIdResp, GetBankAccountByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/bank-accounts/${queryArg.bankAccountId}` }),
    }),
    deleteCompanyBankAccount: build.mutation<DeleteCompanyBankAccountResp, DeleteCompanyBankAccountArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/bank-accounts/${queryArg.bankAccountId}`,
        method: "DELETE",
      }),
    }),
    getCompanyBankTransfersByAccount: build.query<
      GetCompanyBankTransfersByAccountResp,
      GetCompanyBankTransfersByAccountArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/bank-accounts/${queryArg.bankAccountId}/bank-transfers`,
      }),
    }),
    getBankTransferByCompanyId: build.query<GetBankTransferByCompanyIdResp, GetBankTransferByCompanyIdArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/bank-accounts/${queryArg.bankAccountId}/bank-transfers/${queryArg.bankTransferId}`,
      }),
    }),
    getCompanyBankTransferWithdrawals: build.query<
      GetCompanyBankTransferWithdrawalsResp,
      GetCompanyBankTransferWithdrawalsArg
    >({
      query: (queryArg) => ({ url: `/companies/${queryArg}/bank-withdrawals` }),
    }),
    getComplianceHitsByCompanyId: build.query<GetComplianceHitsByCompanyIdResp, GetComplianceHitsByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/compliance-hits` }),
    }),
    createCompanyComplianceHit: build.mutation<CreateCompanyComplianceHitResp, CreateCompanyComplianceHitArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    getCompanyComplianceHit: build.query<GetCompanyComplianceHitResp, GetCompanyComplianceHitArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}` }),
    }),
    getCompanyComplianceHitAttachmentsByCompanyComplianceHitId: build.query<
      GetCompanyComplianceHitAttachmentsByCompanyComplianceHitIdResp,
      GetCompanyComplianceHitAttachmentsByCompanyComplianceHitIdArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
      }),
    }),
    createCompanyComplianceHitAttachment: build.mutation<
      CreateCompanyComplianceHitAttachmentResp,
      CreateCompanyComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCompanyComplianceHitAttachment: build.mutation<
      UpdateCompanyComplianceHitAttachmentResp,
      UpdateCompanyComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCompanyComplianceHitAttachment: build.mutation<
      DeleteCompanyComplianceHitAttachmentResp,
      DeleteCompanyComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCompanyComplianceHitAttachment: build.query<
      GetCompanyComplianceHitAttachmentResp,
      GetCompanyComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    updateCompanyComplianceHitStatus: build.mutation<
      UpdateCompanyComplianceHitStatusResp,
      UpdateCompanyComplianceHitStatusArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getCustomersByCompanyId: build.query<GetCustomersByCompanyIdResp, GetCustomersByCompanyIdArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/customers`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    dashboardStats: build.query<DashboardStatsResp, DashboardStatsArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/dashboard/stats`,
        params: { from: queryArg["from"], to: queryArg.to, unit: queryArg.unit },
      }),
    }),
    getIdentificationsByCompanyId: build.query<GetIdentificationsByCompanyIdResp, GetIdentificationsByCompanyIdArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/identifications` }),
    }),
    createCompanyIdentification: build.mutation<CreateCompanyIdentificationResp, CreateCompanyIdentificationArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications`,
        method: "POST",
        body: queryArg.identification,
      }),
    }),
    getCompanyIdentification: build.query<GetCompanyIdentificationResp, GetCompanyIdentificationArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}` }),
    }),
    updateCompanyIdentification: build.mutation<UpdateCompanyIdentificationResp, UpdateCompanyIdentificationArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}`,
        method: "PUT",
        body: queryArg.identification,
      }),
    }),
    deleteCompanyIdentification: build.mutation<DeleteCompanyIdentificationResp, DeleteCompanyIdentificationArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}`,
        method: "DELETE",
      }),
    }),
    getCompanyIdentificationAttachmentsByCompanyIdentificationId: build.query<
      GetCompanyIdentificationAttachmentsByCompanyIdentificationIdResp,
      GetCompanyIdentificationAttachmentsByCompanyIdentificationIdArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}/attachments`,
      }),
    }),
    createCompanyIdentificationAttachment: build.mutation<
      CreateCompanyIdentificationAttachmentResp,
      CreateCompanyIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCompanyIdentificationAttachment: build.mutation<
      UpdateCompanyIdentificationAttachmentResp,
      UpdateCompanyIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCompanyIdentificationAttachment: build.mutation<
      DeleteCompanyIdentificationAttachmentResp,
      DeleteCompanyIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCompanyIdentificationAttachment: build.query<
      GetCompanyIdentificationAttachmentResp,
      GetCompanyIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    getKeysByCompany: build.query<GetKeysByCompanyResp, GetKeysByCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/keys` }),
    }),
    createKey: build.mutation<CreateKeyResp, CreateKeyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/keys`, method: "POST", body: queryArg.newKey }),
    }),
    getKeyByCompany: build.query<GetKeyByCompanyResp, GetKeyByCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/keys/${queryArg.keyId}` }),
    }),
    invalidateKey: build.mutation<InvalidateKeyResp, InvalidateKeyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/keys/${queryArg.keyId}`, method: "DELETE" }),
    }),
    updateApiKeyPermissionRoles: build.mutation<UpdateApiKeyPermissionRolesResp, UpdateApiKeyPermissionRolesArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/keys/${queryArg.keyId}/permission-roles`,
        method: "PATCH",
        body: queryArg.roles,
      }),
    }),
    getPaymentSessionsByCompanyId: build.query<GetPaymentSessionsByCompanyIdResp, GetPaymentSessionsByCompanyIdArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    createCompanyPaymentSession: build.mutation<CreateCompanyPaymentSessionResp, CreateCompanyPaymentSessionArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions`,
        method: "POST",
        body: queryArg.paymentSession,
      }),
    }),
    getCompanyPaymentSessionByReference: build.query<
      GetCompanyPaymentSessionByReferenceResp,
      GetCompanyPaymentSessionByReferenceArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions/reference/${queryArg.reference}`,
      }),
    }),
    getCompanyPaymentSession: build.query<GetCompanyPaymentSessionResp, GetCompanyPaymentSessionArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/payment-sessions/${queryArg.paymentSessionId}` }),
    }),
    cancelCompanyPaymentSession: build.mutation<CancelCompanyPaymentSessionResp, CancelCompanyPaymentSessionArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions/${queryArg.paymentSessionId}/cancel`,
        method: "POST",
      }),
    }),
    getCompanyRefundSessions: build.query<GetCompanyRefundSessionsResp, GetCompanyRefundSessionsArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions/${queryArg.paymentSessionId}/refund-sessions`,
      }),
    }),
    createCompanyRefundSession: build.mutation<CreateCompanyRefundSessionResp, CreateCompanyRefundSessionArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions/${queryArg.paymentSessionId}/refund-sessions`,
        method: "POST",
        body: queryArg.refundSession,
      }),
    }),
    getCompanyRefundSession: build.query<GetCompanyRefundSessionResp, GetCompanyRefundSessionArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/payment-sessions/${queryArg.paymentSessionId}/refund-sessions/${queryArg.refundSessionId}`,
      }),
    }),
    listCompanyProducts: build.query<ListCompanyProductsResp, ListCompanyProductsArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    createCompanyProduct: build.mutation<CreateCompanyProductResp, CreateCompanyProductArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products`,
        method: "POST",
        body: queryArg.product,
      }),
    }),
    getCompanyProduct: build.query<GetCompanyProductResp, GetCompanyProductArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/products/${queryArg.productId}` }),
    }),
    updateCompanyProduct: build.mutation<UpdateCompanyProductResp, UpdateCompanyProductArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products/${queryArg.productId}`,
        method: "PUT",
        body: queryArg.product,
      }),
    }),
    getCompanyProductAttachmentsByCompanyProductId: build.query<
      GetCompanyProductAttachmentsByCompanyProductIdResp,
      GetCompanyProductAttachmentsByCompanyProductIdArg
    >({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/products/${queryArg.productId}/attachments` }),
    }),
    createCompanyProductAttachment: build.mutation<
      CreateCompanyProductAttachmentResp,
      CreateCompanyProductAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products/${queryArg.productId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCompanyProductAttachment: build.mutation<
      UpdateCompanyProductAttachmentResp,
      UpdateCompanyProductAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products/${queryArg.productId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCompanyProductAttachment: build.mutation<
      DeleteCompanyProductAttachmentResp,
      DeleteCompanyProductAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products/${queryArg.productId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCompanyProductAttachment: build.query<GetCompanyProductAttachmentResp, GetCompanyProductAttachmentArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/products/${queryArg.productId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    listCompanyShoppingCarts: build.query<ListCompanyShoppingCartsResp, ListCompanyShoppingCartsArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/shopping-carts`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    getCompanyTree: build.query<GetCompanyTreeResp, GetCompanyTreeArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg}/tree` }),
    }),
    addUserToCompany: build.mutation<AddUserToCompanyResp, AddUserToCompanyArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/user`,
        method: "POST",
        body: queryArg.addOrRemoveUserToCompany,
      }),
    }),
    removeUserFromCompany: build.mutation<RemoveUserFromCompanyResp, RemoveUserFromCompanyArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/user`,
        method: "DELETE",
        body: queryArg.addOrRemoveUserToCompany,
      }),
    }),
    getUsersByCompanyId: build.query<GetUsersByCompanyIdResp, GetUsersByCompanyIdArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/users`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortby: queryArg.sortby,
          sortdirection: queryArg.sortdirection,
          filter: queryArg.filter,
        },
      }),
    }),
    createCompanyUser: build.mutation<CreateCompanyUserResp, CreateCompanyUserArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/users`, method: "POST", body: queryArg.userNew }),
    }),
    getUserPermissionsByCompany: build.query<GetUserPermissionsByCompanyResp, GetUserPermissionsByCompanyArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/users/${queryArg.userId}/permission-roles` }),
    }),
    updateUserPermissionRoles: build.mutation<UpdateUserPermissionRolesResp, UpdateUserPermissionRolesArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/users/${queryArg.userId}/permission-roles`,
        method: "PATCH",
        body: queryArg.roles,
      }),
    }),
    complianceCheck: build.query<ComplianceCheckResp, ComplianceCheckArg>({
      query: (queryArg) => ({
        url: `/compliance/check`,
        params: {
          paymentSessionId: queryArg.paymentSessionId,
          customerId: queryArg.customerId,
          paymentMethod: queryArg.paymentMethod,
        },
      }),
    }),
    getCountries: build.query<GetCountriesResp, GetCountriesArg>({
      query: () => ({ url: `/countries` }),
    }),
    getCurrencies: build.query<GetCurrenciesResp, GetCurrenciesArg>({
      query: () => ({ url: `/currencies` }),
    }),
    getCustomer: build.query<GetCustomerResp, GetCustomerArg>({
      query: () => ({ url: `/customer` }),
    }),
    updateAccountCustomer: build.mutation<UpdateAccountCustomerResp, UpdateAccountCustomerArg>({
      query: (queryArg) => ({ url: `/customer`, method: "PUT", body: queryArg }),
    }),
    createAccountCustomer: build.mutation<CreateAccountCustomerResp, CreateAccountCustomerArg>({
      query: (queryArg) => ({
        url: `/customer`,
        method: "POST",
        body: queryArg.createCustomerAccount,
        headers: { "X-Checkout-Payment-Session-ID": queryArg["X-Checkout-Payment-Session-ID"] },
      }),
    }),
    getCustomerForEdit: build.query<GetCustomerForEditResp, GetCustomerForEditArg>({
      query: () => ({ url: `/customer/edit` }),
    }),
    loginCustomer: build.mutation<LoginCustomerResp, LoginCustomerArg>({
      query: (queryArg) => ({ url: `/customer/login`, method: "POST", body: queryArg }),
    }),
    logoutCustomer: build.mutation<LogoutCustomerResp, LogoutCustomerArg>({
      query: () => ({ url: `/customer/logout`, method: "POST" }),
    }),
    requestCustomerCheckoutLogin: build.mutation<RequestCustomerCheckoutLoginResp, RequestCustomerCheckoutLoginArg>({
      query: (queryArg) => ({
        url: `/customer/request-login`,
        method: "POST",
        body: queryArg.customerEmail,
        headers: { "X-Checkout-Payment-Session-ID": queryArg["X-Checkout-Payment-Session-ID"] },
      }),
    }),
    getAddressesByCustomer: build.query<GetAddressesByCustomerResp, GetAddressesByCustomerArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg}/addresses` }),
    }),
    createCustomerAddress: build.mutation<CreateCustomerAddressResp, CreateCustomerAddressArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses`,
        method: "POST",
        body: queryArg.address,
      }),
    }),
    getAddressByCustomerId: build.query<GetAddressByCustomerIdResp, GetAddressByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}` }),
    }),
    updateCustomerAddress: build.mutation<UpdateCustomerAddressResp, UpdateCustomerAddressArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}`,
        method: "PUT",
        body: queryArg.address,
      }),
    }),
    deleteCustomerAddress: build.mutation<DeleteCustomerAddressResp, DeleteCustomerAddressArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}`,
        method: "DELETE",
      }),
    }),
    getAttachmentsByCustomerAddressId: build.query<
      GetAttachmentsByCustomerAddressIdResp,
      GetAttachmentsByCustomerAddressIdArg
    >({
      query: (queryArg) => ({ url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}/attachments` }),
    }),
    createCustomerAddressAttachment: build.mutation<
      CreateCustomerAddressAttachmentResp,
      CreateCustomerAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCustomerAddressAttachment: build.mutation<
      UpdateCustomerAddressAttachmentResp,
      UpdateCustomerAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCustomerAddressAttachment: build.mutation<
      DeleteCustomerAddressAttachmentResp,
      DeleteCustomerAddressAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getAttachmentsByCustomerId: build.query<GetAttachmentsByCustomerIdResp, GetAttachmentsByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg.customerId}/attachments` }),
    }),
    createCustomerAttachment: build.mutation<CreateCustomerAttachmentResp, CreateCustomerAttachmentArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCustomerAttachment: build.mutation<UpdateCustomerAttachmentResp, UpdateCustomerAttachmentArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCustomerAttachment: build.mutation<DeleteCustomerAttachmentResp, DeleteCustomerAttachmentArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getBankAccountsByCustomerId: build.query<GetBankAccountsByCustomerIdResp, GetBankAccountsByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg}/bank-accounts` }),
    }),
    createCustomerBankAccount: build.mutation<CreateCustomerBankAccountResp, CreateCustomerBankAccountArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/bank-accounts`,
        method: "POST",
        body: queryArg.bankAccount,
      }),
    }),
    getBankAccountByCustomerId: build.query<GetBankAccountByCustomerIdResp, GetBankAccountByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg.customerId}/bank-accounts/${queryArg.bankAccountId}` }),
    }),
    deleteCustomerBankAccount: build.mutation<DeleteCustomerBankAccountResp, DeleteCustomerBankAccountArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/bank-accounts/${queryArg.bankAccountId}`,
        method: "DELETE",
      }),
    }),
    getCustomerBankTransfersByAccount: build.query<
      GetCustomerBankTransfersByAccountResp,
      GetCustomerBankTransfersByAccountArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/bank-accounts/${queryArg.bankAccountId}/bank-transfers`,
      }),
    }),
    getBankTransferByCustomerId: build.query<GetBankTransferByCustomerIdResp, GetBankTransferByCustomerIdArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/bank-accounts/${queryArg.bankAccountId}/bank-transfers/${queryArg.bankTransferId}`,
      }),
    }),
    getComplianceHitsByCustomerId: build.query<GetComplianceHitsByCustomerIdResp, GetComplianceHitsByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg.customerId}/compliance-hits` }),
    }),
    createCustomerComplianceHit: build.mutation<CreateCustomerComplianceHitResp, CreateCustomerComplianceHitArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    getAttachmentsByCustomerComplianceHitId: build.query<
      GetAttachmentsByCustomerComplianceHitIdResp,
      GetAttachmentsByCustomerComplianceHitIdArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
      }),
    }),
    createCustomerComplianceHitAttachment: build.mutation<
      CreateCustomerComplianceHitAttachmentResp,
      CreateCustomerComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCustomerComplianceHitAttachment: build.mutation<
      UpdateCustomerComplianceHitAttachmentResp,
      UpdateCustomerComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCustomerComplianceHitAttachment: build.mutation<
      DeleteCustomerComplianceHitAttachmentResp,
      DeleteCustomerComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    updateCustomerComplianceHitStatus: build.mutation<
      UpdateCustomerComplianceHitStatusResp,
      UpdateCustomerComplianceHitStatusArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getIdentificationsByCustomerId: build.query<GetIdentificationsByCustomerIdResp, GetIdentificationsByCustomerIdArg>({
      query: (queryArg) => ({ url: `/customers/${queryArg}/identifications` }),
    }),
    createCustomerIdentification: build.mutation<CreateCustomerIdentificationResp, CreateCustomerIdentificationArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications`,
        method: "POST",
        body: queryArg.identification,
      }),
    }),
    updateCustomerIdentification: build.mutation<UpdateCustomerIdentificationResp, UpdateCustomerIdentificationArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}`,
        method: "PUT",
        body: queryArg.identification,
      }),
    }),
    deleteCustomerIdentification: build.mutation<DeleteCustomerIdentificationResp, DeleteCustomerIdentificationArg>({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}`,
        method: "DELETE",
      }),
    }),
    getAttachmentsByCustomerIdentificationId: build.query<
      GetAttachmentsByCustomerIdentificationIdResp,
      GetAttachmentsByCustomerIdentificationIdArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}/attachments`,
      }),
    }),
    createCustomerIdentificationAttachment: build.mutation<
      CreateCustomerIdentificationAttachmentResp,
      CreateCustomerIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCustomerIdentificationAttachment: build.mutation<
      UpdateCustomerIdentificationAttachmentResp,
      UpdateCustomerIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCustomerIdentificationAttachment: build.mutation<
      DeleteCustomerIdentificationAttachmentResp,
      DeleteCustomerIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getCustomerIdentityVerifications: build.query<
      GetCustomerIdentityVerificationsResp,
      GetCustomerIdentityVerificationsArg
    >({
      query: (queryArg) => ({ url: `/customers/${queryArg}/identity-verifications` }),
    }),
    initiateCustomerIdentityVerification: build.mutation<
      InitiateCustomerIdentityVerificationResp,
      InitiateCustomerIdentityVerificationArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identity-verifications`,
        method: "POST",
        body: queryArg.initiateIdentityVerificationRequest,
      }),
    }),
    completeCustomerIdentityVerification: build.mutation<
      CompleteCustomerIdentityVerificationResp,
      CompleteCustomerIdentityVerificationArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/identity-verifications/complete`,
        method: "POST",
        body: queryArg.completeIdentityVerificationRequest,
      }),
    }),
    demoshopCreatePaymentSession: build.mutation<DemoshopCreatePaymentSessionResp, DemoshopCreatePaymentSessionArg>({
      query: (queryArg) => ({ url: `/demoshop/payment-session`, method: "POST", body: queryArg }),
    }),
    createCheckoutGuestCustomer: build.mutation<CreateCheckoutGuestCustomerResp, CreateCheckoutGuestCustomerArg>({
      query: (queryArg) => ({
        url: `/guest`,
        method: "POST",
        body: queryArg.createOrUpdateCustomerGuest,
        headers: { "X-Checkout-Payment-Session-ID": queryArg["X-Checkout-Payment-Session-ID"] },
      }),
    }),
    getIpCountry: build.query<GetIpCountryResp, GetIpCountryArg>({
      query: () => ({ url: `/ip-country` }),
    }),
    getComplianceHitsByApiKeyId: build.query<GetComplianceHitsByApiKeyIdResp, GetComplianceHitsByApiKeyIdArg>({
      query: (queryArg) => ({ url: `/keys/${queryArg}/compliance-hits` }),
    }),
    createApiKeyComplianceHit: build.mutation<CreateApiKeyComplianceHitResp, CreateApiKeyComplianceHitArg>({
      query: (queryArg) => ({
        url: `/keys/${queryArg.keyId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    getApiKeyComplianceHit: build.query<GetApiKeyComplianceHitResp, GetApiKeyComplianceHitArg>({
      query: (queryArg) => ({ url: `/keys/${queryArg.keyId}/compliance-hits/${queryArg.complianceHitId}` }),
    }),
    updateApiKeyComplianceHitStatus: build.mutation<
      UpdateApiKeyComplianceHitStatusResp,
      UpdateApiKeyComplianceHitStatusArg
    >({
      query: (queryArg) => ({
        url: `/keys/${queryArg.keyId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getAllApiKeyRoles: build.query<GetAllApiKeyRolesResp, GetAllApiKeyRolesArg>({
      query: () => ({ url: `/permissions/roles/apikey` }),
    }),
    getAllUserRoles: build.query<GetAllUserRolesResp, GetAllUserRolesArg>({
      query: () => ({ url: `/permissions/roles/user` }),
    }),
    getShopDetails: build.query<GetShopDetailsResp, GetShopDetailsArg>({
      query: (queryArg) => ({ url: `/shops/${queryArg}` }),
    }),
    listProducts: build.query<ListProductsResp, ListProductsArg>({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/products`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    createShoppingCart: build.mutation<CreateShoppingCartResp, CreateShoppingCartArg>({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/shopping-carts`,
        method: "POST",
        body: queryArg.shoppingCart,
      }),
    }),
    getShoppingCart: build.query<GetShoppingCartResp, GetShoppingCartArg>({
      query: (queryArg) => ({ url: `/shops/${queryArg.companyShop}/shopping-carts/${queryArg.shoppingCartId}` }),
    }),
    updateShoppingCart: build.mutation<UpdateShoppingCartResp, UpdateShoppingCartArg>({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/shopping-carts/${queryArg.shoppingCartId}`,
        method: "PUT",
        body: queryArg.shoppingCart,
      }),
    }),
    createShoppingCartShippingAddress: build.mutation<
      CreateShoppingCartShippingAddressResp,
      CreateShoppingCartShippingAddressArg
    >({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/shopping-carts/${queryArg.shoppingCartId}/address`,
        method: "POST",
        body: queryArg.address,
      }),
    }),
    completeShoppingCart: build.mutation<CompleteShoppingCartResp, CompleteShoppingCartArg>({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/shopping-carts/${queryArg.shoppingCartId}/complete`,
        method: "POST",
      }),
    }),
    addShoppingCartProduct: build.mutation<AddShoppingCartProductResp, AddShoppingCartProductArg>({
      query: (queryArg) => ({
        url: `/shops/${queryArg.companyShop}/shopping-carts/${queryArg.shoppingCartId}/${queryArg.productId}/${queryArg.quantity}`,
        method: "POST",
      }),
    }),
    getSelfUser: build.query<GetSelfUserResp, GetSelfUserArg>({
      query: () => ({ url: `/user` }),
    }),
    updateUser: build.mutation<UpdateUserResp, UpdateUserArg>({
      query: (queryArg) => ({ url: `/user`, method: "PUT", body: queryArg }),
    }),
    getUserOwnCompanies: build.query<GetUserOwnCompaniesResp, GetUserOwnCompaniesArg>({
      query: () => ({ url: `/user/companies` }),
    }),
    getUserOwnPermissionsByCompany: build.query<GetUserOwnPermissionsByCompanyResp, GetUserOwnPermissionsByCompanyArg>({
      query: (queryArg) => ({ url: `/user/permission-roles/companies/${queryArg}` }),
    }),
    updateUserPassword: build.mutation<UpdateUserPasswordResp, UpdateUserPasswordArg>({
      query: (queryArg) => ({ url: `/user/update-password`, method: "PUT", body: queryArg }),
    }),
    getUserAddressAttachmentsByUserAddressId: build.query<
      GetUserAddressAttachmentsByUserAddressIdResp,
      GetUserAddressAttachmentsByUserAddressIdArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/addresses/${queryArg.addressId}/attachments` }),
    }),
    createUserAddressAttachment: build.mutation<CreateUserAddressAttachmentResp, CreateUserAddressAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/addresses/${queryArg.addressId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateUserAddressAttachment: build.mutation<UpdateUserAddressAttachmentResp, UpdateUserAddressAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUserAddressAttachment: build.mutation<DeleteUserAddressAttachmentResp, DeleteUserAddressAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getUserAddressAttachment: build.query<GetUserAddressAttachmentResp, GetUserAddressAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/addresses/${queryArg.addressId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    getAttachmentsByUserId: build.query<GetAttachmentsByUserIdResp, GetAttachmentsByUserIdArg>({
      query: (queryArg) => ({ url: `/users/${queryArg}/attachments` }),
    }),
    createUserAttachment: build.mutation<CreateUserAttachmentResp, CreateUserAttachmentArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/attachments`, method: "POST", body: queryArg.body }),
    }),
    updateUserAttachment: build.mutation<UpdateUserAttachmentResp, UpdateUserAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUserAttachment: build.mutation<DeleteUserAttachmentResp, DeleteUserAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getUserAttachment: build.query<GetUserAttachmentResp, GetUserAttachmentArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/attachments/${queryArg.attachmentId}/metadata` }),
    }),
    getComplianceHitsByUserId: build.query<GetComplianceHitsByUserIdResp, GetComplianceHitsByUserIdArg>({
      query: (queryArg) => ({ url: `/users/${queryArg}/compliance-hits` }),
    }),
    createUserComplianceHit: build.mutation<CreateUserComplianceHitResp, CreateUserComplianceHitArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits`,
        method: "POST",
        body: queryArg.complianceHit,
      }),
    }),
    getUserComplianceHit: build.query<GetUserComplianceHitResp, GetUserComplianceHitArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}` }),
    }),
    getUserComplianceHitAttachmentsByUserComplianceHitId: build.query<
      GetUserComplianceHitAttachmentsByUserComplianceHitIdResp,
      GetUserComplianceHitAttachmentsByUserComplianceHitIdArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
      }),
    }),
    createUserComplianceHitAttachment: build.mutation<
      CreateUserComplianceHitAttachmentResp,
      CreateUserComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateUserComplianceHitAttachment: build.mutation<
      UpdateUserComplianceHitAttachmentResp,
      UpdateUserComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUserComplianceHitAttachment: build.mutation<
      DeleteUserComplianceHitAttachmentResp,
      DeleteUserComplianceHitAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getUserComplianceHitAttachment: build.query<GetUserComplianceHitAttachmentResp, GetUserComplianceHitAttachmentArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
    updateUserComplianceHitStatus: build.mutation<UpdateUserComplianceHitStatusResp, UpdateUserComplianceHitStatusArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/compliance-hits/${queryArg.complianceHitId}/status`,
        method: "PUT",
        body: queryArg.complianceHitStatusUpdate,
      }),
    }),
    getIdentificationsByUserId: build.query<GetIdentificationsByUserIdResp, GetIdentificationsByUserIdArg>({
      query: (queryArg) => ({ url: `/users/${queryArg}/identifications` }),
    }),
    createUserIdentification: build.mutation<CreateUserIdentificationResp, CreateUserIdentificationArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications`,
        method: "POST",
        body: queryArg.identification,
      }),
    }),
    getUserIdentification: build.query<GetUserIdentificationResp, GetUserIdentificationArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}` }),
    }),
    updateUserIdentification: build.mutation<UpdateUserIdentificationResp, UpdateUserIdentificationArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}`,
        method: "PUT",
        body: queryArg.identification,
      }),
    }),
    deleteUserIdentification: build.mutation<DeleteUserIdentificationResp, DeleteUserIdentificationArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}`,
        method: "DELETE",
      }),
    }),
    getUserIdentificationAttachmentsByUserIdentificationId: build.query<
      GetUserIdentificationAttachmentsByUserIdentificationIdResp,
      GetUserIdentificationAttachmentsByUserIdentificationIdArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}/attachments`,
      }),
    }),
    createUserIdentificationAttachment: build.mutation<
      CreateUserIdentificationAttachmentResp,
      CreateUserIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}/attachments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateUserIdentificationAttachment: build.mutation<
      UpdateUserIdentificationAttachmentResp,
      UpdateUserIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUserIdentificationAttachment: build.mutation<
      DeleteUserIdentificationAttachmentResp,
      DeleteUserIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}`,
        method: "DELETE",
      }),
    }),
    getUserIdentificationAttachment: build.query<
      GetUserIdentificationAttachmentResp,
      GetUserIdentificationAttachmentArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/identifications/${queryArg.identificationId}/attachments/${queryArg.attachmentId}/metadata`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as merchantApi };
export type EdenAdminGetBankTransferWithdrawalsResp = /** status 200  */ BankTransferListRecordInternal[];
export type EdenAdminGetBankTransferWithdrawalsArg = void;
export type EdenAdminApproveBankTransferResp = /** status 200  */ {
  message?: string;
};
export type EdenAdminApproveBankTransferArg = /** The unique identifier for the bank account. */ string;
export type EdenAdminCancelBankTransferResp = /** status 200  */ {
  message?: string;
};
export type EdenAdminCancelBankTransferArg = /** The unique identifier for the bank account. */ string;
export type GetAllComplianceHitsResp = /** status 200 ComplianceHitListInternal */ ComplianceHitListInternal;
export type GetAllComplianceHitsArg = {
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
};
export type CreateComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateComplianceHitArg = ComplianceHitRecordInternal;
export type GetComplianceHitResp = /** status 200 ComplianceHitRecordInternal */ ComplianceHitRecordInternal;
export type GetComplianceHitArg = /** The unique identifier for the complianceHit. */ string;
export type GetComplianceHitJoinedResp = /** status 200  */ ComplianceHitRecordJoined;
export type GetComplianceHitJoinedArg = /** The unique identifier for the complianceHit. */ string;
export type UpdateComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateComplianceHitStatusArg = {
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetAllCustomersResp = /** status 200  */ CustomerListInternal;
export type GetAllCustomersArg = {
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type GetAllFailureEventsResp = /** status 200  */ FailureEventRecord[];
export type GetAllFailureEventsArg = void;
export type GetAllSystemSettingsFlagsResp = /** status 200  */ SystemSettingsFlagInternal[];
export type GetAllSystemSettingsFlagsArg = void;
export type UpdateSystemSettingsFlagResp = /** status 200  */ {
  message?: string;
};
export type UpdateSystemSettingsFlagArg = {
  /** The key of the system flag. */
  flag: string;
  systemSettingsFlagInternal: SystemSettingsFlagInternal;
};
export type GetAllUsersResp = /** status 200  */ UserListInternal;
export type GetAllUsersArg = {
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type CreateAdminUserResp = /** status 200  */ UserRecordInternal;
export type CreateAdminUserArg = UserNew;
export type GetUserByIdResp = /** status 200  */ UserRecordInternal;
export type GetUserByIdArg = /** The unique identifier for the user. */ string;
export type DeleteUserResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserArg = /** The unique identifier for the user to delete. */ string;
export type GetUserCompaniesResp = /** status 200  */ CompanyRecordInternal[];
export type GetUserCompaniesArg = /** The unique identifier for the user. */ string;
export type GetUserHasEdenAdminRoleResp = /** status 200  */ UserPermissionRoles;
export type GetUserHasEdenAdminRoleArg = /** The unique identifier for the user. */ string;
export type UpdateUserEdenAdminPermissionRoleResp = unknown;
export type UpdateUserEdenAdminPermissionRoleArg = {
  /** The unique identifier for the user. */
  userId: string;
  roles: Roles;
};
export type GetCheckoutSessionResp = /** status 200  */ CheckoutSessionRecord;
export type GetCheckoutSessionArg = /** The unique identifier for the payment session. */ string;
export type GetAttachmentsByPaymentSessionIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByPaymentSessionIdArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type CreateCheckoutAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCheckoutAttachmentArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCheckoutAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCheckoutAttachmentArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCheckoutAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCheckoutAttachmentArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetComplianceHitsByPaymentSessionIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByPaymentSessionIdArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type CreateCheckoutComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateCheckoutComplianceHitArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  complianceHit: ComplianceHit;
};
export type UpdateCheckoutComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateCheckoutComplianceHitStatusArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type RequestCheckoutAttemptResp = /** status 200  */ CheckoutAttemptRecord;
export type RequestCheckoutAttemptArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  checkoutAttempt: CheckoutAttempt;
};
export type GetComplianceHitsByRefundSessionIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByRefundSessionIdArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the refund session. */
  refundSessionId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type CreateCheckoutRefundSessionComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateCheckoutRefundSessionComplianceHitArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the refund session. */
  refundSessionId: string;
  complianceHit: ComplianceHit;
};
export type UpdateCheckoutRefundSessionComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateCheckoutRefundSessionComplianceHitStatusArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the refund session. */
  refundSessionId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetCheckoutStatusResp = /** status 200  */ CheckoutStatusRecord;
export type GetCheckoutStatusArg = /** The unique identifier for the payment session. */ string;
export type GetAllCompaniesResp = /** status 200  */ CompanyRecordInternal[];
export type GetAllCompaniesArg = void;
export type CreateCompanyResp = /** status 200  */ CompanyRecordInternal;
export type CreateCompanyArg = Company;
export type GetCompanyByIdResp = /** status 200  */ CompanyRecord;
export type GetCompanyByIdArg = /** The unique identifier for the company. */ string;
export type UpdateCompanyResp = /** status 200  */ CompanyRecord;
export type UpdateCompanyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  company: Company;
};
export type DeactivateCompanyResp = /** status 200  */ {
  message?: string;
};
export type DeactivateCompanyArg = /** The unique identifier for the company to deactivate. */ string;
export type GetAddressesByCompanyResp = /** status 200  */ AddressRecord[];
export type GetAddressesByCompanyArg = /** The unique identifier for the company. */ string;
export type CreateCompanyAddressResp = /** status 200  */ AddressRecord;
export type CreateCompanyAddressArg = {
  /** The unique identifier for the company. */
  companyId: string;
  address: Address;
};
export type GetAddressByCompanyIdResp = /** status 200  */ AddressRecord;
export type GetAddressByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
};
export type UpdateCompanyAddressResp = /** status 200  */ AddressRecord;
export type UpdateCompanyAddressArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
  address: Address;
};
export type DeleteCompanyAddressResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyAddressArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address to delete. */
  addressId: string;
};
export type GetCompanyAddressAttachmentsByCompanyAddressIdResp = /** status 200  */ AttachmentRecord[];
export type GetCompanyAddressAttachmentsByCompanyAddressIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
};
export type CreateCompanyAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCompanyAddressAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCompanyAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCompanyAddressAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCompanyAddressAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyAddressAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCompanyAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetCompanyAddressAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetAttachmentsByCompanyIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByCompanyIdArg = /** The unique identifier for the company. */ string;
export type CreateCompanyAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCompanyAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCompanyAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCompanyAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCompanyAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCompanyAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetCompanyAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetBankAccountsByCompanyIdResp = /** status 200  */ BankAccountRecord[];
export type GetBankAccountsByCompanyIdArg = /** The unique identifier for the company. */ string;
export type CreateCompanyBankAccountResp = /** status 200  */ BankAccountRecord;
export type CreateCompanyBankAccountArg = {
  /** The unique identifier for the company. */
  companyId: string;
  bankAccount: BankAccount;
};
export type GetBankAccountByCompanyIdResp = /** status 200  */ BankAccountRecord;
export type GetBankAccountByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
};
export type DeleteCompanyBankAccountResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyBankAccountArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the bank account to delete. */
  bankAccountId: string;
};
export type GetCompanyBankTransfersByAccountResp = /** status 200  */ BankTransferRecord[];
export type GetCompanyBankTransfersByAccountArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
};
export type GetBankTransferByCompanyIdResp = /** status 200  */ BankTransferRecord;
export type GetBankTransferByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
  /** The unique identifier for the bank account. */
  bankTransferId: string;
};
export type GetCompanyBankTransferWithdrawalsResp = /** status 200  */ BankTransferListRecordInternal[];
export type GetCompanyBankTransferWithdrawalsArg = /** The unique identifier for the company. */ string;
export type GetComplianceHitsByCompanyIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByCompanyIdArg = /** The unique identifier for the company. */ string;
export type CreateCompanyComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateCompanyComplianceHitArg = {
  /** The unique identifier for the company. */
  companyId: string;
  complianceHit: ComplianceHit;
};
export type GetCompanyComplianceHitResp = /** status 200  */ ComplianceHitRecordJoined;
export type GetCompanyComplianceHitArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type GetCompanyComplianceHitAttachmentsByCompanyComplianceHitIdResp = /** status 200  */ AttachmentRecord[];
export type GetCompanyComplianceHitAttachmentsByCompanyComplianceHitIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
};
export type CreateCompanyComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCompanyComplianceHitAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCompanyComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCompanyComplianceHitAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCompanyComplianceHitAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyComplianceHitAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCompanyComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetCompanyComplianceHitAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type UpdateCompanyComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateCompanyComplianceHitStatusArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetCustomersByCompanyIdResp = /** status 200  */ CustomerListInternal;
export type GetCustomersByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type DashboardStatsResp = /** status 200  */ DashboardStatistics;
export type DashboardStatsArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The start date for the duration. RFC3339 format. Example timezone oslo 2021-01-01T00:00:00+01:00 */
  from: string;
  /** The end date for the duration. RFC3339 format. Example timezone oslo 2021-01-01T00:00:00+01:00 */
  to: string;
  /** The unit of the duration. weeks, months, days, hours, minutes. */
  unit: string;
};
export type GetIdentificationsByCompanyIdResp = /** status 200  */ IdentificationRecord[];
export type GetIdentificationsByCompanyIdArg = /** The unique identifier for the company. */ string;
export type CreateCompanyIdentificationResp = /** status 200  */ IdentificationRecord;
export type CreateCompanyIdentificationArg = {
  /** The unique identifier for the company. */
  companyId: string;
  identification: Identification;
};
export type GetCompanyIdentificationResp = /** status 200  */ IdentificationRecord;
export type GetCompanyIdentificationArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type UpdateCompanyIdentificationResp = /** status 200  */ IdentificationRecord;
export type UpdateCompanyIdentificationArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  identification: Identification;
};
export type DeleteCompanyIdentificationResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyIdentificationArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type GetCompanyIdentificationAttachmentsByCompanyIdentificationIdResp = /** status 200  */ AttachmentRecord[];
export type GetCompanyIdentificationAttachmentsByCompanyIdentificationIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type CreateCompanyIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCompanyIdentificationAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCompanyIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCompanyIdentificationAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCompanyIdentificationAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyIdentificationAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCompanyIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetCompanyIdentificationAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetKeysByCompanyResp = /** status 200  */ ApiKeyRecord[];
export type GetKeysByCompanyArg = /** The unique identifier for the company. */ string;
export type CreateKeyResp = /** status 200  */ NewKeyRecord;
export type CreateKeyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  newKey: NewKey;
};
export type GetKeyByCompanyResp = /** status 200  */ KeyRecord;
export type GetKeyByCompanyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the key. */
  keyId: string;
};
export type InvalidateKeyResp = /** status 200  */ void;
export type InvalidateKeyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the key. */
  keyId: string;
};
export type UpdateApiKeyPermissionRolesResp = /** status 200  */ Roles;
export type UpdateApiKeyPermissionRolesArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the key. */
  keyId: string;
  roles: Roles;
};
export type GetPaymentSessionsByCompanyIdResp = /** status 200  */ PaymentSessionList;
export type GetPaymentSessionsByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type CreateCompanyPaymentSessionResp = /** status 200  */ PaymentSessionRecord;
export type CreateCompanyPaymentSessionArg = {
  /** The unique identifier for the company. */
  companyId: string;
  paymentSession: PaymentSession;
};
export type GetCompanyPaymentSessionByReferenceResp = /** status 200  */ PaymentSessionRecord;
export type GetCompanyPaymentSessionByReferenceArg = {
  /** The reference set for the payment session. */
  reference: string;
  /** The unique identifier for the company. */
  companyId: string;
};
export type GetCompanyPaymentSessionResp = /** status 200  */ PaymentSessionRecord;
export type GetCompanyPaymentSessionArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the company. */
  companyId: string;
};
export type CancelCompanyPaymentSessionResp = /** status 200  */ {
  message?: string;
};
export type CancelCompanyPaymentSessionArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
};
export type GetCompanyRefundSessionsResp = /** status 200  */ RefundSessionList;
export type GetCompanyRefundSessionsArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
};
export type CreateCompanyRefundSessionResp = /** status 200  */ RefundSessionRecord;
export type CreateCompanyRefundSessionArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  refundSession: RefundSession;
};
export type GetCompanyRefundSessionResp = /** status 200  */ RefundSessionRecord;
export type GetCompanyRefundSessionArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the payment session. */
  paymentSessionId: string;
  /** The unique identifier for the refund session. */
  refundSessionId: string;
};
export type ListCompanyProductsResp = /** status 200  */ ProductList;
export type ListCompanyProductsArg = {
  /** The unique identifier for the company */
  companyId: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type CreateCompanyProductResp = /** status 200  */ ProductRecord;
export type CreateCompanyProductArg = {
  /** The unique identifier for the company */
  companyId: string;
  product: Product;
};
export type GetCompanyProductResp = /** status 200  */ ProductRecord;
export type GetCompanyProductArg = {
  /** The unique identifier for the company: */
  companyId: string;
  /** The unique URL based identifier for the product */
  productId: string;
};
export type UpdateCompanyProductResp = /** status 200  */ {
  message?: string;
};
export type UpdateCompanyProductArg = {
  /** The unique identifier for the company: */
  companyId: string;
  /** The unique URL based identifier for the product */
  productId: string;
  product: Product;
};
export type GetCompanyProductAttachmentsByCompanyProductIdResp = /** status 200  */ AttachmentRecord[];
export type GetCompanyProductAttachmentsByCompanyProductIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the product. */
  productId: string;
};
export type CreateCompanyProductAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCompanyProductAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the product. */
  productId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCompanyProductAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCompanyProductAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the product. */
  productId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCompanyProductAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCompanyProductAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the product. */
  productId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCompanyProductAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetCompanyProductAttachmentArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the product. */
  productId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type ListCompanyShoppingCartsResp = /** status 200  */ ShoppingCartList;
export type ListCompanyShoppingCartsArg = {
  /** The unique identifier for the company */
  companyId: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
};
export type GetCompanyTreeResp = /** status 200 CompanyRecord */ CompanyRecord;
export type GetCompanyTreeArg = /** The unique identifier for the company. */ string;
export type AddUserToCompanyResp = /** status 200  */ {
  message?: string;
};
export type AddUserToCompanyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  addOrRemoveUserToCompany: AddOrRemoveUserToCompany;
};
export type RemoveUserFromCompanyResp = /** status 200  */ {
  message?: string;
};
export type RemoveUserFromCompanyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  addOrRemoveUserToCompany: AddOrRemoveUserToCompany;
};
export type GetUsersByCompanyIdResp = /** status 200  */ UserListInternal;
export type GetUsersByCompanyIdArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
  /** The field to sort by. */
  sortby?: string;
  /** The direction to sort by ('asc' or 'desc'). */
  sortdirection?: string;
  /** Filter clauses. */
  filter?: string;
};
export type CreateCompanyUserResp = /** status 200  */ UserRecordInternal;
export type CreateCompanyUserArg = {
  /** The unique identifier for the company. */
  companyId: string;
  userNew: UserNew;
};
export type GetUserPermissionsByCompanyResp = /** status 200  */ UserPermissionRoles;
export type GetUserPermissionsByCompanyArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the user. */
  userId: string;
};
export type UpdateUserPermissionRolesResp = /** status 200  */ Roles;
export type UpdateUserPermissionRolesArg = {
  /** The unique identifier for the company. */
  companyId: string;
  /** The unique identifier for the user. */
  userId: string;
  roles: Roles;
};
export type ComplianceCheckResp = /** status 200  */ Compliance;
export type ComplianceCheckArg = {
  /** The unique identifier for the payment session. */
  paymentSessionId?: string;
  /** The unique identifier for the customer. */
  customerId?: string;
  /** The payment method to be used for the new payment transfer. */
  paymentMethod?: string;
};
export type GetCountriesResp = /** status 200  */ Country[];
export type GetCountriesArg = void;
export type GetCurrenciesResp = /** status 200  */ Currency[];
export type GetCurrenciesArg = void;
export type GetCustomerResp = /** status 200  */ CustomerRecord;
export type GetCustomerArg = void;
export type UpdateAccountCustomerResp = /** status 200  */ {
  message?: string;
};
export type UpdateAccountCustomerArg = UpdateCustomerAccount;
export type CreateAccountCustomerResp = /** status 200  */ {
  token?: string;
};
export type CreateAccountCustomerArg = {
  /** The unique identifier for the payment session. */
  "X-Checkout-Payment-Session-ID"?: string;
  createCustomerAccount: CreateCustomerAccount;
};
export type GetCustomerForEditResp = /** status 200  */ CustomerWithDetails;
export type GetCustomerForEditArg = void;
export type LoginCustomerResp = /** status 200  */ {
  token?: string;
};
export type LoginCustomerArg = CustomerLogin;
export type LogoutCustomerResp = /** status 200  */ {
  message?: string;
};
export type LogoutCustomerArg = void;
export type RequestCustomerCheckoutLoginResp = /** status 200  */ CustomerLoginDetails;
export type RequestCustomerCheckoutLoginArg = {
  /** The unique identifier for the payment session. */
  "X-Checkout-Payment-Session-ID": string;
  customerEmail: CustomerEmail;
};
export type GetAddressesByCustomerResp = /** status 200  */ AddressRecord[];
export type GetAddressesByCustomerArg = /** The unique identifier for the customer. */ string;
export type CreateCustomerAddressResp = /** status 200  */ AddressRecord;
export type CreateCustomerAddressArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  address: Address;
};
export type GetAddressByCustomerIdResp = /** status 200  */ AddressRecord;
export type GetAddressByCustomerIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
};
export type UpdateCustomerAddressResp = /** status 200  */ AddressRecord;
export type UpdateCustomerAddressArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
  address: Address;
};
export type DeleteCustomerAddressResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerAddressArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address to delete. */
  addressId: string;
};
export type GetAttachmentsByCustomerAddressIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByCustomerAddressIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
};
export type CreateCustomerAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCustomerAddressAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCustomerAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCustomerAddressAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCustomerAddressAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerAddressAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetAttachmentsByCustomerIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByCustomerIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type CreateCustomerAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCustomerAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCustomerAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCustomerAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCustomerAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetBankAccountsByCustomerIdResp = /** status 200  */ BankAccountRecord[];
export type GetBankAccountsByCustomerIdArg = /** The unique identifier for the customer. */ string;
export type CreateCustomerBankAccountResp = /** status 200  */ BankAccountRecord;
export type CreateCustomerBankAccountArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  bankAccount: BankAccount;
};
export type GetBankAccountByCustomerIdResp = /** status 200  */ BankAccountRecord;
export type GetBankAccountByCustomerIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
};
export type DeleteCustomerBankAccountResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerBankAccountArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the bank account to delete. */
  bankAccountId: string;
};
export type GetCustomerBankTransfersByAccountResp = /** status 200  */ BankTransferRecord[];
export type GetCustomerBankTransfersByAccountArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
};
export type GetBankTransferByCustomerIdResp = /** status 200  */ BankTransferRecord;
export type GetBankTransferByCustomerIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the bank account. */
  bankAccountId: string;
  /** The unique identifier for the bank account. */
  bankTransferId: string;
};
export type GetComplianceHitsByCustomerIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByCustomerIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type CreateCustomerComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateCustomerComplianceHitArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  complianceHit: ComplianceHit;
};
export type GetAttachmentsByCustomerComplianceHitIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByCustomerComplianceHitIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
};
export type CreateCustomerComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCustomerComplianceHitAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCustomerComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCustomerComplianceHitAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCustomerComplianceHitAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerComplianceHitAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type UpdateCustomerComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateCustomerComplianceHitStatusArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetIdentificationsByCustomerIdResp = /** status 200  */ IdentificationRecord[];
export type GetIdentificationsByCustomerIdArg = /** The unique identifier for the customer. */ string;
export type CreateCustomerIdentificationResp = /** status 200  */ IdentificationRecord;
export type CreateCustomerIdentificationArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  identification: Identification;
};
export type UpdateCustomerIdentificationResp = /** status 200  */ IdentificationRecord;
export type UpdateCustomerIdentificationArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  identification: Identification;
};
export type DeleteCustomerIdentificationResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerIdentificationArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type GetAttachmentsByCustomerIdentificationIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByCustomerIdentificationIdArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type CreateCustomerIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateCustomerIdentificationAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateCustomerIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateCustomerIdentificationAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteCustomerIdentificationAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteCustomerIdentificationAttachmentArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetCustomerIdentityVerificationsResp = /** status 200  */ IdentityVerificationRecord[];
export type GetCustomerIdentityVerificationsArg = /** The unique identifier for the customer. */ string;
export type InitiateCustomerIdentityVerificationResp = /** status 200  */ IdentityVerificationRecord;
export type InitiateCustomerIdentityVerificationArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  initiateIdentityVerificationRequest: InitiateIdentityVerificationRequest;
};
export type CompleteCustomerIdentityVerificationResp = /** status 200  */ IdentityVerificationRecord;
export type CompleteCustomerIdentityVerificationArg = {
  /** The unique identifier for the customer. */
  customerId: string;
  completeIdentityVerificationRequest: CompleteIdentityVerificationRequest;
};
export type DemoshopCreatePaymentSessionResp = /** status 200  */ PaymentSessionRecord;
export type DemoshopCreatePaymentSessionArg = DemoshopPaymentSessionNew;
export type CreateCheckoutGuestCustomerResp = /** status 200  */ {
  token?: string;
};
export type CreateCheckoutGuestCustomerArg = {
  /** The unique identifier for the payment session. */
  "X-Checkout-Payment-Session-ID": string;
  createOrUpdateCustomerGuest: CreateOrUpdateCustomerGuest;
};
export type GetIpCountryResp = /** status 200  */ Country;
export type GetIpCountryArg = void;
export type GetComplianceHitsByApiKeyIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByApiKeyIdArg = /** The unique identifier for the key. */ string;
export type CreateApiKeyComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateApiKeyComplianceHitArg = {
  /** The unique identifier for the key. */
  keyId: string;
  complianceHit: ComplianceHit;
};
export type GetApiKeyComplianceHitResp = /** status 200  */ ComplianceHitRecordJoined;
export type GetApiKeyComplianceHitArg = {
  /** The unique identifier for the key. */
  keyId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type UpdateApiKeyComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateApiKeyComplianceHitStatusArg = {
  /** The unique identifier for the key. */
  keyId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetAllApiKeyRolesResp = /** status 200  */ Role[];
export type GetAllApiKeyRolesArg = void;
export type GetAllUserRolesResp = /** status 200  */ Role[];
export type GetAllUserRolesArg = void;
export type GetShopDetailsResp = /** status 200  */ CompanyShopRecord;
export type GetShopDetailsArg = /** The unique URL based identifier for the company */ string;
export type ListProductsResp = /** status 200  */ ProductList;
export type ListProductsArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  /** Maximum number of records to return. */
  limit: number;
  /** Offset of pagination. */
  offset?: number;
};
export type CreateShoppingCartResp = /** status 200  */ ShoppingCartRecord;
export type CreateShoppingCartArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  shoppingCart: ShoppingCart;
};
export type GetShoppingCartResp = /** status 200  */ ShoppingCartRecordJoined;
export type GetShoppingCartArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  /** The unique identifier for the shopping cart */
  shoppingCartId: string;
};
export type UpdateShoppingCartResp = /** status 200  */ {
  message?: string;
};
export type UpdateShoppingCartArg = {
  /** The unique URL based identifier for the company: */
  companyShop: string;
  /** The unique identifier for the shopping cart */
  shoppingCartId: string;
  shoppingCart: ShoppingCart;
};
export type CreateShoppingCartShippingAddressResp = /** status 200  */ AddressRecord;
export type CreateShoppingCartShippingAddressArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  /** The unique identifier for the shopping cart */
  shoppingCartId: string;
  address: Address;
};
export type CompleteShoppingCartResp = /** status 200  */ CompletedShoppingCart;
export type CompleteShoppingCartArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  /** The unique identifier for the shopping cart */
  shoppingCartId: string;
};
export type AddShoppingCartProductResp = /** status 200  */ ShoppingCartRecord;
export type AddShoppingCartProductArg = {
  /** The unique URL based identifier for the company */
  companyShop: string;
  /** The unique identifier for the shopping cart */
  shoppingCartId: string;
  /** The unique identifier for the product */
  productId: string;
  /** The quantity of the product to set in the shopping cart */
  quantity: number;
};
export type GetSelfUserResp = /** status 200  */ UserRecordInternal;
export type GetSelfUserArg = void;
export type UpdateUserResp = /** status 200  */ UserRecordInternal;
export type UpdateUserArg = UserRecordInternal;
export type GetUserOwnCompaniesResp = /** status 200  */ CompanyRecordInternal[];
export type GetUserOwnCompaniesArg = void;
export type GetUserOwnPermissionsByCompanyResp = /** status 200  */ UserPermissionRoles;
export type GetUserOwnPermissionsByCompanyArg = /** The unique identifier for the company. */ string;
export type UpdateUserPasswordResp = /** status 200  */ {
  message?: string;
};
export type UpdateUserPasswordArg = UpdatePassword;
export type GetUserAddressAttachmentsByUserAddressIdResp = /** status 200  */ AttachmentRecord[];
export type GetUserAddressAttachmentsByUserAddressIdArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the address. */
  addressId: string;
};
export type CreateUserAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateUserAddressAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateUserAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateUserAddressAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteUserAddressAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserAddressAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetUserAddressAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetUserAddressAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the address. */
  addressId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetAttachmentsByUserIdResp = /** status 200  */ AttachmentRecord[];
export type GetAttachmentsByUserIdArg = /** The unique identifier for the user. */ string;
export type CreateUserAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateUserAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateUserAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateUserAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteUserAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetUserAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetUserAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetComplianceHitsByUserIdResp = /** status 200  */ ComplianceHitRecord[];
export type GetComplianceHitsByUserIdArg = /** The unique identifier for the user. */ string;
export type CreateUserComplianceHitResp = /** status 200  */ ComplianceHitRecord;
export type CreateUserComplianceHitArg = {
  /** The unique identifier for the user. */
  userId: string;
  complianceHit: ComplianceHit;
};
export type GetUserComplianceHitResp = /** status 200  */ ComplianceHitRecordJoined;
export type GetUserComplianceHitArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
};
export type GetUserComplianceHitAttachmentsByUserComplianceHitIdResp = /** status 200  */ AttachmentRecord[];
export type GetUserComplianceHitAttachmentsByUserComplianceHitIdArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
};
export type CreateUserComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateUserComplianceHitAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateUserComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateUserComplianceHitAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteUserComplianceHitAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserComplianceHitAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetUserComplianceHitAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetUserComplianceHitAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the compliance hit. */
  complianceHitId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type UpdateUserComplianceHitStatusResp = /** status 200  */ ComplianceHitRecord;
export type UpdateUserComplianceHitStatusArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the complianceHit. */
  complianceHitId: string;
  complianceHitStatusUpdate: ComplianceHitStatusUpdate;
};
export type GetIdentificationsByUserIdResp = /** status 200  */ IdentificationRecord[];
export type GetIdentificationsByUserIdArg = /** The unique identifier for the user. */ string;
export type CreateUserIdentificationResp = /** status 200  */ IdentificationRecord;
export type CreateUserIdentificationArg = {
  /** The unique identifier for the user. */
  userId: string;
  identification: Identification;
};
export type GetUserIdentificationResp = /** status 200  */ IdentificationRecord;
export type GetUserIdentificationArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type UpdateUserIdentificationResp = /** status 200  */ IdentificationRecord;
export type UpdateUserIdentificationArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  identification: Identification;
};
export type DeleteUserIdentificationResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserIdentificationArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type GetUserIdentificationAttachmentsByUserIdentificationIdResp = /** status 200  */ AttachmentRecord[];
export type GetUserIdentificationAttachmentsByUserIdentificationIdArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
};
export type CreateUserIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type CreateUserIdentificationAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type UpdateUserIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type UpdateUserIdentificationAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
  /** FormData containing keys: 'file' (file data), 'type' (AttachmentType), 'fileType' (file type e.g. image/png), 'status' (AttachmentStatus) */
  body: any;
};
export type DeleteUserIdentificationAttachmentResp = /** status 200  */ {
  message?: string;
};
export type DeleteUserIdentificationAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type GetUserIdentificationAttachmentResp = /** status 200  */ AttachmentRecord;
export type GetUserIdentificationAttachmentArg = {
  /** The unique identifier for the user. */
  userId: string;
  /** The unique identifier for the identification. */
  identificationId: string;
  /** The unique identifier for the attachment. */
  attachmentId: string;
};
export type State = string;
export type BankTransferListRecordInternal = {
  /** Amount is the amount to transfer in the smallest unit of the currency. */
  amount?: number;
  /** AmountFloat is the amount to transfer in base currency. */
  amountFloat?: number;
  /** ApprovedAt is the time the bank transfer was approved. */
  approvedAt?: string;
  approvedByApiKeyId?: string;
  approvedByUserId?: string;
  /** BankTransferID is the unique identifier for the bank transfer. */
  bankTransferId?: string;
  /** CancelledAt is the time the bank transfer was cancelled. */
  cancelledAt?: string;
  cancelledByApiKeyId?: string;
  cancelledByUserId?: string;
  /** CompletedAt is the time the bank transfer was completed. */
  completedAt?: string;
  createdAt?: string;
  createdByApiKeyId?: string;
  createdByUserId?: string;
  /** CurrencyDecimalPlaces is the number of decimal places for the currency. */
  currencyDecimalPlaces?: number;
  /** CurrencyID is the currency of the bank transfer. */
  currencyId?: number;
  /** CurrencyISO3 is the currency of the bank transfer in ISO3 format. */
  currencyIso3?: string;
  /** FailedAt is the time the bank transfer failed. */
  failedAt?: string;
  /** InitiatedAt is the time the bank transfer was initiated. */
  initiatedAt?: string;
  /** BankAccountID is the ID of the bank account to transfer the funds to. */
  receiverBankAccountId?: string;
  /** ReceiverName is the name of the receiver of the bank transfer. */
  receiverName?: string;
  /** Reference is the reference for the bank transfer. */
  reference?: string;
  /** SenderBankAccountID is the ID of the bank account to transfer the funds from. */
  senderBankAccountId?: string;
  /** SenderName is the name of the sender of the bank transfer. */
  senderName?: string;
  state?: State;
};
export type InputError = {
  actualTag?: string;
  /** Field is the name of the field that failed validation. For example Email.
    It should be equal to the json key but with upper case first letter. */
  field?: string;
  kind?: string;
  /** Message is the error message returned by the validator. For example "is required".
    It's intentionally kept simple and generic to simplify formatting and translating error messages */
  message?: string;
  namespace?: string;
  /** Param is usually set to the required value. For example 8 if the minimum is 8 */
  param?: string;
  structField?: string;
  structNamespace?: string;
  tag?: string;
  /** Type is the type of the field that failed validation. For example string. */
  type?: string;
};
export type ParamError = {
  message?: string;
  param?: string;
};
export type ErrorResponse = {
  /** ErrorCode is the error code returned by the validator (or manually). For example "permissionDenied". */
  errorCode?: string;
  /** InputErrors is a list of input errors returned by the validator. */
  inputErrors?: InputError[];
  /** Message is the error message returned by the validator. For example "permission denied". */
  message?: string;
  /** ParamErrors is a list of param errors. This occurs if the request is missing a required parameter in the path. */
  paramErrors?: ParamError[];
};
export type ComplianceClientFlags = number;
export type ComplianceHitListRecordInternal = {
  apiKeyId?: string;
  clientFlags?: ComplianceClientFlags;
  companyDisplayName?: string;
  companyId?: string;
  companyName?: string;
  complianceHitId?: string;
  conditionResourceNames?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerId?: string;
  customerLastName?: string;
  customerSecondLastName?: string;
  dataType?: ComplianceHitDataType;
  number?: number;
  paymentSessionId?: string;
  postOperation?: CompliancePostOperation;
  refundSessionId?: string;
  resourceName?: string;
  ruleCode?: string;
  ruleDefinitionId?: string;
  ruleName?: string;
  status?: ComplianceHitStatus;
  statusUpdateDescription?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
};
export type ComplianceHitListInternal = {
  records?: ComplianceHitListRecordInternal[];
  totalCount?: number;
};
export type ComplianceHitRecord = {
  clientFlags?: ComplianceClientFlags;
  complianceHitId?: string;
  dataType?: ComplianceHitDataType;
  number?: number;
  postOperation?: CompliancePostOperation;
  resourceName?: string;
  ruleDefinitionId?: string;
  status?: ComplianceHitStatus;
  statusUpdateDescription?: string;
};
export type ComplianceHitRecordInternal = {
  apiKeyId?: string;
  clientFlags?: ComplianceClientFlags;
  companyId?: string;
  complianceHitId?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  dataType?: ComplianceHitDataType;
  number?: number;
  paymentSessionId?: string;
  postOperation?: CompliancePostOperation;
  refundSessionId?: string;
  resourceName?: string;
  ruleDefinitionId?: string;
  status?: ComplianceHitStatus;
  statusUpdateDescription?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
};
export type ComplianceHitRecordJoined = {
  clientFlags?: ComplianceClientFlags;
  companyDisplayName?: string;
  companyName?: string;
  complianceHitId?: string;
  conditionResourceNames?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerSecondLastName?: string;
  dataType?: ComplianceHitDataType;
  number?: number;
  postOperation?: CompliancePostOperation;
  resourceName?: string;
  ruleDefinitionId?: string;
  status?: ComplianceHitStatus;
  statusUpdateDescription?: string;
};
export type ComplianceHitStatusUpdate = {
  description?: string;
  /** Status is the status of the compliance hit. */
  status?: ComplianceHitStatus;
};
export type AttachmentRecordInternal = {
  addressId?: string;
  attachmentId?: string;
  companyId?: string;
  complianceHitId?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  file?: Blob;
  fileHash?: string;
  fileName?: string;
  filePath?: string;
  fileType?: string;
  /** IDs for which this attachment belongs to. */
  identificationId?: string;
  ordering?: number;
  paymentSessionId?: string;
  productId?: string;
  side?: AttachmentSide;
  status?: AttachmentStatus;
  type?: AttachmentType;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
  verificationStatus?: AttachmentVerificationStatus;
  virusScannedAt?: string;
  visibility?: AttachmentVisibility;
};
export type AddressRecordInternal = {
  /** AddressID is the unique identifier for the phone number. */
  addressId?: string;
  addressLine?: string;
  /** Attachments is a list of attachments for the address. For example a proof of address. */
  attachments?: AttachmentRecordInternal[];
  bankAccountId?: string;
  buildingNumber?: string;
  /** What does the address belongs to? */
  companyId?: string;
  countryId?: number;
  countryIso3?: string;
  countrySubDivision?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  postCode?: string;
  streetName?: string;
  townName?: string;
  type?: AddressType;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
};
export type BankAccountRecordInternal = {
  bankAccountId?: string;
  /** BIC is the Bank Identifier Code. */
  bic?: string;
  companyId?: string;
  countryId?: number;
  /** CountryISO3 is the ISO3 country code. */
  countryIso3?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  currencyId?: number;
  currencyIso3?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  /** IBAN is the International Bank Account Number. */
  iban?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
};
export type IdentificationRecordInternal = {
  attachments?: AttachmentRecordInternal[];
  companyId?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  expiryAt?: string;
  identificationId?: string;
  identifier?: string;
  identityVerificationId?: string;
  issueAt?: string;
  issueBy?: string;
  issueCountryIso3?: string;
  status?: IdentificationStatus;
  type?: IDType;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
  verificationStatus?: IdentificationVerificationStatus;
};
export type PhoneNumberRecordInternal = {
  companyId?: string;
  countryId?: number;
  countryIso3?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  number?: string;
  /** PhoneNumberID is the unique identifier for the phone number. */
  phoneNumberId?: string;
  type?: PhoneNumberType;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
  verificationStatus?: PhoneNumberVerificationStatus;
};
export type CustomerListRecordInternal = {
  addresses?: AddressRecordInternal[];
  attachments?: AttachmentRecordInternal[];
  bankAccounts?: BankAccountRecordInternal[];
  birthCity?: string;
  birthCountryIso3?: string;
  birthDate?: string;
  birthState?: string;
  companyIds?: string;
  companyNames?: string;
  complianceHits?: ComplianceHitRecordInternal[];
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  customerId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  identifications?: IdentificationRecordInternal[];
  isGuest?: boolean;
  knowYourCustomerCheckedAt?: string;
  lastName?: string;
  monthlyNetIncome?: number;
  nationalityCountryIso3?: string;
  occupation?: string;
  /** add fields shown in table */
  paymentSessionCount?: number;
  phoneNumbers?: PhoneNumberRecordInternal[];
  politicallyExposedPersonCheckedAt?: string;
  politicallyExposedPersonHitId?: number;
  politicallyExposedPersonProvider?: string;
  residenceCountryIso3?: string;
  secondLastName?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
};
export type CustomerListInternal = {
  records?: CustomerListRecordInternal[];
  totalCount?: number;
};
export type FailureEventType = number;
export type FailureEventSystem = string;
export type FailureEventRecord = {
  createdAt?: string;
  failureEventId?: number;
  failureEventType?: FailureEventType;
  solvedAt?: string;
  system?: FailureEventSystem;
  torqLedgerFailureEventId?: number;
  value?: string;
};
export type SystemSettingsFlagInternal = {
  expiration?: string;
  flag?: Flag;
  value?: boolean;
};
export type UserListRecordInternal = {
  companyIds?: string;
  companyNames?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  email?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  passwordHash?: string;
  suspendedAt?: string;
  suspendedByApiKeyId?: string;
  suspendedByUserId?: string;
  twoFactorAuth?: string;
  twoFactorBackups?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
};
export type UserListInternal = {
  records?: UserListRecordInternal[];
  totalCount?: number;
};
export type UserRecordInternal = {
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  /** DeletedAt is the timestamp of when the record was deleted. */
  deletedAt?: string;
  /** DeletedByAPIKeyID is the ID of the API key used to delete the record. */
  deletedByApiKeyId?: string;
  /** DeletedByUserID is the ID of the user who deleted the record. */
  deletedByUserId?: string;
  email?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  passwordHash?: string;
  suspendedAt?: string;
  suspendedByApiKeyId?: string;
  suspendedByUserId?: string;
  twoFactorAuth?: string;
  twoFactorBackups?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record. */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  userId?: string;
};
export type UserNew = {
  /** CreateSignInToken is required to be true, if SendInvite is set to true */
  createSignInToken?: boolean;
  email?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  /** Either password or CreateSignInToken must be set */
  password?: string;
  sendInvite?: boolean;
};
export type CompanyRecordInternal = {
  /** BillingEmail is the email address for billing-related communications. */
  billingEmail?: string;
  childCompanies?: CompanyRecordInternal[];
  commissionAmount?: number;
  commissionMultiplier?: number;
  /** CompanyID is the unique identifier of the company. */
  companyId?: string;
  /** CompanyName represents the name of the company. */
  companyName?: string;
  /** CompanyType describes the type of the company. */
  companyType?: string;
  countryId?: number;
  /** CountryISO3 is the identifier of the country where the company is located. */
  countryIso3?: string;
  createdAt?: string;
  /** CreatedByAPIKeyID is the ID of the API key used to create the record. */
  createdByApiKeyId?: string;
  /** CreatedByUserID is the ID of the user who created the record. */
  createdByUserId?: string;
  defaultPaymentSessionExpirySec?: number;
  defaultShippingCost?: number;
  defaultShippingCostVatType?: VATType;
  /** Department represents the department of the company. */
  department?: string;
  /** DisplayName is the name that the company is displayed as. */
  displayName?: string;
  hodlMultiplier?: number;
  /** IndustryCategory describes the industry category of the company. */
  industryCategory?: string;
  /** Language is the default language of the company. */
  language?: string;
  onChainConfirmations?: number;
  /** ParentCompanyID is the unique identifier of the parent company. */
  parentCompanyId?: string;
  publicLogoAttachmentId?: string;
  publicLogoAttachmentPath?: string;
  publicSymbolAttachmentId?: string;
  publicSymbolAttachmentPath?: string;
  shopCurrencyId?: number;
  shopCurrencyIso3?: string;
  shopFailureUrl?: string;
  shopHandle?: string;
  shopRedirectUrl?: string;
  shopVatType?: ShopVATType;
  status?: Status;
  /** SubDepartment represents the sub-department of the company. */
  subDepartment?: string;
  updatedAt?: string;
  /** UpdatedByAPIKeyID is the ID of the API key used to last update the record */
  updatedByApiKeyId?: string;
  /** UpdatedByUserID is the ID of the user who last updated the record. */
  updatedByUserId?: string;
  /** Website is the official website URL of the company. */
  website?: string;
};
export type UserPermissionRoles = {
  email?: string;
  firstName?: string;
  language?: string;
  lastName?: string;
  permissionRoles?: string[];
};
export type Roles = {
  roles?: string[];
};
export type CheckoutSessionRecord = {
  /** Amount is the total amount of the payment in the main currency unit (like EUR) */
  amount?: number;
  /** CurrencyDecimalPlaces is the number of decimal places for the currency used for the payment. */
  currencyDecimalPlaces?: number;
  /** CurrencyISO3 is the identifier of the currency used for the payment. */
  currencyIso3?: string;
  /** Description provides additional details about the payment. */
  description?: string;
  /** ExpiresAt is the time when the payment session expires. */
  expiresAt?: string;
  /** FailureURL is the URL to redirect to upon a failed payment. */
  failureUrl?: string;
  /** PaymentMethods is the list of payment methods available for the payment session. */
  paymentMethods?: ("lightning" | "on-chain" | "card" | "invoice")[];
  /** PaymentSessionID is the unique identifier for the payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after payment processing. */
  redirectUrl?: string;
};
export type AttachmentRecord = {
  addressId?: string;
  attachmentId?: string;
  companyId?: string;
  complianceHitId?: string;
  customerId?: string;
  file?: Blob;
  fileName?: string;
  filePath?: string;
  fileType?: string;
  /** IDs for which this attachment belongs to. */
  identificationId?: string;
  ordering?: number;
  paymentSessionId?: string;
  productId?: string;
  side?: AttachmentSide;
  status?: AttachmentStatus;
  type?: AttachmentType;
  userId?: string;
  verificationStatus?: AttachmentVerificationStatus;
  virusScannedAt?: string;
  visibility?: AttachmentVisibility;
};
export type ComplianceHit = {
  clientFlags?: ComplianceClientFlags;
  dataType?: ComplianceHitDataType;
  number?: number;
  postOperation?: CompliancePostOperation;
  resourceName?: string;
  ruleDefinitionId?: string;
};
export type CheckoutAttemptRecord = {
  /** AmountMsat is the amount in milli satoshis for the payment attempt. */
  amountMsat?: number;
  /** BlockedByCompliance is a flag indicating if the payment attempt is blocked by compliance. */
  blockedByCompliance?: boolean;
  /** Expiry is the expiration time of the payment attempt. */
  expiry?: string;
  /** LNInvoice is the lightning invoice for the payment attempt. */
  lnInvoice?: string;
  missingComplianceClientFlags?: ComplianceClientFlags;
  /** OnChainAddress is the on-chain address for the payment attempt. */
  onChainAddress?: string;
  /** PaymentMethod denotes the method used for the payment transfer. */
  paymentMethod?: PaymentMethod;
  /** PaymentSessionUUID is the identifier of the associated payment session. */
  paymentSessionId?: string;
  /** SuggestedFeeRate is the suggested fee rate (sat/vB) for on-chain payments. */
  suggestedFeeRate?: number;
};
export type CheckoutAttempt = {
  /** CustomerID is the identifier of the customer initiating the payment.
    
    This field is required for online payment gateways. */
  customerId?: string;
  /** PaymentMethod denotes the method to be used for the new payment transfer. */
  paymentMethod: PaymentMethod;
};
export type CheckoutStatusRecord = {
  /** ExpiresAt is the time when the payment session expires. */
  expiresAt?: string;
  /** FeeStatus represents the status of paid fees of on-chain payment. */
  feeStatus?: FeeStatus;
  /** FundingAmountAvailable is the amount that is already available for funding. */
  fundingAmountAvailable?: number;
  /** FundingStatus represents the status of the funding of the payment session. */
  fundingStatus?: FundingStatus;
  /** PaidAt is the time when the payment session was paid. */
  paidAt?: string;
  /** PaymentMethod is the payment method that the customer used. */
  paymentMethod?: PaymentMethod;
  /** PaymentSessionID is the unique identifier for the payment session. */
  paymentSessionId?: string;
  /** Status represents the current status of the payment session. */
  status?: PaymentSessionStatus;
};
export type Company = {
  /** BillingEmail is the email address for billing-related communications. */
  billingEmail?: string;
  /** CompanyName represents the name of the company. */
  companyName?: string;
  /** CompanyType describes the type of the company. */
  companyType?: string;
  /** CountryISO3 is the identifier of the country where the company is located. */
  countryIso3?: string;
  /** Department represents the department of the company. */
  department?: string;
  /** DisplayName is the name that the company is displayed as. */
  displayName?: string;
  /** IndustryCategory describes the industry category of the company. */
  industryCategory?: string;
  /** Language is the default language of the company. */
  language?: string;
  /** ParentCompanyID is the unique identifier of the parent company. */
  parentCompanyId?: string;
  /** SubDepartment represents the sub-department of the company. */
  subDepartment?: string;
  /** Website is the official website URL of the company. */
  website?: string;
};
export type CompanyRecord = {
  /** BillingEmail is the email address for billing-related communications. */
  billingEmail?: string;
  childCompanies?: CompanyRecord[];
  /** CompanyID is the unique identifier of the company. */
  companyId?: string;
  /** CompanyName represents the name of the company. */
  companyName?: string;
  /** CompanyType describes the type of the company. */
  companyType?: string;
  /** CountryISO3 is the identifier of the country where the company is located. */
  countryIso3?: string;
  /** Department represents the department of the company. */
  department?: string;
  /** DisplayName is the name that the company is displayed as. */
  displayName?: string;
  /** IndustryCategory describes the industry category of the company. */
  industryCategory?: string;
  /** Language is the default language of the company. */
  language?: string;
  /** ParentCompanyID is the unique identifier of the parent company. */
  parentCompanyId?: string;
  publicLogoAttachmentId?: string;
  publicLogoAttachmentPath?: string;
  publicSymbolAttachmentId?: string;
  publicSymbolAttachmentPath?: string;
  shopHandle?: string;
  /** SubDepartment represents the sub-department of the company. */
  subDepartment?: string;
  /** Website is the official website URL of the company. */
  website?: string;
};
export type AddressRecord = {
  /** AddressID is the unique identifier for the phone number. */
  addressId?: string;
  addressLine?: string;
  /** Attachments is a list of attachments for the address. For example a proof of address. */
  attachments?: AttachmentRecord[];
  bankAccountId?: string;
  buildingNumber?: string;
  /** What does the address belongs to? */
  companyId?: string;
  countryIso3?: string;
  countrySubDivision?: string;
  createdAt?: string;
  customerId?: string;
  postCode?: string;
  streetName?: string;
  townName?: string;
  type?: AddressType;
  updatedAt?: string;
};
export type Address = {
  addressLine?: string;
  buildingNumber?: string;
  countryIso3?: string;
  countrySubDivision?: string;
  postCode?: string;
  streetName?: string;
  townName?: string;
  type?: AddressType;
};
export type BankAccountRecord = {
  address?: Address;
  /** BankAccountID is the unique identifier for the bank account. */
  bankAccountId?: string;
  /** BIC is the Bank Identifier Code. */
  bic?: string;
  /** CountryISO3 is the ISO3 country code. */
  countryIso3?: string;
  /** IBAN is the International Bank Account Number. */
  iban?: string;
};
export type BankAccount = {
  address?: Address;
  /** BIC is the Bank Identifier Code. */
  bic?: string;
  /** CountryISO3 is the ISO3 country code. */
  countryIso3?: string;
  /** IBAN is the International Bank Account Number. */
  iban?: string;
};
export type BankTransferRecord = {
  /** Amount is the amount to transfer in the smallest unit of the currency. */
  amount?: number;
  /** ApprovedAt is the time the bank transfer was approved. */
  approvedAt?: string;
  /** BankTransferID is the unique identifier for the bank transfer. */
  bankTransferId?: string;
  /** CancelledAt is the time the bank transfer was cancelled. */
  cancelledAt?: string;
  /** CompletedAt is the time the bank transfer was completed. */
  completedAt?: string;
  /** FailedAt is the time the bank transfer failed. */
  failedAt?: string;
  /** InitiatedAt is the time the bank transfer was initiated. */
  initiatedAt?: string;
  /** BankAccountID is the ID of the bank account to transfer the funds to. */
  receiverBankAccountId?: string;
  /** Reference is the reference for the bank transfer. */
  reference?: string;
  /** SenderBankAccountID is the ID of the bank account to transfer the funds from. */
  senderBankAccountId?: string;
  state?: State;
};
export type TransactionStatHistory = {
  date?: string;
  revenue?: number;
  transactions?: number;
};
export type DashboardStatistics = {
  history?: TransactionStatHistory[];
  totalRevenue?: number;
  totalTransactions?: number;
};
export type IdentificationRecord = {
  expiryAt?: string;
  identificationId?: string;
  identifier?: string;
  identityVerificationId?: string;
  issueAt?: string;
  issueBy?: string;
  issueCountryIso3?: string;
  status?: IdentificationStatus;
  type?: IDType;
  verificationStatus?: IdentificationVerificationStatus;
};
export type Identification = {
  expiryAt?: string;
  identifier?: string;
  issueAt?: string;
  issueBy?: string;
  issueCountryIso3?: string;
  status?: IdentificationStatus;
  type?: IDType;
};
export type ApiKeyRecord = {
  companyId?: string;
  keyId?: string;
  name?: string;
  userId?: string;
};
export type NewKeyRecord = {
  /** The API key */
  apiKey?: string;
  /** The ID of the company the API key is for. */
  companyId?: string;
  /** CreatedAt is the time the API key was created. */
  createdAt?: string;
  /** The UUID of the API key */
  keyId?: string;
  /** The name of the API key to make them easier to identify. */
  name?: string;
  /** The ID of the user who created  API key. */
  userId?: string;
};
export type NewKey = {
  /** Name of the API key to make them easier to identify. */
  name: string;
};
export type KeyRecord = {
  /** The ID of the company the API key is for. */
  companyId?: string;
  /** CreatedAt is the time the API key was created. */
  createdAt?: string;
  /** InvalidatedAt is the time the API key was invalidated. */
  invalidatedAt?: string;
  /** The UUID of the API key */
  keyId?: string;
  /** The name of the API key to make them easier to identify. */
  name?: string;
  /** Permissions for the API key. */
  permissionRoles?: string[];
};
export type PaymentSessionListRecord = {
  /** Amount is the total amount of the payment in the smallest currency unit (like cents). */
  amount?: number;
  /** AmountFloat is the total amount of the payment (that the end customer paid) in the base currency. */
  amountFloat?: number;
  createdAt?: string;
  currencyDecimalPlaces?: number;
  /** CurrencyISO3 is the identifier of the currency used for the payment. */
  currencyIso3?: string;
  /** CustomerID is the ID of the customer who did the payment. */
  customerId?: string;
  /** Description is the textual description of the payment. For example a product name. */
  description?: string;
  /** Details can be used to provide additional information for internal use. */
  details?: string;
  /** ExpiresAt is the timestamp of when the payment expires (maximum 30 days). */
  expiresAt?: string;
  /** FailureURL is the URL to redirect to upon a failed payment. */
  failureUrl?: string;
  /** FeeStatus is the status of the fee. */
  feeStatus?: FeeStatus;
  /** FundingAmountAvailable is the amount that is available for the payment session.
    So for FundingStatus=WellFunded, FundingAmountAvailable=Amount if the payment is fully funded.
    For FundingStatus=UnderFunded, FundingAmountAvailable < Amount.
    For FundingStatus=OverFunded, FundingAmountAvailable > Amount. */
  fundingAmountAvailable?: number;
  /** FundingStatus is the status of the funding. */
  fundingStatus?: FundingStatus;
  /** HodlMultiplier is the multiplier for the Hodl amount calculation. */
  hodlMultiplier?: number;
  /** OnChainConfirmations is the number of confirmations required for the on-chain payment. */
  onChainConfirmations?: number;
  /** PaidAt is the timestamp of when the payment was paid. */
  paidAt?: string;
  /** PaymentCustomerFullName is the full name of the customer who did the payment. */
  paymentCustomerFullName?: string;
  /** PaymentCustomerID is the ID of the customer who did the payment. */
  paymentCustomerId?: string;
  /** PaymentMethod is the payment method used for the payment. */
  paymentMethod?: PaymentMethod;
  /** PaymentSessionID is the unique identifier for the payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after payment processing. */
  redirectUrl?: string;
  /** Reference is a unique reference that can be used to identify the payment. */
  reference?: string;
  /** SettledAmount is the amount that will be paid to the company */
  settledAmount?: number;
  /** SettledAmountFloat is the amount to be paid to the company in base currency. */
  settledAmountFloat?: number;
  /** Status is the status of the payment. */
  status?: PaymentSessionStatus;
};
export type PaymentSessionList = {
  records?: PaymentSessionListRecord[];
  totalCount?: number;
};
export type PaymentSessionRecord = {
  /** Amount is the total amount of the payment in the smallest currency unit (like cents). */
  amount?: number;
  /** CurrencyISO3 is the identifier of the currency used for the payment. */
  currencyIso3?: string;
  /** CustomerID is the ID of the customer who did the payment. */
  customerId?: string;
  /** Description is the textual description of the payment. For example a product name. */
  description?: string;
  /** Details can be used to provide additional information for internal use. */
  details?: string;
  /** ExpiresAt is the timestamp of when the payment expires (maximum 30 days). */
  expiresAt?: string;
  /** FailureURL is the URL to redirect to upon a failed payment. */
  failureUrl?: string;
  /** FeeStatus is the status of the fee. */
  feeStatus?: FeeStatus;
  /** FundingAmountAvailable is the amount that is available for the payment session.
    So for FundingStatus=WellFunded, FundingAmountAvailable=Amount if the payment is fully funded.
    For FundingStatus=UnderFunded, FundingAmountAvailable < Amount.
    For FundingStatus=OverFunded, FundingAmountAvailable > Amount. */
  fundingAmountAvailable?: number;
  /** FundingStatus is the status of the funding. */
  fundingStatus?: FundingStatus;
  /** HodlMultiplier is the multiplier for the Hodl amount calculation. */
  hodlMultiplier?: number;
  /** OnChainConfirmations is the number of confirmations required for the on-chain payment. */
  onChainConfirmations?: number;
  /** PaidAt is the timestamp of when the payment was paid. */
  paidAt?: string;
  /** PaymentMethod is the payment method used for the payment. */
  paymentMethod?: PaymentMethod;
  /** PaymentSessionID is the unique identifier for the payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after payment processing. */
  redirectUrl?: string;
  /** Reference is a unique reference that can be used to identify the payment. */
  reference?: string;
  /** SettledAmount is the amount that will be paid to the company */
  settledAmount?: number;
  /** Status is the status of the payment. */
  status?: PaymentSessionStatus;
};
export type PaymentSession = {
  /** Amount is the total amount of the payment in the smallest currency unit (like cents). */
  amount?: number;
  /** CurrencyISO3 is the identifier of the currency used for the payment. */
  currencyIso3?: string;
  /** Description is the textual description of the payment. For example a product name. */
  description?: string;
  /** Details can be used to provide additional information for internal use. */
  details?: string;
  /** ExpiresAt is the timestamp of when the payment expires (maximum 30 days). */
  expiresAt?: string;
  /** FailureURL is the URL to redirect to upon a failed payment. */
  failureUrl?: string;
  /** HodlMultiplier is the multiplier for the Hodl amount calculation. */
  hodlMultiplier?: number;
  /** OnChainConfirmations is the number of confirmations required for the on-chain payment. */
  onChainConfirmations?: number;
  /** RedirectURL is the URL to redirect to after payment processing. */
  redirectUrl?: string;
  /** Reference is a unique reference that can be used to identify the payment. */
  reference?: string;
};
export type RefundSessionListRecord = {
  /** Amount is the total amount of the refund in the smallest currency unit (like cents). */
  amount?: number;
  /** AmountFloat is the total amount of the refund. */
  amountFloat?: number;
  createdAt?: string;
  currencyDecimalPlaces?: number;
  /** CurrencyISO3 is the identifier of the currency used for the refund. */
  currencyIso3?: string;
  /** FailureURL is the URL to redirect to upon a failed refund. */
  failureUrl?: string;
  /** PaymentSessionID is the identifier of the associated payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after refund processing. */
  redirectUrl?: string;
  /** RefundCustomerFullName is the full name of the customer who claims the refund. */
  refundCustomerFullName?: string;
  /** RefundCustomerID is the ID of the customer who claims the refund. */
  refundCustomerId?: string;
  /** RefundSessionID is the unique identifier for the refund session. */
  refundSessionId?: string;
  /** RefundURL is the URL to process the refund. */
  refundUrl?: string;
};
export type RefundSessionList = {
  records?: RefundSessionListRecord[];
  totalCount?: number;
};
export type RefundSessionRecord = {
  /** Amount is the total amount of the refund in the smallest currency unit (like cents). */
  amount?: number;
  /** CurrencyISO3 is the identifier of the currency used for the refund. */
  currencyIso3?: string;
  /** FailureURL is the URL to redirect to upon a failed refund. */
  failureUrl?: string;
  /** PaymentSessionID is the identifier of the associated payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after refund processing. */
  redirectUrl?: string;
  /** RefundSessionID is the unique identifier for the refund session. */
  refundSessionId?: string;
  /** RefundURL is the URL to process the refund. */
  refundUrl?: string;
};
export type RefundSession = {
  /** Amount is the total amount of the refund in the smallest currency unit (like cents). */
  amount?: number;
  /** CurrencyISO3 is the identifier of the currency used for the refund. */
  currencyIso3?: string;
  /** FailureURL is the URL to redirect to upon a failed refund. */
  failureUrl?: string;
  /** PaymentSessionID is the identifier of the associated payment session. */
  paymentSessionId?: string;
  /** RedirectURL is the URL to redirect to after refund processing. */
  redirectUrl?: string;
  /** RefundURL is the URL to process the refund. */
  refundUrl?: string;
};
export type ProductStatus = string;
export type ProductRecord = {
  currencyIso3?: string;
  deliveryType?: DeliveryType;
  description?: string;
  displayName?: string;
  price?: number;
  priceFloat?: number;
  productId?: string;
  productName?: string;
  publicImageAttachmentIds?: string[];
  publicImageAttachmentPaths?: string[];
  status?: ProductStatus;
  vatType?: VATType;
  website?: string;
};
export type ProductList = {
  attachments?: AttachmentRecord[];
  records?: ProductRecord[];
  totalCount?: number;
};
export type Product = {
  currencyIso3?: string;
  deliveryType?: DeliveryType;
  description?: string;
  displayName?: string;
  price?: number;
  productName?: string;
  status?: ProductStatus;
  vatType?: VATType;
  website?: string;
};
export type ShoppingCartWithStatus = {
  PaymentDate?: string;
  PaymentExpiresAt?: string;
  PaymentFeeStatus?: PaymentFeeStatus;
  PaymentFundingStatus?: PaymentFundingStatus;
  PaymentMethod?: PaymentMethod;
  PaymentStatus?: PaymentStatus;
  currencyIso3?: string;
  customerId?: string;
  shippingAddressId?: string;
  shippingCost?: number;
  shippingCostFloat?: number;
  shippingCostVatMultiplier?: number;
  shippingCostVatType?: VATType;
  shoppingCartId?: string;
  totalAmount?: number;
  totalAmountFloat?: number;
  totalVat?: number;
  totalVatFloat?: number;
  vatCountryIso3?: string;
};
export type ShoppingCartList = {
  records?: ShoppingCartWithStatus[];
  totalCount?: number;
};
export type AddOrRemoveUserToCompany = {
  userId?: string;
};
export type ComplianceHitCompleted = {
  /** Attachments is a list of attachments. */
  attachments?: AttachmentRecord[];
  clientFlags?: ComplianceClientFlags;
  /** ComplianceHitID is the identifier of the compliance hit. */
  complianceHitId?: string;
  /** DataType is the data type for what the compliance hit was about. */
  dataType?: ComplianceHitDataType;
  /** Number a reference number. */
  number?: number;
  /** PostOperation is the post operation for the compliance hit. */
  postOperation?: ComplianceHitDataType2;
  /** ResourceName is the name of the resource for the compliance hit. */
  resourceName?: string;
  /** RuleDefinitionID is the identifier of the rule definition. */
  ruleDefinitionId?: string;
  /** Status is the status of the compliance hit. */
  status?: ComplianceHitStatus;
};
export type ComplianceHits = {
  /** ComplianceHits is a list of compliance hits. */
  complianceHits?: ComplianceHitCompleted[];
};
export type Compliance = {
  /** Blocked is a flag indicating if there are blocking compliance hits. */
  blocked?: boolean;
  complianceHits?: ComplianceHits;
  /** CustomerID is the identifier of the customer initiating the payment. */
  customerId?: string;
  missingComplianceClientFlags?: ComplianceClientFlags;
  /** PaymentMethod denotes the method to be used for the new payment transfer. */
  paymentMethod?: PaymentMethod;
  /** PaymentSessionID is the identifier of the payment session. */
  paymentSessionId?: string;
};
export type Country = {
  countryId?: number;
  formattedName?: string;
  iso2?: string;
  iso3?: string;
  name?: string;
  number?: number;
  phoneCode?: number;
  vatName?: string;
  vatNameShort?: string;
  vatParking?: number;
  vatReduced?: number;
  vatReducedAlt?: number;
  vatStandard?: number;
  vatSuperReduced?: number;
};
export type Currency = {
  CurrencyID?: number;
  DecimalPlaces?: number;
  FormattedName?: string;
  ISO3?: string;
  Name?: string;
  Number?: number;
  Symbol?: string;
};
export type CustomerRecord = {
  customerId?: string;
  email?: string;
  firstName?: string;
  isGuest?: boolean;
  lastName?: string;
  secondLastName?: string;
};
export type PhoneNumber = {
  countryIso3?: string;
  number?: string;
  type?: PhoneNumberType;
};
export type UpdateCustomerAccount = {
  address?: Address;
  birthDate?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  residenceCountryIso3?: string;
  secondLastName?: string;
};
export type CreateCustomerAccount = {
  address?: Address;
  birthDate?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  phoneNumber?: PhoneNumber;
  pin?: string;
  repeatPassword?: string;
  repeatPin?: string;
  residenceCountryIso3?: string;
  secondLastName?: string;
};
export type CustomerWithDetails = {
  address?: Address;
  birthDate?: string;
  customerId?: string;
  email?: string;
  firstName?: string;
  isGuest?: boolean;
  knowYourCustomerChecked?: boolean;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  residenceCountryIso3?: string;
  secondLastName?: string;
};
export type CustomerLogin = {
  email: string;
  password?: string;
  pin?: string;
};
export type CustomerLoginDetails = {
  /** AllowGuest if account doesn't exist */
  allowGuest?: boolean;
  /** Only relevant when allowGuest is false */
  password?: boolean;
  /** Only relevant when allowGuest is false */
  pin?: boolean;
};
export type CustomerEmail = {
  email: string;
};
export type IdentityVerificationRecord = {
  address?: string;
  authority?: string;
  birthPlace?: string;
  birthdayAt?: string;
  citizenship?: string;
  documentExpiresAt?: string;
  documentIssuedAt?: string;
  documentNumber?: string;
  email?: string;
  firstName?: string;
  identificationCreationStatus?: IdentificationCreationStatus;
  identityVerificationId?: string;
  identomatSessionToken?: string;
  issuingCountry?: string;
  lastName?: string;
  /** Personal details */
  localFirstName?: string;
  localLastName?: string;
  localRejectReason?: string;
  machineReadableZone?: string;
  manualCheckComment?: string;
  matchStatus?: string;
  nationality?: string;
  personalNumber?: string;
  phone?: string;
  /** RejectReasonDescription why rejection at provider */
  rejectReasonDescription?: string;
  remoteIp?: string;
  remoteIpCountryIso3?: string;
  sex?: string;
  userAgent?: string;
  verificationData?: string;
  verificationProvider?: IdentityVerificationProvider;
  verificationStatus?: IdentificationVerificationStatus;
  verificationUrl?: string;
};
export type InitiateIdentityVerificationRequest = {
  /** ReturnPath is the path in Eden to redirect to after the identity verification process.
    should start with / */
  returnPath?: string;
};
export type CompleteIdentityVerificationRequest = {
  identomatSessionToken?: string;
  verificationProvider?: VerificationProvider;
};
export type DemoshopPaymentSessionNew = {
  /** Amount is the total amount of the new payment in the smallest currency unit (like cents). */
  amount?: number;
  /** CurrencyID is the identifier of the currency used for the new payment. */
  currencyIso3?: string;
  /** Description provides additional details about the new payment. */
  description?: string;
  /** Details can be used to provide additional information for internal use. */
  details?: string;
  /** HodlMultiplier is the multiplier for the Hodl amount calculation. */
  hodlMultiplier?: number;
  /** OnChainConfirmations is the number of confirmations required for the on-chain payment. */
  onChainConfirmations?: number;
  /** RedirectURL is the URL to redirect to after processing the new payment. */
  redirectUrl?: string;
  /** Reference is the reference of the new payment. */
  reference?: string;
};
export type CreateOrUpdateCustomerGuest = {
  address?: Address;
  birthDate?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  residenceCountryIso3?: string;
  secondLastName?: string;
};
export type Role = {
  role?: string;
};
export type CompanyShopRecord = {
  companyCountry?: Country;
  companyName?: string;
  defaultShippingCost?: number;
  defaultShippingCostVatType?: VATType;
  displayName?: string;
  publicLogoAttachmentPath?: string;
  publicSymbolAttachmentPath?: string;
  shopCurrencyIso3?: string;
  shopFailureUrl?: string;
  shopHandle?: string;
  shopRedirectUrl?: string;
  shopVatType?: ShopVATType;
  website?: string;
};
export type ShoppingCartRecord = {
  currencyIso3?: string;
  customerId?: string;
  shippingAddressId?: string;
  shippingCost?: number;
  shippingCostFloat?: number;
  shippingCostVatMultiplier?: number;
  shippingCostVatType?: VATType;
  shoppingCartId?: string;
  totalAmount?: number;
  totalAmountFloat?: number;
  totalVat?: number;
  totalVatFloat?: number;
  vatCountryIso3?: string;
};
export type ShoppingCart = {
  currencyIso3?: string;
  customerId?: string;
  shippingAddressId?: string;
  shippingCost?: number;
  shippingCostVatMultiplier?: number;
  shippingCostVatType?: VATType;
  totalAmount?: number;
  totalVat?: number;
};
export type ShoppingCartProduct = {
  currencyIso3?: string;
  deliveryType?: DeliveryType;
  description?: string;
  displayName?: string;
  price?: number;
  priceFloat?: number;
  productId?: string;
  productName?: string;
  publicImageAttachmentIds?: string[];
  publicImageAttachmentPaths?: string[];
  quantity?: number;
  status?: ProductStatus;
  vatMultiplier?: number;
  vatType?: VATType;
  website?: string;
};
export type ShoppingCartRecordJoined = {
  currencyIso3?: string;
  customerId?: string;
  products?: ShoppingCartProduct[];
  shippingAddress?: AddressRecord;
  shippingAddressId?: string;
  shippingCost?: number;
  shippingCostFloat?: number;
  shippingCostVatMultiplier?: number;
  shippingCostVatType?: VATType;
  shoppingCartId?: string;
  totalAmount?: number;
  totalAmountFloat?: number;
  totalVat?: number;
  totalVatFloat?: number;
  vatCountryIso3?: string;
};
export type CompletedShoppingCart = {
  paymentSessionId?: string;
};
export type UpdatePassword = {
  /** The user's existing password */
  existingPassword: string;
  /** The user's new password */
  newPassword: string;
  /** The user's new password repeated */
  repeatNewPassword: string;
};
export enum ComplianceHitDataType {
  Customer = "customer",
  User = "user",
  Company = "company",
  ApiKey = "api-key",
  PaymentMethod = "payment-method",
  PaymentSession = "payment-session",
  PaymentAttempt = "payment-attempt",
  IpLookupData = "ip-lookup-data",
}
export enum CompliancePostOperation {
  Block = "block",
  PoliticallyExposedPerson = "politically-exposed-person",
  DigitalKnowYourCustomer = "digital-know-your-customer",
  Exempt = "exempt",
}
export enum ComplianceHitStatus {
  Inactive = "inactive",
  Active = "active",
  Resolved = "resolved",
  Archived = "archived",
}
export enum AttachmentSide {
  Front = "front",
  Back = "back",
  Left = "left",
  Right = "right",
  Top = "top",
  Bottom = "bottom",
}
export enum AttachmentStatus {
  Inactive = "inactive",
  Active = "active",
  Deleted = "deleted",
}
export enum AttachmentType {
  NationalId = "national-id",
  Passport = "passport",
  DriversLicense = "drivers-license",
  SocialSecurity = "social-security",
  TaxId = "tax-id",
  LegalEntityId = "legal-entity-id",
  Picture = "picture",
  FaceImage = "face-image",
  FaceImageWithDocument = "face-image-with-document",
  FingerPrint = "finger-print",
  KnowYourCustomer = "know-your-customer",
  ProofOfDomicile = "proof-of-domicile",
  ProofOfFunds = "proof-of-funds",
  ProofOfOccupation = "proof-of-occupation",
  Logo = "logo",
  Symbol = "symbol",
  Unknown = "unknown",
}
export enum AttachmentVerificationStatus {
  Rejected = "rejected",
  Verified = "verified",
  Pending = "pending",
  TimedOut = "timed-out",
  Fraud = "fraud",
  Virus = "virus",
}
export enum AttachmentVisibility {
  Private = "private",
  Public = "public",
  Shop = "shop",
}
export enum AddressType {
  Domicile = "domicile",
}
export enum IdentificationStatus {
  Inactive = "inactive",
  Active = "active",
  Deleted = "deleted",
}
export enum IDType {
  NationalId = "national-id",
  Passport = "passport",
  DriversLicense = "drivers-license",
  SocialSecurity = "social-security",
  TaxId = "tax-id",
  LegalEntityId = "legal-entity-id",
  Unknown = "unknown",
}
export enum IdentificationVerificationStatus {
  Rejected = "rejected",
  Verified = "verified",
  Pending = "pending",
  TimedOut = "timed-out",
}
export enum PhoneNumberType {
  Mobile = "mobile",
  Landline = "landline",
}
export enum PhoneNumberVerificationStatus {
  Rejected = "rejected",
  Verified = "verified",
  Unverified = "unverified",
}
export enum Flag {
  IpLookupPrimaryServiceDisabled = "IPLookupPrimaryServiceDisabled",
  EmailLookupPrimaryServiceDisabled = "EmailLookupPrimaryServiceDisabled",
  EmailLookupSecondaryServiceDisabled = "EmailLookupSecondaryServiceDisabled",
  DemoshopComplianceEnabled = "DemoshopComplianceEnabled",
  DemoshopOpenSanctionsEnabled = "DemoshopOpenSanctionsEnabled",
}
export enum VATType {
  Standard = "standard",
  Reduced = "reduced",
  ReducedAlt = "reduced_alt",
  SuperReduced = "super_reduced",
  Parking = "parking",
}
export enum ShopVATType {
  Fixed = "fixed",
  EuDynamic = "eu_dynamic",
}
export enum Status {
  Inactive = "inactive",
  Active = "active",
}
export enum PaymentMethod {
  Lightning = "lightning",
  OnChain = "on-chain",
  Card = "card",
  Invoice = "invoice",
}
export enum FeeStatus {
  Correct = "correct",
  Low = "low",
  Insufficient = "insufficient",
}
export enum FundingStatus {
  WellFunded = "well-funded",
  UnderFunded = "under-funded",
  OverFunded = "over-funded",
}
export enum PaymentSessionStatus {
  Draft = "draft",
  Active = "active",
  FundingInTransit = "funding-in-transit",
  FundingReceived = "funding-received",
  FundingCollected = "funding-collected",
  Exchanged = "exchanged",
  Funded = "funded",
  Expired = "expired",
  Cancelled = "cancelled",
}
export enum DeliveryType {
  Physical = "physical",
  Virtual = "virtual",
}
export enum PaymentFeeStatus {
  Correct = "correct",
  Low = "low",
  Insufficient = "insufficient",
}
export enum PaymentFundingStatus {
  WellFunded = "well-funded",
  UnderFunded = "under-funded",
  OverFunded = "over-funded",
}
export enum PaymentStatus {
  Draft = "draft",
  Active = "active",
  FundingInTransit = "funding-in-transit",
  FundingReceived = "funding-received",
  FundingCollected = "funding-collected",
  Exchanged = "exchanged",
  Funded = "funded",
  Expired = "expired",
  Cancelled = "cancelled",
}
export enum ComplianceHitDataType2 {
  Block = "block",
  PoliticallyExposedPerson = "politically-exposed-person",
  DigitalKnowYourCustomer = "digital-know-your-customer",
  Exempt = "exempt",
}
export enum IdentificationCreationStatus {
  Initial = "initial",
  Pending = "pending",
  ResponseFetched = "response-fetched",
  CreatedIdentification = "created-identification",
  CustomerKycCheckedUpdated = "customer-kyc-checked-updated",
  AttachmentsAdded = "attachments-added",
  Complete = "complete",
}
export enum IdentityVerificationProvider {
  Identomat = "identomat",
}
export enum VerificationProvider {
  Identomat = "identomat",
}
