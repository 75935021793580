import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { PersonAdd20Regular as UserAddIcon, Send20Regular as SendIcon } from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Form, Input } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import styles from "pages/users/UsersPage.module.scss";
import api, { InputError, UserNew } from "api/api";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { FormToJson } from "api/apiUtils";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import React from "react";
import * as routes from "constants/routes";
import NoMatch from "pages/noMatch/NoMatch";
import { useConfirmation } from "components/dialogue/ConfirmContext";

function InviteUserModal() {
  const { t: tu } = useTranslation("user");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const location = useLocation();
  const isAdmin: boolean = location.pathname.includes(routes.INVITE_ADMIN_USER);
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();
  const toastRef = React.useContext(ToastContext);
  const writePermission = useHasPermission(["companyUsersWriteRead"]);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);

  const [inviteUser, { error: inviteUserErrors, isLoading: loadingInviteUser, isSuccess: isSuccessCreateUser }] =
    api.useCreateCompanyUserMutation();

  const [
    inviteAdminUser,
    { error: inviteAdminUserErrors, isLoading: loadingInviteAdminUser, isSuccess: isSuccessCreateAdminUser },
  ] = api.useCreateAdminUserMutation();

  useEffect(() => {
    if (isSuccessCreateUser || isSuccessCreateAdminUser) {
      toastRef?.current?.addToast(tu("newUserSuccessfullyInvited"), toastCategory.success);
      navigate(-1);
    } else {
      const err = getErrorResponse(inviteUserErrors || inviteAdminUserErrors);
      setErrMessage(err?.message);
      if (err && err.inputErrors) {
        setInputErrors(err.inputErrors);
      }
    }
  }, [isSuccessCreateUser, isSuccessCreateAdminUser, inviteUserErrors, inviteAdminUserErrors]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isAdmin && !selectedCompanyId) {
      setErrMessage("Company not selected");
      return;
    }

    // Get all the data from the form
    const formJson = FormToJson<UserNew>(event);

    if (isAdmin) {
      const result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: (tu("confirmInviteAdminUser") + event.currentTarget.email.value) as string,
      });
      if (result) {
        inviteAdminUser(formJson);
      }
    } else {
      inviteUser({ companyId: selectedCompanyId || "", userNew: formJson });
    }
  };

  if (isAdmin && !hasEdenAdminPermission) {
    return <NoMatch />;
  }

  return (
    <PopoutPageTemplate
      title={isAdmin ? tu("InviteAdminUser") : tu("InviteUserToCompany")}
      show={true}
      onClose={() => navigate(-1)}
      icon={<UserAddIcon />}
    >
      {!writePermission && (
        <div className={styles.modalContentWrapper}>
          <Note noteType={NoteType.error}>{t("youLackPermissionsView")}</Note>
        </div>
      )}
      {writePermission && (
        <div className={styles.modalContentWrapper}>
          <Form intercomTarget={"invite-user-form"} onSubmit={handleSubmit}>
            <Input label={tu("Email")} name={"email"} type={"email"} errors={Tie("Email", ie, ["user"])} />
            <Input hidden type={"checkbox"} name={"createSignInToken"} checked />
            <Input hidden type={"checkbox"} name={"sendInvite"} checked />
            {/* If there are errors then add a Note */}
            {(inviteUserErrors || inviteAdminUserErrors) && (
              <Note noteType={NoteType.error} title={t("Error")}>
                {errMessage}
              </Note>
            )}
            <ButtonRow>
              <Button
                intercomTarget={"save-permissions-button"}
                buttonColor={ColorVariant.success}
                type={"submit"}
                disabled={loadingInviteUser || loadingInviteAdminUser || !writePermission}
                icon={loadingInviteUser || loadingInviteAdminUser ? <Spinny /> : <SendIcon />}
              >
                {t("Invite")}
              </Button>
            </ButtonRow>
          </Form>
        </div>
      )}
    </PopoutPageTemplate>
  );
}

export default InviteUserModal;
