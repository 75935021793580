import React, { ReactNode } from "react";
import classNames from "classnames";
import { GetColorClass, GetSizeClass, InputColorVaraint, InputSizeVariant } from "components/forms/input/variants";
import styles from "components/forms/resultBox/ResultField.module.scss";
import Button from "components/buttons/Button";
import { Copy16Regular as CopyIcon } from "@fluentui/react-icons";
import { Label } from "components/forms/forms";

type Props = {
  label?: string;
  colorVariant?: InputColorVaraint;
  sizeVariant?: InputSizeVariant;
  intercomTarget?: string;
  className?: string;
  leftIcon?: ReactNode;
  button?: React.ReactNode;
  children: ReactNode;
  copyText?: string;
};

export default function ResultField(props: Props) {
  // Copy function
  function copyToClipboard() {
    // Put props.copyText into clipboard
    navigator.clipboard.writeText(props.copyText || "");
  }

  // Create a copy button if props.copyText is defined and replace the provided button
  let button = props.button;
  if (props.copyText) {
    button = <Button intercomTarget={"copy-button"} onClick={copyToClipboard} icon={<CopyIcon />} />;
  }

  return (
    <div
      className={classNames(styles.inputWrapper, GetSizeClass(props.sizeVariant), GetColorClass(props.colorVariant))}
      data-intercom-target={props.intercomTarget}
    >
      {props.label && <Label label={props.label} />}
      <div className={classNames({ [styles.inputButtonWrapper]: !!button })}>
        <div className={classNames(styles.inputFieldContainer, { [styles.hasLeftIcon]: !!props.leftIcon })}>
          {props.leftIcon && <div className={styles.leftIcon}>{props.leftIcon}</div>}
          <div className={classNames(styles.input, props.className)}>{props.children}</div>
          {button && <div className={styles.button}>{button}</div>}
        </div>
      </div>
    </div>
  );
}
