import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import {
  BinRecycle20Regular as RemoveUserIcon,
  List20Regular as DetailsIcon,
  Person20Regular as UserIcon,
  Save20Regular as SaveIcon,
} from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Checkbox, Form, Select } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useRef, useState } from "react";
import styles from "pages/users/UsersPage.module.scss";
import api, { UserPermissionRoles } from "api/api";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";
import { getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { UserRoleList } from "pages/users/permissions/namedRoles";
import { SectionContainer } from "features/section/SectionContainer";

type RoleOption = {
  label: string;
  value: string;
  description: string;
};

function EditUserPermissionsModal() {
  const { t: tu } = useTranslation("user");
  const { t: tp } = useTranslation("permissions");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const { userId } = useParams<{ userId: string }>();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAdmin, setIsAdmin] = useState(false); // company admin
  const [errMessage, setErrMessage] = useState<string>();
  const { data: currentUser, error: currentUserError } = api.useGetSelfUserQuery();
  const readPermission = useHasPermission(["companyUserRolesRead"]);
  const writePermission = useHasPermission(["companyUserRolesWriteRead"]);
  const edenAdminPermission = useHasPermission(["edenAdmin"]);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleOption | null>(null);

  if (currentUserError) {
    console.error("Error getting current user", currentUserError);
  }

  const { data: company } = api.useGetCompanyByIdQuery(selectedCompanyId || "", { skip: !selectedCompanyId });

  const [updatePermissions, { error: permissionSaveErrors, isLoading: loadingSavePerm }] =
    api.useUpdateUserPermissionRolesMutation();

  const { data: userPermissions } = api.useGetUserPermissionsByCompanyQuery(
    { companyId: selectedCompanyId || "", userId: userId || "" },
    { skip: !selectedCompanyId || !userId },
  );

  const { data: allRoles } = api.useGetAllUserRolesQuery();

  const [deleteUser, { error: deleteUserErrors, isLoading: loadingDeleteUser }] = api.useDeleteUserMutation();

  function setPermissions(user: UserPermissionRoles) {
    setIsAdmin(false);
    // otherwise, loop through the roles and check the checkboxes
    const checkboxes = formRef.current?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
    checkboxes?.forEach((checkbox) => {
      checkbox.checked = false;
      if (checkbox.name && user.permissionRoles?.includes(checkbox.name)) {
        if (checkbox.name === "companyAdmin") {
          setIsAdmin(true);
        }
        checkbox.checked = true;
      }
    });
  }

  // useeffect to update the permissions form checkboxes when the userPermissions or allRoles changes
  useEffect(() => {
    // if the apikey is not loaded, or the roles are not loaded, do nothing
    if (!userPermissions || !allRoles) {
      return;
    }
    setPermissions(userPermissions);
  }, [userPermissions?.permissionRoles?.length, allRoles?.length]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const roles: string[] = [];

    const checkboxes = event.currentTarget.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.name && checkbox.checked) {
        roles.push(checkbox.name);
      }
    });

    // Send it to the API
    updatePermissions({
      userId: userId || "",
      companyId: selectedCompanyId || "",
      roles: {
        roles: roles,
      },
    });
  };

  const composePreparedRoles = () => {
    return (
      UserRoleList.map((role) => {
        return {
          label: tp(`${role.name}.label`),
          value: role.name,
          description: tp(`${role.name}.description`),
        };
      }) || []
    );
  };

  const setPredefinedRoles = async (role: string): Promise<boolean> => {
    // Ask user to confirm role change
    const accepted = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: tp("confirmChangeRole", { role: tp(`${role}.label`) }),
    });

    if (!accepted) {
      return false;
    }

    setIsAdmin(false);
    const checkboxes = formRef.current?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
    checkboxes?.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const predefinedRole = UserRoleList.find((r) => r.name === role);
    predefinedRole?.permissions.forEach((permission) => {
      const checkbox = formRef.current?.querySelector<HTMLInputElement>(`input[name="${permission}"]`);
      if (checkbox) {
        checkbox.checked = true;
        if (checkbox.name === "companyAdmin") {
          setIsAdmin(true);
        }
      }
    });

    return true;
  };

  function isRoleSelected(roleName: string): boolean {
    const role = UserRoleList.find((r) => r.name === roleName);
    if (!role) {
      return false;
    }

    const selectedCheckboxes = formRef.current?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]:checked');
    if (!selectedCheckboxes) {
      return false;
    }

    const selectedPermissions = Array.from(selectedCheckboxes).map((checkbox) => checkbox.name);

    return (
      selectedPermissions.length === role.permissions.length &&
      role.permissions.every((permission) => selectedPermissions.includes(permission))
    );
  }

  const checkRole = () => {
    const matchingRole = UserRoleList.find((role) => isRoleSelected(role.name));
    if (matchingRole) {
      setSelectedRole({
        label: tp(`${matchingRole.name}.label`),
        value: matchingRole.name,
        description: tp(`${matchingRole.name}.description`),
      });
    } else {
      setSelectedRole({
        label: tp("Custom Role"),
        value: "custom",
        description: tp("customRoleDescription"),
      });
    }
  };

  useEffect(() => {
    if (!userPermissions || !allRoles) {
      return;
    }

    checkRole();
  }, [userPermissions, allRoles]);

  const handleRemoveUser = () => {
    return async () => {
      const result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: (
          <div>
            <div>{tu("confirmRemoveUser")}</div>
            <div className={styles.removeUserConfirmUserName}>
              {userPermissions?.firstName} {userPermissions?.lastName} ({userPermissions?.email})
            </div>
          </div>
        ),
      });
      if (result) {
        deleteUser(userId || "")
          .unwrap()
          .then((resp) => {
            if (resp.message) {
              navigate(-1);
            }
          });
      }
    };
  };

  useEffect(() => {
    const err = getErrorResponse(permissionSaveErrors);
    setErrMessage(err?.message);
  }, [permissionSaveErrors]);

  useEffect(() => {
    const err = getErrorResponse(deleteUserErrors);
    setErrMessage(err?.message);
  }, [deleteUserErrors]);

  return (
    <PopoutPageTemplate title={tu("EditUserPermissions")} show={true} onClose={() => navigate(-1)} icon={<UserIcon />}>
      {!readPermission && (
        <div className={styles.modalContentWrapper}>
          <Note noteType={NoteType.error}>{t("youLackPermissionsView")}</Note>
        </div>
      )}
      {readPermission && (
        <div className={styles.modalContentWrapper}>
          {currentUser?.userId === userId && (
            <div className={styles.modalTopInfo}>
              <Note noteType={NoteType.warning}>{tu("cannotEditOwnPermissions")}</Note>
            </div>
          )}
          <div>
            <h4 className={styles.permissionsModalUserNameHeader}>{tu("User")}</h4>
            <div className={styles.permissionsModalUserName}>
              {userPermissions?.firstName} {userPermissions?.lastName} ({userPermissions?.email})
            </div>
            <div className={styles.companyName}>{company?.companyName}</div>
          </div>
          <div>
            <h4 className={styles.permissionsModalHeader}>{t("Permissions", { ns: "common" })}</h4>
            <Select
              onChange={async (newValue) => {
                const accepted = await setPredefinedRoles((newValue as RoleOption).value);
                if (!accepted) {
                  // TODO: Set the role to the role that fits, or a "custom role" label
                  // reset the select to the previous value
                  setSelectedRole(null);
                  return;
                }
                setSelectedRole(newValue as RoleOption);
                // {
                //   value: "custom",
                //   label: tp("Custom Role"),
                //   description: tp("customRoleDescription"),
                // }
              }}
              value={selectedRole}
              intercomTarget={"quick-roles-select"}
              options={composePreparedRoles().map((role) => ({
                label: (
                  <div>
                    <div>{role.label}</div>
                    <div style={{ fontSize: "small", color: "gray" }}>{role.description}</div>
                  </div>
                ),
                value: role.value,
              }))}
            />
          </div>
          <Form intercomTarget={"permissions-form"} onSubmit={handleSubmit} ref={formRef}>
            {/* list allRoles as a series of checkboxes */}
            <SectionContainer
              title={tp("Permission details")}
              icon={DetailsIcon}
              expanded={detailsExpanded}
              disabled={!writePermission || currentUser?.userId === userId}
              handleToggle={() => setDetailsExpanded(!detailsExpanded)}
            >
              <div className={styles.permissionsListContainer}>
                {allRoles?.map((role) => (
                  <div className={styles.permissionItem} key={role.role}>
                    <Checkbox
                      id={role.role}
                      name={role.role}
                      label={tp(`${role.role}.label`)}
                      disabled={
                        (isAdmin && role.role !== "companyAdmin") || !writePermission || currentUser?.userId === userId
                      }
                      onChange={(e) => {
                        if (e.target.name === "companyAdmin") {
                          setIsAdmin(e.target.checked);
                        }
                        checkRole();
                      }}
                    />
                    {/* I'm using label here to let users also click the description */}
                    <label htmlFor={role.role} className={styles.permissionDescription}>
                      {tp(`${role.role}.description`)}
                    </label>
                  </div>
                ))}
              </div>
            </SectionContainer>
            {/* If there are errors then add a Note */}
            {(permissionSaveErrors || deleteUserErrors) && (
              <Note noteType={NoteType.error} title={t("Error")}>
                {errMessage}
              </Note>
            )}
            <ButtonRow>
              <Button
                intercomTarget={"save-permissions-button"}
                buttonColor={ColorVariant.success}
                type={"submit"}
                disabled={loadingSavePerm || !writePermission || currentUser?.userId === userId}
                icon={loadingSavePerm ? <Spinny /> : <SaveIcon />}
              >
                {t("Save permissions")}
              </Button>
            </ButtonRow>
          </Form>
          {/* for now only show delete button for eden admin */}
          {/* TODO add "remove user from company" button */}
          {edenAdminPermission && (
            <div className={styles.removeUserButton}>
              <Button
                intercomTarget={"remove-user-button"}
                buttonColor={ColorVariant.error}
                type={"button"}
                onClick={handleRemoveUser()}
                disabled={loadingDeleteUser || !writePermission || currentUser?.userId === userId}
                icon={loadingDeleteUser ? <Spinny /> : <RemoveUserIcon />}
              >
                {tu("removeUser")}
              </Button>
            </div>
          )}
        </div>
      )}
    </PopoutPageTemplate>
  );
}

export default EditUserPermissionsModal;
