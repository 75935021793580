import { createContext, useEffect, useState } from "react";
import styles from "pages/consumerCompliance/ComplianceCheckPage.module.scss";
import ProgressTabs from "features/progressTabs/ProgressTab";
import ProgressHeader from "features/progressTabs/ProgressHeader";
import { RenderStepHeaders, RenderSteps } from "pages/consumerCompliance/modules/ComplianceSteps";
import { useLocation, useNavigate } from "react-router-dom";
import api, { CustomerWithDetails, PaymentMethod } from "api/api";
import { QueryStatus } from "@reduxjs/toolkit/query";
import * as routes from "constants/routes";
import { useLivechatCustomer } from "components/support/livechatUserDataHooks";
import { ContactSupportText } from "components/support/contactSupportText";

export type ComplianceCheckContextProps = {
  customerEmail?: string;
  setCustomerEmail: (email?: string) => void;
  paymentSessionId?: string;
  paymentMethod?: PaymentMethod;
  loggedInCustomer: CustomerWithDetails | undefined | null;
  preFillCustomerData?: CustomerWithDetails;
  returnFunction: () => void;
};

// Context provider for the ComplianceCheckPage containing the CustomerId
export const ComplianceCheckContext = createContext<ComplianceCheckContextProps>({
  loggedInCustomer: undefined,
  setCustomerEmail: () => {
    console.error("setCustomerEmail not defined");
  },
  returnFunction: () => {
    console.error("Return function not defined");
  },
});

function ComplianceCheckPage() {
  const [step, setStep] = useState(0);

  const loggedInCustomerResp = api.useGetCustomerForEditQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [loggedInCustomer, setLoggedInCustomer] = useState<CustomerWithDetails | undefined | null>();

  const location = useLocation();
  const { customerEmail: locationCustomerEmail, paymentMethod, preFillCustomerData } = location.state || {};

  const [customerEmail, setCustomerEmail] = useState<string | undefined>(locationCustomerEmail as string);

  // payment session from query params
  const query = new URLSearchParams(location.search);
  // payment session id is in 'ps' query param
  const paymentSessionId = query.get("ps") || undefined;

  useLivechatCustomer(customerEmail, loggedInCustomer || undefined, paymentSessionId);

  const navigate = useNavigate();

  // This could be sent from the backend.
  const steps: Array<string> = ["personalInfo", /*"questionnaire",*/ "identomat", "complete"];

  // This should be sent from the backend after the user has completed the steps, and it's validated by the backend.
  const [completedSteps, setLocalCompletedSteps] = useState<string[]>([]);

  const setCompletedSteps = (update: (prevSteps: string[]) => string[]) => {
    setLocalCompletedSteps((prevSteps) => update(prevSteps));
  };

  useEffect(() => {
    if (
      loggedInCustomerResp.isSuccess &&
      !loggedInCustomerResp.isFetching &&
      loggedInCustomerResp.status === QueryStatus.fulfilled
    ) {
      setLoggedInCustomer(loggedInCustomerResp?.data);
      setCustomerEmail(loggedInCustomerResp?.data?.email);
    } else if (
      loggedInCustomerResp.isError ||
      loggedInCustomerResp.isUninitialized ||
      loggedInCustomerResp.status === QueryStatus.rejected
    ) {
      setLoggedInCustomer(null);
    }
  }, [loggedInCustomerResp]);

  const returnFunction = () => {
    // if has payment session id in query params, always go to checkout
    if (paymentSessionId) {
      navigate(`${routes.CHECKOUT}/${paymentSessionId}`, {
        state: {
          paymentMethod: paymentMethod,
        },
      });
    } else {
      console.warn("No payment session found in query params. Going to earlier page.");
      navigate(-1);
    }
  };

  return (
    <ComplianceCheckContext.Provider
      value={{
        returnFunction,
        customerEmail,
        setCustomerEmail,
        paymentSessionId,
        paymentMethod,
        loggedInCustomer: loggedInCustomer,
        preFillCustomerData,
      }}
    >
      <div className={styles.compliancePage}>
        <div className={styles.background} />
        <div className={styles.cardWrapper}>
          <ProgressHeader modalCloseHandler={() => {}} className={styles.complianceHeaderWrapper}>
            {RenderStepHeaders(steps, step, setStep, completedSteps)}
          </ProgressHeader>
          <ProgressTabs showTabIndex={step} className={styles.complianceTabWrapper}>
            {RenderSteps(steps, step, setStep, completedSteps, setCompletedSteps)}
          </ProgressTabs>
          <ContactSupportText />
        </div>
      </div>
    </ComplianceCheckContext.Provider>
  );
}

export default ComplianceCheckPage;
