import { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import ConfirmDialogue from "components/dialogue/ConfirmDialogue";

export interface ConfirmationOptions {
  title: string;
  message: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
}

export type ConfirmFunction = (options: ConfirmationOptions) => Promise<boolean>;

const ConfirmationContext = createContext<ConfirmFunction | null>(null);

export const useConfirmation = (): ConfirmFunction => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error("useConfirmation must be used within a ConfirmationProvider");
  }
  return context;
};

interface ConfirmationState extends ConfirmationOptions {
  resolve: (value: boolean) => void;
}

export const ConfirmationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [confirmOptions, setConfirmOptions] = useState<ConfirmationState | null>(null);

  const confirm: ConfirmFunction = useCallback((options) => {
    return new Promise((resolve) => {
      setConfirmOptions({ ...options, resolve });
    });
  }, []);

  const handleClose = useCallback(
    (result: boolean) => {
      if (confirmOptions) {
        confirmOptions.resolve(result);
        setConfirmOptions(null);
      }
    },
    [confirmOptions],
  );

  return (
    <ConfirmationContext.Provider value={confirm}>
      {children}
      {confirmOptions && (
        <ConfirmDialogue
          isOpen={!!confirmOptions}
          title={confirmOptions.title}
          message={confirmOptions.message}
          confirmLabel={confirmOptions.confirmLabel}
          cancelLabel={confirmOptions.cancelLabel}
          onClosed={handleClose}
        />
      )}
    </ConfirmationContext.Provider>
  );
};
