import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const namespaces = [
  "apiKeys",
  "auth",
  "bootstrapping",
  "checkout",
  "common",
  "company",
  "connectionStatus",
  "currency",
  "dashboard",
  "demoShop",
  "navigation",
  "payment",
  "permissions",
  "user",
  "validation",
];

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    // resources,
    interpolation: {
      skipOnVariables: false,
    },
    lng: "en",
    fallbackLng: "en", // Fallback language
    ns: namespaces, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    /* TODO select detection order when localizations are available
      detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    */
    react: {
      useSuspense: true, // Show the page only when the translations are loaded
    },
    // debug: true, // DEBUG
  })
  .then(() => {
    // sat space formatting (give value in sats!)
    //eslint-disable-next-line
    i18n.services?.formatter?.add("satspace", (value, format, lng) => {
      const btcPart = Math.floor(value / 100_000_000);
      const satPart = value % 100_000_000;

      // non-breking space
      const nbsp = String.fromCharCode(160);

      return `${btcPart},${String(Math.floor((satPart / 1_000_000) % 1_000)).padStart(2, "0")}${nbsp}${String(
        Math.floor((satPart / 1_000) % 1_000),
      ).padStart(3, "0")}${nbsp}${String(Math.round(satPart % 1_000)).padStart(3, "0")}`;
    });

    // btc price formatting, rounded to int, space as thousand separator
    //eslint-disable-next-line
    i18n.services?.formatter?.add("btcprice", (value, format, lng) => {
      // use en locale to have comma as thousand separator and dot as decimal separator
      let rateStr = value.toLocaleString("en", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

      // replace the thousand separator with non-breaking space
      rateStr = rateStr.replace(",", String.fromCharCode(160));

      // replace the decimal separator with a comma
      rateStr = rateStr.replace(".", ",");

      return rateStr;
    });
  });

export default i18n;
