import styles from "components/forms/PhoneInput/PhoneInput.module.scss";
import PhoneExtensionSelect from "components/forms/PhoneInput/PhoneExtensionSelect";
import { Input } from "components/forms/forms";
import { InputProps } from "components/forms/input/Input";
import { useEffect, useState } from "react";
import { getPhoneExtensionsAsOptions, PhoneExtensionOption } from "api/api";
import { useSelector } from "react-redux";
import Label from "components/forms/label/Label";
import { useTranslation } from "react-i18next";

type PhoneInputProps = InputProps & {
  extensionIso3?: string;
};

function PhoneInput(props: PhoneInputProps) {
  const { t } = useTranslation("common");
  const [iso3, setIso3] = useState<PhoneExtensionOption | undefined>();

  const phoneExtensionOptions = useSelector(getPhoneExtensionsAsOptions);

  useEffect(() => {
    if (props.extensionIso3) {
      // find the option in the list of phone extensions
      const option = phoneExtensionOptions.find((option) => option.value === props.extensionIso3);
      setIso3(option);
    }
  }, [props.extensionIso3]);

  return (
    <div className={styles.phoneInputWrapper}>
      <Label label={props?.label} id={props.id} helpText={props.helpText} />

      <div className={styles.phoneInputContainer}>
        <PhoneExtensionSelect
          name={"phoneExtension"}
          value={iso3}
          onChange={(value) => {
            setIso3(value as PhoneExtensionOption);
          }}
          required={props.required}
          label={""}
          placeholder={t("Extension")}
        />
        <Input type="text" name="phoneNumber" required={props.required} value={props.value} label={""} />
      </div>
    </div>
  );
}

export default PhoneInput;
