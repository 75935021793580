import * as routes from "constants/routes";
import classNames from "classnames";
import MenuItem from "features/navigation/MenuItem";
import MerchantServicesLogo from "icons/mion-logo-ms.svg";
import {
  Building20Regular as CompanyIcon,
  BuildingBank20Regular as BankAccountsIcon,
  ChatHelp20Regular as HelpIcon,
  KeyMultiple20Regular as ApiKeysIcon,
  LockClosed20Regular as LogoutIcon,
  Navigation20Regular as CollapseIcon,
  NotebookError20Regular as FailureEventsIcon,
  PanelSeparateWindow20Regular as DashboardIcon,
  Payment20Regular as PaymentsIcon,
  People20Regular as UsersIcon,
  PeopleCommunity20Regular as CustomersIcon,
  Person20Regular as UserIcon,
  PersonProhibited20Regular as ComplianceHitsIcon,
  Settings20Regular as AdminSettingsIcon,
  Wallet20Regular as WithdrawIcon,
  Building20Regular as ShopIcon,
  ArrowHookUpLeft20Regular as RefundsIcon,
} from "@fluentui/react-icons";
import styles from "features/navigation/nav.module.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectHidden, toggleNav } from "features/navigation/navSlice";
import { useEffect } from "react";
import { CompanySelect } from "features/navigation/companySelect/CompanySelect";
import CustomItemWrapper from "features/navigation/CustomItemWrapper";
import MenuButtonItem from "features/navigation/MenuButtonItem";
import ComponentPermissionChecker from "features/authorization/ComponentPermissionChecker";
import NavCategory from "features/navigation/NavCategory";
import api, { CompanyRecord } from "api/api";
import { LivechatToggle } from "components/support/livechat";

function Navigation(props: { currentCompany?: CompanyRecord }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("navigation");
  // const { track } = userEvents();
  const hidden = useAppSelector(selectHidden);

  const { data: userDetails } = api.useGetSelfUserQuery(undefined, { refetchOnReconnect: true });

  const showShopMenu = !!props.currentCompany?.shopHandle;

  function toggleNavHandler() {
    // track("Toggle Navigation");
    dispatch(toggleNav());
  }

  function toggleNavOnMobile() {
    if (window.innerWidth < 900) {
      dispatch(toggleNav());
    }
  }

  const event = new CustomEvent("navHidden", { detail: hidden });

  // useEffect that triggers an event saying if navHidden has changed
  useEffect(() => {
    window.dispatchEvent(event);
  }, [hidden]);

  return (
    <div className={classNames(styles.navigation)}>
      <div className={styles.logoWrapper}>
        <div className={classNames(styles.logo, styles.logoNav)}>
          <img src={MerchantServicesLogo} alt="Logo" />
        </div>
        <div
          className={styles.collapseButton}
          id={"collapse-navigation"}
          data-testid={"collapse-navigation"}
          onClick={toggleNavHandler}
          data-intercom-target="collapse-nav-button"
        >
          <CollapseIcon />
        </div>
      </div>

      <div className={styles.mainNavWrapper}>
        <MenuItem
          intercomTarget="dashboard-nav-button"
          text={t("Dashboard")}
          icon={<DashboardIcon />}
          routeTo={"/"}
          onClick={toggleNavOnMobile}
        />
        <ComponentPermissionChecker roles={["companyRead", "companyWriteRead"]}>
          <MenuItem
            intercomTarget="company-nav-button"
            text={t("Company")}
            icon={<CompanyIcon />}
            routeTo={routes.COMPANY}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["apiKeysRead", "apiKeysWriteRead"]}>
          <MenuItem
            intercomTarget="apikeys-nav-button"
            text={t("API Keys")}
            icon={<ApiKeysIcon />}
            routeTo={routes.API_KEYS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>

        <ComponentPermissionChecker roles={["companyPaymentsRead", "companyPaymentsWriteRead"]}>
          <MenuItem
            intercomTarget="payments-nav-button"
            text={t("Payments")}
            icon={<PaymentsIcon />}
            routeTo={routes.PAYMENTS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["companyRefundsRead", "companyRefundsWriteRead"]}>
          <MenuItem
            intercomTarget="refunds-nav-button"
            text={t("Refunds")}
            icon={<RefundsIcon />}
            routeTo={routes.REFUNDS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["companyCustomersRead"]}>
          <MenuItem
            intercomTarget="customers-nav-button"
            text={t("Customers")}
            icon={<CustomersIcon />}
            routeTo={routes.CUSTOMERS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["companyBankAccountsRead", "companyBankAccountsWriteRead"]}>
          <MenuItem
            intercomTarget="bank-accounts-nav-button"
            text={t("BankAccounts")}
            icon={<BankAccountsIcon />}
            routeTo={routes.COMPANY_BANK_ACCOUNTS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["companyBankTransfersRead", "companyBankTransfersWriteRead"]}>
          <MenuItem
            intercomTarget="company-bank-withdrawals-nav-button"
            text={t("BankWithdrawals")}
            icon={<WithdrawIcon />}
            routeTo={routes.COMPANY_BANK_WITHDRAWALS}
            onClick={toggleNavOnMobile}
          />
        </ComponentPermissionChecker>
        <ComponentPermissionChecker roles={["companyUsersRead", "companyUsersWriteRead"]}>
          <MenuItem intercomTarget="users-nav-button" text={t("Users")} icon={<UsersIcon />} routeTo={routes.USERS} />
        </ComponentPermissionChecker>
        {showShopMenu && (
          <ComponentPermissionChecker roles={["companyProductsRead", "companyProductsWriteRead"]}>
            <NavCategory text={t("Shop")} collapsed={false} intercomTarget={"shop-nav-section"}>
              <MenuItem
                intercomTarget="products-nav-button"
                text={t("Products")}
                icon={<ShopIcon />}
                routeTo={routes.COMPANY_PRODUCTS}
                onClick={toggleNavOnMobile}
              />
            </NavCategory>
          </ComponentPermissionChecker>
        )}
        <ComponentPermissionChecker roles={["edenAdmin"]}>
          <NavCategory text={t("Admin")} collapsed={false} intercomTarget={"admin-nav-section"}>
            <MenuItem
              intercomTarget="admin-users-nav-button"
              text={t("AllUsers")}
              icon={<UsersIcon />}
              routeTo={routes.ADMIN_USERS}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="admin-customers-nav-button"
              text={t("AllCustomers")}
              icon={<CustomersIcon />}
              routeTo={routes.ADMIN_CUSTOMERS}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="admin-withdrawals-nav-button"
              text={t("AllBankWithdrawals")}
              icon={<WithdrawIcon />}
              routeTo={routes.ADMIN_WITHDRAWALS}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="failure-events-nav-button"
              text={t("FailureEvents")}
              icon={<FailureEventsIcon />}
              routeTo={routes.ADMIN_FAILURE_EVENTS}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="compliance-hits-nav-button"
              text={t("ComplianceHits")}
              icon={<ComplianceHitsIcon />}
              routeTo={routes.ADMIN_COMPLIANCE_HITS}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="create-company-nav-button"
              text={t("CreateCompany")}
              icon={<CompanyIcon />}
              withBackground={true}
              routeTo={routes.CREATE_COMPANY}
              onClick={toggleNavOnMobile}
            />
            <MenuItem
              intercomTarget="super-admin-settings"
              text={t("Admin Settings")}
              icon={<AdminSettingsIcon />}
              routeTo={routes.ADMIN_SUPER_ADMIN_SETTINGS}
              onClick={toggleNavOnMobile}
            />
          </NavCategory>
        </ComponentPermissionChecker>
      </div>

      <div className={classNames(styles.bottomWrapper)} data-intercom-target={"bottom-nav-section"}>
        {/*<MenuButtonItem*/}
        {/*  intercomTarget="intercom-launcher"*/}
        {/*  text={t("Help")}*/}
        {/*  icon={<HelpIcon />}*/}
        {/*  id={"intercom-launcher"}*/}
        {/*  onClick={() => {*/}
        {/*    track("Toggle Help");*/}
        {/*  }}*/}
        {/*/>*/}
        <CustomItemWrapper>
          <CompanySelect collapsed={hidden} />
        </CustomItemWrapper>
        <MenuItem
          intercomTarget="user-details-nav-button"
          text={userDetails?.email || t("NotLoggedIn")}
          icon={<UserIcon />}
          routeTo={routes.USER_SELF_DETAILS}
          withBackground={true}
          onClick={toggleNavOnMobile}
        />
        <MenuButtonItem
          intercomTarget="intercom-launcher"
          text={t("Help")}
          icon={<HelpIcon />}
          onClick={() => {
            toggleNavOnMobile();
            LivechatToggle();
          }}
        />
        <MenuItem intercomTarget="logout-nav-button" text={t("Sign out")} icon={<LogoutIcon />} routeTo={"/logout"} />
      </div>
    </div>
  );
}

export default Navigation;
