// React component for showing a round image at the top right of the page
//   the user is logged in and when howevering over the image you can see the user's name, address and email

import { useTranslation } from "react-i18next";
import styles from "pages/demo/demoUserHeader.module.scss";

export type user = {
  name: string;
  address: string;
  email: string;
  image: string;
};

export default function DemoUserHeader(props: user) {
  const { t } = useTranslation("demoShop");

  return (
    <div className={styles.userHeader}>
      <div className={styles.userHeaderInfo}>
        <div className={styles.customerLabel}>{t("Customer") + ":"}</div>
        <div className={styles.userHeaderName}>{props.name}</div>
        <div className={styles.userHeaderAddress}>{props.address}</div>
        <div className={styles.userHeaderEmail}>{props.email}</div>
      </div>
      <div className={styles.userHeaderImage}>
        <img src={props.image} alt={t("CustomerPhoto")} />
      </div>
    </div>
  );
}
