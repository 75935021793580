import { useAppDispatch } from "store/hooks";
import { toggleNav } from "features/navigation/navSlice";
import classNames from "classnames";
import MerchantServicesLogo from "icons/mion-logo-black.svg";
import { Navigation20Regular as CollapseIcon } from "@fluentui/react-icons";
import styles from "features/navigation/nav.module.scss";

function TopNavigation() {
  const dispatch = useAppDispatch();

  function toggleNavHandler() {
    dispatch(toggleNav());
  }

  return (
    <div className={classNames(styles.topNavigation)}>
      <div className={classNames(styles.topLogo)}>
        <img src={MerchantServicesLogo} alt="Logo" />
      </div>

      <div className={styles.topCollapseButton} onClick={toggleNavHandler}>
        <CollapseIcon />
      </div>
    </div>
  );
}

export default TopNavigation;
