export type SelectOption = { value: string | number; label: string; isDisabled?: boolean | undefined };

export type QuestionnaireField = {
  name: string;
  label: string;
  type: string;
  required?: boolean;
  options?: SelectOption[];
};

export const questionnaireFields: QuestionnaireField[] = [
  // {
  //   name: "phoneNumber",
  //   label: "Phone Number",
  //   type: "tel",
  //   required: false,
  // },
  {
    name: "expectedYearlyIncome",
    label: "Expected Yearly Income",
    type: "select",
    required: false,
    options: [
      { value: "0-20k", label: "€0-20k" },
      { value: "20-50k", label: "€20-50k" },
      { value: "50-80k", label: "€50-800k" },
      { value: "100k+", label: "€100k+" },
    ],
  },
  {
    name: "sourceOfFunds",
    label: "Source of Funds",
    type: "select",
    required: false,
    options: [
      { value: "salary", label: "Salary" },
      { value: "investment", label: "Investment" },
      { value: "gift", label: "Gift" },
      { value: "other", label: "Other" },
    ],
  },
  {
    name: "expectedAmount",
    label: "Expected Amount",
    type: "select",
    required: false,
    options: [
      { value: "0-10k", label: "€0-10k" },
      { value: "10-50k", label: "€10-50k" },
      { value: "50-100k", label: "€50-100k" },
      { value: "100k+", label: "€100k+" },
    ],
  },
  // Are you a politically exposed person?
  {
    name: "politicallyExposed",
    label: "Are you a politically exposed person?",
    type: "select",
    required: false,
    options: [
      { value: "no", label: "No" },
      { value: "yes", label: "Yes" },
    ],
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    required: false,
  },
];
