import styles from "components/forms/label/label.module.scss";
import classNames from "classnames";
import HelpTooltip from "components/forms/helpTooltip/helpTooltip";

type LabelProps = {
  label?: string;
  helpText?: string;
  id?: string;
  required?: boolean;
  className?: string;
};

function Label(props: LabelProps) {
  if (!props?.label) {
    return null;
  }

  return (
    <div className={classNames(styles.labelWrapper, props?.className)}>
      <label htmlFor={props?.id} className={styles.label} title={"Something"}>
        {props?.label}
        <span className={styles.required}>{props?.required && "*"}</span>
      </label>
      {/* Create a div with a circled question mark icon with a data label named data-title */}
      <HelpTooltip label={props?.label} text={props?.helpText} />
    </div>
  );
}

export default Label;
