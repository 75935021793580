export const REST_API_PATHNAME = "/api";
export const WS_API_PATHNAME = "/ws";
export const DEV_FRONTEND_PORT = "3001";
export const DEV_BACKEND_PORT = "8081";

export enum Status {
  Inactive,
  Active,
  Pending,
  Deleted,
  Initializing,
  Archived,
}

export const CurrencyUnits = {
  EUR: "€",
  USD: "$",
  BTC: "฿",
};

export type CurrencyIso3 = "EUR" | "USD" | "BTC";
