import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import {
  EditRegular as EditIcon,
  Key20Regular as KeyIcon,
  Warning20Regular as WarningIncon,
} from "@fluentui/react-icons";
import styles from "pages/apikeys/ApiKeysPage.module.scss";
import { ResultField } from "components/forms/forms";
import classNames from "classnames";
import Note, { NoteType } from "features/note/Note";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { API_KEY } from "constants/routes";

type ApiKeyProps = {
  companyId?: string;
  invalidatedAt?: string;
  keyId?: string;
  name?: string;
};

export default function ApiKey(props: ApiKeyProps) {
  const { t } = useTranslation("apiKeys");
  const navigate = useNavigate();
  const location = useLocation();

  // Translated status
  const status = !props.invalidatedAt ? t("Active") : t("Inactive");

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.statusWrapper}>
          <div className={classNames(styles.statusIndicator, { [styles.active]: !props.invalidatedAt })} />
          <div className={styles.statusLabel}>{status}</div>
          <Button
            intercomTarget={"edit-key"}
            buttonColor={ColorVariant.ghost}
            icon={<EditIcon />}
            key={"edit-link"}
            onClick={() => {
              navigate(API_KEY.replace(":keyId", props?.keyId || ""), { state: { background: location } });
            }}
          >
            {t("Edit")}
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <ResultField label={t("Key ID")} copyText={props.keyId} leftIcon={<KeyIcon />}>
          {props.keyId}
        </ResultField>
      </div>
    </div>
  );
}

export function NewApiKey(props: ApiKeyProps & { apiKey?: string; onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <div className={styles.content}>
      <ResultField label={t("Key ID")} copyText={props.keyId}>
        {props.keyId}
      </ResultField>
      <ResultField label={t("Key Secret")} copyText={props.apiKey}>
        {props.apiKey}
      </ResultField>

      <Note title={t("Attention")} noteType={NoteType.warning} icon={<WarningIncon />}>
        {t("ApiKeyNote")}
      </Note>

      <Button
        onClick={props.onClose}
        intercomTarget={"close-button"}
        buttonColor={ColorVariant.warning}
        buttonPosition={ButtonPosition.fullWidth}
      >
        {t("CloseConfirm")}
      </Button>
    </div>
  );
}
