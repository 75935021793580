import api, { AddressRecord } from "api/api";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import Page from "layout/Page";
import NoMatch from "pages/noMatch/NoMatch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "components/pageTitle/PageTitle";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import AddressForm from "features/addressForm/addressForm";
import UpdateCompanyForm from "pages/company/UpdateCompanyForm";
import Card, { CardHeader } from "components/cards/Card";
import { CompanyTree } from "pages/company/companyTree/CompanyTree";
import styles from "pages/company/CompanyPage.module.scss";

function CompanyPage() {
  const { t } = useTranslation("common");
  const { t: tc } = useTranslation("company");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const readPermission = useHasPermission(["companyRead", "companyWriteRead"]);
  // Get the entire company tree
  const { data: companyTree } = api.useGetCompanyTreeQuery(selectedCompanyId || "", {
    skip: !readPermission || !selectedCompanyId,
    refetchOnMountOrArgChange: true,
  });

  // TODO: Create child company

  // add one empty address, if there are existing no addresses
  const [addresses, setAddresses] = useState<AddressRecord[]>([{ addressId: "" } as AddressRecord]);

  const { data: addressesQ } = api.useGetAddressesByCompanyQuery(selectedCompanyId || "", {
    skip: !selectedCompanyId || !readPermission,
    refetchOnMountOrArgChange: true,
  });

  // replace the empty address with the fetched addresses
  useEffect(() => {
    if (addressesQ && addressesQ.length > 0) {
      setAddresses(addressesQ);
    } else {
      setAddresses([{ addressId: "" } as AddressRecord]);
    }
  }, [addressesQ]);

  if (!readPermission) {
    return <NoMatch />;
  }

  return (
    <Page head={<PageTitle title={tc("Company")} />}>
      <Card>
        <CardHeader header={"Company Details"} />
        <UpdateCompanyForm />
      </Card>
      <Card>
        {/* Addresses, currently only 1 */}
        <CardHeader header={t("Address")} />
        <div className={styles.addressesContainer}>
          {addresses &&
            addresses.map((a) => (
              <div key={a.addressId}>
                <AddressForm companyId={selectedCompanyId} addressRecord={a}></AddressForm>
              </div>
            ))}
        </div>
      </Card>
      <Card>
        <CardHeader header={t("Branches")} />
        {companyTree && <CompanyTree companyTree={companyTree} level={0} currentCompanyId={selectedCompanyId} />}
      </Card>
    </Page>
  );
}

export default CompanyPage;
