const eventIcons = new Map<string, string>([
  [
    "rebalanced_in",
    `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1464 2.64645C13.9512 2.84171 13.9512 3.15829 14.1464 3.35355L15.7929 5H9C5.68629 5 3 7.68629 3 11C3 14.3137 5.68629 17 9 17C12.3137 17 15 14.3137 15 11C15 10.7239 14.7761 10.5 14.5 10.5C14.2239 10.5 14 10.7239 14 11C14 13.7614 11.7614 16 9 16C6.23858 16 4 13.7614 4 11C4 8.23858 6.23858 6 9 6H15.7929L14.1464 7.64645C13.9512 7.84171 13.9512 8.15829 14.1464 8.35355C14.3417 8.54882 14.6583 8.54882 14.8536 8.35355L17.3536 5.85355C17.5488 5.65829 17.5488 5.34171 17.3536 5.14645L14.8536 2.64645C14.6583 2.45118 14.3417 2.45118 14.1464 2.64645Z" fill="#033048"/>
        </svg>
    `,
  ],
  [
    "max_htlc",
    `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1.00122C13.2761 1.00122 13.5 0.777363 13.5 0.501221C13.5 0.255761 13.3231 0.0516133 13.0899 0.00927734L13 0.0012207H2C1.72386 0.0012207 1.5 0.225078 1.5 0.501221C1.5 0.746681 1.67688 0.95083 1.91012 0.993166L2 1.00122H13ZM7.50014 15.999C7.7456 15.999 7.9497 15.822 7.99197 15.5887L8 15.4989L7.996 3.70501L11.6414 7.35334C11.8148 7.52707 12.0842 7.5466 12.2792 7.41179L12.3485 7.354C12.5222 7.18059 12.5418 6.91118 12.407 6.71619L12.3492 6.64689L7.85745 2.14689C7.78495 2.07426 7.69568 2.02858 7.60207 2.00986L7.49608 2.00012C7.33511 2.00012 7.19192 2.07624 7.10051 2.19444L2.64386 6.64631C2.44846 6.84143 2.44823 7.15802 2.64336 7.35342C2.8168 7.52711 3.08621 7.54658 3.28117 7.41173L3.35046 7.35392L6.996 3.71301L7 15.4992C7.00008 15.7753 7.224 15.999 7.50014 15.999Z" fill="#033048"/>
        </svg>
    `,
  ],
  [
    "min_htlc",
    `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 14.9989C13.7761 14.9989 14 15.2227 14 15.4989C14 15.7443 13.8231 15.9485 13.5899 15.9908L13.5 15.9989H2.5C2.22386 15.9989 2 15.775 2 15.4989C2 15.2534 2.17688 15.0493 2.41012 15.0069L2.5 14.9989H13.5ZM8.00014 0.00109863C8.2456 0.00109863 8.4497 0.178104 8.49197 0.411366L8.5 0.501244L8.496 12.2951L12.1414 8.64677C12.3148 8.47304 12.5842 8.45351 12.7792 8.58832L12.8485 8.64611C13.0222 8.81952 13.0418 9.08893 12.907 9.28392L12.8492 9.35322L8.35745 13.8532C8.28495 13.9259 8.19568 13.9715 8.10207 13.9902L7.99608 14C7.83511 14 7.69192 13.9239 7.60051 13.8057L3.14386 9.3538C2.94846 9.15867 2.94823 8.84209 3.14336 8.64669C3.3168 8.473 3.58621 8.45353 3.78117 8.58838L3.85046 8.64619L7.496 12.2871L7.5 0.500953C7.50008 0.224811 7.724 0.00109863 8.00014 0.00109863Z" fill="#033048"/>
      </svg>
    `,
  ],
  [
    "rebalanced_out",
    `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.85355 2.64645C6.04882 2.84171 6.04882 3.15829 5.85355 3.35355L4.20711 5H11C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 10.7239 5.22386 10.5 5.5 10.5C5.77614 10.5 6 10.7239 6 11C6 13.7614 8.23858 16 11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6H4.20711L5.85355 7.64645C6.04882 7.84171 6.04882 8.15829 5.85355 8.35355C5.65829 8.54882 5.34171 8.54882 5.14645 8.35355L2.64645 5.85355C2.45118 5.65829 2.45118 5.34171 2.64645 5.14645L5.14645 2.64645C5.34171 2.45118 5.65829 2.45118 5.85355 2.64645Z" fill="#033048"/>
        </svg>

`,
  ],
  [
    "disabled",
    `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 0C1.67157 0 1 0.671573 1 1.5V14.5C1 15.3284 1.67157 16 2.5 16H4.5C5.32843 16 6 15.3284 6 14.5V1.5C6 0.671573 5.32843 0 4.5 0H2.5ZM2.5 1H4.5C4.77614 1 5 1.22386 5 1.5V14.5C5 14.7761 4.77614 15 4.5 15H2.5C2.22386 15 2 14.7761 2 14.5V1.5C2 1.22386 2.22386 1 2.5 1ZM11.5 0C10.6716 0 10 0.671573 10 1.5V14.5C10 15.3284 10.6716 16 11.5 16H13.5C14.3284 16 15 15.3284 15 14.5V1.5C15 0.671573 14.3284 0 13.5 0H11.5ZM11.5 1H13.5C13.7761 1 14 1.22386 14 1.5V14.5C14 14.7761 13.7761 15 13.5 15H11.5C11.2239 15 11 14.7761 11 14.5V1.5C11 1.22386 11.2239 1 11.5 1Z" fill="#033048"/>
      </svg>

    `,
  ],
  [
    "enabled",
    `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2204 6.68703C16.2558 7.25661 16.2558 8.74339 15.2204 9.31298L5.2234 14.812C4.22371 15.362 3 14.6393 3 13.4991L3 2.50093C3 1.36068 4.22371 0.638047 5.2234 1.18795L15.2204 6.68703ZM14.7381 8.43766C15.0833 8.2478 15.0833 7.7522 14.7381 7.56234L4.74113 2.06327C4.4079 1.87997 4 2.12084 4 2.50093L4 13.4991C4 13.8792 4.4079 14.12 4.74114 13.9367L14.7381 8.43766Z" fill="#033048"/>
      </svg>

`,
  ],
  [
    "channel_open",
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1939_30587)">
<path d="M4.19079 0.770537C4.3211 0.314446 4.73797 0 5.21231 0H10.4614C11.1865 0 11.6986 0.710426 11.4693 1.39836L11.4668 1.40584L11.4667 1.40582L10.2053 5H12.7691C13.7155 5 14.1764 6.1436 13.5356 6.81137L13.532 6.81508L13.532 6.81506L4.85551 15.6726C4.10113 16.4551 2.79636 15.7329 3.06026 14.6773L4.22998 9.99841H2.96271C2.25687 9.99841 1.74727 9.32283 1.94118 8.64415L4.19079 0.770537ZM5.21231 1C5.18445 1 5.15996 1.01847 5.15231 1.04526L2.90271 8.91887C2.89132 8.95873 2.92125 8.99841 2.96271 8.99841H4.87037C5.02434 8.99841 5.16972 9.06935 5.26447 9.19071C5.35923 9.31207 5.39279 9.47031 5.35544 9.61968L4.03041 14.9198C4.02649 14.9355 4.02679 14.9448 4.02721 14.949C4.02765 14.9534 4.02868 14.9568 4.03027 14.9601C4.03383 14.9676 4.04314 14.9798 4.06076 14.9896C4.07838 14.9993 4.09368 15.0007 4.10191 14.9997C4.10557 14.9993 4.109 14.9984 4.11295 14.9964C4.11674 14.9945 4.12478 14.9898 4.13597 14.9782L4.13952 14.9745L4.13954 14.9745L12.8151 6.11787C12.8273 6.10484 12.8305 6.09481 12.8318 6.0864C12.8336 6.07504 12.8325 6.05898 12.8253 6.04178C12.8181 6.0246 12.8079 6.01365 12.8002 6.00817C12.7949 6.00438 12.7869 6 12.7691 6H9.49996C9.33785 6 9.1858 5.9214 9.09205 5.78915C8.9983 5.65689 8.97449 5.48739 9.02817 5.33443L10.5212 1.08022C10.5331 1.04042 10.5033 1 10.4614 1H5.21231Z" fill="#033048"/>
</g>
<defs>
<clipPath id="clip0_1939_30587">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

`,
  ],
  [
    "channel_close",
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1939_30575)">
<path d="M3.27265 3.97975L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L9.8577 10.5648L4.85429 15.6726C4.09991 16.4551 2.79514 15.7329 3.05904 14.6773L4.22876 9.99841H2.96148C2.25565 9.99841 1.74605 9.32283 1.93996 8.64415L3.27265 3.97975ZM9.15056 9.85766L4.08155 4.78865L2.90148 8.91887C2.8901 8.95873 2.92003 8.99841 2.96148 8.99841H4.86915C5.02312 8.99841 5.1685 9.06935 5.26325 9.19071C5.35801 9.31207 5.39156 9.47031 5.35422 9.61968L4.02919 14.9198C4.02527 14.9355 4.02557 14.9448 4.02599 14.949C4.02643 14.9534 4.02746 14.9568 4.02904 14.9601C4.03261 14.9676 4.04192 14.9798 4.05954 14.9896C4.07716 14.9993 4.09246 15.0007 4.10069 14.9997C4.10435 14.9993 4.10778 14.9984 4.11173 14.9964C4.11552 14.9945 4.12356 14.9898 4.13475 14.9782L4.13832 14.9745L9.15056 9.85766ZM12.8139 6.11787L10.5501 8.42896L11.2572 9.13611L13.5308 6.81506L13.5344 6.81137C14.1752 6.1436 13.7143 5 12.7678 5H10.2041L11.4655 1.40582L11.468 1.39836C11.6974 0.710426 11.1853 0 10.4602 0H5.21109C4.73675 0 4.31988 0.314446 4.18956 0.770537L3.90113 1.78004L4.71004 2.58894L5.15109 1.04526C5.15874 1.01847 5.18323 1 5.21109 1H10.4602C10.5021 1 10.5319 1.04042 10.52 1.08022L9.02695 5.33443C8.97327 5.48739 8.99708 5.65689 9.09083 5.78915C9.18458 5.9214 9.33663 6 9.49874 6H12.7678C12.7857 6 12.7936 6.00438 12.799 6.00817C12.8067 6.01365 12.8168 6.0246 12.824 6.04178C12.8313 6.05898 12.8324 6.07504 12.8306 6.0864C12.8293 6.09481 12.8261 6.10484 12.8139 6.11787Z" fill="#033048"/>
</g>
<defs>
<clipPath id="clip0_1939_30575">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

`,
  ],
  [
    "channel_force_close",
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8 10.5C8.41421 10.5 8.75 10.8358 8.75 11.25C8.75 11.6642 8.41421 12 8 12C7.58579 12 7.25 11.6642 7.25 11.25C7.25 10.8358 7.58579 10.5 8 10.5ZM8 4C8.24546 4 8.44961 4.17688 8.49194 4.41012L8.5 4.5V9C8.5 9.27614 8.27614 9.5 8 9.5C7.75454 9.5 7.55039 9.32312 7.50806 9.08988L7.5 9V4.5C7.5 4.22386 7.72386 4 8 4Z" fill="#033048"/>
</svg>
`,
  ],
  [
    "fee_rate",
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7ZM7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6ZM1.5 2C0.671573 2 0 2.67157 0 3.5V10.5C0 11.3284 0.671573 12 1.5 12H12.5C13.3284 12 14 11.3284 14 10.5V3.5C14 2.67157 13.3284 2 12.5 2H1.5ZM1 3.5C1 3.22386 1.22386 3 1.5 3H3V4C3 4.55228 2.55228 5 2 5L1 5V3.5ZM1 6L2 6C3.10457 6 4 5.10457 4 4V3H10V4C10 5.10457 10.8954 6 12 6L13 6V8H12C10.8954 8 10 8.89543 10 10V11H4V10C4 8.89543 3.10457 8 2 8H1V6ZM11 3H12.5C12.7761 3 13 3.22386 13 3.5V5L12 5C11.4477 5 11 4.55228 11 4V3ZM13 9V10.5C13 10.7761 12.7761 11 12.5 11H11V10C11 9.44772 11.4477 9 12 9H13ZM3 11H1.5C1.22386 11 1 10.7761 1 10.5V9H2C2.55228 9 3 9.44772 3 10V11ZM15.0001 10.5C15.0001 11.8807 13.8808 13 12.5001 13H2.08545C2.29137 13.5826 2.84699 14 3.5001 14H12.5001C14.4331 14 16.0001 12.433 16.0001 10.5V5.49997C16.0001 4.84686 15.5827 4.29125 15.0001 4.08533V10.5Z" fill="#033048"/>
      </svg>
      `,
  ],
  [
    "base_fee",
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7ZM7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6ZM1.5 2C0.671573 2 0 2.67157 0 3.5V10.5C0 11.3284 0.671573 12 1.5 12H12.5C13.3284 12 14 11.3284 14 10.5V3.5C14 2.67157 13.3284 2 12.5 2H1.5ZM1 3.5C1 3.22386 1.22386 3 1.5 3H3V4C3 4.55228 2.55228 5 2 5L1 5V3.5ZM1 6L2 6C3.10457 6 4 5.10457 4 4V3H10V4C10 5.10457 10.8954 6 12 6L13 6V8H12C10.8954 8 10 8.89543 10 10V11H4V10C4 8.89543 3.10457 8 2 8H1V6ZM11 3H12.5C12.7761 3 13 3.22386 13 3.5V5L12 5C11.4477 5 11 4.55228 11 4V3ZM13 9V10.5C13 10.7761 12.7761 11 12.5 11H11V10C11 9.44772 11.4477 9 12 9H13ZM3 11H1.5C1.22386 11 1 10.7761 1 10.5V9H2C2.55228 9 3 9.44772 3 10V11ZM15.0001 10.5C15.0001 11.8807 13.8808 13 12.5001 13H2.08545C2.29137 13.5826 2.84699 14 3.5001 14H12.5001C14.4331 14 16.0001 12.433 16.0001 10.5V5.49997C16.0001 4.84686 15.5827 4.29125 15.0001 4.08533V10.5Z" fill="#033048"/>
      </svg>
      `,
  ],
]);

export default eventIcons;
