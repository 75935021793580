import api from "api/api";
import { BankAccountRecord, GetBankAccountsByCompanyIdResp } from "api/api.generated";
import * as routes from "constants/routes";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { ColumnMetaData } from "features/table/types";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import TablePageTemplate, {
  TableControlsButtonGroup,
  TableControlSection,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import { Add20Regular as AddIcon } from "@fluentui/react-icons";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { useLocation, useNavigate } from "react-router";
import PageTitle from "components/pageTitle/PageTitle";

const columns: ColumnMetaData<BankAccountRecord>[] = [
  {
    heading: "iban",
    type: "TextCell",
    key: "iban",
    valueType: "string",
    headingLocalizePackage: "bank",
  },
  {
    heading: "bic",
    type: "TextCell",
    key: "bic",
    valueType: "string",
    headingLocalizePackage: "bank",
  },
  {
    heading: "bankCountry",
    type: "TextCell",
    key: "countryIso3",
    valueType: "string",
    headingLocalizePackage: "bank",
  },
];

function BankAccountsPage() {
  const { t } = useTranslation("bank");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const navigate = useNavigate();
  const location = useLocation();
  const hasEditPermission = useHasPermission(["companyBankAccountsWriteRead"]);

  let isLoading: boolean = false;
  let isFetching: boolean = false;
  let isUninitialized: boolean = true;

  const bankAccountsResponse = api.useGetBankAccountsByCompanyIdQuery<{
    data: GetBankAccountsByCompanyIdResp;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(selectedCompanyId || "", { skip: !selectedCompanyId });

  isLoading = bankAccountsResponse.isLoading;
  isFetching = bankAccountsResponse.isFetching;
  isUninitialized = bankAccountsResponse.isUninitialized;

  const data = bankAccountsResponse.data || [];

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <TableControlsButtonGroup intercomTarget={"table-controls-left"}></TableControlsButtonGroup>
    </TableControlSection>
  );

  const pageTitle = (
    <PageTitle title={t("BankAccounts")}>
      <Button
        buttonColor={ColorVariant.overlay}
        hideMobileText={true}
        buttonPosition={ButtonPosition.right}
        icon={<AddIcon />}
        onClick={() => {
          navigate(routes.CREATE_COMPANY_BANK_ACCOUNT, { state: { background: location } });
        }}
        intercomTarget={"add-company-bank-account-button"}
        disabled={!hasEditPermission}
      >
        {t("AddBankAccount")}
      </Button>
    </PageTitle>
  );

  return (
    <TablePageTemplate titleComponent={pageTitle} titleContent={""} tableControls={tableControls}>
      <Table
        intercomTarget={"bank-accounts-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={columns}
        isLoading={isLoading || isFetching || isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default BankAccountsPage;
