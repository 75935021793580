import { Outlet } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectHidden, selectSelectedCompanyId } from "features/navigation/navSlice";
import styles from "layout/default-layout.module.scss";
import navStyles from "features/navigation/nav.module.scss";
import Navigation from "features/navigation/Navigation";
import classNames from "classnames";
import api from "api/api";
import { hasToken } from "api/manualApi";
import TopNavigation from "features/navigation/TopNavigation";
import { LivechatMerchantUI } from "components/support/livechat";
import { useLivechatMerchantUser } from "components/support/livechatUserDataHooks";

// import mixpanel from "mixpanel-browser";
// import { useGetServicesQuery, useGetSettingsQuery } from "apiSlice";
// import { userEvents } from "utils/userEvents";
// import { useIntercom } from "react-use-intercom";

function DefaultLayout() {
  // const { boot } = useIntercom();
  const hidden = useAppSelector(selectHidden);

  // redirect to login if not logged in
  if (!hasToken()) {
    window.location.href = "/login";
  }

  // redirect to login if not logged in
  // -poll every minute to check if logged in
  const { data: user } = api.useGetSelfUserQuery(undefined, {
    pollingInterval: 60000,
    refetchOnReconnect: true,
  });

  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const { data: company } = api.useGetCompanyByIdQuery(selectedCompanyId || "", {
    skip: !selectedCompanyId || !user,
  });

  useLivechatMerchantUser(user, company?.companyName);

  // const { data: servicesData, isSuccess: servicesDataSuccess } = useGetServicesQuery();
  // const [hasIdentity, setHasIdentity] = useState<boolean>(false);
  // const { data: settingsData, isSuccess: settingsDataSuccess } = useGetSettingsQuery(undefined, {
  //   pollingInterval: hasIdentity ? undefined : 1000,
  // });

  // useEffect(() => {
  //   if (process.env.NODE_ENV === "production" && process.env.REACT_APP_E2E_TEST !== "true") {
  //     /*mixpanel.init("", {
  //       ip: false,
  //       loaded: (mixpanel) => {
  //         // eslint-disable-next-line unused-imports/no-unused-vars
  //         const distinct_id = mixpanel.get_distinct_id();
  //       },
  //     });*/
  //   } else {
  //     /*mixpanel.init("", {
  //       ip: false,
  //       loaded: (mixpanel) => {
  //         // eslint-disable-next-line unused-imports/no-unused-vars
  //         const distinct_id = mixpanel.get_distinct_id();
  //       },
  //     });*/
  //   }
  // }, []);

  // const { register } = userEvents();

  // useEffect(() => {
  //   if (!settingsData?.merchantServicesUuid || !settingsDataSuccess) {
  //     return;
  //   }
  //   setHasIdentity(true);
  //   boot({
  //     userId: settingsData?.merchantServicesUuid,
  //     customLauncherSelector: "#intercom-launcher",
  //     hideDefaultLauncher: true,
  //   });
  //   console.debug("Intercom has booted");
  //
  //   mixpanel.identify(settingsData.merchantServicesUuid);
  //   mixpanel.people.set_once({
  //     $created: new Date().toISOString(),
  //   });
  //   register({
  //     nodeEnv: process.env.NODE_ENV,
  //     defaultDateRange: settingsData.defaultDateRange,
  //     defaultLanguage: settingsData.defaultLanguage,
  //     weekStartsOn: settingsData.weekStartsOn,
  //   });
  //   console.debug("Mixpanel has booted");
  // }, [settingsData, settingsData?.merchantServicesUuid]);
  //
  // // check if all services are running
  // useEffect(() => {
  //   if (!settingsData?.merchantServicesUuid) return;
  //
  //   // Register version
  //   if (servicesData?.version) {
  //     register({
  //       merchantServicesVersion: servicesData?.version,
  //     });
  //   }
  //
  //   // Create an object of each lnd service typeString and coreService and register once
  //   if (servicesData?.coreServices?.length) {
  //     const coreServices = servicesData?.coreServices?.reduce(
  //       (acc, service) => {
  //         acc[`coreService${service.typeString}`] = service.statusString;
  //         return acc;
  //       },
  //       {} as { [key: string]: string },
  //     );
  //     register(coreServices);
  //   }
  //
  //   // Register main service status
  //   if (servicesData?.rootService) {
  //     register({
  //       rootService: servicesData?.rootService?.statusString,
  //     });
  //   }
  // }, [servicesData?.coreServices, servicesDataSuccess, settingsData?.merchantServicesUuid]);

  return (
    <>
      <LivechatMerchantUI />
      <div className={classNames(styles.mainContentWrapper, { [navStyles.navCollapsed]: hidden })}>
        <TopNavigation />
        <div className={navStyles.navigationWrapper}>
          <Navigation currentCompany={company} />
        </div>
        <div className={styles.pageWrapper}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default DefaultLayout;
