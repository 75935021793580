import Page from "layout/Page";
import styles from "pages/dashboard/DashboardPage.module.scss";
import PageTitle from "components/pageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import Card, { CardHeader } from "components/cards/Card";
import SimpleBarChart from "pages/dashboard/transactionsChart/SimpleBarChart";
import api, { DashboardStatsResp, ErrorResponse, TransactionStatHistory } from "api/api";
import { useEffect, useState } from "react";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";
import { subSeconds } from "date-fns";

function DashboardPage() {
  const { t } = useTranslation();
  const { t: tc } = useTranslation("currency");

  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();

  const selectedCompanyId: string = useAppSelector(selectSelectedCompanyId) || "";
  // From 28 days ago

  const txResponse = api.useDashboardStatsQuery<{
    data: DashboardStatsResp;
    error: ErrorResponse;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(
    {
      companyId: selectedCompanyId,
      from: (from || new Date()).toISOString(),
      to: (to || new Date()).toISOString(),
      unit: "days",
    },
    { skip: [!from, !to, !selectedCompanyId].some((v) => v) },
  );

  useEffect(() => {
    setFrom(subSeconds(new Date(), 28 * 24 * 60 * 60));
    setTo(new Date());
  }, []);

  // function to convert the historical reveue to EUR instead of cents (value/100)
  function convertRevenueToEur(history?: TransactionStatHistory[]) {
    if (!history) return history;
    return history.map((h) => {
      return {
        ...h,
        revenue: (h.revenue || 0) / 100,
      };
    });
  }

  return (
    <Page head={<PageTitle title={t("Dashboard")} />}>
      <div className={styles.topTotalWrapper}>
        <Card>
          <CardHeader header={t("Total Revenue")} />
          <div>{tc("CurrencyFormatter", { value: (txResponse?.data?.totalRevenue || 0) / 100, currency: "EUR" })}</div>
        </Card>
        <Card>
          <CardHeader header={t("Total Transactions")} />
          <div>{tc("intlNumber", { val: txResponse?.data?.totalTransactions })}</div>
        </Card>
      </div>
      <div>
        <Card>
          <CardHeader header={t("Revenue")} />
          <div style={{ width: "100%", height: "400px" }} className={styles.chartContainer}>
            <SimpleBarChart
              data={convertRevenueToEur(txResponse?.data?.history) || [{}]}
              label={t("Revenue")}
              dataKey="revenue"
              from={(from || new Date()).toString()}
              to={(to || new Date()).toString()}
            />
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <CardHeader header={t("Transactions")} />
          <div style={{ width: "100%", height: "400px" }} className={styles.chartContainer}>
            <SimpleBarChart
              data={txResponse?.data?.history || [{}]}
              label={t("Transactions")}
              dataKey="transactions"
              from={(from || new Date()).toString()}
              to={(to || new Date()).toString()}
            />
          </div>
        </Card>
      </div>
    </Page>
  );
}

export default DashboardPage;
