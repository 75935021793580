import { useEffect } from "react";

const currentPaymentSessionIdKey = "currentPaymentSessionId";

export function getCurrentPaymentSessionId(): string | null {
  return sessionStorage.getItem(currentPaymentSessionIdKey);
}

const useCurrentPaymentSession = (paymentSessionId: string | undefined) => {
  useEffect(() => {
    if (paymentSessionId) {
      sessionStorage.setItem(currentPaymentSessionIdKey, paymentSessionId);
    } else {
      sessionStorage.removeItem(currentPaymentSessionIdKey);
    }

    return () => {
      sessionStorage.removeItem(currentPaymentSessionIdKey);
    };
  }, [paymentSessionId]);
};

export default useCurrentPaymentSession;
