import { Outlet } from "react-router-dom";
import styles from "layout/page.module.scss";

function ShopLayout() {
  return (
    <div className={styles.page}>
      <Outlet />
    </div>
  );
}

export default ShopLayout;
