import { Outlet } from "react-router-dom";
import { ReactComponent as MerchantServicesLogo } from "icons/mion-logo-ms.svg";
import styles from "layout/LoginLayout.module.scss";

function LoginLayout() {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContent}>
        <div className={styles.logo}>
          <MerchantServicesLogo />
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default LoginLayout;
