import styles from "pages/checkout/checkoutPage.module.scss";
import api, {
  CheckCustomerResponse,
  CheckoutAttemptResponse,
  CustomerRecord,
  GetSessionResponse,
  PaymentMethod,
  PaymentSessionStatus,
} from "api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWebSocket from "react-use-websocket";
import { WsPaymentStatusUpdate } from "api/websocketApiTypes";
import { getPaymentSessionStatusUrl } from "api/websocketApi";
import PaymentMethodSelector from "pages/checkout/paymentMethods/PaymentMethodSelector";
import { ReactComponent as MerchantServicesLogo } from "pages/checkout/mion-logo-ms.svg";
import Note, { NoteType } from "features/note/Note";
import CheckoutSuccess from "pages/checkout/checkoutSuccess/CheckoutSuccess";
import CheckOutEmailLoginFields from "pages/checkout/parts/CheckoutEmailLoginFields";
import AccountOptions from "pages/checkout/parts/AccountOptions";
import GuestFormPart from "pages/checkout/parts/GuestForm";
import ExistingCustomer from "pages/checkout/existingCustomer/ExistingCustomer";
import { QueryStatus } from "@reduxjs/toolkit/query";
import PaymentMethodRenderer from "pages/checkout/paymentMethods/paymentMethodRenderer";
import useCurrentPaymentSession from "utils/useCurrentPaymentSession";
import * as routes from "constants/routes";
import { useLivechatCustomer } from "components/support/livechatUserDataHooks";
import { ContactSupportText } from "components/support/contactSupportText";

const refetchSessionInterval = 5000;

type CheckoutContextType = {
  sessionId: string | undefined;
  sessionResp: GetSessionResponse | undefined | null;
  attemptResp: CheckoutAttemptResponse | undefined | null;
  setAttemptResp: (attempt: CheckoutAttemptResponse | undefined) => void;
  email: string | undefined;
  setEmail: (value?: string) => void;
  isGuest: boolean | undefined;
  setIsGuest: (value?: boolean) => void;
  loggedInCustomer: CustomerRecord | undefined | null;
  customerResp: CheckCustomerResponse | undefined | null;
  setCustomerResp: (customer: CheckCustomerResponse) => void | null;
  editCustomer: boolean | undefined;
  setEditCustomer: (editCustomer: boolean | undefined) => void;
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: (value?: PaymentMethod) => void;
  paymentSessionStatus: WsPaymentStatusUpdate;
  setPaymentSessionStatus: (statusUpdate: WsPaymentStatusUpdate) => void;
  pageError?: Array<string>;
  setPageError: (value?: Array<string>) => void;
};

// CheckoutContext
export const CheckoutContext = createContext<CheckoutContextType>({
  sessionId: undefined,
  sessionResp: null,
  attemptResp: null,
  setAttemptResp: () => {},
  email: undefined,
  setEmail: () => {},
  isGuest: undefined,
  setIsGuest: () => {},
  loggedInCustomer: undefined,
  customerResp: null,
  setCustomerResp: () => null,
  editCustomer: false,
  setEditCustomer: () => null,
  paymentMethod: undefined,
  setPaymentMethod: () => {},
  paymentSessionStatus: {
    paymentSessionId: "",
    status: PaymentSessionStatus.Draft,
    expiresAt: "",
  },
  setPaymentSessionStatus: () => {},
  pageError: [],
  setPageError: () => {},
});

function CheckoutPage() {
  const { t } = useTranslation("checkout");

  const { session: sessionId } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string | undefined>();
  const [isGuest, setIsGuest] = useState<boolean | undefined>(undefined);
  const [customerResp, setCustomerResp] = useState<CheckCustomerResponse | undefined | null>();
  const [loggedInCustomer, setLoggedInCustomer] = useState<CustomerRecord | undefined | null>();
  const [editCustomer, setEditCustomer] = useState<boolean | undefined>(undefined);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentSessionStatus, setPaymentSessionStatus] = useState<WsPaymentStatusUpdate>({
    paymentSessionId: sessionId || "",
    status: PaymentSessionStatus.Draft,
    expiresAt: "",
  });
  const [pageError, setPageError] = useState<Array<string> | undefined>([]);
  const [attemptResp, setAttemptResp] = useState<CheckoutAttemptResponse | undefined | null>(undefined);

  // set to session storage to use in call headers
  useCurrentPaymentSession(sessionId);

  const location = useLocation();
  const { paymentMethod: locationPaymentMethod } = location.state || {};

  const sessionResp = api.useGetCheckoutSessionQuery(sessionId || "");

  const loggedInCustomerResp = api.useGetCustomerQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useLivechatCustomer(email, loggedInCustomerResp.data, sessionId);

  const {
    data: paymentStatusMutationData,
    error: paymentStatusFetchError,
    refetch: refetchPaymentStatus,
  } = api.useGetCheckoutStatusQuery(sessionId || "");

  useWebSocket(getPaymentSessionStatusUrl(sessionId || ""), {
    shouldReconnect: () => true,
    share: false,
    onMessage: handleWSPaymentStatusUpdate,
  });

  function handleWSPaymentStatusUpdate(message: MessageEvent<string>) {
    const statusUpdate = JSON.parse(message.data) as WsPaymentStatusUpdate;
    setPaymentSessionStatus(statusUpdate);
  }

  useEffect(() => {
    if (locationPaymentMethod as PaymentMethod) {
      setPaymentMethod(locationPaymentMethod);
    }
  }, [locationPaymentMethod]);

  useEffect(() => {
    // account edit opens in consumer compliance page
    if (editCustomer && loggedInCustomer && isGuest === false) {
      navigate(`${routes.CONSUMER_ACCOUNT}?ps=${sessionId}`, {
        state: {
          customerEmail: loggedInCustomer?.email,
        },
      });

      // reset after redirect
      setEditCustomer(false);
    }
  }, [editCustomer]);

  // check if customer is logged in
  useEffect(() => {
    if (
      loggedInCustomerResp?.isSuccess === true &&
      !loggedInCustomerResp?.isFetching &&
      loggedInCustomerResp.status === QueryStatus.fulfilled
    ) {
      setLoggedInCustomer(loggedInCustomerResp?.data);
      setEmail(loggedInCustomerResp?.data?.email);
      setIsGuest(loggedInCustomerResp?.data?.isGuest);
      if (editCustomer === undefined) {
        setEditCustomer(false);
      }
    } else if (
      loggedInCustomerResp.isError ||
      loggedInCustomerResp.isUninitialized ||
      loggedInCustomerResp.status === QueryStatus.rejected
    ) {
      setLoggedInCustomer(undefined);
      setEditCustomer(undefined);
    }
  }, [loggedInCustomerResp]);

  // try refetching session if it does not exist
  useEffect(() => {
    const refetchTimer = setInterval(() => {
      if (!sessionResp?.data) {
        sessionResp?.refetch();
      }
    }, refetchSessionInterval);

    // Clear interval on unmount
    return () => clearInterval(refetchTimer);
  }, []);

  // refetch payment status periodically
  useEffect(() => {
    const refetchTimer = setInterval(() => {
      refetchPaymentStatus();
    }, refetchSessionInterval);

    // Clear interval on unmount
    return () => clearInterval(refetchTimer);
  }, []);

  // handle session fetch error
  useEffect(() => {
    if (sessionResp?.error) {
      // TODO: Properly format errors
      console.error("Error fetching payment session", sessionResp?.error);
      setPageError([t("Error fetching payment session.")]);
      return;
    }

    if (paymentStatusFetchError) {
      // TODO: Properly format errors
      console.error("Error fetching payment status", paymentStatusFetchError);
      setPageError([t("Error fetching payment status.")]);
      return;
    }

    setPageError([]);
  }, [sessionResp?.error, paymentStatusFetchError]);

  // handle payment status change from mutation
  useEffect(() => {
    if (!paymentStatusMutationData) {
      return;
    }

    // have same fields so can cast
    setPaymentSessionStatus(paymentStatusMutationData as WsPaymentStatusUpdate);
  }, [paymentStatusMutationData]);

  return (
    <CheckoutContext.Provider
      value={{
        sessionId,
        sessionResp,
        attemptResp,
        setAttemptResp,
        email,
        setEmail,
        isGuest,
        setIsGuest,
        loggedInCustomer,
        customerResp,
        setCustomerResp,
        editCustomer,
        setEditCustomer,
        paymentMethod,
        setPaymentMethod,
        paymentSessionStatus,
        setPaymentSessionStatus,
        pageError,
        setPageError,
      }}
    >
      <CheckoutContent />
    </CheckoutContext.Provider>
  );
}

export default CheckoutPage;

function CheckoutContent() {
  const { t } = useTranslation("checkout");
  const { t: tc } = useTranslation("currency");

  const {
    email,
    isGuest,
    loggedInCustomer,
    editCustomer,
    customerResp,
    paymentMethod,
    setPaymentMethod,
    paymentSessionStatus,
    sessionResp,
    pageError,
  } = useContext(CheckoutContext);

  const paymentDetailsRef = useRef<HTMLDivElement>(null);

  function isPaid(pss: PaymentSessionStatus): boolean {
    return (
      pss === PaymentSessionStatus.Funded ||
      pss === PaymentSessionStatus.Exchanged ||
      pss === PaymentSessionStatus.FundingCollected ||
      pss === PaymentSessionStatus.FundingReceived
    );
  }

  // checks if payment session is in state that allows user to initiate or make a payment
  function isActive(pss: PaymentSessionStatus): boolean {
    if ((pss as string) == "" || isPaid(pss)) {
      return false;
    }

    if (pss === PaymentSessionStatus.Cancelled || pss === PaymentSessionStatus.Expired) {
      return false;
    }

    return true;
  }

  // scroll to bottom when payment method is selected
  useEffect(() => {
    if (paymentMethod) {
      setTimeout(() => {
        const scrollMargin = 20; // Scroll to a bit above the element

        const topPos = paymentDetailsRef?.current?.getBoundingClientRect().top || 0 + window.scrollY;

        window.scrollTo({ top: topPos - scrollMargin, behavior: "smooth" });
      }, 100);
    }
  }, [paymentMethod]); // delay to allow the invoice to load

  return (
    <div className={styles.checkoutPageWrapper}>
      <div className={styles.background} />
      <div className={styles.leftWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.logoWrapper}>
            <MerchantServicesLogo />
          </div>
          <div className={styles.amountWrapper}>
            <div className={styles.amountLabel}>{sessionResp?.data?.amount && t("Total")}</div>
            {sessionResp?.data?.currencyIso3 === "BTC" && sessionResp?.data?.amount && (
              <div className={styles.btcAmount}>
                {tc("SatSpaceFormatterWithSymbol", {
                  value: sessionResp?.data?.amount * 100_000_000,
                  currency: sessionResp?.data?.currencyIso3,
                })}
              </div>
            )}
            {sessionResp?.data?.currencyIso3 !== "BTC" && (
              <div className={styles.fiatAmount}>
                {tc("CurrencyFormatter", {
                  value: sessionResp?.data?.amount,
                  currency: sessionResp?.data?.currencyIso3,
                })}
              </div>
            )}
          </div>
          {!!pageError?.length && (
            <Note noteType={NoteType.error} title={t("CheckoutError")}>
              {pageError}
            </Note>
          )}

          {isActive(paymentSessionStatus.status) && (
            <PaymentMethodSelector
              onSelect={setPaymentMethod}
              methodSelected={paymentMethod}
              enabledPaymentMethods={(sessionResp?.data?.paymentMethods as PaymentMethod[]) || []}
            />
          )}

          {/* Initial email input. PW/pin input also here if customer has an account */}
          {isActive(paymentSessionStatus.status) && paymentMethod && !loggedInCustomer && (
            <CheckOutEmailLoginFields paymentSessionId={sessionResp?.data?.paymentSessionId || ""} />
          )}

          {/* Logged in, edit off */}
          {loggedInCustomer && !editCustomer && <ExistingCustomer />}

          {/* Logged in, edit on, guest edit here. Account edit opens in consumer compliance page. */}
          {loggedInCustomer && editCustomer && isGuest === true && (
            <GuestFormPart email={email || ""} className={styles.guestFormWrapper} />
          )}

          {/* Customer has given email and customer doesn't have an account */}
          {customerResp?.isSuccess && !loggedInCustomer && isActive(paymentSessionStatus.status) && (
            <>
              {isGuest === undefined && <AccountOptions />}

              {/* Guest selected. Create new guest */}
              {isGuest === true && <GuestFormPart email={email || ""} className={styles.guestFormWrapper} />}
            </>
          )}

          <div ref={paymentDetailsRef} className={styles.paymentDetailsWrapper}>
            {isActive(paymentSessionStatus.status) && !editCustomer && sessionResp?.data && loggedInCustomer && (
              <PaymentMethodRenderer method={paymentMethod} />
            )}

            {isPaid(paymentSessionStatus.status) && <CheckoutSuccess />}

            {paymentSessionStatus.status === PaymentSessionStatus.Cancelled && (
              <Note noteType={NoteType.error} title={t("PaymentSessionCancelled")}>
                {t("PaymentSessionCancelledMessage")}
              </Note>
            )}
            {paymentSessionStatus.status === PaymentSessionStatus.Expired && (
              <Note noteType={NoteType.error} title={t("PaymentSessionExpired")}>
                {t("PaymentSessionExpiredMessage")}
              </Note>
            )}
          </div>
        </div>
        <ContactSupportText />
      </div>
      <div className={styles.rightWrapper} />
    </div>
  );
}
