import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import React from "react";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import TablePageTemplate, {
  TableControlSection,
  TableControlsButtonGroup,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import { Checkmark20Regular as CheckIcon, Dismiss20Regular as DismissIcon } from "@fluentui/react-icons";
import api, {
  BankTransferListRecordInternal,
  EdenAdminGetBankTransferWithdrawalsResp,
  GetCompanyBankTransferWithdrawalsResp,
} from "api/api";
import NoMatch from "pages/noMatch/NoMatch";
import { ColumnMetaData } from "features/table/types";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { getErrorResponse } from "components/errors/validationErrors";
import { ColorVariant } from "components/buttons/buttonVariants";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import { formatAmountFunc, formatTranslateFunc } from "features/table/columnFormatFunctions";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";

export const enum BankTransfersPageType {
  AdminWithdrawals,
  CompanyWithdrawals,
}

const columns: ColumnMetaData<BankTransferListRecordInternal>[] = [
  {
    heading: "",
    type: "LinkCell",
    key: "bankTransferId",
    valueType: "multilink",
    multilinkData: [],
  },
  {
    heading: "Amount",
    type: "TextCell",
    key: "amountFloat",
    valueType: "string",
    formatFunction: formatAmountFunc,
    headingLocalizePackage: "bank",
  },
  {
    heading: "State",
    type: "TextCell",
    key: "state",
    valueType: "string",
    formatFunction: formatTranslateFunc,
    formatLocalizePackage: "bank",
    headingLocalizePackage: "bank",
  },
  {
    heading: "Receiver",
    type: "TextCell",
    key: "receiverName",
    valueType: "string",
    headingLocalizePackage: "bank",
  },
  {
    heading: "Sender",
    type: "TextCell",
    key: "senderName",
    valueType: "string",
    headingLocalizePackage: "bank",
  },
  {
    heading: "InitiatedAt",
    type: "DateCell",
    key: "initiatedAt",
    valueType: "datetime",
    headingLocalizePackage: "bank",
  },
  {
    heading: "CompletedAt",
    type: "DateCell",
    key: "completedAt",
    valueType: "datetime",
    headingLocalizePackage: "bank",
  },
];

function addAdminColumns(
  cols: ColumnMetaData<BankTransferListRecordInternal>[],
): ColumnMetaData<BankTransferListRecordInternal>[] {
  const c = [
    ...cols,
    {
      heading: "ApprovedAt",
      type: "DateCell",
      key: "approvedAt",
      valueType: "datetime",
      headingLocalizePackage: "bank",
    },
    {
      heading: "ApprovedByUserId",
      type: "TextCell",
      key: "approvedByUserId",
      valueType: "string",
      headingLocalizePackage: "bank",
    },
    {
      heading: "FailedAt",
      type: "DateCell",
      key: "failedAt",
      valueType: "datetime",
      headingLocalizePackage: "bank",
    },
    {
      heading: "CancelledAt",
      type: "DateCell",
      key: "cancelledAt",
      valueType: "datetime",
      headingLocalizePackage: "bank",
    },
    {
      heading: "CancelledByUserId",
      type: "TextCell",
      key: "cancelledByUserId",
      valueType: "string",
      headingLocalizePackage: "bank",
    },
    {
      heading: "CreatedAt",
      type: "DateCell",
      key: "createdAt",
      valueType: "datetime",
      headingLocalizePackage: "common",
    },
    {
      heading: "CreatedByUserId",
      type: "TextCell",
      key: "createdByUserId",
      valueType: "string",
      headingLocalizePackage: "common",
    },
  ] as ColumnMetaData<BankTransferListRecordInternal>[];

  return c.map((col) => {
    if (col.key === "bankTransferId" && !col?.multilinkData?.find((link) => link.text === "Approve")) {
      col.multilinkData = [
        ...(col.multilinkData || []),
        {
          basePath: "",
          text: "Approve",
          icon: <CheckIcon />,
          internal: true,
          disabled: false,
        },
        {
          basePath: "",
          text: "Cancel",
          icon: <DismissIcon />,
          color: ColorVariant.error,
          internal: true,
          disabled: false,
        },
      ];
    }
    return col as ColumnMetaData<BankTransferListRecordInternal>;
  });
}

function BankTransfersPage(props: { pageType: BankTransfersPageType }) {
  const { t } = useTranslation("bank");
  const toastRef = React.useContext(ToastContext);
  const confirm = useConfirmation();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  let pageTitle = "";

  let isLoading: boolean = false;
  let isFetching: boolean = false;
  let isUninitialized: boolean = true;
  let data: BankTransferListRecordInternal[] = [];

  const adminWithdrawalsResponse = api.useEdenAdminGetBankTransferWithdrawalsQuery<{
    data: EdenAdminGetBankTransferWithdrawalsResp;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(undefined, { skip: props.pageType != BankTransfersPageType.AdminWithdrawals, pollingInterval: 60000 });

  const companyWithdrawalsResponse = api.useGetCompanyBankTransferWithdrawalsQuery<{
    data: GetCompanyBankTransferWithdrawalsResp;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(selectedCompanyId || "", {
    skip: props.pageType != BankTransfersPageType.CompanyWithdrawals,
    pollingInterval: 60000,
  });

  const [approveMutation, { error: approveError, isSuccess: approveSuccess }] =
    api.useEdenAdminApproveBankTransferMutation();

  const approveTransfer = async (value: string) => {
    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: t("confirmApprove"),
    });
    if (result) {
      approveMutation(value);
    }
  };

  const approveDisabled = (row: object | undefined) => {
    if (row && "state" in row) {
      return row.state !== "draft";
    }

    return true;
  };

  const [cancelMutation, { error: cancelError, isSuccess: cancelSuccess }] =
    api.useEdenAdminCancelBankTransferMutation();

  const cancelTransfer = async (value: string) => {
    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: t("confirmCancel"),
    });
    if (result) {
      cancelMutation(value);
    }
  };

  const cancelDisabled = (row: object | undefined) => {
    if (row && "state" in row) {
      return row.state !== "draft" && row.state !== "approved";
    }

    return true;
  };

  useEffect(() => {
    if (approveSuccess) {
      toastRef?.current?.addToast(t("Approved successfully"), toastCategory.success);
    }
  }, [approveSuccess]);

  useEffect(() => {
    if (approveError) {
      const err = getErrorResponse(approveError);
      toastRef?.current?.addToast(err?.message || t("Failed to approve"), toastCategory.error);
    }
  }, [approveError]);

  useEffect(() => {
    if (cancelSuccess) {
      toastRef?.current?.addToast(t("Cancelled successfully"), toastCategory.success);
    }
  }, [cancelSuccess]);

  useEffect(() => {
    if (cancelError) {
      const err = getErrorResponse(cancelError);
      toastRef?.current?.addToast(err?.message || t("Failed to cancel"), toastCategory.error);
    }
  }, [cancelError]);

  switch (props.pageType) {
    case BankTransfersPageType.AdminWithdrawals:
      pageTitle = t("AdminWithdrawals");

      isLoading = adminWithdrawalsResponse.isLoading;
      isFetching = adminWithdrawalsResponse.isFetching;
      isUninitialized = adminWithdrawalsResponse.isUninitialized;

      data = adminWithdrawalsResponse.data || [];

      break;
    case BankTransfersPageType.CompanyWithdrawals:
      pageTitle = t("Withdrawals");

      isLoading = companyWithdrawalsResponse.isLoading;
      isFetching = companyWithdrawalsResponse.isFetching;
      isUninitialized = companyWithdrawalsResponse.isUninitialized;

      data = companyWithdrawalsResponse.data || [];
      break;
    default:
      return <NoMatch />;
  }

  // clear multilink
  let cols = columns.map((col) => {
    if (col.valueType === "multilink") {
      return { ...col, multilinkData: [] };
    }
    return col;
  });

  if (props.pageType === BankTransfersPageType.AdminWithdrawals) {
    cols = addAdminColumns(cols);
  }

  // set multilink values
  cols = cols.map((col) => {
    if (col.valueType === "multilink") {
      return {
        ...col,
        multilinkData: col.multilinkData?.map((link) => {
          switch (link.text) {
            case "Approve":
              return { ...link, action: approveTransfer, disabledFunc: approveDisabled };
            case "Cancel":
              return { ...link, action: cancelTransfer, disabledFunc: cancelDisabled };
          }

          return link;
        }),
      };
    }
    return col;
  });

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <TableControlsButtonGroup intercomTarget={"table-controls-left"}></TableControlsButtonGroup>
    </TableControlSection>
  );

  return (
    <TablePageTemplate title={pageTitle} titleContent={""} tableControls={tableControls}>
      <Table
        intercomTarget={"bank-transfers-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={isLoading || isFetching || isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default BankTransfersPage;
