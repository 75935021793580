import styles from "pages/company/companyTree/CompanyTree.module.scss";
import { CompanyRecord } from "api/api.generated";
import classNames from "classnames";
import { useAppDispatch } from "store/hooks";
import { setSelectedCompanyId } from "features/navigation/navSlice";
import api from "api/api";
import Button, { ButtonPosition } from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import * as routes from "constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import ComponentPermissionChecker from "features/authorization/ComponentPermissionChecker";

export function CompanyTree({
  companyTree,
  level,
  currentCompanyId,
}: {
  companyTree?: CompanyRecord;
  level?: number;
  currentCompanyId?: string;
}) {
  return (
    <div>
      {(level || 0) < 1 && <CompanyRootItem company={companyTree} currentCompanyId={currentCompanyId} />}
      <div>
        {companyTree?.childCompanies?.map((c: CompanyRecord) => {
          return (
            <CompanyItem company={c} key={c.companyId} level={(level || 0) + 1} currentCompanyId={currentCompanyId} />
          );
        })}
      </div>
    </div>
  );
}

export function CompanyItem({
  company,
  level,
  currentCompanyId,
}: {
  company?: CompanyRecord;
  level?: number;
  currentCompanyId?: string;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("company");
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className={classNames(styles.companyTreeItem, {
        [styles.selectedCompany]: currentCompanyId === company?.companyId,
      })}
    >
      <div
        className={classNames(styles.companyTreeContainer)}
        onClick={() => {
          dispatch(setSelectedCompanyId(company?.companyId));
          dispatch(api.util.invalidateTags(["company", "addresses", "phoneNumbers", "companyKeys", "companyTree"]));
        }}
      >
        {company?.displayName || company?.companyName}
        <ComponentPermissionChecker roles={["edenAdmin", "companyAdmin"]}>
          <Button
            intercomTarget={"create-branch-button"}
            buttonPosition={ButtonPosition.right}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(routes.CREATE_COMPANY + "?parent=" + company?.companyId, { state: { background: location } });
            }}
          >
            {t("Create Branch")}
          </Button>
        </ComponentPermissionChecker>
      </div>
      {company?.childCompanies && (
        <CompanyTree companyTree={company} level={level || 0} currentCompanyId={currentCompanyId} />
      )}
    </div>
  );
}

export function CompanyRootItem({ company, currentCompanyId }: { company?: CompanyRecord; currentCompanyId?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("company");
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className={classNames(styles.companyTreeItem, styles.rootCompany, {
        [styles.selectedCompany]: currentCompanyId === company?.companyId,
      })}
    >
      <div
        className={classNames(styles.companyTreeContainer)}
        onClick={() => {
          dispatch(setSelectedCompanyId(company?.companyId));
          dispatch(api.util.invalidateTags(["company", "addresses", "phoneNumbers", "companyKeys", "companyTree"]));
        }}
      >
        {company?.displayName || company?.companyName}
        <ComponentPermissionChecker roles={["edenAdmin", "companyAdmin"]}>
          <Button
            intercomTarget={"create-branch-button"}
            buttonPosition={ButtonPosition.right}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(routes.CREATE_COMPANY + "?parent=" + company?.companyId, { state: { background: location } });
            }}
          >
            {t("Create Branch")}
          </Button>
        </ComponentPermissionChecker>
      </div>
    </div>
  );
}
