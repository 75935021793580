import { Children, ReactNode } from "react";
import classNames from "classnames";
import styles from "components/forms/row/input_row.module.scss";
import { GetColorClass, InputColorVaraint } from "components/forms/input/variants";
import {
  ErrorCircleRegular as ErrorIcon,
  InfoRegular as InfoIcon,
  WarningRegular as WarningIcon,
} from "@fluentui/react-icons";

type InputRowProps = {
  className?: string;
  button?: ReactNode;
  children: ReactNode;
  infoText?: string;
  warningText?: string;
  errors?: string[];
  verticalAlign?: "top" | "bottom";
};

export default function InputRow({
  className,
  button,
  children,
  infoText,
  warningText,
  errors,
  verticalAlign,
}: InputRowProps) {
  const childrenCount = Children.count(children);
  const gridColumns = {
    gridTemplateColumns: `${Array(childrenCount).fill("1fr").join(" ")} ${button ? "min-content" : ""}`,
  };

  let inputColorClass = "";
  if (warningText != undefined) {
    inputColorClass = GetColorClass(InputColorVaraint.warning);
  }
  if (errors != undefined) {
    inputColorClass = GetColorClass(InputColorVaraint.error);
  }

  let align = "";
  if (verticalAlign === "top") {
    align = styles.top;
  }
  if (verticalAlign === "bottom") {
    align = styles.bottom;
  }

  return (
    <div className={classNames(styles.inputRowWrapper, inputColorClass)}>
      <div className={classNames(styles.inputRowContainer, className, align)} style={gridColumns}>
        {(Children.toArray(children) || []).map((child, index) => {
          return (
            <div className={styles.inputRowItem} key={"input-row-item-" + index}>
              {child}
            </div>
          );
        })}
        {button && <div className={styles.inputRowButton}>{button}</div>}
      </div>
      {infoText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackInfo)}>
          <div className={styles.feedbackIcon}>
            <InfoIcon />
          </div>
          <div className={styles.feedbackText}>{infoText}</div>
        </div>
      )}
      {errors && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackError)}>
          <div className={styles.feedbackIcon}>
            <ErrorIcon />
          </div>
          <div className={styles.feedbackText}>{errors.join(", ")}</div>
        </div>
      )}
      {warningText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackWarning)}>
          <div className={styles.feedbackIcon}>
            <WarningIcon />
          </div>
          <div className={styles.feedbackText}>{warningText}</div>
        </div>
      )}
    </div>
  );
}
