import { useState } from "react";
import { getRestEndpoint } from "utils/apiUrlBuilder";
import { ChevronLeft28Regular as PrevIcon, ChevronRight28Regular as NextIcon } from "@fluentui/react-icons";
import styles from "components/imageCarousel/attachmentImageCarousel.module.scss";
import classNames from "classnames";

export function AttachmentImageCarousel(props: { className?: string; imagePaths: string[] }) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

  function nextImage() {
    if (activeIndex == undefined) {
      return;
    }

    if (activeIndex < props.imagePaths.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else if (activeIndex === props.imagePaths.length - 1) {
      setActiveIndex(0);
    }
  }

  function prevImage() {
    if (activeIndex == undefined) {
      return;
    }

    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    } else if (activeIndex === 0) {
      setActiveIndex(props.imagePaths.length - 1);
    }
  }

  return (
    <div className={classNames(props.className, styles.carouselContainer)}>
      {activeIndex !== undefined && activeIndex < props.imagePaths.length && (
        <>
          <img src={getRestEndpoint() + props.imagePaths[activeIndex]} />
          {props.imagePaths.length > 1 && (
            <>
              <div className={classNames(styles.arrow, styles.leftArrow)} onClick={() => prevImage()}>
                <PrevIcon />
              </div>
              <div className={classNames(styles.arrow, styles.rightArrow)} onClick={() => nextImage()}>
                <NextIcon />
              </div>
              {props.imagePaths.length > 1 &&
                props.imagePaths.map((v, i) => (
                  <div key={i} className={classNames(styles.dot, i === activeIndex ? styles.activeDot : "")}></div>
                ))}
            </>
          )}
        </>
      )}
    </div>
  );
}
