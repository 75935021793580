import styles from "pages/checkout/checkoutPage.module.scss";
import api, { Address, CountryOption, CreateOrUpdateCustomerGuest, CustomerWithDetails, InputError } from "api/api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { Form } from "components/forms/forms";
import { FormToJson } from "api/apiUtils";
import Note, { NoteType } from "features/note/Note";
import classNames from "classnames";
import { getErrorResponse } from "components/errors/validationErrors";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import Spinny from "features/spinny/Spinny";
import CustomerFormFields from "features/customerFormFields/customerFormFields";
import { hasToken } from "api/manualApi";
import * as routes from "constants/routes";
import { useNavigate } from "react-router-dom";
import { ErrCodeBlockedByCompliance, ErrCodeKYCRequired } from "components/errors/errorCodes";
import { useModal } from "components/dialogue/ModalContext";

type GuestFormPartProps = {
  email: string;
  className?: string;
};

export default function GuestFormPart(props: GuestFormPartProps) {
  const { t } = useTranslation("checkout");
  const { t: tv } = useTranslation("validation");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showModal: showComplianceErrorModal, closeModal: closeComplianceErrorModal } = useModal();

  // create guest can also be used as edit as it will update the customer if it exists
  const [createGuestCustomer, createLoginResp] = api.useCreateCheckoutGuestCustomerMutation();

  const [country, setCountry] = useState<CountryOption>();
  const [errMessage, setErrMessage] = useState<string>();
  const [ie, setInputErrors] = useState<InputError[]>();
  const [blockedByCompliance, setBlockedByCompliance] = useState<boolean>(false);

  // get the required data from the context
  const { sessionId, setIsGuest, customerResp, setEditCustomer, paymentMethod } = useContext(CheckoutContext);

  // fill info if logged in
  const { data: existingCustomerData } = api.useGetCustomerForEditQuery(undefined, {
    skip: !hasToken(),
    refetchOnMountOrArgChange: false,
  });

  function loginAndClose(success: boolean, token?: string) {
    if (!success) {
      return;
    }

    if (token) {
      setEditCustomer(false);
      // put customer token in session storage, to clear after closing the browser
      window.sessionStorage.setItem("token", token);
      window.localStorage.removeItem("token");
    }

    // dispatch manually after setting the token to trigger logged in customer refetch
    dispatch(api.util.invalidateTags(["customer"]));
  }

  useEffect(() => {
    loginAndClose(createLoginResp?.isSuccess === true, createLoginResp?.data?.token);
  }, [createLoginResp]);

  useEffect(() => {
    const err = getErrorResponse(createLoginResp?.error);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }

    const complianceBlock = err?.errorCode === ErrCodeBlockedByCompliance || err?.errorCode === ErrCodeKYCRequired;
    setBlockedByCompliance(complianceBlock);
    if (complianceBlock) {
      openComplianceErrorModal(err?.errorCode);
    }
  }, [createLoginResp?.error]);

  useEffect(() => {
    if (customerResp?.isSuccess) {
      setInputErrors(undefined);
      setErrMessage(undefined);
    }
  }, [customerResp?.isSuccess]);

  function openComplianceErrorModal(errCode: string | undefined) {
    if (errCode) {
      showComplianceErrorModal(
        <div>
          <Note noteType={NoteType.error}>
            {errCode === ErrCodeKYCRequired && <div>{t("MustKYCMessage")}</div>}
            {errCode === ErrCodeBlockedByCompliance && <div>{t("Guest customer creation not allowed")}</div>}
          </Note>
          <ButtonRow className={styles.buttonWrapper}>
            <Button type={"button"} onClick={closeComplianceErrorModal}>
              {t("Close", { ns: "common" })}
            </Button>
            {/* only allow account creation on 'KYC required' code */}
            {errCode === ErrCodeKYCRequired && (
              <Button
                onClick={navigateCreateAccount}
                type={"button"}
                intercomTarget={"navigate-account-creation-after-guest-creation-fail"}
              >
                {t("CreateAccount")}
              </Button>
            )}
          </ButtonRow>
        </div>,
      );
    } else {
      console.error("Error code is undefined when trying to open error modal");
    }
  }

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setErrMessage(undefined);
    setInputErrors(undefined);

    // Create
    const formJson = FormToJson<CreateOrUpdateCustomerGuest>(event);

    formJson.email = props.email;

    formJson.address = FormToJson<Address>(event, "address");
    formJson.residenceCountryIso3 = formJson.address.countryIso3;

    // cast birthdate to standard string
    if (formJson.birthDate) {
      formJson.birthDate = new Date(formJson.birthDate as string).toISOString();
    }

    // create
    createGuestCustomer({ "X-Checkout-Payment-Session-ID": sessionId || "", createOrUpdateCustomerGuest: formJson });
  }

  function navigateCreateAccount() {
    setIsGuest(false);

    let formJson: CreateOrUpdateCustomerGuest | undefined = undefined;

    if (formRef.current) {
      const syntheticEvent = { currentTarget: formRef.current } as FormEvent<HTMLFormElement>;
      formJson = FormToJson<CustomerWithDetails>(syntheticEvent);

      formJson.email = props.email;

      formJson.address = FormToJson<Address>(syntheticEvent, "address");
      formJson.residenceCountryIso3 = formJson.address.countryIso3;

      if (formJson.birthDate) {
        formJson.birthDate = new Date(formJson.birthDate as string).toISOString();
      }
    }

    navigate(`${routes.CONSUMER_ACCOUNT}?ps=${sessionId}`, {
      state: {
        customerEmail: props.email,
        paymentMethod: paymentMethod,
        preFillCustomerData: formJson,
      },
    });

    closeComplianceErrorModal();
  }

  function resetForm(event: FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    // go thorugh all the inputs and reset them
    const inputs = formRef.current?.querySelectorAll("input");
    inputs?.forEach((input) => {
      input.value = "";
    });
    setCountry(undefined);
    setErrMessage(undefined);
    setInputErrors(undefined);
    setBlockedByCompliance(false);
  }

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div className={classNames(styles.guestForm, props.className)}>
      <Form intercomTarget={"guest-details"} onSubmit={submit} ref={formRef}>
        <div
          className={classNames(styles.formPart, {
            [styles.loadingForm]: customerResp?.isLoading,
          })}
        >
          <CustomerFormFields ie={ie} country={country} setCountry={setCountry} filledCustomer={existingCustomerData} />
          {/* compliance errors are shown in modal instead */}
          {errMessage && !blockedByCompliance && (
            <Note noteType={NoteType.error}>{errMessage && !blockedByCompliance && <div>{tv(errMessage)}</div>}</Note>
          )}
          <ButtonRow className={styles.buttonWrapper}>
            <ButtonRow>
              <Button
                intercomTarget={"back-guest-details"}
                type={"button"}
                onClick={() => {
                  if (existingCustomerData) {
                    // already logged in, go back to edit = off
                    setEditCustomer(false);
                  } else {
                    // not logged in, go back to guest = off
                    setIsGuest(undefined);
                  }
                }}
                buttonColor={ColorVariant.ghost}
              >
                {t("Back")}
              </Button>
              <Button
                intercomTarget={"reset-guest-details"}
                type={"reset"}
                onClick={resetForm}
                buttonColor={ColorVariant.ghost}
              >
                {t("Reset")}
              </Button>
            </ButtonRow>
            <Button
              intercomTarget={"submit-guest-details"}
              type={"submit"}
              buttonPosition={ButtonPosition.fullWidth}
              disabled={blockedByCompliance || createLoginResp.isLoading}
              icon={createLoginResp.isLoading ? <Spinny /> : undefined}
            >
              {t("Save")}
            </Button>
          </ButtonRow>
        </div>
      </Form>
    </div>
  );
}
