import styles from "pages/consumerCompliance/modules/personalInfo/PersonalInfo.module.scss";
import { Form, Input, InputRow } from "components/forms/forms";
import { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import { useTranslation } from "react-i18next";
import { FormEvent, useContext, useEffect, useState } from "react";
import { FormToJson } from "api/apiUtils";
import { StepProps } from "pages/consumerCompliance/modules/types";
import { ComplianceCheckContext } from "pages/consumerCompliance/ComplianceCheckPage";
import api, { Address, CountryOption, CreateCustomerAccount, InputError, UpdateCustomerAccount } from "api/api";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import { useDispatch } from "react-redux";
import { Password20Regular as PinIcon, Password20Regular as PasswordIcon } from "@fluentui/react-icons";
import { NavigateButtons, modules } from "pages/consumerCompliance/modules/ComplianceSteps";
import CustomerFormFields from "features/customerFormFields/customerFormFields";

export default function PersonalInfo(props: StepProps) {
  const { t: tc } = useTranslation("checkout");
  const { t: tv } = useTranslation("validation");

  const { customerEmail, setCustomerEmail, paymentSessionId, loggedInCustomer, preFillCustomerData, returnFunction } =
    useContext(ComplianceCheckContext);

  const dispatch = useDispatch();

  const [createAccountCustomer, createResp] = api.useCreateAccountCustomerMutation();
  const [updateAccountCustomer, updateResp] = api.useUpdateAccountCustomerMutation();

  api.useGetCountriesQuery();
  const [country, setCountry] = useState<CountryOption>();
  const [errMessage, setErrMessage] = useState<string>();
  const [ie, setInputErrors] = useState<InputError[]>();
  const [pinInputErr, setPinInputErr] = useState<string[] | undefined>();
  const [repeatPinInputErr, setRepeatPinInputErr] = useState<string[] | undefined>();
  const [passwordInputErr, setPasswordInputErr] = useState<string[] | undefined>();
  const [repeatPasswordInputErr, setRepeatPasswordInputErr] = useState<string[] | undefined>();

  useEffect(() => {
    const err = getErrorResponse(createResp.error || updateResp.error);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [createResp.error, updateResp.error]);

  function loginAndNext(success: boolean, token?: string) {
    if (!success) {
      return;
    }

    if (token) {
      // put customer token in session storage, to clear after closing the browser
      window.sessionStorage.setItem("token", token);
      window.localStorage.removeItem("token");
    }

    // dispatch manually after setting the token to get faster login
    dispatch(api.util.invalidateTags(["customer"]));

    // proceed to next step
    const nextStep = Object.keys(modules).indexOf("personalInfo") + 1;
    props.setCompletedSteps((prevSteps) => [...prevSteps, "personalInfo"]);
    props.setStep(nextStep);
  }

  useEffect(() => {
    loginAndNext(createResp?.isSuccess, createResp.data?.token);
  }, [createResp]);

  useEffect(() => {
    loginAndNext(updateResp?.isSuccess, undefined);
  }, [updateResp]);

  function validatePin(pin: string): boolean {
    const onlyNumbers = /^\d+$/.test(pin) || pin === "";
    const maxLength = pin.length <= 4;

    return onlyNumbers && maxLength;
  }

  function validatePassword(password: string): boolean {
    return password.length >= 8;
  }

  function validatePinAndPasswordForSubmit(formJson: CreateCustomerAccount): boolean {
    if (formJson.pin || formJson.repeatPin) {
      if (!validatePin(formJson.pin || "")) {
        setPinInputErr([tc("PinCodeFormatErr")]);
        return false;
      }

      if (formJson.pin && formJson.pin !== formJson.repeatPin) {
        setRepeatPinInputErr([tc("RepeatPinCodeIncorrectErr")]);
        return false;
      }
    }

    if (!validatePassword(formJson.password || "")) {
      setPasswordInputErr([tc("PasswordFormatErr")]);
      return false;
    }

    if (formJson.password !== formJson.repeatPassword) {
      setRepeatPasswordInputErr([tc("RepeatPasswordIncorrectErr")]);
      return false;
    }

    return true;
  }

  // function for handling form submit.
  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setErrMessage(undefined);
    setInputErrors(undefined);

    let formJson: CreateCustomerAccount | UpdateCustomerAccount = {};

    // Get all the data from the form (add type to FormToJson<the type>(event))
    if (loggedInCustomer) {
      formJson = FormToJson<UpdateCustomerAccount>(event);
    } else {
      const formJsonTemp = FormToJson<CreateCustomerAccount>(event);

      if (!formJsonTemp.pin && !formJsonTemp.repeatPin) {
        formJsonTemp.pin = undefined;
        formJsonTemp.repeatPin = undefined;
      }

      formJson = formJsonTemp;
    }

    formJson.address = FormToJson<Address>(event, "address");
    formJson.residenceCountryIso3 = formJson.address.countryIso3;

    // cast birthdate to standard string
    if (formJson.birthDate) {
      formJson.birthDate = new Date(formJson.birthDate as string).toISOString();
    }

    setCustomerEmail(formJson.email);

    if (loggedInCustomer) {
      // update
      updateAccountCustomer(formJson);
      return;
    }

    // pin and password edit only for create
    if (!validatePinAndPasswordForSubmit(formJson)) {
      return;
    }

    createAccountCustomer({
      "X-Checkout-Payment-Session-ID": paymentSessionId || "-",
      createCustomerAccount: formJson,
    });

    // proceed to next step after token is set
  }

  // handleBack handles the logic for moving backwards. Should only be available if the user is not on the first step.
  const handleBack = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.step > 0) {
      props.setStep(props.step - 1);
    } else {
      returnFunction();
    }
  };

  const nextStep = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.setStep(props.step + 1);
  };

  return (
    <ProgressTabContainer>
      <Form intercomTarget={"create-consumer-personalInfo"} onSubmit={submit}>
        <div className={styles.formPart}>
          {loggedInCustomer?.knowYourCustomerChecked && <Note>{tc("CannotEditInfoKYCedNote")}</Note>}
          <InputRow verticalAlign={"top"}>
            {/* do not allow email edit if is editing */}
            {/* use readOnly instead of disabled to submit the value */}
            <Input
              label={tc("EmailInput")}
              type={"email"}
              name={"email"}
              required={true}
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.currentTarget.value)}
              errors={Tie("Email", ie, ["checkout"])}
              readOnly={!!loggedInCustomer}
            />
          </InputRow>
          <CustomerFormFields
            ie={ie}
            filledCustomer={preFillCustomerData || loggedInCustomer || undefined}
            country={country}
            setCountry={setCountry}
            lockedNameAndBirthday={loggedInCustomer?.knowYourCustomerChecked}
          />
          {/* pin and password only in create */}
          {!loggedInCustomer && (
            <>
              <InputRow verticalAlign={"top"}>
                <Input
                  minLength={8}
                  required={true}
                  leftIcon={<PasswordIcon />}
                  label={tc("PasswordInput")}
                  name={"password"}
                  type={"password"}
                  onChange={() => setPasswordInputErr(undefined)}
                  errors={Tie("Password", ie, ["checkout"]) || passwordInputErr}
                  disabled={!!loggedInCustomer}
                />
                <Input
                  minLength={8}
                  required={true}
                  leftIcon={<PasswordIcon />}
                  label={tc("RepeatPassword")}
                  name={"repeatPassword"}
                  type={"password"}
                  onChange={() => setRepeatPasswordInputErr(undefined)}
                  errors={Tie("RepeatPassword", ie, ["checkout"]) || repeatPasswordInputErr}
                  disabled={!!loggedInCustomer}
                />
              </InputRow>
              <InputRow verticalAlign={"top"}>
                <Input
                  pinLength={4}
                  maxLength={4}
                  minLength={4}
                  leftIcon={<PinIcon />}
                  label={tc("PinCode")}
                  name={"pin"}
                  type={"password"}
                  onChange={() => setPinInputErr(undefined)}
                  errors={Tie("PinCode", ie, ["checkout"]) || pinInputErr}
                  helpText={tc("PinCodeCreateHelpText")}
                  disabled={!!loggedInCustomer}
                />
                <Input
                  pinLength={4}
                  maxLength={4}
                  minLength={4}
                  leftIcon={<PinIcon />}
                  label={tc("RepeatPinCode")}
                  name={"repeatPin"}
                  type={"password"}
                  onChange={() => setRepeatPinInputErr(undefined)}
                  errors={Tie("RepeatPinCode", ie, ["checkout"]) || repeatPinInputErr}
                  disabled={!!loggedInCustomer}
                />
              </InputRow>
            </>
          )}
          {errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}
          <NavigateButtons
            step={props.step}
            loading={createResp.isLoading || updateResp.isLoading}
            handleBack={handleBack}
            showSave={!!loggedInCustomer}
            handleNext={loggedInCustomer ? nextStep : undefined}
          />
        </div>
      </Form>
    </ProgressTabContainer>
  );
}
