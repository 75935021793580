import api, { getCountriesSelector } from "api/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ColumnMetaData } from "features/table/types";

export type FormatFunctionOptions = {
  translationPackage: string;
};

interface AmountRowBase {
  currencyIso3: string;
}

export const formatAmountFunc = <T>(row: T, dataKey: string) => {
  if (!row) return "";

  const { t: tc } = useTranslation("currency");
  const data = row[dataKey as keyof T] as number;

  let currIso3 = "";
  if (typeof row === "object" && row !== null && "currencyIso3" in row) {
    currIso3 = (row as AmountRowBase).currencyIso3;
  }

  return tc("CurrencyFormatter", { value: data, currency: currIso3 });
};

export const formatTranslateFunc = <T>(row: T, dataKey: string, options?: FormatFunctionOptions) => {
  const data = row[dataKey as keyof T];
  const { t } = useTranslation(options?.translationPackage);

  return t(data as string);
};

export const formatCountryIso3ToNameFunc = <T>(row: T, dataKey: string): string => {
  const data = row[dataKey as keyof T];
  api.useGetCountriesQuery();
  const countries = useSelector(getCountriesSelector);
  // default to iso3 if no country name is found
  return countries.find((c) => c.iso3 === data)?.formattedName || String(data);
};

// Joins the the cols2 to end of cols1.
// If there is already a column with the same key, it will be overwritten by cols2 column.
// NOTE: make sure that the amount of columns changes, do not use just for overwriting, because
// render at useTableColumns hook will not trigger if the columns are same length as original!
export function joinAndOverwriteColumns<T>(cols1: ColumnMetaData<T>[], cols2: ColumnMetaData<T>[]) {
  const cols = [...cols1]; // copy

  for (const col of cols2) {
    const index = cols.findIndex((c) => c.key === col.key);
    if (index > -1) {
      cols[index] = { ...col };
    } else {
      cols.push({ ...col });
    }
  }

  return cols;
}
