import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { BuildingShop20Regular as ProductIcon, Save20Regular as SaveIcon } from "@fluentui/react-icons";
import { Form, Input, InputRow, Select, TextArea } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import api, { AttachmentType, AttachmentVisibility, DeliveryType, InputError, Product, Status, VATType } from "api/api";
import { FormEvent, useEffect, useState } from "react";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import styles from "pages/products/products.module.scss";
import { EnumAsOptions, FormToJson } from "api/apiUtils";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";
import AttachmentForm, { AttachmentCategory, AttachmentFormVisualType } from "features/attachments/AttachmentForm";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";

function ProductDetailsModal() {
  const navigate = useNavigate();
  const { t: ts } = useTranslation("shop");
  const { t: tv } = useTranslation("validation");
  const { t } = useTranslation("common");
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();
  const { productId } = useParams();
  const writePermission = useHasPermission(["companyProductsWriteRead"]);

  const companyId = useAppSelector(selectSelectedCompanyId);

  const { data: product } = api.useGetCompanyProductQuery(
    {
      companyId: companyId || "",
      productId: productId || "",
    },
    { skip: !companyId || !productId },
  );

  const [updateProduct, { error: updateError, isLoading: loadingUpdate, isSuccess: isSuccessUpdate }] =
    api.useUpdateCompanyProductMutation();

  const [createProduct, { error: createError, isLoading: loadingCreate, isSuccess: isSuccessCreate }] =
    api.useCreateCompanyProductMutation();

  useEffect(() => {
    const err = getErrorResponse(updateError || createError);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [updateError, createError]);

  useEffect(() => {
    if (isSuccessUpdate || isSuccessCreate) {
      navigate(-1);
    }
  }, [isSuccessUpdate, isSuccessCreate]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!companyId || !writePermission) {
      return;
    }

    setErrMessage(undefined);
    setInputErrors(undefined);

    if (!productId) {
      createProduct({
        companyId: companyId,
        product: FormToJson<Product>(event),
      });
      return;
    }

    updateProduct({
      companyId: companyId,
      productId: productId || "",
      product: FormToJson<Product>(event),
    });
  };

  return (
    <PopoutPageTemplate title={ts("ProductDetails")} show={true} onClose={() => navigate(-1)} icon={<ProductIcon />}>
      {/* only render when product loaded, to handle select field defaults */}
      {!(productId && !product) && (
        <div className={styles.modalContentWrapper}>
          <Form intercomTarget={"product-details-form"} onSubmit={handleSubmit}>
            <InputRow verticalAlign="top">
              <Input
                label={ts("ProductName")}
                name={"productName"}
                defaultValue={product?.productName}
                type={"text"}
                errors={Tie("ProductName", ie, ["shop"])}
                required={true}
                disabled={!writePermission}
              />
              <Input
                label={ts("DisplayName")}
                name={"displayName"}
                defaultValue={product?.displayName}
                type={"text"}
                errors={Tie("DisplayName", ie, ["shop"])}
                required={true}
                disabled={!writePermission}
              />
            </InputRow>
            <InputRow verticalAlign="top">
              <Input
                label={ts("Price")}
                defaultValue={product?.price}
                name={"price"}
                type={"number"}
                errors={Tie("Price", ie, ["shop"])}
                helpText={ts("PriceInCentsHelpText")}
                required={true}
                disabled={!writePermission}
              />
              <Input
                label={ts("Currency")}
                defaultValue={product?.currencyIso3 || "EUR"}
                name={"currencyIso3"}
                type={"text"}
                errors={Tie("CurrencyISO3", ie, ["shop"])}
                readOnly={true}
                required={true}
                disabled={!writePermission}
              />
            </InputRow>
            <TextArea
              label={ts("Description")}
              name={"description"}
              defaultValue={product?.description}
              errors={Tie("Description", ie, ["shop"])}
              disabled={!writePermission}
            />
            <Select
              label={ts("DeliveryType")}
              name={"deliveryType"}
              options={EnumAsOptions(DeliveryType)}
              defaultValue={EnumAsOptions(DeliveryType).find((option) => option.value === product?.deliveryType)}
              required={true}
              errors={Tie("DeliveryType", ie, ["shop"])}
              isDisabled={!writePermission}
            />
            <Select
              label={ts("Status")}
              name={"status"}
              options={EnumAsOptions(Status)}
              defaultValue={EnumAsOptions(Status).find((option) => option.value === product?.status)}
              required={true}
              errors={Tie("Status", ie, ["shop"])}
              isDisabled={!writePermission}
            />
            <Select
              label={ts("VATType")}
              name={"vatType"}
              options={EnumAsOptions(VATType)}
              defaultValue={EnumAsOptions(VATType).find((option) => option.value === product?.vatType)}
              required={true}
              errors={Tie("VATType", ie, ["shop"])}
              isDisabled={!writePermission}
            />
            <Input
              label={t("Website")}
              name={"website"}
              defaultValue={product?.website}
              type={"text"}
              errors={Tie("Website", ie, ["shop"])}
              disabled={!writePermission}
            />

            <ButtonRow>
              <Button
                intercomTarget={"save-product-details-button"}
                buttonColor={ColorVariant.success}
                buttonPosition={ButtonPosition.fullWidth}
                type={"submit"}
                disabled={loadingUpdate || loadingCreate || !writePermission}
                icon={loadingUpdate || loadingCreate ? <Spinny /> : <SaveIcon />}
              >
                {t("Save changes")}
              </Button>
            </ButtonRow>
            {errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}
          </Form>
          <div className={styles.attachmentFormWrapper}>
            {!productId && <div>{ts("AddImageAfterCreate")}</div>}
            {productId && (
              <div>
                <h2>{ts("Product") + " " + t("Pictures")}</h2>
                <div className={styles.productDetailsImageAttachmentContainer}>
                  <AttachmentForm
                    title={t("New")}
                    attachmentFormVisualType={AttachmentFormVisualType.Picture}
                    attachmentId={undefined}
                    category={AttachmentCategory.ShopProductImage}
                    type={AttachmentType.Picture}
                    primaryEntityId={companyId}
                    secondaryEntityId={productId}
                    writePermissionNames={["companyProductsWriteRead"]}
                    defaultVisibility={AttachmentVisibility.Public}
                    attachmentFileGetPath={undefined}
                    onSuccess={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                {product?.publicImageAttachmentIds?.map((attachmentId, i) => (
                  <div key={i} className={styles.productDetailsImageAttachmentContainer}>
                    <AttachmentForm
                      attachmentFormVisualType={AttachmentFormVisualType.Picture}
                      attachmentId={attachmentId}
                      category={AttachmentCategory.ShopProductImage}
                      type={AttachmentType.Picture}
                      primaryEntityId={companyId}
                      secondaryEntityId={productId}
                      writePermissionNames={["companyProductsWriteRead"]}
                      defaultVisibility={AttachmentVisibility.Public}
                      attachmentFileGetPath={
                        product?.publicImageAttachmentPaths ? product?.publicImageAttachmentPaths[i] : undefined
                      }
                      onSuccess={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </PopoutPageTemplate>
  );
}

export default ProductDetailsModal;
