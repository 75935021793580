import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Clock20Regular as TimeIcon, CalendarLtr20Regular as DateIcon } from "@fluentui/react-icons";
import styles from "components/table/cells/date/date_cell.module.scss";
import cellStyles from "components/table/cells/cell.module.scss";

export interface DateCellProps {
  value?: string | Date;
  className?: string;
  totalCell?: boolean;
  includeTime?: boolean;
}

function DateCell(props: DateCellProps) {
  let date = props.value;
  if (typeof props.value === "string") {
    date = new Date(props.value);
  }
  let displayDate = "";
  let displayTime = "";
  if (date != null && date.valueOf() !== 0) {
    if (props.includeTime) {
      displayDate = format(date as Date, "yyyy-MM-dd");
      displayTime = format(date as Date, "HH:mm:ss");
    } else {
      // utc date if show only date
      // (probably the date is 00:00:00 utc, so local date could be wrong depending on timezone)
      displayDate = format(toZonedTime(date, "UTC"), "yyyy-MM-dd");
    }
  } else {
    displayDate = "";
  }

  return (
    <div
      className={classNames(cellStyles.cell, cellStyles.alignLeft, styles.DateCell, props.className, {
        [cellStyles.totalCell]: props.totalCell,
      })}
    >
      {!props.totalCell && (
        <>
          <div className={styles.dateRow}>
            {displayDate && <DateIcon />}
            {displayDate}
          </div>
          {props.includeTime && (
            <div className={styles.timeRow}>
              {displayTime && <TimeIcon />}
              {displayTime}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const DateCellMemo = React.memo(DateCell);
export default DateCellMemo;
