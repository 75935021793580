import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { Save20Regular as SaveIcon, PersonAvailable20Regular as ResolveIcon } from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Form, TextArea } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import api, { ComplianceHitStatus, InputError } from "api/api";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import NoMatch from "pages/noMatch/NoMatch";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import styles from "pages/complianceHits/ComplianceHitsPage.module.scss";
import ToastContext from "features/toast/context";
import React from "react";
import { toastCategory } from "features/toast/Toasts";

function UpdateComplianceHitStatusModal() {
  const { t } = useTranslation("common");
  const { t: tc } = useTranslation("compliance");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();
  const toastRef = React.useContext(ToastContext);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);
  const { complianceHitId } = useParams<{ complianceHitId: string }>();

  const [description, setDescription] = useState<string>("");

  const { data: complianceHit } = api.useGetComplianceHitJoinedQuery(complianceHitId || "", {
    skip: !complianceHitId || !hasEdenAdminPermission,
  });

  const [updateComplianceHitStatus, { error: updateError, isLoading, isSuccess }] =
    api.useUpdateComplianceHitStatusMutation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!hasEdenAdminPermission) {
      return;
    }

    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: "Are you sure to set compliance hit to resolved?" as string,
    });
    if (result) {
      updateComplianceHitStatus({
        complianceHitId: complianceHitId || "",
        complianceHitStatusUpdate: {
          status: ComplianceHitStatus.Resolved,
          description: description,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastRef?.current?.addToast(t("Success"), toastCategory.success);
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    const err = getErrorResponse(updateError);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [updateError]);

  if (!hasEdenAdminPermission) {
    return <NoMatch />;
  }

  return (
    <PopoutPageTemplate
      title={tc("ResolveComplianceHitTitle")}
      show={true}
      onClose={() => navigate(-1)}
      icon={<ResolveIcon />}
    >
      <div className={styles.modalContentWrapper}>
        <Form intercomTarget={"update-compliance-hit-status-form"} onSubmit={handleSubmit}>
          <div>
            {tc("ComplianceHit")} ({t("type")}: <b>{complianceHit?.dataType || "?"}</b>) {t("for")}:
          </div>
          <div>
            <b>
              {complianceHit?.customerFirstName} {complianceHit?.customerLastName} (
              {complianceHit?.customerEmail || "No customer specified"}) / {complianceHit?.companyName}
            </b>
          </div>
          <TextArea
            label={tc("StatusUpdateDescription")}
            name={"description"}
            required={true}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            errors={Tie("Description", ie, ["compliance"])}
          />
          {/* If there are errors then add a Note */}
          {updateError && (
            <Note noteType={NoteType.error} title={t("Error")}>
              {errMessage}
            </Note>
          )}
          <ButtonRow>
            <Button
              intercomTarget={"update-compliance-hit-status-button"}
              buttonColor={ColorVariant.success}
              type={"submit"}
              disabled={isLoading}
              icon={isLoading ? <Spinny /> : <SaveIcon />}
            >
              {tc("Resolve")}
            </Button>
          </ButtonRow>
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default UpdateComplianceHitStatusModal;
