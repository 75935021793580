import { useEffect, useState } from "react";

export const mobileWidth = 700;

const useWindowWidth = () => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  return width;
};
export default useWindowWidth;
