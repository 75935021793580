import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { Save20Regular as SaveIcon, PersonAvailable20Regular as ResolveIcon } from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import {Checkbox, Form, TextArea} from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import api, {
  ComplianceClientFlags,
  ComplianceHitDataType,
  ComplianceHitRecordInternal,
  CompliancePostOperation,
  InputError
} from "api/api";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import NoMatch from "pages/noMatch/NoMatch";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import styles from "pages/complianceHits/ComplianceHitsPage.module.scss";
import ToastContext from "features/toast/context";
import React from "react";
import { toastCategory } from "features/toast/Toasts";

function ExemptComplianceHitModal() {
  const { t } = useTranslation("common");
  const { t: tc } = useTranslation("compliance");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const [ ie, setInputErrors] = useState<InputError[]>();
  const [ errMessage, setErrMessage] = useState<string>();
  const toastRef = React.useContext(ToastContext);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);
  const { complianceHitId } = useParams<{ complianceHitId: string }>();
  const [ ruleDefinitionId, setRuleDefinitionId ] = useState<string>("");
  const [ userId, setUserId ] = useState<string>("");
  const [ apiKeyId, setApiKeyId ] = useState<string>("");
  const [ companyId, setCompanyId ] = useState<string>("");
  const [ customerId, setCustomerId ] = useState<string>("");
  const [ paymentSessionId, setPaymentSessionId ] = useState<string>("");
  const [ refundSessionId, setRefundSessionId ] = useState<string>("");
  const [ resourceName, setResourceName ] = useState<string>("");
  const [ dataType, setDataType ] = useState<ComplianceHitDataType | undefined>(undefined);
  const [ clientFlags, setClientFlags ] = useState<ComplianceClientFlags | undefined>(undefined);
  const [ description, setDescription] = useState<string>("");
  const [ showClientFlags, setShowClientFlags ] = useState<boolean>(false);

  const { data: complianceHit } = api.useGetComplianceHitJoinedQuery(complianceHitId || "", {
    skip: !complianceHitId || !hasEdenAdminPermission,
  });

  const { data: complianceHitInternal } = api.useGetComplianceHitQuery(complianceHitId || "", {
    skip: !complianceHitId || !hasEdenAdminPermission,
  });

  const [createComplianceHit, { error: updateError, isLoading, isSuccess }] =
    api.useCreateComplianceHitMutation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!hasEdenAdminPermission) {
      return;
    }

    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: "Are you sure to exempt current and future compliance hit(s)?" as string,
    });
    if (result) {
      const ch : ComplianceHitRecordInternal = {
        postOperation: CompliancePostOperation.Exempt,
        statusUpdateDescription: description,
      };

      if (ruleDefinitionId) {
        ch.ruleDefinitionId = ruleDefinitionId;
      }
      if (userId) {
        ch.userId = userId;
      }
      if (apiKeyId) {
        ch.apiKeyId = apiKeyId;
      }
      if (companyId) {
        ch.companyId = companyId;
      }
      if (customerId) {
        ch.customerId = customerId;
      }
      if (paymentSessionId) {
        ch.paymentSessionId = paymentSessionId;
      }
      if (refundSessionId) {
        ch.refundSessionId = refundSessionId;
      }
      if (resourceName) {
        ch.resourceName = resourceName;
      }
      if (dataType) {
        ch.dataType = dataType;
      }
      if (clientFlags) {
        ch.clientFlags = clientFlags;
      }

      createComplianceHit(ch);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastRef?.current?.addToast(t("Success"), toastCategory.success);
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (complianceHitInternal) {
      setRuleDefinitionId(complianceHitInternal.ruleDefinitionId || "");
      setUserId("");
      setApiKeyId("");
      setCompanyId(complianceHitInternal.companyId || "");
      setCustomerId(complianceHitInternal.customerId || "");
      setPaymentSessionId(complianceHitInternal.paymentSessionId || "");
      setRefundSessionId(complianceHitInternal.refundSessionId || "");
      setResourceName(complianceHitInternal.resourceName || "");
      setDataType(complianceHitInternal.dataType);
      if (complianceHitInternal.clientFlags && complianceHitInternal.clientFlags !== 0) {
        setClientFlags(complianceHitInternal.clientFlags);
        setShowClientFlags(true);
      }
    }
  }, [complianceHitInternal]);

  useEffect(() => {
    const err = getErrorResponse(updateError);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [updateError]);

  if (!hasEdenAdminPermission) {
    return <NoMatch />;
  }

  return (
    <PopoutPageTemplate
      title={tc("ExemptComplianceHitTitle")}
      show={true}
      onClose={() => navigate(-1)}
      icon={<ResolveIcon />}
    >
      <div className={styles.modalContentWrapper}>
        <Form intercomTarget={"exempt-compliance-hit-form"} onSubmit={handleSubmit}>
          {complianceHit?.postOperation === CompliancePostOperation.Exempt && (
            <Note noteType={NoteType.warning} title={t("Warning")}>
              {tc("ComplianceHitAlreadyExempt")}
            </Note>
          )}

          {complianceHit?.postOperation !== CompliancePostOperation.Exempt && (
            <>
              {complianceHitInternal?.ruleDefinitionId && (
                <Checkbox
                  label={tc("ExemptRuleDefinitionId")}
                  name={"ruleDefinitionId"}
                  checked={complianceHitInternal.ruleDefinitionId === ruleDefinitionId}
                  onChange={(e) => {
                    setRuleDefinitionId(e.target.checked ? complianceHitInternal.ruleDefinitionId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.userId && (
                <Checkbox
                  label={tc("ExemptUserId")}
                  name={"userId"}
                  checked={complianceHitInternal.userId === userId}
                  onChange={(e) => {
                    setUserId(e.target.checked ? complianceHitInternal.userId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.apiKeyId && (
                <Checkbox
                  label={tc("ExemptApiKeyId")}
                  name={"apiKeyId"}
                  checked={complianceHitInternal.apiKeyId === apiKeyId}
                  onChange={(e) => {
                    setApiKeyId(e.target.checked ? complianceHitInternal.apiKeyId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.companyId && (
                <Checkbox
                  label={tc("ExemptCompanyId") + ": " + complianceHit?.companyName}
                  name={"companyId"}
                  checked={complianceHitInternal.companyId === companyId}
                  onChange={(e) => {
                    setCompanyId(e.target.checked ? complianceHitInternal.companyId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.customerId && (
                <Checkbox
                  label={tc("ExemptCustomerId") + ": " + complianceHit?.customerEmail}
                  name={"customerId"}
                  checked={complianceHitInternal.customerId === customerId}
                  onChange={(e) => {
                    setCustomerId(e.target.checked ? complianceHitInternal.customerId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.paymentSessionId && (
                <Checkbox
                  label={tc("ExemptPaymentSessionId")}
                  name={"paymentSessionId"}
                  checked={complianceHitInternal.paymentSessionId === paymentSessionId}
                  onChange={(e) => {
                    setPaymentSessionId(e.target.checked ? complianceHitInternal.paymentSessionId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.refundSessionId && (
                <Checkbox
                  label={tc("ExemptRefundSessionId")}
                  name={"refundSessionId"}
                  checked={complianceHitInternal.refundSessionId === refundSessionId}
                  onChange={(e) => {
                    setRefundSessionId(e.target.checked ? complianceHitInternal.refundSessionId! : "");
                  }}
                />
              )}
              {complianceHitInternal?.resourceName && (
                <Checkbox
                  label={tc("ExemptResourceName") + ": " + complianceHitInternal.resourceName}
                  name={"resourceName"}
                  checked={complianceHitInternal.resourceName === resourceName}
                  onChange={(e) => {
                    setResourceName(e.target.checked ? complianceHitInternal.resourceName! : "");
                  }}
                />
              )}
              {complianceHitInternal?.dataType && (
                <Checkbox
                  label={tc("ExemptDataType") + ": " + complianceHitInternal.dataType}
                  name={"dataType"}
                  checked={complianceHitInternal.dataType === dataType}
                  onChange={(e) => {
                    setDataType(e.target.checked ? complianceHitInternal.dataType! : undefined);
                  }}
                />
              )}
              {showClientFlags && (
                <Checkbox
                  label={tc("ExemptClientFlags")}
                  name={"clientFlags"}
                  checked={complianceHitInternal?.clientFlags === clientFlags}
                  onChange={(e) => {
                    setClientFlags(e.target.checked ? complianceHitInternal!.clientFlags! : undefined);
                  }}
                />
              )}

              <TextArea
                label={tc("ExemptReason")}
                name={"description"}
                required={true}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                errors={Tie("Description", ie, ["compliance"])}
              />
              {updateError && (
                <Note noteType={NoteType.error} title={t("Error")}>
                  {errMessage}
                </Note>
              )}

              <ButtonRow>
                <Button
                  intercomTarget={"exempt-compliance-hit-button"}
                  buttonColor={ColorVariant.success}
                  type={"submit"}
                  disabled={isLoading}
                  icon={isLoading ? <Spinny /> : <SaveIcon />}
                >
                  {tc("Exempt")}
                </Button>
              </ButtonRow>
            </>
          )}
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default ExemptComplianceHitModal;
