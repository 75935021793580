import { Send20Regular as SendIcon } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import Input from "components/forms/input/Input";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import styles from "features/auth/LoginPage.module.scss";
import { Form } from "components/forms/forms";
import { useContext, useEffect, useState } from "react";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { useGeneratePasswordResetTokenMutation } from "api/manualApi";
import { getErrorResponse } from "components/errors/validationErrors";
import NoMatch from "pages/noMatch/NoMatch";
import { useLocation, useNavigate } from "react-router-dom";
import { GeneratePasswordResetTokenRequest } from "types/api";

export enum ForgotPasswordPageType {
  customer = "customer",
  user = "user",
}

const resendTimerSeconds = 60;

function ForgotPasswordPage(props: { pageType: ForgotPasswordPageType }) {
  const { t } = useTranslation("auth");
  const { t: tv } = useTranslation("validation");
  const toastRef = useContext(ToastContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [untilAllowResend, setUntilAllowResend] = useState<number | undefined>(undefined);

  const { email } = location.state || {};
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (email) {
      // prefill email if passed in from location state
      const emailField = document.getElementById("email-field") as HTMLInputElement;
      if (emailField) {
        emailField.value = email;
      }
    }
  }, [email]);

  const [genToken, { isSuccess, isLoading, error, startedTimeStamp }] = useGeneratePasswordResetTokenMutation();

  // allow resend timer
  useEffect(() => {
    if (startedTimeStamp) {
      setUntilAllowResend(resendTimerSeconds);
    }

    const timer = setInterval(() => {
      if (!startedTimeStamp) {
        setUntilAllowResend(undefined);
      } else {
        const until = resendTimerSeconds - Math.floor((new Date().getTime() - startedTimeStamp) / 1000);

        if (until <= 0) {
          setUntilAllowResend(undefined);
        } else {
          setUntilAllowResend(until);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startedTimeStamp]);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const req: GeneratePasswordResetTokenRequest = {
      email: formData.get("email")?.toString() || "",
      type: props.pageType,
    };

    if (urlParams.has("checkout")) {
      req.checkoutPaymentSessionId = urlParams.get("checkout") || "";
    }

    genToken(req);
  };

  useEffect(() => {
    if (isSuccess) {
      toastRef?.current?.addToast(t("Password reset email sent"), toastCategory.success);
    }
  }, [isSuccess]);

  useEffect(() => {
    const err = getErrorResponse(error);
    if (err?.message) {
      toastRef?.current?.addToast(tv(err?.message), toastCategory.error);
    } else if (error) {
      toastRef?.current?.addToast(t("Unexpected error sending password reset email"), toastCategory.error);
    }
  }, [error]);

  if (props.pageType !== ForgotPasswordPageType.user && props.pageType !== ForgotPasswordPageType.customer) {
    console.error("Invalid page type, should specify user or customer", props.pageType);
    return <NoMatch />;
  }

  return (
    <div className={styles.pageWrapper}>
      <Form className={styles.formWrapper} onSubmit={submit} intercomTarget={"forgot-password-form"}>
        <h4 className={styles.forgotPasswordHeader}>{t("Forgot your password?")}</h4>
        <div className={styles.forgotPasswordInfo}>{t("forgotPasswordInfo")}</div>
        <Input
          type="email"
          name={"email"}
          label={t("Email")}
          id={"email-field"}
          intercomTarget={"forgot-password-email-field"}
          autoFocus={true}
          autoComplete={"current-email"}
          aria-autocomplete={"list"}
        />
        <ButtonRow>
          <Button
            type={"button"}
            onClick={() => {
              navigate(-1);
            }}
            buttonPosition={ButtonPosition.left}
          >
            {t("Back")}
          </Button>
          <Button
            type="submit"
            icon={<SendIcon />}
            buttonColor={ColorVariant.success}
            id={"submit-button"}
            intercomTarget={"password-reset-send-button"}
            disabled={isLoading || untilAllowResend !== undefined}
          >
            {t("Send email")}
          </Button>
        </ButtonRow>

        {untilAllowResend !== undefined && (
          <div className={styles.resendTimer}>{t("Retry in {{seconds}} seconds", { seconds: untilAllowResend })}</div>
        )}
      </Form>
    </div>
  );
}

export default ForgotPasswordPage;
