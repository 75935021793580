import { Input, InputRow, Select } from "components/forms/forms";
import { Tie } from "components/errors/validationErrors";
import api, { Address, CountryOption, getCountriesAsOptions, InputError } from "api/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type AddressFormFieldsProps = {
  disabled?: boolean;
  ie?: InputError[];
  country?: CountryOption;
  setCountry: (country?: CountryOption) => void;
  filledAddress?: Address;
  addressRequired?: boolean;
};

// Common form fields for address create/edit
export default function AddressFormFields(props: AddressFormFieldsProps) {
  const { t } = useTranslation("checkout");
  const ie = props.ie;
  const disabled = props.disabled == true;

  // fetch the countries
  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  const getCurrentCountry = () => {
    if (props.country) {
      return props.country;
    }

    // pre filled
    return countryOptions.find((country) => country.value === props.filledAddress?.countryIso3);
  };

  return (
    <>
      <InputRow verticalAlign={"top"}>
        <Input
          label={t("StreetNameInput")}
          type={"text"}
          name={"address.streetName"}
          errors={Tie("StreetName", ie, ["common"])}
          disabled={disabled}
          defaultValue={props.filledAddress?.streetName}
          required={props.addressRequired}
        />
        <Input
          label={t("BuildingNumberInput")}
          type={"text"}
          name={"address.buildingNumber"}
          errors={Tie("BuildingNumber", ie, ["common"])}
          disabled={disabled}
          defaultValue={props.filledAddress?.buildingNumber}
          required={props.addressRequired}
        />
      </InputRow>
      <Input
        label={t("AddressLineInput")}
        type={"text"}
        name={"address.addressLine"}
        errors={Tie("AddressLine", ie, ["common"])}
        disabled={disabled}
        defaultValue={props.filledAddress?.addressLine}
      />
      <InputRow verticalAlign={"top"}>
        <Input
          label={t("TownNameInput")}
          type={"text"}
          name={"address.townName"}
          errors={Tie("TownName", ie, ["common"])}
          disabled={disabled}
          defaultValue={props.filledAddress?.townName}
          required={props.addressRequired}
        />
        <Input
          label={t("PostCodeInput")}
          name={"address.postCode"}
          type={"text"}
          errors={Tie("PostCode", ie, ["common"])}
          disabled={disabled}
          defaultValue={props.filledAddress?.postCode}
          required={props.addressRequired}
        />
      </InputRow>
      <Select
        intercomTarget={"select-countries"}
        label={t("CountryInput")}
        name={"address.countryIso3"}
        options={countryOptions}
        value={getCurrentCountry()}
        required={true}
        onChange={(newValue: unknown) => {
          props.setCountry(countryOptions.find((country) => country.value === (newValue as CountryOption).value));
        }}
        errors={Tie(
          "CountryISO3",
          ie,
          ["common"],
          ["RecordComplete.Address.RecordBase.CountryISO3", "RecordBaseComplete.Address.CountryISO3"],
        )}
        isDisabled={disabled}
      />
    </>
  );
}
