import { useTranslation } from "react-i18next";
import styles from "pages/checkout/existingCustomer/ExistingCustomer.module.scss";
import { useContext } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { ArrowExit20Regular as LogOutIcon } from "@fluentui/react-icons";
import api from "api/api";
import { useDispatch } from "react-redux";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";

export default function ExistingCustomer() {
  const { t } = useTranslation("checkout");

  const dispatch = useDispatch();

  const [logoutCustomer] = api.useLogoutCustomerMutation();

  const { setIsGuest, setEmail, setEditCustomer, loggedInCustomer } = useContext(CheckoutContext);

  if (!loggedInCustomer) {
    return <></>;
  }

  return (
    <div className={styles.existingCustomerWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>{t("Billing information")}</div>
        <div
          className={styles.logOut}
          onClick={() => {
            logoutCustomer()
              .unwrap()
              .then(() => {
                setEmail(undefined);
                setIsGuest(undefined);
                setEditCustomer(undefined);
                localStorage.removeItem("token");
                sessionStorage.removeItem("token");
                dispatch(api.util.invalidateTags(["customer"]));
              });
          }}
        >
          <LogOutIcon />
          {t("Log out")}
        </div>
      </div>

      <div className={styles.details} onClick={() => setEditCustomer(true)}>
        <Button
          className={styles.editButton}
          intercomTarget={"edit-customer"}
          buttonSize={SizeVariant.normal}
          buttonColor={ColorVariant.ghost}
        >
          {t("Edit")}
        </Button>
        <div className={styles.fullName}>
          {loggedInCustomer?.firstName} {loggedInCustomer?.lastName}
        </div>
        <div className={styles.email}>{loggedInCustomer?.email}</div>

        {/* Address etc*/}
      </div>
    </div>
  );
}
