import api from "api/api";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Mail20Regular as EmailIcon,
  Password20Regular as PinIcon,
  Password20Regular as PasswordIcon,
} from "@fluentui/react-icons";
import styles from "pages/checkout/checkoutPage.module.scss";
import Button, { ButtonPosition, ButtonRow } from "components/buttons/Button";
import { Input } from "components/forms/forms";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { getErrorResponse } from "components/errors/validationErrors";
import Spinny from "features/spinny/Spinny";
import { useDispatch } from "react-redux";
import * as routes from "constants/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  paymentSessionId: string;
};

export default function CheckOutEmailLoginFields(props: Props) {
  const { t, i18n } = useTranslation("checkout");
  const { t: tCompl } = useTranslation("compliance");
  const { t: tv } = useTranslation("validation");
  const navigate = useNavigate();

  // email input ref
  const emailInput = useRef<HTMLInputElement>(null);

  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [requiresPin, setRequiresPin] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<string>();
  const [checkPinErrors, setCheckPinErrors] = useState<string[] | undefined>(undefined);
  const [requiresPassword, setRequiresPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [checkPasswordErrors, setCheckPasswordErrors] = useState<string[] | undefined>(undefined);
  const [checkCustomerErrors, setCheckCustomerErrors] = useState<string[] | undefined>(undefined);
  const [delayRetry, setDelayRetry] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [getCheckCustomer, customerResp] = api.useRequestCustomerCheckoutLoginMutation();
  const [loginCustomer, loginResp] = api.useLoginCustomerMutation();

  const {
    setCustomerResp,
    setEmail,
    email: contextEmail,
    isGuest,
    setIsGuest,
    sessionId,
  } = useContext(CheckoutContext);

  // login
  useEffect(() => {
    if (loginResp?.data?.token) {
      // put customer token in session storage, to clear after closing the browser
      window.sessionStorage.setItem("token", loginResp?.data?.token);
      window.localStorage.removeItem("token");
    }

    // dispatch manually after setting the token to trigger logged in customer refetch
    dispatch(api.util.invalidateTags(["customer"]));
  }, [loginResp]);

  useEffect(() => {
    if (!customerResp?.error && !loginResp?.error) {
      setCheckCustomerErrors(undefined);
      setCheckPinErrors(undefined);
      setCheckPasswordErrors(undefined);
      return;
    }
    const err = getErrorResponse(customerResp?.error || loginResp?.error);

    if (loginResp?.error && err?.message) {
      if (customerResp?.data?.pin) {
        setCheckPinErrors([...(checkPinErrors || []), t(err.message)]);
      }

      if (customerResp?.data?.password) {
        setCheckPasswordErrors([...(checkPasswordErrors || []), t(err.message)]);
      }
    }

    if (customerResp?.error && err?.message) {
      // use either compliance of validation package for error localization
      if (i18n.exists(err.message, { ns: "validation" })) {
        setCheckCustomerErrors([tv(err.message)]);
      } else {
        setCheckCustomerErrors([tCompl(err.message)]);
      }
      clear(false, false);
    }
  }, [customerResp?.error, loginResp?.error]);

  useEffect(() => {
    setCustomerResp(customerResp);

    if (customerResp?.data?.pin) {
      setRequiresPin(true);
      setIsGuest(false); // cannot be guest as already has an account
    } else if (customerResp?.data?.password) {
      setRequiresPassword(true);
      setIsGuest(false); // cannot be guest as already has an account
    }
  }, [customerResp]);

  function checkEmailValidity() {
    // regex for email should be any character seperated by an @ symbol followed by any character seperated by a .
    const emailRegex = /\S+@\S+\.\S+/;

    setValidEmail(emailRegex.test(emailInput?.current?.value || ""));
  }

  function validatePin(pin: string): boolean {
    const onlyNumbers = /^\d+$/.test(pin) || pin === "";
    const maxLength = pin.length <= 4;

    return onlyNumbers && maxLength;
  }

  function validatePassword(password: string): boolean {
    return password.length <= 8;
  }

  function handleEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    // we don't want to submit the form when pressing enter in email or pin input
    if (event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  }

  function validateAndSetCheckPin(event: FormEvent<HTMLInputElement>) {
    if (validatePin(event.currentTarget.value)) {
      setCheckPinErrors(undefined);
      setCurrentPin(event.currentTarget.value);
    } else {
      setCheckPinErrors([t("PinCodeFormatErr")]);
    }
  }

  function validateAndSetCheckPassword(event: FormEvent<HTMLInputElement>) {
    if (validatePassword(event.currentTarget.value)) {
      setCheckPasswordErrors(undefined);
      setCurrentPassword(event.currentTarget.value);
    } else {
      setCheckPasswordErrors([t("PasswordFormatErr")]);
    }
  }

  function checkCustomer() {
    const email = emailInput.current?.value || undefined;
    getCheckCustomer({
      "X-Checkout-Payment-Session-ID": props.paymentSessionId,
      customerEmail: { email: email || "" },
    });
    setEmail(email);
    setDelayRetry(true);
    setTimeout(() => setDelayRetry(false), 3000); // 3 sec delay to avoid spamming
  }

  function login() {
    if (requiresPin && !currentPin) {
      setCheckPinErrors([t("PinCodeRequiredErr")]);
      return;
    }

    if (requiresPassword && !currentPassword) {
      setCheckPasswordErrors([t("PasswordRequiredErr")]);
      return;
    }

    loginCustomer({
      email: contextEmail || "",
      pin: currentPin,
      password: currentPassword,
    });
  }

  // either check the customer or login
  function submit() {
    if (!validEmail) {
      return;
    }

    if (!contextEmail || !customerResp?.data) {
      checkCustomer();
      return;
    }

    login();
  }

  function clear(clearEmailInput: boolean, clearCheckCustomerResp: boolean) {
    if (emailInput?.current && clearEmailInput) {
      emailInput.current.value = "";
    }

    setEmail(undefined);
    setRequiresPin(false);
    setCurrentPin("");
    setRequiresPassword(false);
    setCurrentPassword("");
    setIsGuest(undefined);

    if (clearCheckCustomerResp) {
      customerResp.reset();
    }
  }

  function navigateForgotPassword(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    navigate(routes.FORGOT_PASSWORD_CUSTOMER + "?checkout=" + sessionId, {
      state: { email: emailInput.current?.value },
    });
  }

  return (
    <div className={classNames(styles.formPart, styles.emailInputWrapper)}>
      <div className={styles.header}>{t("Billing Information")}</div>
      <Input
        leftIcon={<EmailIcon />}
        ref={emailInput}
        label={t("EmailInput")}
        name={"email"}
        required={true}
        type={"email"}
        onChange={checkEmailValidity}
        onKeyDown={handleEnterKey}
        autoFocus={true}
        disabled={!!contextEmail}
        errors={checkCustomerErrors}
      />
      {/* Only show pin or password input if customer with email exist */}
      {requiresPin && (
        <Input
          autoFocus={true}
          pinLength={4}
          maxLength={4}
          minLength={4}
          required={true}
          leftIcon={<PinIcon />}
          label={t("PinCodeInput")}
          name={"checkPin"}
          value={currentPin || ""}
          type={"password"}
          onChange={validateAndSetCheckPin}
          onKeyDown={handleEnterKey}
          errors={checkPinErrors}
        />
      )}
      {requiresPassword && (
        <Input
          autoFocus={true}
          minLength={8}
          required={true}
          leftIcon={<PasswordIcon />}
          label={t("PasswordInput")}
          name={"checkPassword"}
          value={currentPassword || ""}
          type={"password"}
          onChange={validateAndSetCheckPassword}
          onKeyDown={handleEnterKey}
          errors={checkPasswordErrors}
        />
      )}

      <ButtonRow>
        {contextEmail && !isGuest && customerResp.isSuccess && (
          <Button
            intercomTarget={"back-email-set"}
            type={"button"}
            onClick={() => clear(true, true)}
            buttonPosition={ButtonPosition.left}
          >
            {t("Back")}
          </Button>
        )}
        {(!contextEmail || requiresPassword || requiresPin) && (
          <Button
            intercomTarget={"next-email-set"}
            type={"button"}
            onClick={() => submit()}
            disabled={
              !validEmail ||
              // must change email before trying again after error
              (!customerResp.isSuccess && delayRetry) ||
              loginResp.isLoading
            }
            buttonPosition={ButtonPosition.right}
            icon={loginResp.isLoading || customerResp.isLoading ? <Spinny /> : undefined}
          >
            {t("Next")}
          </Button>
        )}
      </ButtonRow>
      {!isGuest && (requiresPassword || requiresPin) && (
        <div className={styles.forgotPassword}>
          <a href={routes.FORGOT_PASSWORD_CUSTOMER} onClick={navigateForgotPassword}>
            {t("Forgot your password?")}
          </a>
        </div>
      )}
    </div>
  );
}
