import React from "react";
import { LockOpen20Regular as UnlockIcon } from "@fluentui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "api/manualApi";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import type { LoginResponse } from "types/api";
import Input from "components/forms/input/Input";
import Button, { ColorVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import styles from "features/auth/LoginPage.module.scss";
import { useTranslation } from "react-i18next";
import { Form } from "components/forms/forms";
import * as routes from "constants/routes";

function LoginPage() {
  const { t } = useTranslation("auth");
  const { track } = userEvents();
  const [login] = useLoginMutation();

  const navigate = useNavigate();
  const location = useLocation();

  interface LocationState {
    from: {
      pathname: string;
    };
  }

  const toastRef = React.useContext(ToastContext);

  let from = (location.state as LocationState)?.from?.pathname || "/";
  // Don't redirect back to logout/login/services/bootstrapping.
  if (
    from === "/logout" ||
    from === "/login" ||
    from === "/bootstrapping" ||
    from === "/services" ||
    from === "logout" ||
    from === "login" ||
    from === "bootstrapping" ||
    from === "services" ||
    from === "" ||
    from === "/"
  ) {
    from = "/";
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append("username", "admin");
    const res = (await login(formData)) as LoginResponse;
    if (res?.error) {
      const errorMessage = res.error?.data?.error ? "Incorrect credentials" : "Api not reachable.";
      toastRef?.current?.addToast(errorMessage, toastCategory.error);
    } else {
      navigate(from, { replace: true });
    }
    if (process.env.REACT_APP_E2E_TEST !== "true") {
      track("Login");
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <Form className={styles.formWrapper} onSubmit={submit} intercomTarget={"login-form"}>
        <Input
          type="email"
          name={"email"}
          label={t("Email")}
          id={"email-field"}
          intercomTarget={"email-field"}
          autoFocus={true}
          autoComplete={"current-email"}
          aria-autocomplete={"list"}
        />
        <Input
          type="password"
          name={"password"}
          label={t("Password")}
          id={"password-field"}
          intercomTarget={"password-field"}
          autoComplete={"current-password"}
          aria-autocomplete={"list"}
        />
        <Button
          type="submit"
          icon={<UnlockIcon />}
          buttonColor={ColorVariant.success}
          id={"submit-button"}
          intercomTarget={"login-button"}
        >
          {t("Login")}
        </Button>
        <div className={styles.forgotPassword}>
          <a href={routes.FORGOT_PASSWORD_USER}>{t("Forgot your password?")}</a>
        </div>
      </Form>
    </div>
  );
}

export default LoginPage;
