import { Filter20Regular as FilterIcon } from "@fluentui/react-icons";
import Sidebar from "features/sidebar/Sidebar";
import { ColumnMetaData } from "features/table/types";
import FilterSection from "features/sidebar/sections/filter/FilterSection";
import { AndClause, OrClause } from "features/sidebar/sections/filter/filter";
import { InputColorVaraint, InputSizeVariant } from "components/forms/variants";
import { useTranslation } from "react-i18next";
import Select, { SelectOption } from "components/forms/select/Select";
import styles from "features/viewManagement/views.module.scss";

type ViewSidebarProps<T> = {
  expanded: boolean;
  onExpandToggle: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  allColumns: Array<ColumnMetaData<T>>;
  filterTemplate: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  filters: AndClause | OrClause;
  handleFilterUpdate: (filter: AndClause | OrClause) => void;
  setViewRefreshInterval?: (seconds: number) => void;
  viewRefreshInterval?: number;
};

export default function ViewsSidebar<T>(props: ViewSidebarProps<T>) {
  const { t } = useTranslation("common");

  const refreshIntervalOptions: SelectOption[] = [
    { label: t("never"), value: 0 },
    { label: "10 " + t("second", { count: 10 }), value: 10 },
    { label: "30 " + t("second", { count: 30 }), value: 30 },
    { label: "1 " + t("minute", { count: 1 }), value: 60 },
  ];

  const filterableColumns = props.allColumns.filter((column) => column.filterable);

  return (
    <Sidebar title={"Options"} closeSidebarHandler={() => props.onExpandToggle()}>
      {props.setViewRefreshInterval && (
        <Select
          intercomTarget={"select-refresh-interval-input"}
          label={t("Table refresh rate")}
          options={refreshIntervalOptions}
          value={refreshIntervalOptions.find((option) => option.value === props.viewRefreshInterval)}
          onChange={(newValue: unknown) => {
            if (props.setViewRefreshInterval) {
              props.setViewRefreshInterval((newValue as SelectOption).value as number)
            } else {
              console.error("Changing refresh interval is not enabled for this view");
            }
          }}
          colorVariant={InputColorVaraint.primary}
          sizeVariant={InputSizeVariant.normal}
        />
      )}
      <div>
        <h4 className={styles.sectionTitle}>
          <FilterIcon /> {t("Filter")}
        </h4>
        <FilterSection
          filterableColumns={filterableColumns}
          defaultFilter={props.filterTemplate}
          filters={props.filters}
          handleFilterUpdate={props.handleFilterUpdate}
        />
      </div>
    </Sidebar>
  );
}
