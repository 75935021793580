import React from "react";
import styles from "features/note/note.module.scss";
import { Dismiss20Regular as DismissIcon, Note24Regular as DefaultNoteIcon } from "@fluentui/react-icons";
import classNames from "classnames";

export enum NoteType {
  neutral = "neutral",
  info = "info",
  warning = "warning",
  error = "error",
  success = "success",
  brandPrimary = "brandPrimary",
  ghost = "ghost",
  disabled = "disabled",
}

const noteTypeClasses = new Map<NoteType, string>([
  [NoteType.neutral, styles.neutral],
  [NoteType.info, styles.info],
  [NoteType.success, styles.success],
  [NoteType.warning, styles.warning],
  [NoteType.error, styles.error],
  [NoteType.brandPrimary, styles.brandPrimary],
  [NoteType.ghost, styles.ghost],
  [NoteType.disabled, styles.disabled],
]);

type NoteProps = {
  title?: string;
  noteType?: NoteType;
  icon?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  dismissible?: boolean;
  intercomTarget?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  clickDisabled?: boolean;
};

function Note(props: NoteProps) {
  const [dismissed, setDismissed] = React.useState(false);
  if (dismissed) return null;
  return (
    <div
      onClick={props.clickDisabled ? undefined : props.onClick}
      className={classNames(
        styles.noteWrapper,
        noteTypeClasses.get(props.noteType || NoteType.neutral),
        { [styles.clickable]: !!props.onClick && !props.clickDisabled },
        { [styles.clickDisabled]: props.clickDisabled },
        props.className,
      )}
      data-intercom-target={props.intercomTarget}
      data-testid={props.intercomTarget}
    >
      {props.title && (
        <div className={styles.noteTitleWrapper}>
          <div className={styles.noteTitleIcon}>{props.icon || <DefaultNoteIcon />}</div>
          <div className={styles.noteTitle}>{props.title}</div>
          {props.dismissible && (
            <div className={styles.noteDismissIcon} onClick={() => setDismissed(true)}>
              {<DismissIcon />}
            </div>
          )}
        </div>
      )}
      <div className={styles.noteContent}>{props.children}</div>
    </div>
  );
}

export default Note;
