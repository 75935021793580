import React from "react";
import api from "api/api";
import { FailureEventRecord, GetAllFailureEventsResp } from "api/api.generated";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import { useEffect } from "react";
import { getErrorResponse } from "components/errors/validationErrors";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";

const columns: ColumnMetaData<FailureEventRecord>[] = [
  {
    heading: "FailureEventSystem",
    type: "TextCell",
    key: "system",
    valueType: "string",
    headingLocalizePackage: "failureEvents",
  },
  {
    heading: "SolvedAt",
    type: "DateCell",
    key: "solvedAt",
    valueType: "datetime",
    headingLocalizePackage: "failureEvents",
  },
  {
    heading: "CreatedAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
  },
  {
    heading: "FailureEventData",
    type: "LongTextCellWithModalTooltip",
    key: "value",
    valueType: "string",
    headingLocalizePackage: "failureEvents",
  },
];

function FailureEventsPage() {
  const { t } = useTranslation("failureEvents");
  const toastRef = React.useContext(ToastContext);

  const failureEventsResponse = api.useGetAllFailureEventsQuery<{
    data: GetAllFailureEventsResp;
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>();

  const data = failureEventsResponse.data || [];

  useEffect(() => {
    const err = getErrorResponse(failureEventsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [failureEventsResponse.error]);

  const tableControls = <TableControlSection intercomTarget={"table-page-controls"}></TableControlSection>;

  return (
    <TablePageTemplate title={t("FailureEvents")} titleContent={""} tableControls={tableControls}>
      <Table
        intercomTarget={"failure-events-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={columns}
        isLoading={
          failureEventsResponse.isLoading || failureEventsResponse.isFetching || failureEventsResponse.isUninitialized
        }
      />
    </TablePageTemplate>
  );
}

export default FailureEventsPage;
