import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate } from "react-router-dom";
import { Add20Regular as AddIcon, Send20Regular as SendIcon } from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Form, Input, InputRow, Select } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import api, { Address, BankAccount, CountryOption, InputError, getCountriesAsOptions } from "api/api";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useAppSelector } from "store/hooks";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { FormToJson } from "api/apiUtils";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import React from "react";
import styles from "pages/bankAccounts/BankAccountsPage.module.scss";
import { useSelector } from "react-redux";

function CreateBankAccountModal() {
  const { t: tb } = useTranslation("bank");
  const { t: tv } = useTranslation("validation");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>("");
  const toastRef = React.useContext(ToastContext);
  const writePermission = useHasPermission(["companyBankAccountsWriteRead"]);
  const [addressCountry, setAddressCountry] = useState<CountryOption>();
  const [bankCountry, setBankCountry] = useState<CountryOption>();

  // fetch the countries
  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  const [
    createBankAccount,
    { error: createBankAccountErrors, isLoading: loadingCreateBankAccount, isSuccess: isSuccessCreateBankAccount },
  ] = api.useCreateCompanyBankAccountMutation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Get all the data from the form
    const formJson = FormToJson<BankAccount>(event);
    formJson.address = FormToJson<Address>(event, "address");

    createBankAccount({ companyId: selectedCompanyId || "", bankAccount: formJson });
  };

  useEffect(() => {
    if (isSuccessCreateBankAccount) {
      toastRef?.current?.addToast(t("newBankAccountSuccessfullyCreated"), toastCategory.success);
      navigate(-1);
    }
  }, [isSuccessCreateBankAccount]);

  useEffect(() => {
    const err = getErrorResponse(createBankAccountErrors);
    setErrMessage(err?.message || "");
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [createBankAccountErrors]);

  return (
    <PopoutPageTemplate title={tb("AddBankAccount")} show={true} onClose={() => navigate(-1)} icon={<AddIcon />}>
      {!writePermission && (
        <div className={styles.modalContentWrapper}>
          <Note noteType={NoteType.error}>{t("youLackPermissionsView")}</Note>
        </div>
      )}
      {writePermission && (
        <div className={styles.modalContentWrapper}>
          <Form intercomTarget={"invite-user-form"} onSubmit={handleSubmit}>
            <Input label={tb("IbanInput")} name={"iban"} type={"text"} errors={Tie("IBAN", ie, ["bank"])} />
            <Input label={tb("BicInput")} name={"bic"} type={"text"} errors={Tie("BIC", ie, ["bank"])} />
            <Select
              intercomTarget={"select-countries"}
              label={tb("bankCountry")}
              name={"countryIso3"}
              options={countryOptions}
              value={bankCountry}
              onChange={(newValue: unknown) => {
                setBankCountry(countryOptions.find((country) => country.value === (newValue as CountryOption).value));
              }}
              errors={Tie(
                "CountryISO3",
                ie,
                ["common"],
                ["BankAccountRecord.RecordBaseComplete.RecordBase.CountryISO3"],
              )}
            />
            <h4>{tb("BankAccountOwnerAddress")}</h4>
            <InputRow verticalAlign="top">
              <Input
                label={t("StreetName")}
                name={"address.streetName"}
                type={"text"}
                errors={Tie("StreetName", ie, ["common"])}
              />
              <Input
                label={t("BuildingNumber")}
                name={"address.buildingNumber"}
                type={"text"}
                errors={Tie("BuildingNumber", ie, ["common"])}
              />
            </InputRow>
            <InputRow verticalAlign="top">
              <Input
                label={t("TownName")}
                name={"address.townName"}
                type={"text"}
                errors={Tie("TownName", ie, ["common"])}
              />
              <Input
                label={tb("PostCodeInput")}
                name={"address.postCode"}
                type={"text"}
                errors={Tie("PostCode", ie, ["common"])}
              />
            </InputRow>
            <Select
              intercomTarget={"select-countries"}
              label={tb("CountryInput")}
              name={"address.countryIso3"}
              options={countryOptions}
              value={addressCountry}
              onChange={(newValue: unknown) => {
                setAddressCountry(
                  countryOptions.find((country) => country.value === (newValue as CountryOption).value),
                );
              }}
              errors={Tie("CountryISO3", ie, ["common"], ["BankAccountRecord.RecordBaseComplete.Address.CountryISO3"])}
            />
            {/* If there are errors then add a Note */}
            {createBankAccountErrors && (
              <Note noteType={NoteType.error} title={t("Error")}>
                {tv(errMessage)}
              </Note>
            )}
            <ButtonRow>
              <Button
                intercomTarget={"save-permissions-button"}
                buttonColor={ColorVariant.success}
                type={"submit"}
                disabled={loadingCreateBankAccount || !writePermission}
                icon={loadingCreateBankAccount ? <Spinny /> : <SendIcon />}
              >
                {t("Save")}
              </Button>
            </ButtonRow>
          </Form>
        </div>
      )}
    </PopoutPageTemplate>
  );
}

export default CreateBankAccountModal;
