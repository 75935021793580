import { DEV_BACKEND_PORT, DEV_FRONTEND_PORT, REST_API_PATHNAME } from "constants/backend";
import { BASE_PATHNAME_PREFIX } from "constants/subpath-support";

const isRunningOnSubpath = typeof window !== "undefined" && window.location.pathname.startsWith(BASE_PATHNAME_PREFIX);

const basePathnamePrefix = isRunningOnSubpath ? BASE_PATHNAME_PREFIX : "";

const isRunningOnSecureConnection = typeof window !== "undefined" && window.location.protocol.startsWith("https");

const buildBaseUrl = () => {
  if (typeof window === "undefined") {
    // Handle the Node.js environment or provide a default URL
    return `//default-url-if-needed${basePathnamePrefix}`;
  }

  // Below code will run only in the browser environment
  const address =
    window.location.port === DEV_FRONTEND_PORT.toString()
      ? "//" + window.location.hostname + ":" + DEV_BACKEND_PORT.toString()
      : "//" + window.location.host;

  return `//${address}${basePathnamePrefix}`;
};

export const getRestEndpoint = () => buildBaseUrl() + REST_API_PATHNAME;

export const getWsEndpoint = () => {
  const protocol = isRunningOnSecureConnection ? "wss" : "ws";
  const baseApiUrl = buildBaseUrl();
  return `${protocol}:${baseApiUrl}${REST_API_PATHNAME}`;
};
