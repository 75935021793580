import Modal from "components/dialogue/Modal";
import { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";

interface ModalContextType {
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
}

const defaultValues: ModalContextType = {
  showModal: () => {},
  closeModal: () => {},
};

const ModalContext = createContext<ModalContextType>(defaultValues);

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);

  const showModal = useCallback((content: ReactNode) => {
    setModalContent(content);
  }, []);

  const closeModal = useCallback(() => {
    setModalContent(null);
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      {modalContent && <Modal closeModal={closeModal}>{modalContent}</Modal>}
    </ModalContext.Provider>
  );
};
