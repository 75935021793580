import api, { DemoshopPaymentSessionNew, PaymentSessionRecord } from "api/api";
import styles from "pages/demo/demoShopPage.module.scss";
import { ReactComponent as PayWithEdenIcon } from "pages/demo/images/PayWithIcon.svg";
import { useNavigate } from "react-router";
import { product as ProductProps } from "pages/demo/products";
import { ServerErrorResultType } from "components/errors/errors";
import { useTranslation } from "react-i18next";
import Button, { SizeVariant } from "components/buttons/Button";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getErrorResponse } from "components/errors/validationErrors";
import * as routes from "constants/routes";

type Props = {
  product: ProductProps;
};

export default function PayEdenButton(props: Props) {
  const { product } = props;
  const { t } = useTranslation("demoShop");
  const navigate = useNavigate();

  const [createPaymentSession] = api.useDemoshopCreatePaymentSessionMutation();

  function eurFloatPriceToCents() {
    return Math.round(product.price * 100);
  }

  const handlePayButton = () => {
    const ps: DemoshopPaymentSessionNew = {
      amount: eurFloatPriceToCents(),
      currencyIso3: "EUR",
      description: product.name,
      redirectUrl: routes.DEMO_SHOP + "/" + product.id + "?success=1",
    };

    createPaymentSession(ps)
      .unwrap()
      .catch((err: ServerErrorResultType | FetchBaseQueryError) => {
        let e = (err as ServerErrorResultType)?.data?.errors?.server?.[0].description;

        if (!e) {
          e = getErrorResponse(err as FetchBaseQueryError)?.message;
        }

        console.error("Error creating payment session:", e || "");

        // TODO map to user friendly error
        // for now just take the server error string as is
        alert(("Error creating payment session " + e) as string);
      })
      .then((res: PaymentSessionRecord | void) => {
        if (!res) {
          return;
        }

        console.log("Payment session created: ", res, "redirecting to checkout");

        // navigate to checkout page
        navigate(routes.CHECKOUT + "/" + res.paymentSessionId);
      });
  };

  return (
    <Button
      onClick={handlePayButton}
      buttonSize={SizeVariant.xl}
      className={styles.checkoutButton}
      intercomTarget={"pay-button"}
      icon={<PayWithEdenIcon />}
    >
      {t("Pay with Mion")}
    </Button>
  );
}
