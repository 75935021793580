import { Link16Regular as LinkIcon } from "@fluentui/react-icons";
import classNames from "classnames";
import { ColorVariant, ExternalLinkButton, LinkButton, SizeVariant } from "components/buttons/Button";
import styles from "components/table/cells/cell.module.scss";
import { useLocation } from "react-router-dom";
import { userEvents } from "utils/userEvents";

interface LinkCell {
  text: string;
  link: string;
  icon?: JSX.Element;
  internal?: boolean;
  className?: string;
  totalCell?: boolean;
  disabled?: boolean;
  textOutside?: boolean;
}

function LinkCell(props: LinkCell) {
  const { track } = userEvents();
  const location = useLocation();
  return (
    <div className={classNames(styles.cell, styles.numericCell, styles.linkCell, props.className)}>
      {!props.totalCell && (
        <div
          className={classNames(styles.current, styles.text, styles.link, {
            [styles.linkSingleLine]: props.textOutside,
          })}
        >
          {!props.internal && (
            <ExternalLinkButton
              // Link to external site
              href={props.link}
              target="_blank"
              onClick={() => {
                track("Link Cell Clicked", { href: props.link });
              }}
              buttonSize={SizeVariant.tiny}
              buttonColor={ColorVariant.primary}
              icon={props.icon ? props.icon : <LinkIcon />}
              intercomTarget={"link-cell-button"}
              disabled={props.disabled}
            >
              {props.textOutside ? "" : props.text}
            </ExternalLinkButton>
          )}
          {props.internal && (
            <LinkButton
              // Link to internal page
              to={props.link}
              state={{ background: location?.pathname || "/" }}
              buttonSize={SizeVariant.tiny}
              buttonColor={ColorVariant.success}
              icon={props.icon ? props.icon : <LinkIcon />}
              intercomTarget={"link-cell-button-internal"}
              disabled={props.disabled}
            >
              {props.textOutside ? "" : props.text}
            </LinkButton>
          )}
          <div>{props.textOutside ? props.text : ""}</div>
        </div>
      )}
    </div>
  );
}

export default LinkCell;
