import { Input, InputRow } from "components/forms/forms";
import { Tie } from "components/errors/validationErrors";
import api, { CountryOption, CustomerWithDetails, getCountriesAsOptions, InputError } from "api/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddressFormFields from "features/customerFormFields/addressFormFields";

type CustomerFormFieldsProps = {
  disabled?: boolean;
  ie?: InputError[];
  country?: CountryOption;
  setCountry: (country?: CountryOption) => void;
  filledCustomer?: CustomerWithDetails;
  lockedNameAndBirthday?: boolean;
};

// Common form fields for customer create/edit
export default function CustomerFormFields(props: CustomerFormFieldsProps) {
  const { t } = useTranslation("checkout");
  const ie = props.ie;
  const disabled = props.disabled == true;

  // for now address is always required
  const addressRequired = true;

  // fetch the countries
  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  const getCurrentCountry = () => {
    if (props.country) {
      return props.country;
    }

    // pre filled
    return countryOptions.find((country) => country.value === props.filledCustomer?.residenceCountryIso3);
  };

  return (
    <>
      {/* use readOnly instead of disabled to submit the value */}
      <InputRow verticalAlign={"top"}>
        <Input
          label={t("FirstNameInput")}
          type={"text"}
          name={"firstName"}
          required={true}
          errors={Tie("FirstName", ie, ["checkout"])}
          disabled={disabled}
          readOnly={props.lockedNameAndBirthday}
          defaultValue={props.filledCustomer?.firstName}
        />
        {/* backend has no middlename for now
        <Input
          label={t("MiddleNameInput")}
          type={"text"}
          name={"middleName"}
          errors={Tie("MiddleName", ie, ["checkout"])}
          disabled={disabled}
          defaultValue={props.filledCustomer?.secondLastName}
        />
        */}
        <Input
          label={t("LastNameInput")}
          type={"text"}
          name={"lastName"}
          required={true}
          errors={Tie("LastName", ie, ["checkout"])}
          disabled={disabled}
          readOnly={props.lockedNameAndBirthday}
          defaultValue={props.filledCustomer?.lastName}
        />
      </InputRow>
      <InputRow verticalAlign={"top"}>
        <Input
          label={t("DateOfBirthInput")}
          type={"date"}
          name={"birthDate"}
          max={new Date().toISOString().split("T")[0]}
          min={"1900-01-01"}
          required={true}
          errors={Tie("BirthDate", ie, ["checkout"])}
          disabled={disabled}
          readOnly={props.lockedNameAndBirthday}
          defaultValue={props.filledCustomer?.birthDate?.split("T")[0] || ""}
        />
      </InputRow>
      <AddressFormFields
        ie={ie}
        disabled={disabled}
        filledAddress={props.filledCustomer?.address}
        addressRequired={addressRequired}
        country={getCurrentCountry()}
        setCountry={props.setCountry}
      />
    </>
  );
}
