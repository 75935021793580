import { useEffect } from "react";

const CHAT_BASE_URL = "https://chat.mion.group";

const merchantUIInstance = "merchant";
const checkoutInstance = "checkout";

const tokens = {}
tokens[merchantUIInstance] = "XQ4VEkuytUWoJadPABMU9X3E";
tokens[checkoutInstance] = "Mp8RKZCbmiunXBn7sg11rG47";

export function LivechatMerchantUI() {
  return Livechat(merchantUIInstance);
}

export function LivechatCheckout() {
  return Livechat(checkoutInstance);
}

export function LivechatToggle() {
  if (!window.$chatwoot){
    console.error("Chat not loaded on this page.")
    return;
  }

  window.$chatwoot.toggle();
}

export function LivechatOpen() {
  if (!window.$chatwoot){
    console.error("Chat not loaded on this page.")
    return;
  }

  window.$chatwoot.toggle("open");
}

export function LivechatClose() {
  if (!window.$chatwoot){
    console.error("Chat not loaded on this page.")
    return;
  }

  window.$chatwoot.toggle("close");
}

function Livechat(instance) {
  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true, // hide bubble because we have a custom button
      position: "right",
      locale: "en",
      type: "standard",
      // useBrowserLanguage: false, // Set widget language from user's browser
    };

    const g = document.createElement("script");
    g.src = CHAT_BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    document.body.appendChild(g);

    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: tokens[instance],
        baseUrl: CHAT_BASE_URL,
      });
    };

    // Cleanup function to remove script if component unmounts
    return () => {
      document.body.removeChild(g);
    };
  }, []);

  return null;
}
