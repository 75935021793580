import { CustomerRecord, UserRecordInternal } from "api/api.generated";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// https://www.chatwoot.com/hc/user-guide/articles/1677587234-how-to-send-additional-user-information-to-chatwoot-using-sdk

// Hook to set chatwoot data of the customer
export function useLivechatCustomer(email?: string, customer?: CustomerRecord, paymentSessionId?: string) {
  const [chatReady, setChatReady] = useState<boolean>(false);

  // set data on data change
  useEffect(() => {
    if (chatReady) {
      setCustomerData(email, customer, paymentSessionId);
    }
  }, [email, customer, paymentSessionId, chatReady]);

  // clean chatwoot user data on unmount
  useEffect(() => {
    // wait for ready
    window.addEventListener("chatwoot:ready", function () {
      setChatReady(true);
    });

    return () => {
      if (window.$chatwoot) {
        window.$chatwoot.reset();
      }
    };
  }, []);
}

function setCustomerData(email?: string, customer?: CustomerRecord, paymentSessionId?: string) {
  if (!window.$chatwoot) {
    return;
  }

  let desc = "Type: customer. ";

  if (customer) {
    desc += "Guest: ";
    desc += customer.isGuest ? "Yes. " : "No. ";
  }

  desc += paymentSessionId ? "Current payment session id: " + paymentSessionId : "No payment session id available.";

  if (customer) {
    window.$chatwoot.setUser(customer.customerId, {
      email: customer.email,
      name: customer.firstName + " " + customer.lastName,
      description: desc,
    });
  } else if (email) {
    // only has email available
    window.$chatwoot.setUser(email, {
      email: email,
      description: desc,
    });
  } else {
    window.$chatwoot.setUser("unknown_" + uuidv4(), {
      name: "Unknown",
      description: desc,
    });
  }
}

// Hook to set chatwoot data for logged in merchant user
export function useLivechatMerchantUser(user?: UserRecordInternal, companyName?: string) {
  const [chatReady, setChatReady] = useState<boolean>(false);

  // set data on data change
  useEffect(() => {
    if (chatReady) {
      setUserData(user, companyName);
    }
  }, [user, companyName, chatReady]);

  // clean chatwoot user data on unmount
  useEffect(() => {
    // wait for ready
    window.addEventListener("chatwoot:ready", function () {
      setChatReady(true);
    });

    return () => {
      if (window.$chatwoot) {
        window.$chatwoot.reset();
      }
    };
  }, []);
}

function setUserData(user?: UserRecordInternal, companyName?: string) {
  if (!window.$chatwoot) {
    return;
  }

  const desc = "Type: user.";

  if (user) {
    window.$chatwoot.setUser(user.userId, {
      email: user.email,
      name: user.firstName + " " + user.lastName,
      description: desc,
      company_name: companyName,
    });
  } else {
    window.$chatwoot.setUser("unknown_" + uuidv4(), {
      name: "Unknown",
    });
  }
}
