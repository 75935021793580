import { LivechatCheckout } from "components/support/livechat";
import { Outlet } from "react-router-dom";

function CheckoutLayout() {
  return (
    <>
      <LivechatCheckout />
      <Outlet />
    </>
  );
}

export default CheckoutLayout;
