import { useTranslation } from "react-i18next";
import { LivechatOpen } from "components/support/livechat";
import styles from "components/support/contactSupportText.module.scss";
import classNames from "classnames";

export function ContactSupportText() {
  const { t } = useTranslation("common");
  const email = "support@mion.group";

  function openChat(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    LivechatOpen();
  }

  return (
    <div className={classNames(styles.contactSupportText, styles.textBottom)}>
      {t("contactSupportText1")} <a href={"mailto:" + email}>{email}</a> {t("contactSupportText2")}
      <a href="#" onClick={openChat}>
        {t("chat")}
      </a>
      .
    </div>
  );
}
