import styles from "features/spinny/spinny.module.scss";
import { ArrowSync16Filled as Icon } from "@fluentui/react-icons";
import classNames from "classnames";

type SpinnyProps = {
  resize?: boolean;
};

const Spinny = (props: SpinnyProps) => (
  <Icon className={classNames(styles.spinny, { [styles.resize]: props.resize })} />
);

export default Spinny;
