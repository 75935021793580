import React, { useState } from "react";
import styles from "features/toast/toast.module.css";
import {
  ThumbLike20Regular as SuccessIcon,
  Dismiss20Regular as CloseIcon,
  ErrorCircle20Regular as ErrorIcon,
  Warning20Regular as WarnIcon,
  Info20Regular as InfoIcon,
} from "@fluentui/react-icons";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export enum toastCategory {
  success,
  warn,
  error,
  info,
}

export interface ToastItem {
  message: string;
  category: toastCategory;
  uuid: string;
}

const Toast = React.forwardRef(function Toasts(_, ref) {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  React.useImperativeHandle(ref, () => ({
    addToast(message: string, category: toastCategory) {
      addToast(message, category);
    },
  }));

  const addToast = (message: string, category: toastCategory) => {
    const newToast: ToastItem = {
      uuid: uuidv4(),
      message,
      category,
    };
    setToasts((prevToasts) => [...prevToasts, newToast]);

    setTimeout(() => {
      removeToast(newToast.uuid);
    }, 3000);
  };

  const removeToast = (uuid: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.uuid !== uuid));
  };

  return (
    <div className={styles.toastContainer} data-testid={"merchantServices-toast"}>
      <TransitionGroup>
        {toasts.map((toast) => (
          <CSSTransition
            key={toast.uuid}
            timeout={1000}
            classNames={{
              enter: styles.toastEnter,
              enterActive: styles.toastEnterActive,
              exit: styles.toastExit,
              exitActive: styles.toastExitActive,
            }}
          >
            <div
              className={classNames(
                styles.toast,
                { [styles.success]: toast.category === toastCategory.success },
                { [styles.warn]: toast.category === toastCategory.warn },
                { [styles.error]: toast.category === toastCategory.error },
                { [styles.info]: toast.category === toastCategory.info },
              )}
            >
              <div className={styles.icon}>
                <div className={classNames(styles.iconBackground)}>
                  {toast.category === toastCategory.info && <InfoIcon />}
                  {toast.category === toastCategory.success && <SuccessIcon />}
                  {toast.category === toastCategory.warn && <WarnIcon />}
                  {toast.category === toastCategory.error && <ErrorIcon />}
                </div>
              </div>
              <div>
                <p className={styles.message}>{toast.message}</p>
              </div>
              <div>
                <div className={styles.close} onClick={() => removeToast(toast.uuid)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
});

export default Toast;

export type addToastHandle = {
  addToast: (message: string, category: toastCategory) => void;
};
