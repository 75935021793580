import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import {
  ArrowSync20Regular as RefreshIcon,
  CheckmarkCircleRegular as CheckIcon,
  ErrorCircleRegular as ErrorIcon,
  FlashCheckmark20Regular as IndicatorIcon,
  FluentIconsProps,
  PauseRegular as PauseIcon,
  WarningRegular as WarningIcon,
} from "@fluentui/react-icons";
import {coreService, serviceMismatch} from "api/manualApiTypes";
import {SectionContainer, SectionContainerColor} from "features/section/SectionContainer";
import styles from "features/connectionStatus/connection-status.module.scss";
import {useEffect, useState} from "react";
import {useGetServicesQuery} from "api/manualApi";
import Button, {ButtonPosition, ColorVariant, SizeVariant} from "components/buttons/Button";
import {userEvents} from "utils/userEvents";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

//list of service types that are monitored for connection and shown in the connection status modal
const monitoredServiceTypes = ["NotifierService", "SlackService", "ScheduledService"];

export enum servicesConnectionStatus {
  desired,
  someMismatches,
  allMismatches,
}

//matches the values in internal\serviceshelpers\constants.go
enum serviceStatus {
  disabled = 0,
  ok = 1,
  pending = 2,
  initializing = 4,
  fault = 5,
}

const serviceStatusIcon = new Map([
  [serviceStatus.disabled, PauseIcon],
  [serviceStatus.ok, CheckIcon],
  [serviceStatus.pending, RefreshIcon],
  [serviceStatus.initializing, RefreshIcon],
  [serviceStatus.fault, ErrorIcon],
]);

const serviceStatusColor = new Map([
  [serviceStatus.disabled, SectionContainerColor.disabled],
  [serviceStatus.ok, SectionContainerColor.success],
  [serviceStatus.pending, SectionContainerColor.primary],
  [serviceStatus.initializing, SectionContainerColor.primary],
  [serviceStatus.fault, SectionContainerColor.error],
]);

const serviceStatusLocString = new Map([
  [serviceStatus.disabled, "disabled"],
  [serviceStatus.ok, "ok"],
  [serviceStatus.pending, "pending"],
  [serviceStatus.initializing, "initializing"],
  [serviceStatus.fault, "fault"],
]);

//common interface for monitored connection status
export interface monitoredServiceGroup {
  monitoredServices: coreService[];
  monitoredServiceMismatches: serviceMismatch[];
  //connection status for the service group (core services)
  servicesConnectionStatus: servicesConnectionStatus;
}

function mapServiceGroupToMonitoredGroup(
  serviceGroup: coreService[] | undefined,
  serviceMismatches: serviceMismatch[] | undefined,
): monitoredServiceGroup | undefined {
  if (!serviceGroup) {
    return undefined;
  }

  const monitoredServicesData = serviceGroup.filter((s) => monitoredServiceTypes.includes(s.typeString));
  const monitoredServiceMismatchesData = !serviceMismatches
    ? []
    : serviceMismatches.filter((s) => monitoredServiceTypes.includes(s.typeString));

  let groupConnectionStatus: servicesConnectionStatus;

  //caclulate the connection status of core services
  switch (monitoredServiceMismatchesData.length) {
    case 0:
      //no mismatches in monitored services
      groupConnectionStatus = servicesConnectionStatus.desired;
      break;
    case monitoredServicesData.length:
      //same amount of mismatches as monitored services, so all are mismatch
      groupConnectionStatus = servicesConnectionStatus.allMismatches;
      break;
    default:
      groupConnectionStatus = servicesConnectionStatus.someMismatches;
      break;
  }

  return {
    monitoredServices: monitoredServicesData,
    monitoredServiceMismatches: monitoredServiceMismatchesData,
    servicesConnectionStatus: groupConnectionStatus,
  } as monitoredServiceGroup;
}

function getServicesConnectionStatusSectionTitleColor(
  connectionStatus: servicesConnectionStatus | undefined,
): SectionContainerColor {
  switch (connectionStatus) {
    case servicesConnectionStatus.desired:
      return SectionContainerColor.success;
    case servicesConnectionStatus.allMismatches:
      return SectionContainerColor.error;
    case servicesConnectionStatus.someMismatches:
      return SectionContainerColor.warning;
    default:
      return SectionContainerColor.error;
  }
}

function getServicesConnectionStatusIcon(
  status: serviceStatus | undefined,
  connectionStatus: servicesConnectionStatus | undefined,
): React.FC<FluentIconsProps> {
  switch (connectionStatus) {
    case servicesConnectionStatus.desired:
      return CheckIcon;
    case servicesConnectionStatus.allMismatches:
      return ErrorIcon;
    case servicesConnectionStatus.someMismatches:
      return WarningIcon;
    default:
      return ErrorIcon;
  }
}

function connectionStatusModal() {
  const { t } = useTranslation("connectionStatus");
  const { track } = userEvents();
  const navigate = useNavigate();

  const [coreConnectionStatuses, setCoreConnectionStatuses] = useState<monitoredServiceGroup | undefined>(undefined);

  const {
    data: servicesData,
    error: servicesDataFetchError,
    refetch: refetchServicesData,
  } = useGetServicesQuery(undefined);

  const [activeSections, setActiveSections] = useState([] as string[]);
  const [fetchError, setFetchError] = useState(false);

  const serviceGroupSectionHandler = (key: string) => {
    return () => {
      track(`Toggle Connection Status Section`, {
        section: key,
        expanded: !(activeSections?.findIndex((n) => n === key) > -1),
      });

      let updatedactiveSections = [...activeSections];

      if (updatedactiveSections.findIndex((n) => n === key) > -1) {
        //hide
        updatedactiveSections = updatedactiveSections.filter((nId) => nId != key);
      } else {
        //show
        updatedactiveSections.push(key);
      }

      setActiveSections(updatedactiveSections);
    };
  };

  useEffect(() => {
    if (servicesDataFetchError) {
      setFetchError(true);
    } else {
      setFetchError(false);
    }

    //arrange the data to show on ui
    setCoreConnectionStatuses(
      mapServiceGroupToMonitoredGroup(servicesData?.coreServices, servicesData?.serviceMismatches),
    );
  }, [servicesData, servicesDataFetchError]);

  return (
    <PopoutPageTemplate title={t("ConnectionStatus")} show={true} onClose={() => navigate(-1)} icon={<IndicatorIcon />}>
      <div className={styles.connectionStatusContent}>
        <div className={styles.refreshContainer}>
          {fetchError && (
            <div className={styles.errorContainer}>
              <ErrorIcon className={styles.errorIcon} />
              <span>{t("connectionLost")}</span>
            </div>
          )}
          <Button
            intercomTarget="refresh-page-data"
            buttonColor={ColorVariant.primary}
            buttonSize={SizeVariant.small}
            buttonPosition={ButtonPosition.right}
            icon={<RefreshIcon />}
            onClick={() => {
              refetchServicesData();
            }}
          ></Button>
        </div>
        {!fetchError &&
          coreConnectionStatuses &&
          [coreConnectionStatuses].map((serviceGroup: monitoredServiceGroup | undefined) => {
            if (!serviceGroup) return;

            const sectionKey = "core";
            const title = t("coreServices");
            const desiredStatus = undefined;

            return (
              <SectionContainer
                key={sectionKey}
                intercomTarget={"connection-status-section"}
                title={title}
                icon={getServicesConnectionStatusIcon(desiredStatus, serviceGroup?.servicesConnectionStatus)}
                colorVariant={getServicesConnectionStatusSectionTitleColor(desiredStatus)}
                handleToggle={serviceGroupSectionHandler(sectionKey)}
                expanded={activeSections.includes(sectionKey)}
                tightContent={true}
              >
                {serviceGroup?.monitoredServices &&
                  serviceGroup?.monitoredServices.map((service) => {
                    let sStatus: serviceStatus = service.status;

                    if (
                      service.status != serviceStatus.initializing &&
                      serviceGroup.monitoredServiceMismatches.findIndex((s) => s.type === service.type) > -1
                    ) {
                      sStatus = serviceStatus.fault;
                    }

                    const subSectionKey = sectionKey + "_" + service.type;

                    return (
                      <SectionContainer
                        key={subSectionKey}
                        intercomTarget={"connection-status-sub-section"}
                        title={t(`serviceTypeNames.${service.typeString}`)}
                        icon={serviceStatusIcon.get(sStatus) ?? ErrorIcon}
                        colorVariant={serviceStatusColor.get(sStatus)}
                        handleToggle={serviceGroupSectionHandler(subSectionKey)}
                        expanded={activeSections.includes(subSectionKey)}
                      >
                        {t(`statusDescriptions.${serviceStatusLocString.get(sStatus)}`) +
                          "\n" +
                          (service.status != serviceStatus.disabled
                            ? t("uptime") + ": " + service.uptimePercentageString + "%"
                            : "")}
                      </SectionContainer>
                    );
                  })}
              </SectionContainer>
            );
          })}
      </div>
    </PopoutPageTemplate>
  );
}

export default connectionStatusModal;
