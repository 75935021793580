import { FormEvent, useContext, useEffect } from "react";
import { StepProps } from "pages/consumerCompliance/modules/types";
import { NavigateButtons } from "pages/consumerCompliance/modules/ComplianceSteps";
import { ComplianceCheckContext } from "pages/consumerCompliance/ComplianceCheckPage";
import styles from "pages/consumerCompliance/modules/complete/Complete.module.scss";
import { useTranslation } from "react-i18next";

function Complete(props: StepProps) {
  const { returnFunction } = useContext(ComplianceCheckContext);

  const { t } = useTranslation("consumerCompliance");

  const handleBack = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.step > 0) {
      props.setStep(props.step - 1);
    }
  };

  const handleNext = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.index < props.steps.length - 1) {
      props.setStep(props.step + 1);
    }

    // return
    returnFunction();
  };

  useEffect(() => {
    // TODO: Check with the backend is the user has completed the steps. And if he was successfully validated.
    if (props.step === props.steps.length - 1) {
      props.setCompletedSteps((prevSteps) => [...prevSteps, "complete"]);
    }
  }, [props.step]);

  return (
    <div>
      <div className={styles.completeContainer}>{t("CompleteInfoText")}</div>
      <NavigateButtons step={props.step} handleBack={handleBack} handleNext={handleNext} />
    </div>
  );
}

export default Complete;
