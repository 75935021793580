import api from "api/api";
import {
  Options20Regular as OptionsIcon,
  ArrowSync20Regular as RefreshIcon,
  ArrowDownload20Regular as DownloadCsvIcon,
} from "@fluentui/react-icons";
import { GetPaymentSessionsByCompanyIdResp, PaymentSessionListRecord } from "api/api.generated";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { usePagination } from "components/table/pagination/usePagination";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import { formatAmountFunc, formatTranslateFunc } from "features/table/columnFormatFunctions";
import { useTableColumns } from "features/table/tableColumnHooks";
import ToastContext from "features/toast/context";
import { useEffect, useState } from "react";
import { getErrorResponse } from "components/errors/validationErrors";
import { toastCategory } from "features/toast/Toasts";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import React from "react";
import Button, { ColorVariant } from "components/buttons/Button";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { createCsvFile } from "utils/JsonTableToCsv";

const columns: ColumnMetaData<PaymentSessionListRecord>[] = [
  {
    heading: "description",
    type: "TextCell",
    key: "description",
    valueType: "string",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
    // locked: true,
    // maxWidthPx: ColumnWidth.w200,
  },
  {
    heading: "customerPaidAmount",
    type: "TextCell",
    key: "amountFloat",
    valueType: "currency",
    formatFunction: formatAmountFunc,
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
    // filterHelpText: "amountFloatFilterHelpText",
    filterFormatFunc: (parameter) => {
      // assume currency is always divided by 100 subunits!
      // user sets amount as eur, convert to cents for filtering
      return ((parameter as number) * 100).toString();
    },
  },
  {
    heading: "settledAmount",
    type: "TextCell",
    key: "settledAmountFloat",
    valueType: "currency",
    formatFunction: formatAmountFunc,
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
    filterFormatFunc: (parameter) => {
      // assume currency is always divided by 100 subunits!
      // user sets amount as eur, convert to cents for filtering
      return ((parameter as number) * 100).toString();
    },
  },
  {
    heading: "status",
    type: "TextCell",
    key: "status",
    valueType: "string",
    formatFunction: formatTranslateFunc,
    formatLocalizePackage: "payment",
    headingLocalizePackage: "payment",
    // no filter by status because it's hardcoded
  },
  {
    heading: "paidAt",
    type: "DateCell",
    key: "paidAt",
    valueType: "datetime",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
  },
  {
    heading: "customer",
    type: "TextCell",
    key: "paymentCustomerFullName",
    valueType: "string",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
  },
  {
    heading: "paymentMethod",
    type: "TextCell",
    key: "paymentMethod",
    valueType: "string",
    formatFunction: formatTranslateFunc,
    formatLocalizePackage: "payment",
    headingLocalizePackage: "payment",
    sortable: true,
    // filtering would require enum as choises
    // filterable: true,
  },
  {
    heading: "paymentSessionId",
    type: "LongTextCell",
    key: "paymentSessionId",
    valueType: "string",
    headingLocalizePackage: "payment",
    filterable: true,
  },
  {
    heading: "reference",
    type: "TextCell",
    key: "reference",
    valueType: "string",
    headingLocalizePackage: "payment",
    filterable: true,
  },
  {
    heading: "details",
    type: "LongTextCellWithModalTooltip",
    key: "details",
    valueType: "string",
    headingLocalizePackage: "payment",
    filterable: true,
  },
  {
    heading: "createdAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
  },
  {
    heading: "expiresAt",
    type: "DateCell",
    key: "expiresAt",
    valueType: "datetime",
    headingLocalizePackage: "payment",
    filterable: true,
  },
];

function PaymentsPage() {
  const { t } = useTranslation("payment");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const toastRef = React.useContext(ToastContext);
  // const navigate = useNavigate();

  const [cols, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<PaymentSessionListRecord>(columns);

  //const maxRow = useMaximums(paymentsData);
  const [getPaginator, limit, offset] = usePagination("paymentsPagination", 50);

  const paymentsResponse = api.useGetPaymentSessionsByCompanyIdQuery<{
    data: GetPaymentSessionsByCompanyIdResp;
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(
    {
      companyId: selectedCompanyId || "",
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    { skip: !selectedCompanyId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    const err = getErrorResponse(paymentsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [paymentsResponse.error]);

  const data = paymentsResponse.data?.records || [];
  const totalCount = paymentsResponse.data?.totalCount || 0;

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-payments-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          paymentsResponse.refetch();
        }}
      />
      <Button
        intercomTarget="download-csv"
        buttonColor={ColorVariant.overlay}
        title={t("download", { ns: "common" })}
        hideMobile={true}
        icon={<DownloadCsvIcon />}
        onClick={() => {
          createCsvFile(data, "Payments", cols);
        }}
        disabled={data.length === 0}
      />
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "number" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      title={t("PaymentsIn")}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"payments-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={paymentsResponse.isLoading || paymentsResponse.isFetching || paymentsResponse.isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default PaymentsPage;
