import Page from "layout/Page";
import { useTranslation } from "react-i18next";
import PageTitle from "components/pageTitle/PageTitle";
import styles from "pages/apikeys/ApiKeysPage.module.scss";
import ApiKey from "pages/apikeys/ApiKey";
import NewKeyForm from "pages/apikeys/NewKeyForm";
import api from "api/api";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useModal } from "components/dialogue/ModalContext";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import Card from "components/cards/Card";

function ApiKeysPage() {
  const { t } = useTranslation();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const { showModal, closeModal } = useModal();
  // Get the API keys for the selected company
  const { data: apiKeys } = api.useGetKeysByCompanyQuery(selectedCompanyId || "", { skip: !selectedCompanyId });

  const handleNew = () => {
    showModal(<NewKeyForm onClose={closeModal} />);
  };

  const controls = <TableControlSection intercomTarget={"dashboard-controls-section"}></TableControlSection>;

  const pageTitle = (
    <PageTitle title={t("API Keys")}>
      <Button
        intercomTarget="dashboard-update-company-button"
        onClick={handleNew}
        buttonColor={ColorVariant.overlay}
        buttonPosition={ButtonPosition.right}
      >
        {t("New", { ns: "common" })}
      </Button>
    </PageTitle>
  );

  return (
    <Page head={pageTitle}>
      {controls}
      <div className={styles.keysWrapper}>
        {apiKeys?.map((key) => {
          return (
            <Card key={key.keyId}>
              <ApiKey {...key} />
            </Card>
          );
        })}
      </div>
    </Page>
  );
}

export default ApiKeysPage;
