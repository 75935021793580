// https://www.pluralsight.com/guides/using-d3.js-inside-a-react-app
import { useD3 } from "features/charts/useD3";
import { useEffect } from "react";
import { Selection } from "d3";
import ChartCanvas from "features/charts/chartCanvas";
import "features/charts/chart.scss";
import { BarPlot } from "features/charts/charts";
import { AnyObject } from "utils/types";
import classNames from "classnames"; // import styles from "features/channel/channel-page.module.scss";

// import styles from "features/channel/channel-page.module.scss";

type SimplerBarChartProps = {
  data: AnyObject[];
  dataKey: string;
  dashboard?: boolean;
  from: string;
  to: string;
  label?: string;
  isLoading?: boolean;
};

function SimpleBarChart({ data, dataKey, to, from, label }: SimplerBarChartProps) {
  let chart: ChartCanvas;
  let currentSize: [number | undefined, number | undefined] = [undefined, undefined];

  // Check and update the chart size if the navigation changes the container size
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navCheck = (container: Selection<HTMLDivElement, Record<string, never>, HTMLElement, any>) => {
    return () => {
      const boundingBox = container?.node()?.getBoundingClientRect();
      if (currentSize[0] !== boundingBox?.width || currentSize[1] !== boundingBox?.height) {
        chart.resizeChart();
        chart.draw();
        currentSize = [boundingBox?.width, boundingBox?.height];
      }
    };
  };

  // TODO: Change this so that we can update the data without redrawing the entire chart
  const ref = useD3(
    (container: Selection<HTMLDivElement, Record<string, never>, HTMLElement, unknown>) => {
      chart = new ChartCanvas(container, data, {
        from: new Date(from),
        to: new Date(to),
        yScaleKey: dataKey,
        rightYScaleKey: dataKey,
        rightYAxisKeys: [dataKey, dataKey],
        // noData: noData,
      });
      chart.plot(BarPlot, {
        id: dataKey,
        key: dataKey,
        legendLabel: label,
        barColor: "black",
        barHoverColor: "#9747FF",
        textColor: "black",
        labels: true,
        barGap: 0.1,
      });

      chart.draw();

      setInterval(navCheck(container), 200);
    },
    [data, data ? data[0].date : "", data ? data[data.length - 1].date : "", dataKey, label],
  );

  useEffect(() => {
    return () => {
      if (chart) {
        chart.removeResizeListener();
      }
    };
  }, [data]);

  return <div ref={ref} className={classNames("chart-ref")} />; // , { [styles.loading]: isLoading }
}

export default SimpleBarChart;
