import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate } from "react-router-dom";
import { Person20Regular as UserIcon, Save20Regular as SaveIcon } from "@fluentui/react-icons";
import { Form, Input, InputRow, Select } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import styles from "pages/users/UsersPage.module.scss";
import api, { InputError, UserRecordInternal } from "api/api";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import { SelectOption } from "components/forms/select/Select";

const languageOptions: SelectOption[] = [
  { label: "English", value: "en" },
  //{ label: "German", value: "de-DE" },
];

function UserDetailsModal() {
  const navigate = useNavigate();
  const { t: tu } = useTranslation("user");
  const { t: tv } = useTranslation("validation");
  const { t } = useTranslation("common");
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();

  const { data: user } = api.useGetSelfUserQuery();

  const [updateUser, { error: updateError, isLoading: loadingSave, isSuccess }] = api.useUpdateUserMutation();

  const [userState, setUserState] = useState({} as UserRecordInternal);

  useEffect(() => {
    const userChange: UserRecordInternal = {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      // phoneNumber: user?.phoneNumber || "",
      language: user?.language || "en",
    };
    setUserState(userChange || {});
  }, [user]);

  useEffect(() => {
    const err = getErrorResponse(updateError);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [updateError]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const handleFieldChange = (value: string, field: string) => {
    setUserState({ ...userState, [field]: value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!user?.userId) {
      console.error("No user id");
      return;
    }

    updateUser(userState);
  };

  return (
    <PopoutPageTemplate title={tu("UserDetails")} show={true} onClose={() => navigate(-1)} icon={<UserIcon />}>
      <div className={styles.modalContentWrapper}>
        {user && !user.firstName && !user.lastName && (
          <div className={styles.modalTopInfo}>
            <Note noteType={NoteType.info}>{tu("PleaseFillDetails")}</Note>
          </div>
        )}
        <Form intercomTarget={"user-details-form"} onSubmit={handleSubmit}>
          <InputRow verticalAlign="top">
            <Input
              label={tu("FirstName")}
              value={userState?.firstName || ""}
              type={"text"}
              errors={Tie("FirstName", ie, ["user"])}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange(e.target.value, "firstName")}
            />
            <Input
              label={tu("LastName")}
              value={userState?.lastName || ""}
              type={"text"}
              errors={Tie("LastName", ie, ["user"])}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange(e.target.value, "lastName")}
            />
          </InputRow>
          <Input
            label={tu("Email")}
            value={userState?.email || ""}
            required={true}
            disabled={true}
            type={"email"}
            autoComplete={"email"}
            aria-autocomplete={"list"}
            errors={Tie("Email", ie, ["user"])}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange(e.target.value, "email")}
          />
          {/*<InputRow verticalAlign="top">*/}
          {/*  <Input*/}
          {/*    label={tu("PhoneNumber")}*/}
          {/*    value={userState?.phoneNumber || ""}*/}
          {/*    type={"text"}*/}
          {/*    errors={Tie("PhoneNumber", ie, ["user"])}*/}
          {/*    onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange(e.target.value, "phoneNumber")}*/}
          {/*  />*/}
          {/*</InputRow>*/}
          <Select
            label={tu("Language")}
            errors={Tie("Language", ie, ["user"])}
            value={languageOptions.find((option) => option.value === userState.language)}
            options={languageOptions}
            onChange={(e) => handleFieldChange((e as { value: string }).value, "language")}
            intercomTarget={"user-details-language-select"}
          />

          <ButtonRow>
            <Button
              intercomTarget={"save-user-details-button"}
              buttonColor={ColorVariant.success}
              buttonPosition={ButtonPosition.fullWidth}
              type={"submit"}
              disabled={loadingSave}
              icon={loadingSave ? <Spinny /> : <SaveIcon />}
            >
              {t("Save changes")}
            </Button>
          </ButtonRow>
          {errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default UserDetailsModal;
