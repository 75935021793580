import Button from "components/buttons/Button";
import styles from "pages/shop/shopHeader.module.scss";
import {
  Cart20Filled as CartIcon,
  Home20Filled as HomeIcon,
  BuildingShop20Regular as ShopIcon,
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import { CompanyShopRecord } from "api/api.generated";

export default function ShopHeader(props: { cartId?: string; shopHandle: string; companyShop?: CompanyShopRecord }) {
  const navigate = useNavigate();
  return (
    <div className={styles.shopHeader}>
      <div className={styles.shopBanner}>
        <span>{props.companyShop?.displayName}</span>
      </div>
      <div className={styles.headerButtonsRight}>
        {(props.companyShop?.shopRedirectUrl || props.companyShop?.website) && (
          <Button
            onClick={() => {
              window.location.href = props.companyShop?.shopRedirectUrl || props.companyShop?.website || "";
            }}
            className={styles.headerButton}
            icon={<HomeIcon />}
          ></Button>
        )}
        <Button
          onClick={() => {
            navigate(routes.SHOP.replace(":shopHandle", props.shopHandle));
          }}
          className={styles.headerButton}
          icon={<ShopIcon />}
        ></Button>
        <Button
          onClick={() => {
            navigate(routes.SHOPPING_CART.replace(":shopHandle", props.shopHandle));
          }}
          disabled={!props.cartId}
          className={styles.headerButton}
          icon={<CartIcon />}
        ></Button>
      </div>
    </div>
  );
}
