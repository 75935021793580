import { components, OptionProps, SingleValueProps } from "react-select";
import api, { getPhoneExtensionsAsOptions, PhoneExtensionOption } from "api/api";
import { InputColorVaraint, Select } from "components/forms/forms";
import styles from "components/forms/PhoneInput/PhoneInput.module.scss";
import { SelectProps } from "components/forms/select/Select";
import { useSelector } from "react-redux";

type PhoneExtentionSelectProps = SelectProps & {
  options?: PhoneExtensionOption[];
  intercomTarget?: string;
};

export default function PhoneExtensionSelect(props: PhoneExtentionSelectProps) {
  api.useGetCountriesQuery();
  const phoneExtensionOptions = useSelector(getPhoneExtensionsAsOptions);

  const Option = (props: OptionProps) => {
    const option = props.data as PhoneExtensionOption;
    return (
      <div>
        <components.Option {...props}>
          <div className={styles.optionsContainer}>
            <div className={styles.flag}>{iso2ToFlagEmoji(option.iso2)}</div>
            <div>{`+${option.code}`}</div>
          </div>
        </components.Option>
      </div>
    );
  };

  const SingleValue = (props: SingleValueProps<unknown>) => {
    const option = props.data as PhoneExtensionOption;
    return (
      <components.SingleValue {...props}>
        <div className={styles.optionsContainer}>
          <div className={styles.flag}>{iso2ToFlagEmoji(option.iso2)}</div>
          <div>{`+${option.code}`}</div>
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div>
      <Select
        colorVariant={InputColorVaraint.primary}
        selectComponents={{ Option, SingleValue, DropdownIndicator: undefined }}
        {...props}
        options={props.options || phoneExtensionOptions}
      />
    </div>
  );
}

function iso2ToFlagEmoji(iso2: string): string {
  if (iso2.length !== 2) {
    return "";
  }

  const OFFSET = 127397; // Unicode offset for regional indicator symbols
  const codePoints = Array.from(iso2).map((char) => OFFSET + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
