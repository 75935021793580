import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import navReducer from "features/navigation/navSlice";
import timeIntervalReducer from "features/timeIntervalSelect/timeIntervalSlice";
import { merchantServicesApi } from "api/manualApi";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    navigation: navReducer,
    timeInterval: timeIntervalReducer,
    [merchantServicesApi.reducerPath]: merchantServicesApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(merchantServicesApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
