import { ColumnMetaData } from "features/table/types";
import NumericCell from "components/table/cells/numeric/NumericCell";
import BarCell from "components/table/cells/bar/BarCell";
import TextCell from "components/table/cells/text/TextCell";
import DurationCell from "components/table/cells/duration/DurationCell";
import BooleanCell from "components/table/cells/boolean/BooleanCell";
import DateCell from "components/table/cells/date/DateCell";
import EnumCell from "components/table/cells/enum/EnumCell";
import NumericDoubleCell from "components/table/cells/numeric/NumericDoubleCell";
import LongTextCell from "components/table/cells/longText/LongTextCell";
import LinkCell from "components/table/cells/link/LinkCell";
import MultiLinkCell from "components/table/cells/link/MultiLinkCell";
import classNames from "classnames";
import styles from "components/table/cells/cell.module.scss";

export default function DefaultCellRenderer<T>(
  row: T,
  rowIndex: number,
  column: ColumnMetaData<T>,
  columnIndex: number,
  totalsRow?: boolean,
  maxRow?: T,
): JSX.Element {
  const dataKey = column.key as keyof T;
  const dataKey2 = column.key2 as keyof T;
  const suffix = column.suffix as string;
  // const heading = column.heading;
  const percent = column.percent;

  switch (column.valueType) {
    case "string": {
      let text = row[dataKey] as string;
      if (column.formatFunction) {
        const formatOpts = { translationPackage: column.formatLocalizePackage || "common" };
        text = column.formatFunction(row, dataKey as string, formatOpts);
      }

      switch (column.type) {
        case "LongTextCell":
          return (
            <LongTextCell
              text={text}
              key={dataKey.toString() + rowIndex}
              copyText={text}
              totalCell={totalsRow}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "LongTextCellWithHoverTooltip":
          return (
            <LongTextCell
              text={text}
              key={dataKey.toString() + rowIndex}
              copyText={text}
              totalCell={totalsRow}
              hoverTooltip={true}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "LongTextCellWithModalTooltip":
          return (
            <LongTextCell
              text={text}
              key={dataKey.toString() + rowIndex}
              copyText={text}
              totalCell={totalsRow}
              modalTooltip={true}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "TextCell":
          return (
            <TextCell
              text={text}
              key={dataKey.toString() + rowIndex}
              totalCell={totalsRow}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "DurationCell":
          return (
            <DurationCell
              seconds={row[dataKey] as number}
              key={dataKey.toString() + rowIndex}
              totalCell={totalsRow}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "EnumCell": {
          let label: string | undefined;
          if (column.selectOptions) {
            label = column.selectOptions?.find((option) => option.value == (row[dataKey] as string))?.label;
          }

          return (
            <EnumCell
              value={(label || row[dataKey]) as string}
              key={dataKey.toString() + rowIndex + columnIndex}
              totalCell={totalsRow}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        }
      }
      break;
    }
    case "boolean":
      switch (column.type) {
        case "BooleanCell":
          return (
            <BooleanCell
              falseTitle={"Failure"}
              trueTitle={"Success"}
              value={row[dataKey] as boolean}
              key={dataKey.toString() + rowIndex + columnIndex}
              totalCell={totalsRow}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
      }
      break;
    case "date":
      return (
        <DateCell
          includeTime={false}
          value={row[dataKey] as Date}
          key={dataKey.toString() + rowIndex}
          totalCell={totalsRow}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    case "datetime":
      return (
        <DateCell
          includeTime={true}
          value={row[dataKey] as Date}
          key={dataKey.toString() + rowIndex}
          totalCell={totalsRow}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    case "duration":
      // For data that is already in seconds, just return the duration cell
      if (typeof row[dataKey] === "number") {
        return (
          <DurationCell
            seconds={row[dataKey] as number}
            key={dataKey.toString() + rowIndex}
            totalCell={totalsRow}
            className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
          />
        );
      } else if (new Date(row[dataKey] as string).getTime()) {
        // For duration where the data is in date format, get the difference from now in seconds
        const diff = new Date().getTime() - new Date(row[dataKey] as string).getTime();
        return (
          <DurationCell
            seconds={diff / 1000}
            suffix="ago"
            key={dataKey.toString() + rowIndex}
            totalCell={totalsRow}
            className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
          />
        );
      }
      // Otherwise, return empty
      return (
        <DurationCell
          seconds={NaN}
          key={dataKey.toString() + rowIndex}
          totalCell={totalsRow}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    case "link":
      return (
        <LinkCell
          text={column.linkText ? column.linkText : (row[dataKey] as string)}
          textOutside={column.linkTextOutside}
          link={
            ((column.linkBasePath ? column.linkBasePath : "") +
              row[dataKey] +
              (column.linkPathEnd ? column.linkPathEnd : "")) as string
          }
          icon={column.linkIcon}
          internal={column.linkInternal}
          disabled={column.linkDisabled}
          key={dataKey.toString() + rowIndex}
          totalCell={totalsRow}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    case "multilink":
      return (
        <MultiLinkCell
          cellData={row[dataKey] as string}
          links={column.multilinkData || []}
          key={dataKey.toString() + rowIndex}
          totalCell={totalsRow}
          rowData={row as object}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    case "number":
      switch (column.type) {
        case "NumericCell":
          return (
            <NumericCell
              current={row[dataKey] as number}
              key={dataKey.toString() + rowIndex + columnIndex}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "BarCell":
          return (
            <BarCell
              current={row[dataKey] as number}
              max={maxRow ? (maxRow[dataKey] as number) : 0}
              showPercent={percent}
              key={dataKey.toString() + rowIndex + columnIndex}
              suffix={suffix}
              className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
            />
          );
        case "NumericDoubleCell":
          return (
            <NumericDoubleCell
              topValue={row[dataKey] as number}
              bottomValue={row[dataKey2] as number}
              suffix={suffix as string}
              className={classNames(
                dataKey.toString(),
                { [styles.locked]: column.locked },
                styles[column.maxWidthPx || ""],
              )}
              key={dataKey.toString() + rowIndex + columnIndex}
              totalCell={totalsRow}
            />
          );
      }
      break;
    case "enum": {
      let label: string | undefined;
      if (column.selectOptions) {
        label = column.selectOptions?.find((option) => option.value == (row[dataKey] as string))?.label;
      }

      return (
        <EnumCell
          value={(label || row[dataKey]) as string}
          key={dataKey.toString() + rowIndex + columnIndex}
          totalCell={totalsRow}
          className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
        />
      );
    }
  }

  let text = row[dataKey] as string;
  if (column.formatFunction) {
    const formatOpts = { translationPackage: column.formatLocalizePackage || "common" };
    text = column.formatFunction(row, dataKey as string, formatOpts);
  }

  return (
    <TextCell
      text={text}
      key={(dataKey || "").toString() + rowIndex}
      copyText={row[dataKey] as string}
      className={classNames({ [styles.locked]: column.locked }, styles[column.maxWidthPx || ""])}
    />
  );
}
