import api, { Address, AddressRecord, CountryOption, getCountriesAsOptions, InputError } from "api/api";
import { FormToJson } from "api/apiUtils";
import {
  ArrowReset20Regular as CancelIcon,
  Edit20Regular as EditIcon,
  Save20Regular as SaveIcon,
} from "@fluentui/react-icons";
import { Form, Input, InputRow, Select } from "components/forms/forms";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toastCategory } from "features/toast/Toasts";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import ToastContext from "features/toast/context";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";

export interface AddressFormProps {
  companyId?: string;
  customerId?: string;
  addressRecord: AddressRecord;
}

function AddressForm(props: AddressFormProps) {
  const { t } = useTranslation("common");
  const { t: tv } = useTranslation("validation");
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>("");
  const toastRef = useContext(ToastContext);

  const [editing, setEditing] = useState(false);

  const [country, setCountry] = useState<CountryOption>();

  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  const [loading, setLoading] = useState(false);

  // updates

  const [
    updateCompanyAddress,
    { error: updateCompanyErrors, isLoading: loadingUpdateCompanyAddress, isSuccess: isSuccessUpdateCompanyAddress },
  ] = api.useUpdateCompanyAddressMutation();

  const [
    updateCustomerAddress,
    { error: updateCustomerErrors, isLoading: loadingUpdateCustomerAddress, isSuccess: isSuccessUpdateCustomerAddress },
  ] = api.useUpdateCustomerAddressMutation();

  // creates

  const [
    createCompanyAddress,
    { error: createCompanyErrors, isLoading: loadingCreateCompanyAddress, isSuccess: isSuccessCreateCompanyAddress },
  ] = api.useCreateCompanyAddressMutation();

  const [
    createCustomerAddress,
    { error: createCustomerErrors, isLoading: loadingCreateCustomerAddress, isSuccess: isSuccessCreateCustomerAddress },
  ] = api.useCreateCustomerAddressMutation();

  // success
  useEffect(() => {
    if (
      isSuccessUpdateCompanyAddress ||
      isSuccessUpdateCustomerAddress ||
      isSuccessCreateCompanyAddress ||
      isSuccessCreateCustomerAddress
    ) {
      toastRef?.current?.addToast(t("addressSuccessfullyUpdated"), toastCategory.success);
      setEditing(false);
    }
  }, [
    isSuccessUpdateCompanyAddress,
    isSuccessUpdateCustomerAddress,
    isSuccessCreateCompanyAddress,
    isSuccessCreateCustomerAddress,
  ]);

  // loading
  useEffect(() => {
    setLoading(
      loadingUpdateCompanyAddress ||
        loadingUpdateCustomerAddress ||
        loadingCreateCompanyAddress ||
        loadingCreateCustomerAddress,
    );
  }, [
    loadingUpdateCompanyAddress,
    loadingUpdateCustomerAddress,
    loadingCreateCompanyAddress,
    loadingCreateCustomerAddress,
  ]);

  // errors
  useEffect(() => {
    const err = getErrorResponse(
      updateCompanyErrors || updateCustomerErrors || createCompanyErrors || createCustomerErrors,
    );
    setErrMessage(err?.message || "");
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    } else {
      setInputErrors([]);
    }
  }, [updateCompanyErrors, updateCustomerErrors, createCompanyErrors, createCustomerErrors]);

  useEffect(() => {
    if (props.addressRecord) {
      setCountry(countryOptions.find((country) => country.value === props.addressRecord.countryIso3));
    }
  }, [props.addressRecord, countryOptions]);

  // submit
  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!editing) {
      return;
    }

    // Get all the data from the form
    const formJson = FormToJson<Address>(event);

    if (props.addressRecord.addressId) {
      // update
      if (props.companyId) {
        updateCompanyAddress({
          addressId: props.addressRecord.addressId,
          companyId: props.companyId,
          address: formJson,
        });
      } else if (props.customerId) {
        updateCustomerAddress({
          addressId: props.addressRecord.addressId,
          customerId: props.customerId,
          address: formJson,
        });
      } else {
        console.error("No company or customer id provided");
      }
    } else {
      // create
      if (props.companyId) {
        createCompanyAddress({
          companyId: props.companyId,
          address: formJson,
        });
      } else if (props.customerId) {
        createCustomerAddress({
          customerId: props.customerId,
          address: formJson,
        });
      } else {
        console.error("No company or customer id provided");
      }
    }
  }

  // formRef
  const formRef = useRef<HTMLFormElement>(null);

  function resetForm() {
    setEditing(false);
    setInputErrors([]);
    setErrMessage("");
    // change the selected country
    setCountry(
      countryOptions.find((country) => country.value === props.addressRecord?.countryIso3) || {
        value: "",
        label: "",
      },
    );
    // rerender the form to reset values based on the "company" object
    if (formRef.current) {
      formRef?.current?.reset();
    }
  }

  return (
    <Form onSubmit={submit} intercomTarget={"edit-address-form"} ref={formRef}>
      <InputRow verticalAlign="top">
        <Input
          label={t("StreetName")}
          name={"streetName"}
          type={"text"}
          defaultValue={props.addressRecord.streetName}
          errors={Tie("StreetName", ie, ["common"])}
          disabled={!editing}
        />
        <Input
          label={t("BuildingNumber")}
          name={"buildingNumber"}
          type={"text"}
          defaultValue={props.addressRecord.buildingNumber}
          errors={Tie("BuildingNumber", ie, ["common"])}
          disabled={!editing}
        />
      </InputRow>
      <InputRow verticalAlign="top">
        <Input
          label={t("TownName")}
          name={"townName"}
          type={"text"}
          defaultValue={props.addressRecord.townName}
          errors={Tie("TownName", ie, ["common"])}
          disabled={!editing}
        />
        <Input
          label={t("PostCode")}
          name={"postCode"}
          type={"text"}
          defaultValue={props.addressRecord.postCode}
          errors={Tie("PostCode", ie, ["common"])}
          disabled={!editing}
        />
      </InputRow>
      <Select
        intercomTarget={"select-countries"}
        label={t("Country") + " *"}
        name={"countryIso3"}
        options={countryOptions}
        value={country}
        onChange={(newValue: unknown) => {
          setCountry(countryOptions.find((country) => country.value === (newValue as CountryOption).value));
        }}
        errors={Tie("CountryISO3", ie, ["common"])}
        isDisabled={!editing}
      />

      {errMessage && (
        <Note noteType={NoteType.error} title={t("Error")}>
          {tv(errMessage)}
        </Note>
      )}
      {!editing && (
        <Button
          intercomTarget="address-edit-button"
          type={"button"}
          icon={<EditIcon />}
          onClick={() => setEditing(true)}
          buttonColor={ColorVariant.primary}
        >
          {t("Edit")}
        </Button>
      )}
      {editing && (
        <ButtonRow>
          <Button
            intercomTarget="address-save-edit-button"
            type={"button"}
            onClick={resetForm}
            buttonColor={ColorVariant.ghost}
            disabled={loading}
            icon={loading ? <Spinny /> : <CancelIcon />}
          >
            {t("Cancel")}
          </Button>
          <Button
            intercomTarget="address-save-edit-button"
            type={"submit"}
            buttonPosition={ButtonPosition.right}
            buttonColor={ColorVariant.success}
            disabled={loading}
            icon={loading ? <Spinny /> : <SaveIcon />}
          >
            {t("Save")}
          </Button>
        </ButtonRow>
      )}
    </Form>
  );
}

export default AddressForm;
