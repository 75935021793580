import styles from "components/dialogue/Dialogue.module.scss";
import { FC, ReactNode } from "react";

interface ModalProps {
  closeModal: () => void;
  children: ReactNode;
}

const Modal: FC<ModalProps> = ({ closeModal, children }) => {
  return (
    <div className={styles.dialogueWrapper}>
      <div className={styles.backdrop} onClick={() => closeModal()} />
      <div className={styles.dialogueContainer}>{children}</div>
    </div>
  );
};

export default Modal;
