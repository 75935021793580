import styles from "pages/superAdmin/SuperAdminPage.module.scss";
import { useTranslation } from "react-i18next";
import api, { Flag } from "api/api";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import Button, { ButtonRow } from "components/buttons/Button";
import { formatDistanceToNow } from "date-fns";

export default function AdminSettingsPanel() {
  const { t } = useTranslation();

  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);

  const [updateSystemSettingsFlag, { isLoading: isUpdating, error: updateError }] =
    api.useUpdateSystemSettingsFlagMutation();

  const handleUpdateFlag = async (flag: Flag, value: boolean) => {
    try {
      const response = await updateSystemSettingsFlag({
        flag,
        systemSettingsFlagInternal: { flag, value },
      }).unwrap();
      refetch();
      console.log("Flag updated successfully", response);
    } catch (error) {
      console.error("Failed to update flag", error);
    }
  };

  const {
    data = [],
    isLoading,
    isFetching,
    error,
    refetch,
  } = api.useGetAllSystemSettingsFlagsQuery(undefined, {
    skip: !hasEdenAdminPermission,
    pollingInterval: 60000,
    refetchOnMountOrArgChange: true,
  });

  // const controls = (
  //   <TableControlSection intercomTarget={"dashboard-controls-section"}>
  //     <TableControlsButtonGroup intercomTarget={"dashboard-quick-actions"}>
  //       <Button intercomTarget="dashboard-update-company-button">{t("Update Company")}</Button>
  //     </TableControlsButtonGroup>
  //   </TableControlSection>
  // );

  const flagExists = (flag: string) => data?.find((flg) => flg.flag === flag);

  const getFlag = (flag: string) => data?.find((flg) => flg.flag === flag);

  return (
    <div className={styles.systemSettingsFlagsWrapper}>
      <h3>{t("System Settings Flags")}</h3>
      {isLoading || isFetching ? (
        <p>{t("Loading...")}</p>
      ) : error ? (
        <p>{t("Error loading flags")}</p>
      ) : data?.length === 0 ? (
        <p>{t("No flags found")}</p>
      ) : (
        <>&nbsp;</>
      )}
      {isUpdating && <p>{t("Updating...")}</p>}
      {updateError && <p>{t("Error updating flag")}</p>}
      <br />
      <ButtonRow className={styles.flagsButtonRow}>
        <div>
          {flagExists(Flag.IpLookupPrimaryServiceDisabled)
            ? t("Disabled for") +
              " " +
              formatDistanceToNow(Date.parse(getFlag(Flag.IpLookupPrimaryServiceDisabled)!.expiration!)) +
              ": "
            : ""}
          <Button
            onClick={() =>
              handleUpdateFlag(Flag.IpLookupPrimaryServiceDisabled, !flagExists(Flag.IpLookupPrimaryServiceDisabled))
            }
          >
            {flagExists(Flag.IpLookupPrimaryServiceDisabled) ? t("Enable IP lookup") : t("Disable IP lookup")}
          </Button>
        </div>
        <div>
          {flagExists(Flag.EmailLookupPrimaryServiceDisabled)
            ? t("Disabled for") +
              " " +
              formatDistanceToNow(Date.parse(getFlag(Flag.EmailLookupPrimaryServiceDisabled)!.expiration!)) +
              ": "
            : ""}
          <Button
            onClick={() =>
              handleUpdateFlag(
                Flag.EmailLookupPrimaryServiceDisabled,
                !flagExists(Flag.EmailLookupPrimaryServiceDisabled),
              )
            }
          >
            {flagExists(Flag.EmailLookupPrimaryServiceDisabled)
              ? t("Enable Email lookup (primary)")
              : t("Disable Email lookup (primary)")}
          </Button>
        </div>
        <div>
          {flagExists(Flag.EmailLookupSecondaryServiceDisabled)
            ? t("Disabled for") +
              " " +
              formatDistanceToNow(Date.parse(getFlag(Flag.EmailLookupSecondaryServiceDisabled)!.expiration!)) +
              ": "
            : ""}
          <Button
            onClick={() =>
              handleUpdateFlag(
                Flag.EmailLookupSecondaryServiceDisabled,
                !flagExists(Flag.EmailLookupSecondaryServiceDisabled),
              )
            }
          >
            {flagExists(Flag.EmailLookupSecondaryServiceDisabled)
              ? t("Enable Email lookup (secondary)")
              : t("Disable Email lookup (secondary)")}
          </Button>
        </div>
      </ButtonRow>
      <br />
      <ButtonRow>
        <div>
          {flagExists(Flag.DemoshopComplianceEnabled)
            ? t("Enabled for") +
              " " +
              formatDistanceToNow(Date.parse(getFlag(Flag.DemoshopComplianceEnabled)!.expiration!)) +
              ": "
            : ""}
          <Button
            onClick={() =>
              handleUpdateFlag(Flag.DemoshopComplianceEnabled, !flagExists(Flag.DemoshopComplianceEnabled))
            }
          >
            {flagExists(Flag.DemoshopComplianceEnabled)
              ? t("Disable Demoshop Compliance")
              : t("Enable Demoshop Compliance")}
          </Button>
        </div>
        <div>
          {flagExists(Flag.DemoshopOpenSanctionsEnabled)
            ? t("Enabled for") +
              " " +
              formatDistanceToNow(Date.parse(getFlag(Flag.DemoshopOpenSanctionsEnabled)!.expiration!)) +
              ": "
            : ""}
          <Button
            onClick={() =>
              handleUpdateFlag(Flag.DemoshopOpenSanctionsEnabled, !flagExists(Flag.DemoshopOpenSanctionsEnabled))
            }
          >
            {flagExists(Flag.DemoshopOpenSanctionsEnabled)
              ? t("Disable Demoshop Open Sanctions")
              : t("Enable Demoshop Open Sanctions")}
          </Button>
        </div>
      </ButtonRow>
    </div>
  );
}
