const financeLevel1Role = [
  "companyRead",
  "companyPaymentsRead",
  "companyRefundsRead",
  "companyRefundsWriteRead",
  "companyBankAccountsRead",
  "companyBankAccountsWriteRead",
  "companyBankTransfersRead",
  "companyBankTransfersWriteRead",
];

const analystRole = ["companyRead", "companyPaymentsRead", "companyRefundsRead"];

const developerLevel1Role = [
  "companyRead",
  "companyPaymentsRead",
  "companyPaymentsWriteRead",
  "companyRefundsRead",
  "companyRefundsWriteRead",
  "apiKeysRead",
  "apiKeysWriteRead",
];

const developerLevel2Role = [
  "companyRead",
  "companyPaymentsRead",
  "companyPaymentsWriteRead",
  "companyRefundsRead",
  "companyRefundsWriteRead",
  "apiKeysRead",
  "apiKeysWriteRead",
  "companyUserRolesRead",
  "companyUserRolesWriteRead",
];

const fullAdminRole = [
  "companyAdmin",
  "companyRead",
  "companyWriteRead",
  "companyUsersRead",
  "companyUsersWriteRead",
  "companyBankAccountsRead",
  "companyBankAccountsWriteRead",
  "companyBankTransfersRead",
  "companyBankTransfersWriteRead",
  "companyBankTransfersApprove",
  "companyBankTransfersCancel",
  "companyPaymentsRead",
  "companyPaymentsWriteRead",
  "companyRefundsRead",
  "companyRefundsWriteRead",
  "apiKeysRead",
  "apiKeysWriteRead",
  "companyUserRolesRead",
  "companyUserRolesWriteRead",
  "companyCustomersRead",
];

export const UserRoleList = [
  {
    name: "analyst",
    permissions: analystRole,
    description: "Basic read permissions for analysts. Can view payments and refunds",
  },
  {
    name: "financeLevel1",
    permissions: financeLevel1Role,
    description: "Basic read permissions for finance users. Can view payments, refunds, and bank accounts.",
  },
  {
    name: "developerLevel1",
    permissions: developerLevel1Role,
    description: "Developer with basic read and write permissions for payments, refunds, and API keys.",
  },
  {
    name: "developerLevel2",
    permissions: developerLevel2Role,
    description: "Developer with additional permissions to manage user roles.",
  },
  {
    name: "fullAdmin",
    permissions: fullAdminRole,
    description: "Full admin with all permissions for the company.",
  },
];

// // API key roles
// // --------------
// const integrationKeyRole = [
//   "companyRead",
//   "companyPaymentsRead",
//   "companyPaymentsWriteRead",
//   "companyRefundsRead",
//   "companyRefundsWriteRead",
// ];
//
// const limitedIntegrationKeyRole = ["companyRead", "companyPaymentsRead", "companyPaymentsWriteRead"];

// // API key roles explanations and names for use in the UI.
// const apiKeyRoleList = [
//   {
//     name: "integrationKey",
//     permissions: integrationKeyRole,
//     description: "Basic permissions for Mion payments integrations. Create new payment sessions and refunds.",
//   },
//   {
//     name: "limitedIntegrationKey",
//     permissions: limitedIntegrationKeyRole,
//     description: "Basic permissions for Mion payments integrations. Create new payment sessions.",
//   },
// ];
