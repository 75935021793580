import api, { InputError } from "api/api";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { Form, Input } from "components/forms/forms";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import Note, { NoteType } from "features/note/Note";
import { NewApiKey } from "pages/apikeys/ApiKey";

export default function NewKeyForm(props: { onClose: () => void }) {
  const { t } = useTranslation(["apiKeys", "common", "validation"]);
  const newNameRef = useRef<HTMLInputElement>(null);
  const [createApiKey, { data: response, error, isLoading }] = api.useCreateKeyMutation();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>();

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createApiKey({
      companyId: selectedCompanyId || "",
      newKey: { name: newNameRef.current?.value || "" },
    });
  };

  useEffect(() => {
    const err = getErrorResponse(error);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    } else {
      setInputErrors([]);
    }
  }, [error]);

  return (
    <div>
      {!response && (
        <Form onSubmit={submit} intercomTarget={"new-key-form"}>
          <Input label={t("Name")} ref={newNameRef} errors={Tie("Name", ie, ["user"])} />
          <ButtonRow>
            <Button type={"button"} intercomTarget={"cancel-button"} onClick={props.onClose}>
              {t("Cancel", { ns: "common" })}
            </Button>
            <Button
              type={"submit"}
              intercomTarget={"create-new-key"}
              // buttonPosition={ButtonPosition.fullWidth}
              buttonColor={ColorVariant.success}
              disabled={isLoading}
            >
              {t("Create", { ns: "common" })}
            </Button>
          </ButtonRow>
          {errMessage && <Note noteType={NoteType.error}>{t(errMessage, { ns: "validation" })}</Note>}
        </Form>
      )}

      {response && (
        <NewApiKey apiKey={response?.apiKey} name={response?.name} keyId={response?.keyId} onClose={props.onClose} />
      )}
    </div>
  );
}
