import i18n from "i18n";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { ErrorResponse, InputError } from "api/api";
//
// export type ErrorResponse = {
//   message: string;
//   inputErrors?: InputError[];
//   paramErrors?: ParamError[];
// };
//
// export type InputError = {
//   field: string;
//   message: string;
//   tag: string;
//   namespace?: string;
//   structNamespace?: string;
//   structField?: string;
//   actualTag?: string;
//   kind?: string;
//   type?: string;
//   param?: string;
// };
//
// export type ParamError = {
//   param: string;
//   message: string;
// };

export function getInputError(error: ErrorResponse, fieldName: string): InputError[] | undefined {
  if (!error.inputErrors) {
    return undefined;
  }

  return error.inputErrors.filter((e) => e.field === fieldName);
}

// Create a combined Tie function that can be used in the components
// to translate the error messages.
// it accepts a multiple structs of {
//     fieldName: string;
//     errors?: InputError[];
//     namespaces?: string[];
//   }
export function Ties(
  fieldNames: string[],
  errors?: InputError[],
  namespaces?: string[],
  structNamespaces?: string[],
): string[] | undefined {
  // Loop through all the fieldbames calling Tie for each field
  //  then create a flat array of all the error messages. If there are none return a single undefined.
  const errorMessages = fieldNames.map((fieldName) => Tie(fieldName, errors, namespaces, structNamespaces));
  const flatErrors = errorMessages.map((em) => em ?? []).flat();
  return flatErrors.length ? flatErrors : undefined;
}

// Tie (Translation Input Error) is a helper function to translate input errors.
export function Tie(
  fieldName: string,
  errors?: InputError[],
  namespaces?: string[],
  structNamespaces?: string[],
): string[] | undefined {
  const nsp = namespaces?.length ? ["validation", ...namespaces] : ["validation"];
  if (!errors) {
    return undefined;
  }

  let ie = errors.filter((e) => e.field === fieldName);

  // structNamespaces is used to differentiate between fields with same name
  // if a field can can be of different namespaces (like create and edit), add
  // both namespaces to the structNamespaces array
  if (structNamespaces?.length) {
    ie = ie.filter((e) => structNamespaces.includes(e.structNamespace || ""));
  }

  if (!ie.length) {
    return undefined;
  }

  return ie?.map((e) => {
    let key = e.tag;
    // if the tag eguals min og max, we add the type to the tag with dot notation
    if ((e.tag === "min" || e.tag === "max") && e.type === "string") {
      key = `${e.type}.${e.tag}`;
    }

    // If the tag is iban we add the tag and message to the tag but
    //   remove "iban: " since : is a special character in i18next
    if (e.tag === "iban") {
      const message = e?.message?.replace("iban: ", "");
      key = `${e.tag}.${message}`;
    }

    // If the tag is bic we add the tag and message to the tag but
    //   remove "bic: " since : is a special character in i18next
    if (e.tag === "bic") {
      const message = e?.message?.replace("bic: ", "");
      key = `${e.tag}.${message}`;
    }

    return i18n.t(key || "", { ns: nsp, param: e.param, field: `$t(${e?.field})` });
  });
}

export function getErrorResponse(error: FetchBaseQueryError | SerializedError | undefined): ErrorResponse | undefined {
  if (error && "status" in error) {
    // This is a FetchBaseQueryError
    const fetchError = error as FetchBaseQueryError;
    if (fetchError.data && typeof fetchError.data === "object") {
      return fetchError.data as ErrorResponse;
    }

    if (fetchError.data && typeof fetchError.data === "string") {
      return { message: fetchError.data } as ErrorResponse;
    }
  } else if (error && "message" in error) {
    // Handle SerializedError if needed
    // ...
  }
  return undefined;
}
