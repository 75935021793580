import {
  Options20Regular as OptionsIcon,
  ArrowSync20Regular as RefreshIcon,
  ArrowDownload20Regular as DownloadCsvIcon,
} from "@fluentui/react-icons";
import { RefundSessionListRecord } from "api/api.generated";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { usePagination } from "components/table/pagination/usePagination";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import { formatAmountFunc } from "features/table/columnFormatFunctions";
import { useTableColumns } from "features/table/tableColumnHooks";
import { useState } from "react";
import Button, { ColorVariant } from "components/buttons/Button";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { createCsvFile } from "utils/JsonTableToCsv";

const columns: ColumnMetaData<RefundSessionListRecord>[] = [
  {
    heading: "originalPaymentSession",
    type: "TextCell",
    key: "paymentSessionId",
    valueType: "string",
    headingLocalizePackage: "payment",
    sortable: false,
    filterable: true,
  },
  {
    heading: "amount",
    type: "TextCell",
    key: "amountFloat",
    valueType: "currency",
    formatFunction: formatAmountFunc,
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
    // filterHelpText: "amountFloatFilterHelpText",
    filterFormatFunc: (parameter) => {
      // assume currency is always divided by 100 subunits!
      // user sets amount as eur, convert to cents for filtering
      return ((parameter as number) * 100).toString();
    },
  },
  {
    heading: "customer",
    type: "TextCell",
    key: "refundCustomerFullName",
    valueType: "string",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
  },
  {
    heading: "createdAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "payment",
    sortable: true,
    filterable: true,
  },
];

function RefundsPage() {
  const { t } = useTranslation("payment");
  // const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  //const toastRef = React.useContext(ToastContext);
  // const navigate = useNavigate();

  const [cols, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<RefundSessionListRecord>(columns);

  //const maxRow = useMaximums(refundsData);
  const [getPaginator, limit, offset] = usePagination("refundsPagination", 50);

  // TODO remove log
  console.log(sortBy, sortDirection, filterJSON, limit, offset);
  const totalCount = 0; // TODO remove
  const data: RefundSessionListRecord[] = [];

  /*
  useEffect(() => {
    const err = getErrorResponse(refundsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [refundsResponse.error]);

  const data = refundsResponse.data?.records || [];
  const totalCount = refundsResponse.data?.totalCount || 0;
  */

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-refunds-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          //refundsResponse.refetch();
        }}
      />
      <Button
        intercomTarget="download-csv"
        buttonColor={ColorVariant.overlay}
        title={t("download", { ns: "common" })}
        hideMobile={true}
        icon={<DownloadCsvIcon />}
        onClick={() => {
          createCsvFile(data, "Refunds", cols);
        }}
        disabled={data.length === 0}
      />
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "number" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      title={t("Refunds")}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"refunds-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={false}
        // isLoading={refundsResponse.isLoading || refundsResponse.isFetching || refundsResponse.isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default RefundsPage;
