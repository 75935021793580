import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConfirmationProvider } from "components/dialogue/ConfirmContext";
import App from "App";
import { store } from "store/store";
import "i18n";
import "styles/main.scss";
import { ModalProvider } from "components/dialogue/ModalContext";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { onLCP, onINP, onCLS } from "web-vitals/attribution";

const resolveBasename = () => {
  const path = window.location.pathname.split("/");
  const topLevelPath = path.length > 1 && path[1];

  return topLevelPath === "merchantServices" ? topLevelPath : "";
};

const basename = resolveBasename();

const appContainer = document.getElementById("root");
if (!appContainer) {
  throw new Error("No root element");
}

polyfillCountryFlagEmojis();

const root = createRoot(appContainer);
root.render(
  <StrictMode>
    <BrowserRouter basename={basename}>
      <Provider store={store}>
        <ConfirmationProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </ConfirmationProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// getFID(onPerfEntry);
// getFCP(onPerfEntry);
// getTTFB(onPerfEntry);
onCLS(console.log);
onINP(console.log);
onLCP(console.log);
