import { Link16Regular as LinkIcon } from "@fluentui/react-icons";
import classNames from "classnames";
import { ColorVariant, ExternalLinkButton, LinkButton, SizeVariant } from "components/buttons/Button";
import styles from "components/table/cells/cell.module.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { userEvents } from "utils/userEvents";

export type MultiLinkProps = {
  basePath: string;
  pathEnd?: string;
  text: string;
  textOutside?: boolean;
  icon?: JSX.Element;
  color?: ColorVariant;
  internal?: boolean;
  disabled?: boolean;
  localizePackage?: string;
  disabledFunc?: (row: object | undefined) => boolean;
  action?: (value: string) => void;
};

interface MultiLinkCell {
  cellData: string;
  links: MultiLinkProps[];
  className?: string;
  totalCell?: boolean;
  rowData?: object;
}

function MultiLinkCell(props: MultiLinkCell) {
  const { track } = userEvents();
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className={classNames(styles.cell, styles.numericCell, styles.linkCell, props.className)}>
      {!props.totalCell && (
        <div className={classNames(styles.current, styles.text, styles.link, styles.linkSingleLine)}>
          {(props.links || []).map((link, index) => (
            <span key={("multilink-" + index) as string}>
              {link && !link.internal && (
                <ExternalLinkButton
                  // Link to external site
                  href={link.basePath + props.cellData + (link.pathEnd || "")}
                  target="_blank"
                  onClick={() => {
                    track("Link Cell Clicked", { href: link.basePath + props.cellData });
                  }}
                  buttonSize={SizeVariant.tiny}
                  buttonColor={link.color ? link.color : ColorVariant.primary}
                  icon={link.icon ? link.icon : <LinkIcon />}
                  intercomTarget={"link-cell-button"}
                  disabled={link.disabledFunc ? link.disabledFunc(props.rowData) : link.disabled}
                >
                  {link.textOutside ? "" : t(link.text, { ns: link.localizePackage })}
                </ExternalLinkButton>
              )}
              {link && link.internal && (
                <LinkButton
                  // Link to internal page
                  to={link.action ? "#" : link.basePath + props.cellData + (link.pathEnd || "")}
                  // Link to perform action
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    if (link.action) {
                      event.preventDefault();
                      link.action(props.cellData);
                    }
                  }}
                  state={{ background: location?.pathname || "/" }}
                  buttonSize={SizeVariant.tiny}
                  buttonColor={link.color ? link.color : ColorVariant.success}
                  icon={link.icon ? link.icon : <LinkIcon />}
                  intercomTarget={"link-cell-button-internal"}
                  disabled={link.disabledFunc ? link.disabledFunc(props.rowData) : link.disabled}
                >
                  {link.textOutside ? "" : t(link.text, { ns: link.localizePackage })}
                </LinkButton>
              )}
              <div>{link?.textOutside ? t(link.text, { ns: link.localizePackage }) : ""}</div>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiLinkCell;
