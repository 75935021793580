import styles from "components/dialogue/Dialogue.module.scss";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

type Props = {
  isOpen: boolean;
  title: string;
  message: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onClosed: (confirmed: boolean) => void;
};

const ConfirmDialogue: React.FC<Props> = ({ isOpen, title, message, confirmLabel, cancelLabel, onClosed }: Props) => {
  const { t } = useTranslation();

  if (!isOpen) return null;
  return (
    <div className={styles.dialogueWrapper}>
      <div className={styles.backdrop} onClick={() => onClosed(false)} />
      <div className={styles.dialogueContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonRow}>
          <Button
            autoFocus={true}
            intercomTarget={"cancel-button"}
            buttonColor={ColorVariant.ghost}
            buttonPosition={ButtonPosition.fullWidth}
            onClick={() => onClosed(false)}
          >
            {cancelLabel ?? t("No")}
          </Button>
          <Button
            intercomTarget={"confirm-button"}
            buttonColor={ColorVariant.success}
            buttonPosition={ButtonPosition.fullWidth}
            onClick={() => onClosed(true)}
          >
            {confirmLabel ?? t("Yes")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialogue;
